import React, { useEffect, useRef, useState } from "react";
import Hero from "./__component/Hero";
import PlanForm from "./__component/PlanForm";
import PrintComponent from "../PrintTab";
import Breadcrumb from "../../Components/label/BreadCrumb";
import Spinner from "../../Components/Spinner";
import { fetchClientInfo, fetchCompany } from "../../services/fetchServices";
import { CompanyDetail } from "../../types/AuthData";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { Client } from "../../store/slices/clientSlice";
import { useParams } from "react-router-dom";

const ParticipantChecklist = () => {
  const { uid } = useParams();
  const [clientDetail, setClientDetail] = useState<Client | null>(null);
  const [loadingClient, setLoadingClient] = useState<boolean>(true);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [companyOne, setCompanyOne] = useState<
    CompanyDetail | null | undefined
  >(null);

  useEffect(() => {
    fetchClientData();
    handleCompanyFetch();
  }, [uid])

  const handleCompanyFetch = async () => {
    // setLoading(true);
    if (user?.companyId) {
      const data = await fetchCompany(user?.companyId);
      setCompanyOne(data);
    }
    // setLoading(false);
  };
  const fetchClientData = async () => {
    const data = await fetchClientInfo(uid);
    setClientDetail(data);
    setLoadingClient(false);
  };
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Breadcrumb />
      {loadingClient && (
        <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="relative w-auto max-w-lg mx-auto">
            <div
              className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
            >
              <Spinner color="#071952" />
            </div>
          </div>

          <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
        </div>
      )}
      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print this Document"
        documentTitle="Participant Checklist"
      />
      <div ref={componentRef}
        className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
      >
        <Hero clientDetail={clientDetail} companyOne={companyOne} />
        <PlanForm />
      </div>
    </div>
  );
};

export default ParticipantChecklist;
