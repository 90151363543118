import React from "react";

const SafetyCheckliste = () => {
  return (
    <div className="mx-auto my-10">
      <p className="font-semibold text-[25px] text-black">
        4.	Step 2: Safety Checklist
      </p>

      <div className="overflow-x-auto border my-4 ">

        <table className="min-w-full  divide-y-2 divide-gray-200 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right">
            <tr className="divide-x-2 ">
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Risk</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Risk to person or others</th>
              <th className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">Description</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr className="divide-x-2 bg-[#0080809d] py-6">
              <td className=" px-4  font-medium text-gray-900">
                Risk Area
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2">Risk (tick all that apply)</td>
                <td className="px-2">Risk Category(if unmanaged)</td>
              </td>
              <td className=" px-4  font-medium text-gray-900">
                Provide a brief description of the risk e.g. ‘Wendy can trip on uneven surfaces and stairs if walking unaided’
              </td>
            </tr>
            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">1.&nbsp;Relationship</td>
              <td> </td>
              <td> </td>
            </tr>

            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Unsafe sex / non-consensual touching
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Risk of violence (including domestic)
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Risk of abuse, exploitation or neglect
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Social isolation/limited informal supports
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Stranger danger
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>

            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">2.&nbsp;Lifestyle risks</td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Drugs, alcohol, medication misuse
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Smoking / vaping
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Illegal behaviour
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Hoarding
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Homelessness
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Self-neglect
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Anti-social peers
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>

            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">3.&nbsp;Behaviours of concern</td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Suicide ideation / self-harm
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Wandering
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Assault, physical or verbal
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Intimidation, physical or verbal
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Property damage
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Harm to animals
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Fire lighting
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Exploiting others
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>

            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">4.&nbsp;Eating and drinking</td>
              <td> </td>
              <td> </td>
            </tr>

            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Dysphagia or choking
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Food allergies
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Food refusal
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Dehydration
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Alertness
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                PICA or other behaviour
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>

            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">5.&nbsp;Medical and health</td>
              <td> </td>
              <td> </td>
            </tr>

            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Fractures, cuts, bruising
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Seizures
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Respiratory conditions
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Allergies
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Skin conditions
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Endocrine conditions
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Prone to infectious diseases
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Recurrent infections
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Diabetes
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Sleep disorders
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Constipation
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Incontinence
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Mental health
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Dementia
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Weight
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Tooth and gum conditions
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Night checks needed
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Medication refusal
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Medical appointment refusal
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Refusal to following medical advice
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>

            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">6.&nbsp;Personal care risks</td>
              <td> </td>
              <td> </td>
            </tr>

            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Feeding
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Toileting
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Showering/ bathing
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Dental hygiene
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Shaving
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Hair and personal grooming
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Dressing
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>

            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">7.&nbsp;Mobility&nbsp;and&nbsp;movement</td>
              <td> </td>
              <td> </td>
            </tr>

            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Mobility risks
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Transfer risks
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Vehicle access / transfers (mobility)
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Moving in bed / transfers to bed
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Startle Reflex
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Panic
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Grabbing, holding
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Sudden movements
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Falling, tripping, bumping
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>

            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">8.&nbsp;Money&nbsp;and&nbsp;property</td>
              <td> </td>
              <td> </td>
            </tr>

            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Budgeting and managing money
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Vulnerable to exploitation
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Giving away/losing money or property
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Tries to borrow money
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Impulsive spending
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Gambling
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>

            <tr className="font-semibold bg-[#dcdcdca9] text-[16px] text-black">
              <td className="px-2">9.&nbsp;Hazards&nbsp;and&nbsp;environmental</td>
              <td> </td>
              <td> </td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Electricity
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Sharps/ knives
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Poisons
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Water/ bathing / swimming
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Sun exposure
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Evacuation
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Traffic, roads
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Travel - car/van/public transport
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
            <tr className="divide-x-2 py-6">
              <td className=" px-4  font-medium text-gray-900">
                Other
              </td>
              <td className=" px-4  divide-x-2 divide-gray-200 font-medium text-gray-900">
                <td className="px-2"><input type="checkbox" name="" id="" /></td>
                <td className="px-2 w-[100%]" contentEditable> </td>
              </td>
              <td className=" px-4  font-medium text-gray-900" contentEditable></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SafetyCheckliste;
