import React from 'react'
import AttachmentForm from './AttachmentForm'
import { Schedule } from '../../../store/slices/ClientSlice/clientSupport'
interface Props {
  clientSupportSchedule: Schedule[]
}

const Attachment = ({ clientSupportSchedule }: Props) => {
  return (
    <div>
      <div className="mt-5">
        <strong>Attachment – Schedule of supports</strong>
        <AttachmentForm clientSupportSchedule={clientSupportSchedule} />
      </div>
    </div>
  )
}

export default Attachment