import React from "react";
import { ShiftReport } from "../../../../../store/slices/StaffSlices/staffReportSlice";
interface Props {
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    shiftReportDetail: ShiftReport
}

const EditFormPartA = ({ handleInputChange, shiftReportDetail }: Props) => {
    return (
        <table className="divide-y-2 divide-gray-300 bg-white text-sm rounded-lg border w-full border-gray-300 mb-6">
            <tbody className="divide-y divide-gray-200 w-full">
                <tr className="w-full">
                    <td className="px-4 py-2 font-medium text-red-600" colSpan={2}>
                        URGENT MATTER ALERTS
                    </td>
                </tr>
                <tr className="w-full">
                    <td className="px-4 py-2 w-full" colSpan={2}>
                        <textarea
                            name="urgentMatters"
                            value={shiftReportDetail.urgentMatters}
                            onChange={handleInputChange}
                            className="border w-full p-2 focus:outline-none resize-none"
                        />
                    </td>
                </tr>
                <tr className="divide-x-2">
                    <td className="md:whitespace-nowrap px-4  font-medium text-gray-900">
                        Medications Given:
                    </td>
                    <td className="md:whitespace-nowrap px-4  text-gray-700 w-full">
                        {/* <input
                            type="text"
                            name="medicationGiven"
                            value={shiftReportDetail.medicationGiven}
                            onChange={handleInputChange}
                            className="w-full outline-none h-10 bg-transparent"
                        /> */}

                        <textarea
                            name="medicationGiven"
                            value={shiftReportDetail.medicationGiven}
                            onChange={handleInputChange}
                            className="w-full outline-none resize-none"
                            style={{ height: '100%' }} // Ensure adequate height
                        />
                    </td>
                </tr>
                <tr className="divide-x-2">
                    <td className="md:whitespace-nowrap px-4  font-medium text-gray-900">
                        Medications Signed For:
                    </td>
                    <td className="md:whitespace-nowrap px-4  text-gray-700 w-full">
                        <input
                            type="text"
                            name="medicationSigned"
                            value={shiftReportDetail.medicationSigned}
                            onChange={handleInputChange}
                            className="w-full outline-none h-10 bg-transparent"
                        />
                    </td>
                </tr>
                <tr className="divide-x-2">
                    <td className="md:whitespace-nowrap px-4  font-medium text-gray-900">
                        Medications Available for the Next 30:
                    </td>
                    <td className="md:whitespace-nowrap px-4  text-gray-700 w-full">
                        <input
                            type="text"
                            name="medicationAvailable"
                            value={shiftReportDetail.medicationAvailable}
                            onChange={handleInputChange}
                            className="w-full outline-none h-10 bg-transparent"
                        />
                    </td>
                </tr>
                <tr className="divide-x-2 h-8">
                    <td className="md:whitespace-nowrap px-4  font-medium text-gray-900"></td>
                    <td className="md:whitespace-nowrap px-4  text-gray-700 w-full outline-none h-10 bg-transparent"></td>
                </tr>
                <tr className="divide-x-2">
                    <td className="md:whitespace-nowrap px-4  font-medium text-gray-900">
                        Details of Any Medication Errors:
                    </td>
                    <td className="md:whitespace-nowrap px-4  text-gray-700 w-full">
                        <input
                            type="text"
                            name="medicatioErrors"
                            value={shiftReportDetail.medicatioErrors}
                            onChange={handleInputChange}
                            className="w-full outline-none h-10 bg-transparent"
                        />
                    </td>
                </tr>

            </tbody>
        </table>
    );
};

export default EditFormPartA;
