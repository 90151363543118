import React from 'react'
import { Button, Modal } from 'rsuite'
interface Props {
    modal1: boolean
    setModal1: React.Dispatch<React.SetStateAction<boolean>>
    modal2: boolean
    setModal2: React.Dispatch<React.SetStateAction<boolean>>
    modal3: boolean
    setModal3: React.Dispatch<React.SetStateAction<boolean>>
    modal4: boolean
    setModal4: React.Dispatch<React.SetStateAction<boolean>>
    modal5: boolean
    setModal5: React.Dispatch<React.SetStateAction<boolean>>
    modal6: boolean
    setModal6: React.Dispatch<React.SetStateAction<boolean>>
    modal7: boolean
    setModal7: React.Dispatch<React.SetStateAction<boolean>>

}

const TimePeriodModals = ({ modal1, setModal1, setModal2, modal2, modal3, setModal3,
    modal4, setModal4, modal5, setModal5, modal6, setModal6, modal7, setModal7
}: Props) => {
    return (
        <>
            <Modal open={modal1} onClose={() => setModal1(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>What Are Time Periods</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <small className="text-sm">
                        {""} In the context of <b> NDIS (National Disability Insurance Scheme)</b> standards, specific time periods may be defined to ensure consistency and clarity in scheduling care and support services for individuals with disabilities. Here are some common time periods defined within NDIS standards:
                        <ol className="list-decimal ml-8 my-2">
                            <li>
                                <strong>Normal Start Time Period:</strong> This refers to the designated time range or window within which a task, activity, or shift is expected to begin under normal circumstances.
                            </li>
                            <li>
                                <strong>Normal End Time Period:</strong> This refers to the designated time range or window within which a task, activity, or shift is expected to conclude under normal circumstances.
                            </li>
                            <li>
                                <strong>Evening Start Time Period:</strong> This refers to the designated time range or window within which a task, activity, or shift is expected to begin in the evening hours.
                            </li>
                            <li>
                                <strong>Evening End Time Period:</strong> This refers to the designated time range or window within which a task, activity, or shift is expected to conclude in the evening hours.
                            </li>
                            <li>
                                <strong>Sleepover Start Time Period:</strong> This refers to the designated time range or window within which a sleepover shift begins, typically involving overnight care and support.
                            </li>
                            <li>
                                <strong>Sleepover End Time Period:</strong> This refers to the designated time range or window within which a sleepover shift concludes, typically following an overnight period of care and support.
                            </li>
                        </ol>

                        These time periods are defined to help providers and participants in the NDIS effectively schedule and coordinate care and support services while ensuring that the needs and preferences of individuals with disabilities are met in a timely and consistent manner.
                    </small>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setModal1(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal open={modal2} onClose={() => setModal2(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Normal Start Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8'>
                        The "Normal Start Time Period" typically refers to the designated time range or window within which a task, activity, or shift is expected to begin. The specific time for the "Normal Start Time Period"
                        can vary depending on the context or requirements of the task or activity. For example,
                        in the context of scheduling shifts for NDIS care support workers, the "Normal Start Time Period" might specify
                        the range of acceptable start times for a particular shift, such as between 8:00 AM and 10:00 AM.

                    </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setModal2(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal open={modal3} onClose={() => setModal3(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Normal End Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8 text-sm'>
                        The "Normal End Time Period" refers to the designated time range or window within which a task, activity, or shift is expected to conclude. Similar to the "Normal Start Time Period," the specific time for the "Normal End Time Period" can vary depending on the context or requirements of the task or activity. In the context of scheduling shifts for NDIS care support workers, the "Normal End Time Period" might specify the range of acceptable end times for a particular shift, such as between 4:00 PM and 6:00 PM.

                    </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setModal3(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal open={modal4} onClose={() => setModal4(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Evening Start Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8 text-sm'>
                        The "Evening Start Time Period" typically refers to the designated time range or window within which a task, activity, or shift is expected to begin in the evening hours. This time period may vary depending on the specific context or requirements of the task or activity. For example, in the context of scheduling shifts for NDIS care support workers, the "Evening Start Time Period" might specify the range of acceptable start times for evening shifts, such as between 5:00 PM and 7:00 PM.
                    </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setModal4(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal open={modal5} onClose={() => setModal5(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Evening End Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8 text-sm'>
                        The "Evening End Time Period" refers to the designated time range or window within which a task, activity, or shift is expected to conclude in the evening hours. This time period typically follows the "Evening Start Time Period" and may vary depending on the specific context or requirements of the task or activity. For instance, in the context of scheduling shifts for NDIS care support workers, the "Evening End Time Period" might specify the range of acceptable end times for evening shifts, such as between 9:00 PM and 11:00 PM.

                    </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setModal5(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal open={modal6} onClose={() => setModal6(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Sleepover Start Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8 text-sm'>
                        The "Sleepover StartTime Period" refers to the designated time range or window within which a sleepover shift begins. In the context of NDIS care support workers, a sleepover shift typically involves providing overnight care and assistance to individuals with disabilities. The "Sleepover StartTime Period" specifies the range of acceptable start times for these overnight shifts, which may vary depending on the specific requirements of the care plan and the preferences of the individual receiving care. For example, the "Sleepover StartTime Period" might indicate that the sleepover shift begins between 8:00 PM and 10:00 PM.
                    </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setModal6(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal open={modal7} onClose={() => setModal7(false)} backdrop="static" >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Sleepover End Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className='indent-8 text-sm'>
                        The "Sleepover End Time Period" refers to the designated time range or window within which a sleepover shift concludes. In the context of NDIS care support workers, a sleepover shift typically involves providing overnight care and assistance to individuals with disabilities. The "Sleepover End Time Period" specifies the range of acceptable end times for these overnight shifts, which may vary depending on the specific requirements of the care plan and the preferences of the individual receiving care. For example, the "Sleepover End Time Period" might indicate that the sleepover shift ends between 6:00 AM and 8:00 AM.

                    </p>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setModal7(false)} className='bg-red-500 text-white'>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>


        </>
    )
}

export default TimePeriodModals