import React, { useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
// import { useSelector } from 'react-redux';
// import { RootState } from '../../../store/store';
import { toast } from 'react-toastify';
import axiosInstance from '../../../store/axiosInstance';
import Spinner from '../../../Components/Spinner';

interface ApiError {
    response: {
        data: {
            message: string;
            title?: string; // Optional property
        };
    };
}

const ClientBehaviourSupport = () => {
    const [loading, setLoading] = useState(false);
    const [currentHealthState, setCurrentHealthState] = useState("");
    const [additionalSupport, setAdditionalSupport] = useState("");
    const [details, setDetails] = useState("");
    const [behaviourPlan, setBehaviourPlan] = useState("");
    const [riskAss, setRiskAss] = useState("");
    const clientProfileString = sessionStorage.getItem('clientProfile')
    const clientProfile = JSON.parse(clientProfileString as string)
    // const user = useSelector((state: RootState) => state?.auth?.user);

    const submitForm = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true)
        try {
            if (currentHealthState === "" || additionalSupport === "" || behaviourPlan === "" || riskAss === "" || details === "") {
                return toast.error("Input Fields cannot be empty")
            }
            const info = {
                profileId: clientProfile.profileId,
                question1: currentHealthState,
                question2: additionalSupport,
                question3: details,
                behaviourPlan: behaviourPlan,
                riskAssessment: riskAss,
            }
            const { data } = await axiosInstance.post(`/BehaviourSupports`, info);
            console.log(data);
            toast.success(data.message)
            setLoading(false)
        } catch (error) {
            const apiError = error as ApiError;
            toast.error(apiError.response.data.message);
            if (apiError.response.data.title) {
                toast.error(apiError.response.data.title);
            }
            setLoading(false)
        } finally {
            setLoading(false)
        }



    }

    return (
        <>
            <SEO title="Behaviour Support Needs" description="Client" name="Promax-Care" />
            <div className="flex justify-between items-center flex-wrap">
                <Breadcrumb />
            </div>
            <div className="space-y-4 py-12 font-['Rubik']">
                <div className="border border-gray-300 w-[100%] rounded-md bg-white ">
                    <div className=" bg-[#f7f7f7] py-1 px-5 text-white rounded-md">
                        <h3 className="text-[#1f1f1f] text-[16px] font-semibold">
                            Behaviour Support Needs
                        </h3>
                    </div>

                    <section className="py-3 px-5 max-[688px]:flex-col flex items-start justify-between flex-wrap">
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%]">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="desc"
                            >
                                Describe how you would react if someone you lived with did something you found disruptive or upsetting?
                            </label>
                            <textarea
                                id="desc"
                                onChange={(e) => setCurrentHealthState(e.target.value)}
                                // value={currentHealthState}
                                className="border border-gray-200 outline-none p-1 mt-2 rounded-md"
                            ></textarea>
                        </div>
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%] max-[688px]:mt-6">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="add"
                            >
                                Do you have any behaviours of concern that require specific support? If so, please provide detail
                            </label>
                            <textarea
                                id="add"
                                onChange={(e) => setAdditionalSupport(e.target.value)}
                                className="border border-gray-200 outline-none p-1 mt-2 rounded-md"
                            ></textarea>
                        </div>


                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%] mt-6">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="hea"
                            >
                                Do you do anything that others might find disruptive?
                            </label>
                            <textarea
                                id="hea"
                                onChange={(e) => setDetails(e.target.value)}
                                className="border border-gray-200 outline-none p-1 mt-2 rounded-md"
                            ></textarea>
                        </div>
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%] mt-6">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="hea"
                            >
                                Behaviour Plan
                            </label>
                            <textarea
                                id="hea"
                                onChange={(e) => setBehaviourPlan(e.target.value)}
                                className="border border-gray-200 outline-none p-1 mt-2 rounded-md"
                            ></textarea>
                        </div>
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%] mt-6">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="hea"
                            >
                                Risk Assessment
                            </label>
                            <textarea
                                id="hea"
                                onChange={(e) => setRiskAss(e.target.value)}
                                className="border border-gray-200 outline-none p-1 mt-2 rounded-md"
                            ></textarea>
                        </div>
                    </section>
                    <button onClick={(e) => submitForm(e)} className="my-3 mx-5 py-2 px-6 rounded-md bg-[#405189] text-white font-semibold">
                        {loading ? <Spinner /> : "Submit"}
                    </button>
                </div>
            </div>
        </>
    )
}

export default ClientBehaviourSupport
