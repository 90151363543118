import React, { FormEvent, useEffect, useState } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import { Avatar, Button, Modal } from "rsuite";
import man from "../../../images/user/man.png";
import CardHeader from "./components/CardHeader";
import CardNameTag from "./components/CardNameTag";
import { useParams } from "react-router-dom";
import axiosInstance from "../../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import Spinner from "../../../Components/Spinner";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import StaffShiftRoster from "./staffTab/ShiftRoster";
import ShiftReport from "./staffTab/ShiftReport";
import TimeSheet from "./staffTab/TimeSheet";
import Schedule from "./staffTab/Schedule";
import StaffEditComp from "./components/StaffEditComp";
import { claimsTypes, StaffDataRow } from "../../../types/AuthData";
import StaffEmergComp from "./components/StaffEmergComp";
import StaffBankComp from "./components/StaffBankComp";
import StaffEmployComp from "./components/StaffEmployComp";
import StaffOtherComp from "./components/StaffOtherComp";
import EditDropDown from "../../../Components/menu/EditDropDown";
import WebcamCapture from "./components/CapturePicture";
import CustomInput from "../../../Components/Input/CustomInput";
import { fetchStaffInfo } from "../../../services/fetchServices";
import {
  fetchStaffProfile,
  selectProfileData,
  selectProfileLoading,
} from "../../../store/slices/staffProfileSlice";
import FallBack from "../../../Pages/FallBack";
import FeaturedDocument from "./staffTab/Document";
import dayjs from "dayjs";


const StaffDetails = () => {
  const { uid } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [deactivateModal, setDeactivateModal] = useState<boolean>(false);
  const [deapproveAuditModal, setDeapproveAuditModal] =
    useState<boolean>(false);
  const [ApproveAuditModal, setApproveAuditModal] = useState<boolean>(false);
  const [activateModal, setActivateModal] = useState<boolean>(false);
  const [personalInfoModal, setPersonalInfoModal] = useState<boolean>(false);
  const [emergencyModal, setEmergencyModal] = useState<boolean>(false);
  const [bankModal, setBankModal] = useState<boolean>(false);
  const [employModal, setEmployModal] = useState<boolean>(false);
  const [othersModal, setOthersModal] = useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const [editedProfile, setEditedProfile] = useState<StaffDataRow>({
    aboutMe: "",
    accountName: "",
    accountNumber: 0,
    address: "",
    adm_Designations: "",
    adm_DesignationsId: "",
    auditApproved: false,
    australianCitizen: "",
    bankName: "",
    branch: "",
    bsb: "",
    city: "",
    company: "",
    companyId: 0,
    country: "",
    dateCreated: "",
    dateJoined: "",
    dateModified: "",
    dateOfBirth: "",
    email: "",
    employeeId: "",
    employmentType: "",
    facebook: "",
    firstName: "",
    fullName: "",
    gender: "",
    github: "",
    handbook: "",
    imageFile: "",
    imageUrl: "",
    instagram: "",
    isActive: false,
    isAdmin: false,
    isCompleted: false,
    isDocumentUploaded: false,
    kinAddress: "",
    kinCity: "",
    kinCountry: "",
    kinEmail: "",
    kinPhoneNumber: 0,
    kinPostcode: "",
    kinState: "",
    level: "",
    linkedIn: "",
    maxStaffId: "",
    middleName: "",
    nextOfKin: "",
    offerLetter: "",
    payDay: 0,
    payRate: "",
    phoneNumber: "",
    position: "",
    postcode: "",
    profession: "",
    relationship: "",
    salary: 0,
    staffId: 0,
    state: "",
    suburb: "",
    superForm: "",
    supportPosition: "",
    surName: "",
    taxFile: "",
    twitter: "",
    userCreated: "",
    userModified: "",
    xerolink: "",
    youtube: "",
    signatureUrl: null,
    signatureFile: null,
  });

  const dispatch: AppDispatch = useAppDispatch();

  const handleFetchStaff = async () => {
    setLoading(true);

    const data = await fetchStaffInfo(uid);
    setEditedProfile({ ...data });

    setLoading(false);
  };


  useEffect(() => {
    if (uid) {
      handleFetchStaff();
      dispatch(fetchStaffProfile(uid as string));
    }
  }, []);
  const profile = useSelector(selectProfileData);
  const loadingProfile = useSelector(selectProfileLoading);

  const handleActivate = () => {
    setActivateModal(true);
  };

  const handleDeactivate = () => {
    setDeactivateModal(true);
  };

  const handleDeapproveAudit = () => {
    setDeapproveAuditModal(true);
  };
  const handleApproveAudit = () => {
    setApproveAuditModal(true);
  };
  const handleView = (xerolink: string) => {
    window.open(xerolink, "_blank");
  };

  const handleActivateStaff = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Staffs/activate_staff?userId=${user?.uid}&staffid=${uid}`
      );
      toast.success(data.message);
      setActivateModal(false);
      handleFetchStaff();
      dispatch(fetchStaffProfile(uid as string));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setActivateModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDeactivateStaff = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Staffs/deactivate_staff?userId=${user?.uid}&staffid=${uid}`
      );
      toast.success(data.message);
      setDeactivateModal(false);
      handleFetchStaff();
      dispatch(fetchStaffProfile(uid as string));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setDeactivateModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleDisApproveAudit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Staffs/disapprove_audit?userId=${user?.uid}&id=${uid}`
      );
      toast.success(data.message);
      setDeapproveAuditModal(false);
      handleFetchStaff();
      dispatch(fetchStaffProfile(uid as string));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setDeapproveAuditModal(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubApproveAudit = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      const { data } = await axiosInstance.get(
        `/Staffs/approve_audit?userId=${user?.uid}&id=${uid}`
      );
      toast.success(data.message);
      setApproveAuditModal(false);
      handleFetchStaff();
      dispatch(fetchStaffProfile(uid as string));
      setLoading(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
      setApproveAuditModal(false);
    } finally {
      setLoading(false);
    }
  };

  const [activeTab, setActiveTab] = useState<string>("Documents");
  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const tabsArray: string[] = [
    "Documents",
    "Shift Roster",
    "Progress Report",
    "TimeSheet",
    "Schedule",
  ];

  const [loading1, setLoading1] = useState<boolean>(false);
  const [isCameraModalOpen, setCameraModalOpen] = useState(false);
  const [photoModal, setPhotoModal] = useState(false);
  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [upLoadPic, setUpLoadPic] = useState<File | null>(null);

  const handleCapture = async (imageBlob: Blob | null) => {
    if (imageBlob) {
      // Convert Blob to File
      const imageFile = new File([imageBlob], "captured-image.jpg", {
        type: imageBlob.type, // Preserving the MIME type
        lastModified: Date.now(), // Setting last modification time
      });

      if (imageFile) {
        setLoading1(true);
        const formData = new FormData();

        if (imageFile) {
          formData.append("imageFile", imageFile);
        }
        for (const key in editedProfile) {
          const value = editedProfile[key as keyof typeof editedProfile];
          if (value === null) {
            formData.append(key, ""); // Pass empty string if value is null
          } else {
            formData.append(key, value.toString());
          }
        }

        try {
          const { data } = await axiosInstance.post(
            `/Staffs/edit/${uid}?userId=${user?.uid}`,
            formData
          );
          toast.success(data.message);
          setLoading1(false);
          setCameraModalOpen(false);
          handleFetchStaff();
          dispatch(fetchStaffProfile(uid as string));
        } catch (error: unknown) {
          if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // throw error;
          } else {
            toast.error("An error occurred");
          }
          setLoading1(false);
        } finally {
          setLoading1(false);
        }
      }

      // Close modal
    } else {
      console.log("No image captured");
      setCameraModalOpen(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUpLoadPic(file);
    }
  };

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setEditedProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const submitData = async (e: FormEvent) => {
    e.preventDefault();
    setLoading1(true);
    const formData = new FormData();

    if (upLoadPic) {
      formData.append("imageFile", upLoadPic);
    }

    for (const key in editedProfile) {
      const value = editedProfile[key as keyof typeof editedProfile];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    try {
      const { data } = await axiosInstance.post(
        `/Staffs/edit/${uid}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading1(false);
      setPersonalInfoModal(false);
      setEmergencyModal(false);
      setBankModal(false);
      setOthersModal(false);
      setEmployModal(false);
      setFileModalOpen(false);
      handleFetchStaff();
      dispatch(fetchStaffProfile(uid as string));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const staffClaimsString = sessionStorage.getItem("claims");
  const claims: claimsTypes[] = staffClaimsString
    ? JSON.parse(staffClaimsString)
    : [];
  const hasRequiredClaims = (claimType: string): boolean => {
    return claims.some((claim) => claim.value === claimType);
  };

  return (
    <>
      <SEO title="Staffs" description="Staff" name="Promax-Care" />
      <div className="flex justify-between items-center flex-wrap">
        <Breadcrumb />
        <h1 className="font-bold text-black text-2xl">Staff</h1>
      </div>

      <div>
        {loadingProfile ? (
          <FallBack />
        ) : (
          <div className="space-y-8 py-12">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4">
              <div className="border-2 rounded-lg">
                <CardHeader label="Profile" />
                <div className="flex gap-2 flex-col justify-center items-center py-4">
                  <div className="flex flex-col gap-1 items-center">
                    <Avatar
                      size="lg"
                      circle
                      src={profile?.imageUrl || man}
                      alt="@simonguo"
                      style={{ marginTop: 8, cursor: "pointer" }}
                      onClick={() => setPhotoModal(true)}
                    />
                    <EditDropDown>
                      <div className="z-[999] w-max rounded bg-white  p-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <button
                          onClick={() => setCameraModalOpen(true)}
                          className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                          Take Photo
                        </button>

                        <button
                          onClick={() => setFileModalOpen(true)}
                          className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                          Choose Photo
                        </button>
                      </div>
                    </EditDropDown>
                  </div>
                  {/* <Avatar size="xxl" circle /> */}
                  <p className="font-bold text-sm text-black">
                    {profile?.fullName}
                  </p>
                  <p className=" text-sm text-black">
                    <span className="font-bold">Staff ID:</span>{" "}
                    <span>
                      {!profile?.maxStaffId ? "N/A" : profile?.maxStaffId}
                    </span>
                  </p>
                  <p className=" text-sm ">{profile?.phoneNumber}</p>
                  {user?.role === "CompanyAdmin" || user?.role === "Administrator" || hasRequiredClaims("Edit Staff") ? <div>
                    {profile?.isActive ? (
                      <button
                        onClick={handleDeactivate}
                        className="bg-red-500 text-white rounded-md  p-2"
                      >
                        Deactivate Staff
                      </button>
                    ) : (
                      <button
                        onClick={handleActivate}
                        className="bg-primary text-white rounded-md  p-2"
                      >
                        Activate Staff
                      </button>
                    )}
                  </div> : ""}
                  {user?.role === "CompanyAdmin" || user?.role === "Administrator" || hasRequiredClaims("Edit Staff") ? <div>
                    {profile?.auditApproved ? (
                      <button
                        onClick={handleDeapproveAudit}
                        className="bg-red-500 text-white rounded-md  p-2"
                      >
                        Disapprove for Auditing
                      </button>
                    ) : (
                      <button
                        onClick={handleApproveAudit}
                        className="bg-primary text-white rounded-md  p-2"
                      >
                        Approve for Auditing
                      </button>
                    )}
                  </div> : ""}
                  {user?.role === "CompanyAdmin" || user?.role === "Administrator" || hasRequiredClaims("Edit Staff") ? <div>
                    {!editedProfile.employeeId && (
                      <button
                        onClick={() => handleView(editedProfile.xerolink)}
                        className="bg-primary text-white rounded-md  p-2"
                      >
                        Update Record to Xero
                      </button>
                    )}
                  </div> : ""}
                </div>
              </div>
              <Modal
                open={photoModal}
                onClose={() => setPhotoModal(false)}
                role="alertdialog"
                autoFocus
                size={"xs"}
              >
                <Modal.Header></Modal.Header>
                <Modal.Body>
                  <div className="w-56 h-56 overflow-hidden mx-auto">
                    <img
                      src={editedProfile.imageUrl || man}
                      alt=""
                      className="w-full h-full object-cover"
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
              <Modal
                open={isCameraModalOpen}
                onClose={() => setCameraModalOpen(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
              >
                <Modal.Header>
                  <Modal.Title className="font-bold">Take photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <WebcamCapture onCapture={handleCapture} loading={loading1} />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
              <Modal
                open={isFileModalOpen}
                onClose={() => setFileModalOpen(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
              >
                <Modal.Header>
                  <Modal.Title className="font-bold">Upload photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <CustomInput
                    label=""
                    type="file"
                    accept=".pdf,.doc,.docx,.jpg,.jpeg"
                    onChange={handleFileChange}
                  />

                  <div className="mx-auto text-center mt-3">
                    <button
                      type="submit"
                      onClick={submitData}
                      disabled={loading ? true : false}
                      className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                    >
                      {loading1 ? <Spinner /> : "Upload"}
                    </button>
                  </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
              <div className=" lg:col-span-2 space-y-2">
                <div className="border-2 rounded-lg ">
                  {user?.role === "CompanyAdmin" || user?.role === "Administrator" || hasRequiredClaims("Edit Staff") ? <CardHeader
                    label="  Emergency Contact"
                    showEditIcon
                    onEdit={() => setEmergencyModal(true)}
                  /> : ""}

                  <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 p-4">
                    <CardNameTag label="Name" name={profile?.nextOfKin} />
                    <CardNameTag label="Phone" name={profile?.kinPhoneNumber} />
                    <CardNameTag label="City" name={profile?.kinCity} />
                    <CardNameTag
                      label="Relationship"
                      name={profile?.relationship}
                    />
                    <CardNameTag label="Country" name={profile?.kinCountry} />
                    <CardNameTag
                      label="Postal Code"
                      name={profile?.kinPostcode}
                    />
                    <CardNameTag label="Email" name={profile?.kinEmail} />
                    <CardNameTag label="State" name={profile?.kinState} />
                  </div>
                </div>

                <div className="border-2 rounded-lg ">
                  {user?.role === "CompanyAdmin" || user?.role === "Administrator" || hasRequiredClaims("Edit Staff") ? <CardHeader
                    label="  Bank Information"
                    showEditIcon
                    onEdit={() => setBankModal(true)}
                  /> : ""}

                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-3 p-4">
                    <CardNameTag label="Bank name" name={profile?.bankName} />
                    <CardNameTag
                      label="Account name"
                      name={profile?.accountName}
                    />
                    <CardNameTag
                      label="Account number"
                      name={profile?.accountNumber}
                    />
                    <CardNameTag label="Branch" name={profile?.branch} />
                    <CardNameTag label="Bsb" name={profile?.bsb} />
                  </div>
                </div>
              </div>
              <div className="border-2 rounded-lg">
                {user?.role === "CompanyAdmin" || user?.role === "Administrator" || hasRequiredClaims("Edit Staff") ? <CardHeader
                  label="Personal Information"
                  showEditIcon
                  onEdit={() => setPersonalInfoModal(true)}
                /> : ""}
                <div className="grid grid-cols-1  gap-2  p-4 overflow-hidden">
                  <CardNameTag label="Phone" name={profile?.phoneNumber} />
                  <CardNameTag label="Email" name={profile?.email} />
                  <CardNameTag label="Gender" name={profile?.gender} />
                  <CardNameTag label="Date of Birth" name={dayjs(profile?.dateOfBirth).format('DD-MM-YYYY') || "N/A"} />
                  <CardNameTag label="Nationality" name={profile?.country} />
                  <CardNameTag label="State" name={profile?.state} />
                  <CardNameTag label="Address" name={profile?.address} />
                  <CardNameTag label="Suburb" name={profile?.suburb} />
                  <CardNameTag label="Postcode" name={profile?.postcode} />
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <div className="border-2 rounded-lg ">
                {user?.role === "CompanyAdmin" || user?.role === "Administrator" || hasRequiredClaims("Edit Staff") ? <CardHeader
                  label="Employment Details"
                  showEditIcon
                  onEdit={() => setEmployModal(true)}
                /> : ""}

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2  p-4">
                  <CardNameTag
                    label="Employment Type"
                    name={profile?.employmentType}
                  />
                  <CardNameTag
                    label="Pay Rate"
                    name={profile?.payRate?.toString()}
                  />
                  <CardNameTag label="Date Joined" name={profile?.dateJoined} />
                  {/* <CardNameTag label='Earning Rate' name={profile?.earningRate} /> */}
                  <CardNameTag
                    label="Salary"
                    name={profile?.salary.toLocaleString()}
                  />
                  <CardNameTag label="Level" name={profile?.level} />
                </div>
              </div>
              <div className="border-2 rounded-lg ">
                {user?.role === "CompanyAdmin" || user?.role === "Administrator" || hasRequiredClaims("Edit Staff") ? <CardHeader
                  label="Other Information"
                  showEditIcon
                  onEdit={() => setOthersModal(true)}
                /> : ""}

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2  p-4">
                  <CardNameTag label="Instagram" name={profile?.instagram} />
                  <CardNameTag label="Linked-In" name={profile?.linkedIn} />
                  <CardNameTag label="Facebook" name={profile?.facebook} />
                  <CardNameTag label="Youtube" name={profile?.youtube} />
                  <CardNameTag
                    label="X (Formely Twitter)"
                    name={profile?.twitter}
                  />
                </div>
              </div>
            </div>

            <div className="flex gap-2 items-center horizontal-scroll scroll-smoothscrollbar-hide  border-t border-b py-2 px-4 md:px-0">
              {tabsArray.map((category, index) => (
                <button
                  key={index}
                  className={`${activeTab === category
                    ? "bg-gray-500 text-white"
                    : "border-gray-400 border"
                    }  rounded text-xs p-2 whitespace-nowrap`}
                  onClick={() => handleTab(category)}
                >
                  {category}
                </button>
              ))}
            </div>

            {activeTab === "Documents" && uid !== undefined && (
              <FeaturedDocument uid={uid} staffName={editedProfile.fullName} />
            )}
            {activeTab === "Shift Roster" && uid !== undefined && (
              <StaffShiftRoster uid={uid} />
            )}

            {activeTab === "Progress Report" && uid !== undefined && (
              <ShiftReport uid={uid} fullName={editedProfile.fullName} />
            )}

            {activeTab === "TimeSheet" && uid !== undefined && (
              <TimeSheet uid={uid} />
            )}
            {activeTab === "Schedule" && uid !== undefined && (
              <Schedule uid={uid} />
            )}
          </div>
        )}
      </div>

      <Modal
        open={activateModal}
        onClose={() => setActivateModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Activate Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              You are about to activate this Staff. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setActivateModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleActivateStaff}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deactivateModal}
        onClose={() => setDeactivateModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Deactivate Staff</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              You are about to deactivate this Staff. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeactivateModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDeactivateStaff}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={deapproveAuditModal}
        onClose={() => setDeapproveAuditModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              This will disapprove staff for auditing. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setDeapproveAuditModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleDisApproveAudit}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <Modal
        open={ApproveAuditModal}
        onClose={() => setApproveAuditModal(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <p className="mb-4 font-bold">
              This will approve staff for auditing. Do you wish to proceed?
            </p>
            <div className="flex justify-center items-center space-x-4">
              <Button
                onClick={() => setApproveAuditModal(false)}
                className="bg-gray-500 text-white"
              >
                No, cancel
              </Button>
              <Button
                onClick={handleSubApproveAudit}
                className="bg-primary text-white hover:bg-primary hover:text-white"
              >
                {loading ? <Spinner /> : "Yes, I'm sure"}
              </Button>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <StaffEditComp
        personalInfoModal={personalInfoModal}
        setPersonalInfoModal={setPersonalInfoModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
      <StaffEmergComp
        emergencyModal={emergencyModal}
        setEmergencyModal={setEmergencyModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
      <StaffBankComp
        bankModal={bankModal}
        setBankModal={setBankModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
      <StaffEmployComp
        employModal={employModal}
        setEmployModal={setEmployModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
      <StaffOtherComp
        othersModal={othersModal}
        setOthersModal={setOthersModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
    </>
  );
};

export default StaffDetails;
