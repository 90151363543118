import React from "react";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
import { FaPlus } from "react-icons/fa";
import dayjs from "dayjs";
import ShiftLabel from "./ShiftLabel";

interface Props {
  daysArray: string[];
  activitiesByDay: Shift[][];
  setAddModal: React.Dispatch<React.SetStateAction<boolean>>;
  currentMonth: number;
  handleShiftClick: (shift: Shift) => void;
  // handleShowMoreClick: (dayShifts: Shift[], dayDate: string) => void;
  handleDayClick: (day: string) => void;
  currentDay: string;
  today: string;
  handleDayShow: (day: string) => void;
  getActivityStatus(activity: Shift): string;
  setDuplicateDate: React.Dispatch<React.SetStateAction<string>>;
  duplicateDate: string;
  duplicateAttendance: (shiftId: number) => Promise<void>;
}

const MonthView: React.FC<Props> = ({
  daysArray,
  activitiesByDay,
  currentMonth,
  handleShiftClick,
  handleDayClick,
  currentDay,
  today,
  handleDayShow,
  getActivityStatus,
}) => {
  return (
    <div className="lg:grid grid-cols-1 lg:grid-cols-7">
      {daysArray.map((day, i) => (
        <div
          key={i}
          className={`border border-gray-300 ${
            day === today ? "bg-gray-300" : ""
          }`}
          onClick={() => handleDayClick(day)}
        >
          <div className="text-center p-1 flex justify-between items-center text-xs font-semibold text-black border-b border-t border-gray-300">
            <span>{day.split(", ")[0]}.</span>
            <span className="text-xs p-1 text-green-700 rounded border-green-700 cursor-pointer">
              {activitiesByDay[i].length}
            </span>
            <button
              onClick={() => handleDayShow(day)}
              className="text-xs text-white p-1 bg-brand rounded"
            >
              <FaPlus />
            </button>
          </div>
          <div className="p-1">
            <small
              className={`font-bold text-brand ${
                dayjs(day).month() !== currentMonth ? "text-gray-300" : ""
              } ${
                day === currentDay
                  ? "border bg-secondary text-primary py-1 px-2 rounded"
                  : ""
              } ${
                day === today
                  ? "border bg-primary text-white py-1 px-2 rounded"
                  : ""
              }`}
            >
              {day.split(", ")[1]}
            </small>
          </div>
          <div className="p-1 flex flex-col gap-1 h-32 overflow-y-auto overflow-x-hidden">
            {activitiesByDay[i].map((activity, index) => (
              <div key={index} onClick={() => handleShiftClick(activity)}>
                <ShiftLabel
                  staff={activity.staff.fullName}
                  client={activity.clients}
                  timeFrom={activity.dateFrom}
                  timeTo={activity.dateTo}
                  onSelect={() => handleShiftClick(activity)}
                  activity={activity}
                  getActivityStatus={getActivityStatus}
                />
              </div>
            ))}
          </div>
          <div className="flex justify-end items-end p-1">
            {activitiesByDay[i].length > 4 && (
              <small className="text-xs font-bold cursor-pointer">
                + {activitiesByDay[i].length - 4} more
              </small>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default MonthView;
