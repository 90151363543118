// PrintComponent.tsx
import React, { FC, RefObject, useCallback } from 'react';
import ReactToPrint from 'react-to-print';

interface PrintComponentProps {
    componentRef: RefObject<HTMLElement>;
    triggerLabel: string;
    documentTitle?: string;
    onBeforePrint?: () => void;
    onAfterPrint?: () => void;
    onBeforeGetContent?: () => Promise<void>;
}

const PrintComponent: FC<PrintComponentProps> = ({
    componentRef,
    triggerLabel,
    documentTitle = 'Document',
    onBeforePrint,
    onAfterPrint,
    onBeforeGetContent,
}) => {
    const handleTrigger = useCallback(() => {
        return <div className="w-full p-3 flex justify-end">
            <button
                className="flex justify-between items-center px-4 py-2 border rounded font-semibold border-brand bg-gray-300 text-brand no-underline hover:no-underline"
            >
                {triggerLabel}
            </button>
        </div>;
    }, [triggerLabel]);

    return (
        <ReactToPrint
            content={() => componentRef.current}
            trigger={handleTrigger}
            documentTitle={documentTitle}
            onBeforePrint={onBeforePrint}
            onAfterPrint={onAfterPrint}
            onBeforeGetContent={onBeforeGetContent}
            removeAfterPrint
        />
    );
};

export default PrintComponent;
