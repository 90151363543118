import React, { useRef, useState } from 'react'
import CustomInput from '../../../Components/Input/CustomInput'
import SEO from '../../../constants/SEO'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import AuthButton from '../../../Components/Buttons/AuthButton'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { toast } from 'react-toastify'
import { isAxiosError } from 'axios'
import { useDispatch } from 'react-redux'
import { logout } from '../../../store/slices/authSlice'
import Spinner from '../../../Components/Spinner'
import { useNavigate } from 'react-router-dom'
import axiosInstance from '../../../store/axiosInstance'

const ChangePassword = () => {
    const user = useSelector((state: RootState) => state?.auth?.user);

    const oldPassword = useRef<HTMLInputElement>(null);
    const password = useRef<HTMLInputElement>(null);
    const confirmPassword = useRef<HTMLInputElement>(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        if (oldPassword.current?.value === "" || password.current?.value === "" || confirmPassword.current?.value === "") {
            return toast.error("Input Fields cannot be empty")
        }
        const info = {
            email: user?.email,
            oldPassword: oldPassword.current?.value,
            password: password.current?.value,
            confirmPassword: confirmPassword.current?.value,
        }
        try {
            setLoading(true)
            const { data } = await axiosInstance.post("/Account/change_password", info)
            if (data.status === "Success") {
                toast.success(data.message)
                dispatch(logout());
                navigate('/')
            } else {
                toast.error(data.message)
                return
            }

            setLoading(false)
        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("Error duplicating shift");
            }
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }


    return (
        <>

            <SEO
                title='Change Password - Promax Care'
                description='Change Password'
                name='Promax-Care'
            />
            <Breadcrumb showNavigation={false} />
            <form className=' w-full mt-5' onSubmit={handleSubmit}>
                <div className='bg-white p-2 md:p-6 lg:p-10 grid grid-cols-1 gap-4 max-w-lg mx-auto rounded-xl shadow'>
                    <h2 className='font-bold text-lg'>
                        Change Password
                    </h2>
                    <div>
                        <CustomInput
                            label='Email'
                            value={user?.email}
                            readOnly

                        />
                    </div>
                    <div>
                        <CustomInput
                            label='Old Password'
                            ref={oldPassword}
                            required
                        />
                    </div>
                    <div>
                        <CustomInput
                            label='New Password'
                            ref={password}
                            required
                        />
                    </div>
                    <div>
                        <CustomInput
                            label='Confirm Password'
                            ref={confirmPassword}
                            required
                        />
                    </div>
                    <div>
                        <AuthButton
                            type='submit'
                        >
                            {
                                loading ? <Spinner /> : "Submit"
                            }
                        </AuthButton>
                    </div>
                </div>
            </form>
        </>
    )
}

export default ChangePassword