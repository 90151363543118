import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import ShiftRoster from './ShiftRoster'

const CalendarLayout = () => {



    return (
        <div>

            <Routes>
                <Route path="/" element={<Navigate to="/app/shift/roster" replace />} />
                <Route path='/roster' element={<ShiftRoster />} />


            </Routes>

        </div>
    )
}

export default CalendarLayout