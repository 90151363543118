import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../../../Components/Spinner";
import axiosInstance from "../../../../store/axiosInstance";
import { RootState } from "../../../../store/store";
import SEO from "../../../../constants/SEO";
import Breadcrumb from "../../../../Components/label/BreadCrumb";
import FormHero from "./ReportForm/FormHero";
import FormPartA from "./ReportForm/FormPartA";
import FormPartB from "./ReportForm/FormPartB";
import {
    fetchReportInfo,
    fetchRosterInfo,
} from "../../../../services/fetchServices";
import { Shift } from "../../../../store/slices/shiftRosterSlice";
import { isAxiosError } from "axios";
import { ShiftReport } from "../../../../store/slices/StaffSlices/staffReportSlice";
import EditShiftReportForm from "./EditShiftReportForm";

const ShiftReportForm = () => {
    const medicationGiven = useRef<HTMLTableCellElement>(null);
    const medicationSigned = useRef<HTMLTableCellElement>(null);
    const medicationAvailable = useRef<HTMLTableCellElement>(null);
    const medicatioErrors = useRef<HTMLTableCellElement>(null);
    const urgentMatters = useRef<HTMLTextAreaElement>(null);
    const goal_Progress = useRef<HTMLTextAreaElement>(null);
    const contactFamily = useRef<HTMLTextAreaElement>(null);
    const [isMealManagementPlan, setIsMealManagementPlan] = useState(true);
    const details_IfNotMealMaganementPlan = useRef<HTMLTextAreaElement>(null);
    const [isDrinkingProblem, setIsDrinkingProblem] = useState(false);
    const details_IfProblemExist = useRef<HTMLTextAreaElement>(null);
    const [isHealthIssues, setIsHealthIssues] = useState(false);
    const details_IfHealthIssuesExist = useRef<HTMLTextAreaElement>(null);
    const [isIncident, setIsIncident] = useState(false);
    const details_IfIsIncident = useRef<HTMLTextAreaElement>(null);
    const [isBehaviourConcerned, setIsBehaviourConcerned] = useState(false);
    const details_ifIsBehaviourConcerned = useRef<HTMLTextAreaElement>(null);

    const { uid } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingReport, setLoadingReport] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [shiftDetail, setShiftDetail] = useState<Shift | null>(null);
    const [shiftReportDetail, setShiftReportDetail] =
        useState<ShiftReport | null>(null);

    const handleFetchRoster = async () => {
        const data = await fetchRosterInfo(Number(uid));
        setShiftDetail(data);
    };

    const reportId: string | null = localStorage.getItem("staffReportId");
    const handleFetchReportInfo = async () => {
        setLoadingReport(true);
        const data = await fetchReportInfo(Number(reportId), Number(uid));
        setShiftReportDetail(data);
        setLoadingReport(false);
        localStorage.removeItem("staffReportId");
    };

    useEffect(() => {
        handleFetchRoster();
        handleFetchReportInfo();
    }, [uid]);
    const navigate = useNavigate();

    const SubmitForm = async (e: React.FormEvent) => {
        e.preventDefault();

        setLoading(true);
        const info = {
            urgentMatters: urgentMatters?.current?.value || "",
            medicationGiven: medicationGiven.current?.innerText,
            medicationSigned: medicationSigned.current?.innerText,
            medicationAvailable: medicationAvailable.current?.innerText,
            medicatioErrors: medicatioErrors.current?.innerText,
            isMealManagementPlan: isMealManagementPlan,
            details_IfNotMealMaganementPlan:
                details_IfNotMealMaganementPlan.current?.value || "",
            isDrinkingProblem: isDrinkingProblem,
            details_IfProblemExist: details_IfProblemExist.current?.value || "",
            isHealthIssues: isHealthIssues,
            details_IfHealthIssuesExist:
                details_IfHealthIssuesExist.current?.value || "",
            goal_Progress: goal_Progress.current?.value || "",
            contactFamily: contactFamily.current?.value || "",
            isIncident: isIncident,
            details_IfIsIncident: details_IfIsIncident.current?.value || "",
            isBehaviourConcerned: isBehaviourConcerned,
            details_ifIsBehaviourConcerned:
                details_ifIsBehaviourConcerned.current?.value || "",
            shiftRosterId: uid,
            companyID: user?.companyId,
        };

        try {
            const { data } = await axiosInstance.post(
                `/ShiftReports/add_shiftreport?userId=${user?.uid}`,
                info
            );

            toast.success(data.message);
            setLoading(false);
            // localStorage.setItem('staffReportId', data.shiftReport.shiftRosterId)
            navigate(`/app/staff/dashboard`);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    const [signature] = useState<string | null>(null);

    return (
        <>
            <SEO title="Report Form" description="Staff" name="Promax-Care" />
            <Breadcrumb showNavigation={false} />
            <div className="py-2">
                <h2 className="font-bold text-2xl text-black w-auto">
                    Shift Report Form{" "}
                </h2>
            </div>

            <div className="bg-white py-4 px-2">
                {loadingReport && (
                    <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                        <div className="relative w-auto max-w-lg mx-auto">
                            <div
                                className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                            >
                                <Spinner color="#071952" />
                            </div>
                        </div>

                        <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
                    </div>
                )}
                {shiftReportDetail ? (
                    <EditShiftReportForm
                        shiftDetail={shiftDetail}
                        reportId={reportId}
                        uid={uid}
                    />
                ) : (
                    <form action="" onSubmit={SubmitForm}>
                        <div className="mx-auto  max-w-2xl">
                            <FormHero shiftDetail={shiftDetail} />
                            <FormPartA
                                urgentMatters={urgentMatters}
                                medicatioErrors={medicatioErrors}
                                medicationAvailable={medicationAvailable}
                                medicationGiven={medicationGiven}
                                medicationSigned={medicationSigned}
                            />
                            <FormPartB
                                signature={signature}
                                isMealManagementPlan={isMealManagementPlan}
                                setIsMealManagementPlan={setIsMealManagementPlan}
                                isDrinkingProblem={isDrinkingProblem}
                                setIsDrinkingProblem={setIsDrinkingProblem}
                                isHealthIssues={isHealthIssues}
                                setIsHealthIssues={setIsHealthIssues}
                                isBehaviourConcerned={isBehaviourConcerned}
                                isIncident={isIncident}
                                setIsBehaviourConcerned={setIsBehaviourConcerned}
                                setIsIncident={setIsIncident}
                                details_IfHealthIssuesExistt={details_IfHealthIssuesExist}
                                details_IfIsIncident={details_IfIsIncident}
                                details_IfNotMealMaganementPlan={
                                    details_IfNotMealMaganementPlan
                                }
                                details_IfProblemExist={details_IfProblemExist}
                                details_ifIsBehaviourConcerned={details_ifIsBehaviourConcerned}
                                contactFamily={contactFamily}
                                goal_Progress={goal_Progress}
                                shiftDetail={shiftDetail}
                            />
                        </div>
                        <div className="mx-auto text-center mt-3 lg:col-span-2">
                            <button
                                type="submit"
                                disabled={loading}
                                className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
                            >
                                {loading ? <Spinner /> : " Submit"}
                            </button>
                        </div>
                    </form>
                )}
            </div>
        </>
    );
};

export default ShiftReportForm;
