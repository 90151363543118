import React from "react";
import { Staff } from "../../../store/slices/staffSlice";
interface Props {
  careManager: Staff | null
}

const AuthorisedRepForm = ({ careManager }: Props) => {
  return (
    <div className="overflow-x-auto  rounded-lg border mt-9 border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
          <tr className="divide-x-2">
            <th className="whitespace-nowrap px-4 py-4 font-medium text-gray-900">

            </th>
            <th className="whitespace-nowrap px-4 divide-x-2 py-4 font-medium text-gray-900"></th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Name:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" >
              {careManager?.fullName}
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Phone:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" >
              {careManager?.phoneNumber}
            </td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Email:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" >
              {careManager?.email}
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Address:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              {careManager?.address}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default AuthorisedRepForm;
