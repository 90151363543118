import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface SavedInvoice {
    description: string;
    fromDate: string;
    toDate: string;
    clientId: number;
    totalDuration: number;
    amount: number;
    totalAgreedDuration: number;
    totalKm: number;
}

export interface SavedInvoiceState {
    loading: boolean;
    data: Array<SavedInvoice>;
    error: string | undefined;
}
const initialState: SavedInvoiceState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchSavedInvoice = createAsyncThunk(
    "SavedInvoice/fetchSavedInvoice",
    async (companyId: number) => {
        try {
            const response = await api.fetchSavedInvoice(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const savedInvoiceSlice = createSlice({
    name: 'savedInvoice',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchSavedInvoice.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchSavedInvoice.fulfilled, (state, action: PayloadAction<Array<SavedInvoice>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchSavedInvoice.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.savedInvoice;
export default savedInvoiceSlice.reducer;


export const savedInvoiceData = (state: RootState) => state.savedInvoice.data;
export const savedInvoiceLoading = (state: RootState) => state.savedInvoice.loading;
export const savedInvoiceError = (state: RootState) => state.savedInvoice.error;