import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface DashboardState {
    shiftRosterCount: number;
    attendanceCount: number;
    progressNoteCount: number;
    shiftForMonth: number;
    shiftForDay: number;
    monthPercentage: number;
    weekCount: number;
    weekPercentage: number;
    dayPercentage: number;
    month: string;
    fromWeek: string;
    toWeek: string;
    isLoading: boolean;
    error: string | null;
}

const initialState: DashboardState = {
    shiftRosterCount: 0,
    attendanceCount: 0,
    progressNoteCount: 0,
    shiftForMonth: 0,
    shiftForDay: 0,
    monthPercentage: 0,
    weekCount: 0,
    weekPercentage: 0,
    dayPercentage: 0,
    month: "",
    fromWeek: "",
    toWeek: "",
    isLoading: true,
    error: null,
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        setShiftRosterCount: (state, action: PayloadAction<number>) => {
            state.shiftRosterCount = action.payload;
        },
        setAttendanceCount: (state, action: PayloadAction<number>) => {
            state.attendanceCount = action.payload;
        },
        setProgressNoteCount: (state, action: PayloadAction<number>) => {
            state.progressNoteCount = action.payload;
        },
        setShiftForMonth: (state, action: PayloadAction<number>) => {
            state.shiftForMonth = action.payload;
        },
        setShiftForDay: (state, action: PayloadAction<number>) => {
            state.shiftForDay = action.payload;
        },
        setMonthPercentage: (state, action: PayloadAction<number>) => {
            state.monthPercentage = action.payload;
        },
        setMonth: (state, action: PayloadAction<string>) => {
            state.month = action.payload;
        },
        setWeekCount: (state, action: PayloadAction<number>) => {
            state.weekCount = action.payload;
        },
        setWeekPercentage: (state, action: PayloadAction<number>) => {
            state.weekPercentage = action.payload;
        },
        setDayPercentage: (state, action: PayloadAction<number>) => {
            state.dayPercentage = action.payload;
        },
        setFromWeek: (state, action: PayloadAction<string>) => {
            state.fromWeek = action.payload;
        },
        setToWeek: (state, action: PayloadAction<string>) => {
            state.toWeek = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload;
        },
        setError: (state, action: PayloadAction<string | null>) => {
            state.error = action.payload;
        },
    },
});

export const {
    setShiftRosterCount,
    setAttendanceCount,
    setProgressNoteCount,
    setShiftForMonth,
    setShiftForDay,
    setMonthPercentage,
    setMonth,
    setFromWeek,
    setToWeek,
    setWeekCount,
    setDayPercentage,
    setWeekPercentage,
    setLoading,
    setError,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;

export const attendanceCount = (state: RootState) => state.dashboard.attendanceCount;
export const progressNoteCount = (state: RootState) => state.dashboard.progressNoteCount;
export const shiftRosterCount = (state: RootState) => state.dashboard.shiftRosterCount;
export const shiftForDay = (state: RootState) => state.dashboard.shiftForDay;
export const shiftForMonth = (state: RootState) => state.dashboard.shiftForMonth;
export const dayPercentage = (state: RootState) => state.dashboard.dayPercentage;
export const fromWeek = (state: RootState) => state.dashboard.fromWeek;
export const month = (state: RootState) => state.dashboard.month;
export const monthPercentage = (state: RootState) => state.dashboard.monthPercentage;
export const toWeek = (state: RootState) => state.dashboard.toWeek;
export const error = (state: RootState) => state.dashboard.error;
export const weekPercentage = (state: RootState) => state.dashboard.weekPercentage;
export const weekCount = (state: RootState) => state.dashboard.weekCount;
export const isLoading = (state: RootState) => state.dashboard.isLoading;