import React, { ReactNode } from "react";
import Dashboard from "../Modules/Admin/Dashboard";
import SetupLayout from "../Modules/Admin/Setup";
import UserManagementLayout from "../Modules/Admin/UserManagement";
import CalendarLayout from "../Modules/Admin/RosteringManagement";
import ReportLayout from "../Modules/Admin/ReportManagement";
import FormLayout from "../Components/Forms/UserManagement";
import DocumentLayout from "../Modules/Admin/Document";
import CommunicationLayout from "../Modules/Admin/Communication";
import StaffLayout from "../Modules/Staff";
import ParticipantDocumentLayout from "../participantDocument";
import XeroReportLayout from "../Modules/Admin/report";
import ClientLayout from "../Modules/Client";

export interface RouteProps {
  layout: string;
  path: string;
  component: ReactNode;
}

const routes: RouteProps[] = [
  {
    layout: "/app",
    path: "/dashboard",
    component: <Dashboard />,
  },
  {
    layout: "/app",
    path: "/setup/*",
    component: <SetupLayout />,
  },
  {
    layout: "/app",
    path: "/user/*",
    component: <UserManagementLayout />,
  },
  {
    layout: "/app",
    path: "/shift/*",
    component: <CalendarLayout />,
  },
  {
    layout: "/app",
    path: "/reports/*",
    component: <ReportLayout />,
  },
  {
    layout: "/app",
    path: "/forms/*",
    component: <FormLayout />,
  },
  {
    layout: "/app",
    path: "/document/*",
    component: <DocumentLayout />,
  },
  {
    layout: "/app",
    path: "/communication/*",
    component: <CommunicationLayout />,
  },
  {
    layout: "/app",
    path: "/participant/*",
    component: <ParticipantDocumentLayout />,
  },
  {
    layout: "/app",
    path: "/report/*",
    component: <XeroReportLayout />,
  },
  {
    layout: "/app",
    path: "/staff/*",
    component: <StaffLayout />,
  },
  {
    layout: "/app",
    path: "/client/*",
    component: <ClientLayout />,
  },
];

export default routes;
