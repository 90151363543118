import React, { useEffect, useRef, useState } from "react";
import Hero from "./__component/Hero";
import { CompanyDetail } from "../../types/AuthData";
import { fetchNDISdoc } from "../../services/fetchServices";
import { NdisForm, Representative } from "../../types/DataType";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { Client } from "../../store/slices/clientSlice";
import EditNDISForm from "./__component/EditNDISForm";
import EditedAbout from "./__component/EditedAbout";
import Pricing from "./__component/Pricing";
import EditedPayments from "./__component/EditedPayment";
import { Staff } from "../../store/slices/staffSlice";
import Attachment from "./__component/Attachment";
import { Schedule } from "../../store/slices/ClientSlice/clientSupport";
import Spinner from "../../Components/Spinner";
import axiosInstance from "../../store/axiosInstance";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import PrintComponent from "../PrintTab";
interface Props {
  companyOne: CompanyDetail | null | undefined;
  uid: string | undefined;
  ndisForm: NdisForm | null;
  clientDetail: Client | null;
  appointmentText: string[];
  paymentText: string[];
  fundingAvailability: boolean;
  setFundingAvailability: React.Dispatch<React.SetStateAction<boolean>>;
  careManager: Staff | null;
  clientRep: Representative | null;
  handleSignatureSave: (signatureBlob: Blob) => void;
  setSignature: React.Dispatch<React.SetStateAction<Blob | null>>;
  signature: Blob | null;
  clientSupportSchedule: Schedule[];
}

const EditServiceAgreement = ({
  companyOne,
  uid,
  clientDetail,
  appointmentText,
  ndisForm,
  fundingAvailability,
  setFundingAvailability,
  paymentText,
  careManager,
  clientRep,
  handleSignatureSave,
  setSignature,
  signature,
  clientSupportSchedule,
}: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);

  const componentRef = useRef<HTMLDivElement>(null);

  const [editedNdisForm, setEditNdisForm] = useState<NdisForm>({
    ndisFormId: 0,
    document_Name: "",
    provider_Name: "",
    provider_Reg_No: "",
    appointment_Option: "",
    cancellation_Option: "",
    funding_Availability: false,
    payment_Option: "",
    implementationDate: "",
    expirationDate: "",
    profileId: 0,
    profile: null,
    companyId: 0,
    signatureUrl: "",
    signatureFile: null,
    dateCreated: "",
    userCreated: null,
    dateModified: "",
    userModified: null,
  });
  const [paymentOptions, setPaymentOptions] = useState<string[]>([]);
  const [appointmentOptions, setAppointmentOptions] = useState<string[]>([]);
  const [availability, setAvailability] = useState(false);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => {
    const target = e.target as HTMLInputElement; // Treat all inputs as HTMLInputElement for simplicity
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setEditNdisForm((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditNdisForm((prevState) => ({
      ...prevState!,
      [name]: value ? `${value}T00:00:00` : null,
    }));
  };
  useEffect(() => {
    fetchNDIS();
  }, [uid]);
  const fetchNDIS = async () => {
    const data = await fetchNDISdoc(
      Number(uid),
      Number(user?.companyId),
      "PCD-100 P01 Service agreement"
    );
    setEditNdisForm({ ...data });
    setAppointmentOptions(data.appointment_Option.split(", "));
    setAvailability(data.funding_Availability);
    setPaymentOptions(data.payment_Option.split(", "));
  };
  const handlePaymentChange = (option: string) => {
    setPaymentOptions((currentOptions) => {
      const isOptionSelected = currentOptions.includes(option);
      if (isOptionSelected) {
        return currentOptions.filter((item) => item !== option);
      } else {
        return [...currentOptions, option];
      }
    });
  };

  const handleAppointmentChange = (option: string) => {
    setAppointmentOptions((currentOptions) => {
      const isOptionSelected = currentOptions.includes(option);
      if (isOptionSelected) {
        return currentOptions.filter((item) => item !== option);
      } else {
        return [...currentOptions, option];
      }
    });
  };
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    // if (!signature) {
    //     return toast.error("Signature is required");
    // }
    const formData = new FormData();
    appointmentOptions &&
      formData.append("Appointment_Option", appointmentOptions.join(", "));
    paymentOptions &&
      formData.append("Payment_Option", paymentOptions.join(", "));
    formData.append("Document_Name", "PCD-100 P01 Service agreement");
    formData.append("Cancellation_Option", "false");
    uid && formData.append("ProfileId", uid);
    formData.append("CompanyId", user?.companyId.toString() || "");
    formData.append("funding_Availability", availability.toString());
    if (signature) {
      formData.append("SignatureFile", signature, "signature.png"); // Append the blob, specify filename
    }

    for (const key in editedNdisForm) {
      const value = editedNdisForm[key as keyof typeof editedNdisForm];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    // for (var pair of formData.entries()) {
    //     console.log(pair[0] + ', ' + pair[1]);
    // }
    try {
      setLoading(true);

      const { data } = await axiosInstance.post(
        `/NDISForms/edit_form/${ndisForm?.ndisFormId}?userId=${user?.uid}`,
        formData
      );

      if (data.status === "Success") {
        toast.success(data.message);
        setLoading(false);
        fetchNDIS();
      } else {
        toast.error(data.message);
        setLoading(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print this Document"
        documentTitle="NDIS Service Agreement"
      />

      <form action="" onSubmit={handleSubmit}>
        <div
          className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white"
          ref={componentRef}
        >
          <Hero companyOne={companyOne} />
          <EditNDISForm
            clientDetail={clientDetail}
            editedNdisForm={editedNdisForm}
            handleInputChange={handleInputChange}
          />
          <EditedAbout
            companyOne={companyOne}
            handleAppointmentChange={handleAppointmentChange}
            appointmentOptions={appointmentOptions}
            appointmentText={appointmentText}
          />

          <Pricing
            companyOne={companyOne}
            fundingAvailability={fundingAvailability}
            setFundingAvailability={setFundingAvailability}
            availability={availability}
            ndisForm={ndisForm}
            setAvailability={setAvailability}
          />
          <EditedPayments
            companyOne={companyOne}
            handlePaymentChange={handlePaymentChange}
            paymentOptions={paymentOptions}
            paymentText={paymentText}
            editedNdisForm={editedNdisForm}
            handleInputChange={handleInputChange}
            careManager={careManager}
            clientDetail={clientDetail}
            clientRep={clientRep}
            handleSignatureSave={handleSignatureSave}
            setSignature={setSignature}
            signature={signature}
            handleDateChange={handleDateChange}
          />
          <Attachment clientSupportSchedule={clientSupportSchedule} />
        </div>
        <div className="mx-auto text-center mt-3 lg:col-span-2">
          <button
            type="submit"
            disabled={loading}
            className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
          >
            {loading ? <Spinner /> : "Update"}
          </button>
        </div>
      </form>
    </>
  );
};

export default EditServiceAgreement;
