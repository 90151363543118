import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";
import { Shift } from "./shiftRosterSlice";

export interface ShiftReport {
    shiftReportId: number;
    urgentMatters: string;
    medicationGiven: string;
    medicationSigned: string;
    medicationAvailable: string;
    medicatioErrors: string;
    isMealManagementPlan: boolean;
    details_IfNotMealMaganagementPlan: string;
    isDrinkingProblem: boolean;
    details_IfProblemExist: string;
    isHealthIssues: true;
    details_IfHealthIssuesExist: string;
    goal_Progress: string;
    contactFamily: string;
    isIncident: boolean;
    details_IfIsIncipient: string;
    isBehaviourConcerned: boolean;
    details_ifIsBehaviourConcerned: string;
    shiftRosterId: number;
    shiftRoster: Shift;
    companyID: number;
    dateCreated: string;
    userCreated: string;
    dateModified: string;
    userModified: string | null;
}

export interface ShiftReportState {
    loading: boolean;
    data: Array<ShiftReport>;
    error: string | undefined;
}
const initialState: ShiftReportState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchShiftReport = createAsyncThunk(
    "ShiftReport/fetchShiftReport",
    async (companyId: number) => {
        try {
            const response = await api.fetchShiftReport(companyId);
            return response.sort(
                (a: ShiftReport, b: ShiftReport) =>
                    new Date(b.dateCreated).getTime() - new Date(a.dateCreated).getTime()
            );
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const shiftReportSlice = createSlice({
    name: 'shiftReport',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchShiftReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchShiftReport.fulfilled, (state, action: PayloadAction<Array<ShiftReport>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchShiftReport.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.shiftReport;
export default shiftReportSlice.reducer;


export const shiftReportData = (state: RootState) => state.shiftReport.data;
export const shiftReportLoading = (state: RootState) => state.shiftReport.loading;
export const shiftReportError = (state: RootState) => state.shiftReport.error;