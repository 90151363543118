import React from 'react'
import { useNavigate } from 'react-router-dom';
import AuthButton from '../../../Components/Buttons/AuthButton';

const SetupBreak = () => {

    const navigate = useNavigate();
    const goTo = () => {

        navigate(`/setup/business/2`, { replace: true });

    }


    return (
        <div className='bg-lightbg h-screen overflow-auto'>

            <main className='max-w-screen-xl mx-auto container'>
                <section className="relative   lg:py-0  ">

                    <div className="w-full px-4 pt-8 sm:px-6  hidden md:hidden lg:block xl:block lg:px-20  overflow-y-auto">
                        <span >

                        </span>

                    </div >

                    <div className='px-2 py-8 sm:px-6 '>


                        <div className='mx-auto mt-10 max-w-2xl  flex flex-col items-center gap-8 lg:px-10 px-4 py-8 sm:px-6 text-center  rounded-2xl' >
                            <div>
                                <svg width="150" height="141" viewBox="0 0 192 191" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="96" cy="95.6328" r="92.875" stroke="#4BBA39" strokeWidth="5" />
                                    <path d="M55.125 88.8203L86.5673 122.883L136.875 68.3828" stroke="#4BBA39" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>


                            </div>
                            <h1 className='text-4xl font-bold text-black'>
                                You are Almost There!
                            </h1>
                            <p className='text-sm mt-4 font-semibold'>
                                "Great job completing your business profile! Now, let's dive into everything you need to kickstart your HR assignments."
                            </p>

                            <div className="w-full lg:w-[70%] mt-4">


                                <AuthButton type='button' onClick={goTo}>
                                    Continue  ▶
                                </AuthButton>
                            </div>



                        </div>

                    </div>
                </section>
            </main>


        </div>
    )
}

export default SetupBreak