import React, { useRef } from "react";
import Hero from "./__component/Hero";
import SkillForm from "./__component/SkillForm";
import Breadcrumb from "../../Components/label/BreadCrumb";
import PrintComponent from "../PrintTab";

const SkillDevelopment = () => {
  const componentRef = useRef<HTMLDivElement>(null);
  return (
    <div>
      <Breadcrumb />
      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print this Document"
        documentTitle="Skill Development Form"
      />
      <div ref={componentRef} className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white">
        <Hero />
        <div>
          <div className="flex items-center h-10 bg-slate-400  font-bold divide-x divide-gray-300">
            <div className="w-[30%] text-[20px] px-6">
              Daily care or life skill goal{" "}
            </div>
            <div className="w-[70%] text-[20px] px-6">I want to: </div>
          </div>
          <div className="flex items-center h-8 bg-gray-300  font-bold divide-x divide-gray-400">
            <div className="w-[48%] px-2">
              Steps I need to take to achieve my goal
            </div>
            <div className="w-[18%] px-2">Progress on achieving goal</div>
            <div className="w-[30%] px-2">Comments on progress</div>
          </div>
          <SkillForm />
        </div>
      </div>
    </div>
  );
};

export default SkillDevelopment;
