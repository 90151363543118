import React, { ButtonHTMLAttributes } from 'react';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>

const AuthButton: React.FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <button
            {...props}
            className={`px-4 py-3 bg-primary text-white rounded-md disabled:bg-slate-400
        transition duration-300 transform active:scale-95 ease-in-out
        w-full ${props.className || ''}`}
        >
            {children}
        </button>
    );
};

export default AuthButton;
