import React from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import { Client } from "../../../store/slices/clientSlice";
import ClientDailyLiving from "../../Admin/Profiles/clientTab/DailyLiving";

const DailyLiving = () => {

    const clientProfileString = sessionStorage.getItem("clientProfile");
    const clientProfile: Client = clientProfileString
        ? JSON.parse(clientProfileString)
        : null;

    return (
        <>
            <SEO title={` Daily Living & Night Support`} description="Client" name="Promax-Care" />
            <Breadcrumb showNavigation={false} />

            <div>
                <ClientDailyLiving uid={clientProfile.profileId.toString()} />
            </div>
        </>
    );
};

export default DailyLiving;
