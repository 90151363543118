import React, { ReactNode, ErrorInfo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorComponent from './ErrorComponent';

interface ErrorBoundaryWrapperProps {
    children: ReactNode;
}

const ErrorBoundaryWrapper: React.FC<ErrorBoundaryWrapperProps> = ({ children }) => {
    return (
        <ErrorBoundary
            FallbackComponent={ErrorComponent}
            onError={(error: Error, errorInfo: ErrorInfo) => {
                // Handle or log the error and the error info
                console.error('Error occurred:', error);
                console.error('Error info:', errorInfo.componentStack || "No component stack available.");
            }}
            onReset={() => {
                // Optional: Implement any logic to reset the error boundary
                console.log('Error boundary reset.');
            }}
        >
            {children}
        </ErrorBoundary>
    );
};

export default ErrorBoundaryWrapper;
