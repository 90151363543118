import React from 'react'

const Section2 = () => {
    return (
        <div className=" p-4 max-[1024px]:w-[100%]  my-3 border border-black ">
            <strong>Services and community access</strong>
            <div className='my-6'>
                <p>I give my consent for the participant named above to attend services provided by Insert name. I understand that a degree of risk is involved with the provision of support. I understand that while staff will take the utmost care, they will not accept any personal responsibility for any personal illness or accident nor accept any responsibility for loss/damage of property that may occur:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input id='ye' type="checkbox" />
                        <label htmlFor="ye">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input id='nop' type="checkbox" />
                        <label htmlFor="nop">No</label>
                    </p>
                </div>
            </div>

            <div className='my-6'>
                <p>I give consent for Maxicare Plus to obtain medical treatment for the participant in the event of any emergency:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input id='ye' type="checkbox" />
                        <label htmlFor="ye">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input id='nop' type="checkbox" />
                        <label htmlFor="nop">No</label>
                    </p>
                </div>
            </div>

            <div className='my-6'>
                <p>I give consent for the participant to travel in the worker’s insured vehicle, Maxicare Plus owned vehicles as well as public transport alternatives:</p>
                <div className='flex my-2 items-center gap-20'>
                    <p className='flex items-center gap-2'>
                        <input id='ye' type="checkbox" />
                        <label htmlFor="ye">Yes</label>
                    </p>
                    <p className='flex items-center gap-2'>
                        <input id='nop' type="checkbox" />
                        <label htmlFor="nop">No</label>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Section2