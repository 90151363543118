import React, { useEffect, useState } from 'react';
import { CheckPicker, Modal } from 'rsuite';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { isAxiosError } from 'axios';
import { AppDispatch, RootState, useAppDispatch } from '../../../../store/store';
import { ClientShift, fetchClientShift } from '../../../../store/slices/clientShiftSlice';
import { fetchRosterInfo } from '../../../../services/fetchServices';
import axiosInstance from '../../../../store/axiosInstance';
import Spinner from '../../../../Components/Spinner';
import { Client } from '../../../../store/slices/clientSlice';
import { activityOptions } from './data';



interface Props {
    editId: number;
    editModal: boolean;
    setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
}


const EditRoster = ({ editModal, setEditModal, editId }: Props) => {
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [shiftOne, setShiftOne] = useState<ClientShift | null>(null);
    const [selectedActivities, setSelectedActivities] = useState<string[]>([]);


    const dispatch: AppDispatch = useAppDispatch();
    const clientProfileString = sessionStorage.getItem("clientProfile");
    const clientProfile: Client = clientProfileString
        ? JSON.parse(clientProfileString)
        : null;

    useEffect(() => {
        const handleFetchRoster = async () => {
            setLoading(true);
            try {
                const data: ClientShift = await fetchRosterInfo(editId);
                setShiftOne(data);
                const { activities }: ClientShift = await fetchRosterInfo(editId);
                // Assuming activities is a comma-separated string of activity values
                const selectedActivities = activities.split(', ').filter(activity =>
                    activityOptions.some(option => option.value === activity.trim())
                );
                setSelectedActivities(selectedActivities);

            } catch (error) {
                console.error('Failed to fetch roster info:', error);
            } finally {
                setLoading(false);
            }
        };

        if (editId && editModal) {
            handleFetchRoster();
        }
    }, [editId, editModal]);

    const handleUpdateActivities = async (e: React.FormEvent) => {
        e.preventDefault();

        const info = {
            ...shiftOne,
            companyID: user?.companyId,
            shiftRosterId: editId,
            activities: selectedActivities.join(', ')

        }
        delete info.profile;
        delete info.staff;
        setLoadingSubmit(true);



        try {
            setLoadingSubmit(true)
            const { data } = await axiosInstance.post(`/ShiftRosters/edit_shift/${editId}?userId=${user?.uid}`, info)
            toast.success(data.message)

            setLoadingSubmit(false);
            if (clientProfile) {
                dispatch(fetchClientShift(Number(clientProfile.profileId)));
            }
            setEditModal(false);

        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("Error Editing Shift");
            }

            setLoadingSubmit(false)
        } finally {
            setLoadingSubmit(false);
            setEditModal(false);
        }

    };




    return (
        <Modal open={editModal} onClose={() => setEditModal(false)} backdrop="static" autoFocus size="sm">
            <Modal.Header>
                <Modal.Title className='font-bold'>Edit Activities</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Spinner />
                ) : (
                    <div>
                        <form action="" className='p-2 grid grid-cols-1 gap-4 lg:grid-cols-2' onSubmit={handleUpdateActivities}>


                            <div className='lg:col-span-2'>
                                <label className="text-xs text-gray-600 font-semibold">Activities</label>
                                <CheckPicker
                                    data={activityOptions}
                                    onChange={setSelectedActivities}
                                    value={selectedActivities}
                                    size="lg"
                                    placeholder="Select Activities"
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    searchable={false}  // Added to simplify the picker interaction
                                    style={{ width: '100%' }}  // Ensure full width within modal
                                />
                            </div>

                            <div className='mx-auto text-center mt-3 lg:col-span-2'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary">
                                    {
                                        loadingSubmit ?
                                            <Spinner /> : " Update"
                                    }

                                </button>

                            </div>


                        </form>
                    </div>
                )}
            </Modal.Body>

        </Modal>
    );
};

export default EditRoster;
