import React from 'react'

const Hero = () => {
  return (
    <div>
        <div className="flex justify-end">
          <img
            className="w-24  mb-4 lg:mb-4"
            src="https://profitmax-001-site10.ctempurl.com/Uploads/botosoft-300x183-1-300x183235551864.png"
            alt="Promaxcare Logo"
          />
        </div>
        <p className=' text-2xl font-semibold'>Skill development plan</p>
        <p className='text-[18px]'>Transfer the skill goal from the participant’s completed support plan. Complete a new sheet for each identified goal. Add more steps as necessary. Progress towards achieving goals is to be reviewed at the support plan review meeting.</p>
        <div className='flex items-center my-4 gap-10'>
            <p className='text-[18px]'>Participant name: <input className='border outline-none border-gray-300' type="text" /></p>
            <p className='text-[18px]'>Date completed: <input className='border outline-none border-gray-300' type="date" /></p>
        </div>
    </div>
  )
}

export default Hero