import React from 'react'
import { Link } from 'react-router-dom'
import { StaffProfile } from '../../../../store/slices/staffProfileSlice'
interface Props {
    profile: StaffProfile | null
}

const ProfileCard = ({ profile }: Props) => {
    return (
        <div className="bg-white relative shadow rounded-2xl p-2">
            <div className='border border-primary rounded-2xl border-dashed '>
                <div className="flex justify-center">
                    <img src={profile?.imageUrl} alt="img" className="rounded-full mx-auto absolute -top-12 w-24 h-24 shadow-md border-4 border-white transition duration-200 transform hover:scale-110" />
                </div>
                <div className="mt-10 p-2">
                    <h1 className="font-bold text-center text-xl text-gray-900">{profile?.fullName}</h1>
                    <p className="text-center text-sm text-gray-400 font-medium">Email: {profile?.email}</p>
                    <p className="text-center text-sm text-gray-400 font-medium">Staff ID: {profile?.maxStaffId}</p>

                    <div className='p-2  h-28'>

                        <div className="mt-5 w-full flex flex-col items-center overflow-hidden text-sm">


                        </div>
                    </div>

                    <div className='text-center my-3 '>
                        <Link to="/app/staff/profile"

                            className="text-white bg-primary hover:bg-primary hover:text-white
font-medium rounded-full text-xs px-16 py-2  ">
                            View Profile

                        </Link>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileCard