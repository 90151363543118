import dayjs from "dayjs";
import React, { FormEvent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Spinner from "../../../Components/Spinner";
import SEO from "../../../constants/SEO";
import { clientShiftData, ClientShift, clientShiftLoading, fetchClientShift } from "../../../store/slices/clientShiftSlice";
import { Client } from "../../../store/slices/clientSlice";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import EmptyShift from "./components/EmptyShift";
import ProfileCard from "./components/ProfileCard";
import TodayShift from "./components/TodayShift";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Upcoming from "./components/Upcoming";
import Modals from "./components/Modals";
import EditRoster from "./components/EditRoster";
import { fetchClientPercentage, fetchRosterInfo } from "../../../services/fetchServices";
import { toast } from "react-toastify";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";

const ClientDashboard = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Australia/Sydney");
  const nowInAustraliaTime = dayjs().tz().format("YYYY-MM-DD");
  const clientProfileString = sessionStorage.getItem("clientProfile");
  const clientProfile: Client = clientProfileString
    ? JSON.parse(clientProfileString)
    : null;

  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    if (clientProfile.profileId) {
      dispatch(fetchClientShift(Number(clientProfile.profileId)));
    }
    handleFetchPercentage();
  }, [dispatch, clientProfile.profileId]);


  const [clientPercentage, setClientPercentage] = useState<number>(0);


  const handleFetchPercentage = async () => {
    const data = await fetchClientPercentage(clientProfile.profileId);
    setClientPercentage(data);
  };



  const clientShift = useSelector(clientShiftData);

  const loading = useSelector(clientShiftLoading)
  const [selectedShift, setSelectedShift] = useState<ClientShift | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [saveShiftId, setSaveShiftId] = useState<number>(0);
  const [reasonModal, setReasonModal] = useState<boolean>(false);
  const [appointmentModal, setAppointmentModal] = useState<boolean>(false);
  const [editLoading, setEditLoading] = useState<boolean>(false);
  const [reason, setReason] = useState<string>("");
  const [appointment, setAppointment] = useState<string>("");
  const tomorrow = dayjs().tz().add(1, "day").startOf("day");
  const filteredAndSortedShifts = clientShift
    .filter((shift) =>
      dayjs(shift.dateFrom).isAfter(tomorrow.subtract(1, "second"))
    )
    .sort((a, b) => dayjs(a.dateFrom).diff(dayjs(b.dateFrom)));

  //todayShift
  const allTodayShifts = clientShift
    .filter(
      (shift: ClientShift) =>
        dayjs(shift.dateFrom).format("YYYY-MM-DD") === nowInAustraliaTime ||
        (shift.attendance && shift.attendId > 0 && !shift.isEnded)
    )
    .sort(
      (a: ClientShift, b: ClientShift) =>
        new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime()
    );

  const handleShiftClick = (shift: ClientShift) => {
    setSelectedShift(shift);
    setShowModal(true);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState(0);

  const handleEdit = (shiftId: number) => {
    setEditId(shiftId);
    setEditModal(true);
  };

  const requestCancel = async (shiftId: number) => {
    setSaveShiftId(shiftId);
    setEditLoading(true);
    setReasonModal(true);
    try {
      const { reason }: ClientShift = await fetchRosterInfo(shiftId);
      setReason(reason);
      setEditLoading(false);
    } catch (error) {
      console.error("Failed to fetch roster info:", error);
    } finally {
      setEditLoading(false);
    }
  };

  const addAppointMent = async (shiftId: number) => {
    setSaveShiftId(shiftId);
    setEditLoading(true);
    setAppointmentModal(true);
    try {
      const { appointment }: ClientShift = await fetchRosterInfo(shiftId);
      setAppointment(appointment);
      setEditLoading(false);
    } catch (error) {
      console.error("Failed to fetch roster info:", error);
    } finally {
      setEditLoading(false);
    }
  };

  const submitReason = async (e: FormEvent) => {
    e.preventDefault();

    if (reason === "") {
      return toast.error("Input Fields cannot be empty");
    }

    setIsLoading(true);

    try {
      const { data } = await axiosInstance.get(`/ShiftRosters/client_shift_cancellation?userId=${user?.uid}&reasons=${reason}&shiftid=${saveShiftId}`)
      toast.success(data.message);
      setIsLoading(false);
      setReasonModal(false);
      dispatch(fetchClientShift(Number(clientProfile.profileId)));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const submitAppointment = async (e: FormEvent) => {
    e.preventDefault();

    if (appointment === "") {
      return toast.error("Input Fields cannot be empty");
    }

    setIsLoading(true);

    try {
      const { data } = await axiosInstance.post(`ShiftRosters/add_appointment?userId=${user?.uid}&shiftId=${saveShiftId}&appointment=${appointment}`);
      toast.success(data.message);
      setIsLoading(false);
      setAppointmentModal(false);
      dispatch(fetchClientShift(Number(clientProfile.profileId)));
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  let bgColor: string;
  if (clientPercentage && clientPercentage >= 70) {
    bgColor = 'bg-green-500';
  } else if (clientPercentage && clientPercentage >= 60) {
    bgColor = 'bg-primary';
  } else if (clientPercentage && clientPercentage >= 50) {
    bgColor = 'bg-secondary';
  } else {
    bgColor = 'bg-red-500';
  }


  return (
    <>
      <SEO title="Dashboard" description="Dashboard" name="Promax-Care" />
      <div className="my-10 grid grid-cols-1 gap-4 lg:gap-8 md:gap-6 md:grid-cols-2 xl:grid-cols-3">
        <ProfileCard bgColor={bgColor} percentage={clientPercentage} profile={clientProfile} />

        {loading && (
          <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
            <div className="relative w-auto max-w-lg mx-auto">
              <div
                className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
              >
                <Spinner color="#071952" />
              </div>
            </div>

            <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
          </div>
        )}

        {allTodayShifts.length > 0 ? (
          allTodayShifts.map((activity, index) => (
            <TodayShift
              key={index}
              activity={activity}
              handleEdit={handleEdit}
              requestCancel={requestCancel}
              addAppointMent={addAppointMent}
            />
          ))
        ) : (
          <EmptyShift />
        )}

        {filteredAndSortedShifts.length > 0 && (
          <Upcoming
            filteredAndSortedShifts={filteredAndSortedShifts}
            handleShiftClick={handleShiftClick}
          />
        )}
      </div>

      <Modals
        selectedShift={selectedShift}
        setShowModal={setShowModal}
        showModal={showModal}
        loading={isLoading}
        appointment={appointment}
        setAppointment={setAppointment}
        setReason={setReason}
        reason={reason}
        setReasonModal={setReasonModal}
        reasonModal={reasonModal}
        submitReason={submitReason}
        editLoading={editLoading}
        appointmentModal={appointmentModal}
        setAppointmentModal={setAppointmentModal}
        submitAppointment={submitAppointment}
      />

      <EditRoster
        editId={editId}
        editModal={editModal}
        setEditModal={setEditModal}
      />
    </>
  );
};

export default ClientDashboard;
