import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { CheckPicker, InputPicker, Modal } from 'rsuite';
import CustomInput from '../../../../../Components/Input/CustomInput';
import { Shift, fetchShiftRoster } from '../../../../../store/slices/shiftRosterSlice';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState, useAppDispatch } from '../../../../../store/store';
import { ZodType, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../../store/axiosInstance';
import Spinner from '../../../../../Components/Spinner';
import { fetchStaff, staffData } from '../../../../../store/slices/staffSlice';
import { ConfirmModal } from './ConfirmModal';
import { formatClient, formattedClientData } from '../../../../../store/slices/clientSlice';
import { activityOptions } from './data';
// import CustomInput from '../../../../../Components/Input/CustomInput';

type DataType = {
    startKm: string;
    endKm: string;
    report: string;
};

interface Props {
    deleteModal: boolean,
    setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleDelete: (shiftId: number) => Promise<void>;
    delLoading: boolean;
    markAttendanceModal: boolean;
    setMarkAttendanceModal: React.Dispatch<React.SetStateAction<boolean>>;
    reAssignModal: boolean;
    setReAssignModal: React.Dispatch<React.SetStateAction<boolean>>;
    cancelShiftModal: boolean;
    setCancelShiftModal: React.Dispatch<React.SetStateAction<boolean>>;
    activity: Shift | undefined;
    unMarkModal: boolean;
    loadingUnMark: boolean;
    setUnMarkModal: React.Dispatch<React.SetStateAction<boolean>>;
    unMarkAttendance: (shiftId: number) => Promise<void>;
    showConfirmModal: boolean;
    setShowConfirmModal: React.Dispatch<React.SetStateAction<boolean>>;
    loadingConfirm: boolean;
    handleConfirm: (shiftId: number) => Promise<void>;
    showRejectModal: boolean;
    setShowRejectModal: React.Dispatch<React.SetStateAction<boolean>>;
    loadingReject: boolean;
    handleRejectCancelShift: (shiftId: number) => Promise<void>;
    addModal: boolean,
    setAddModal: React.Dispatch<React.SetStateAction<boolean>>;
    duplicateDate: string
    duplicateModal: boolean
    loadingDuplicate: boolean
    loadingMove: boolean
    setDuplicateModal: React.Dispatch<React.SetStateAction<boolean>>
    moveModal: boolean
    setMoveModal: React.Dispatch<React.SetStateAction<boolean>>
    duplicateAttendance: (shiftId: number) => Promise<void>
    moveAttendance: (shiftId: number) => Promise<void>
    setDuplicateDate: React.Dispatch<React.SetStateAction<string>>
    setDatefrom: React.Dispatch<React.SetStateAction<string>>
    dateFrom: string
    multipleModal: boolean
    loadingMultiple: boolean
    setMultipleModal: React.Dispatch<React.SetStateAction<boolean>>
    handleMultipleDelete: () => Promise<void>
    selectedShifts: number[];
    repeatModal: boolean;
    setRepeatModal: React.Dispatch<React.SetStateAction<boolean>>
    setSelectedStaff: (staffId: number) => void;
    selectedStaff: number;
    filterRoster: () => void

}
const LargeModal = ({ markAttendanceModal, setMarkAttendanceModal, activity, reAssignModal,
    setReAssignModal, cancelShiftModal, setCancelShiftModal, delLoading, deleteModal, handleDelete, setDeleteModal,
    loadingUnMark, setUnMarkModal, unMarkAttendance, unMarkModal, handleConfirm, handleRejectCancelShift, loadingConfirm, loadingReject, setShowConfirmModal,
    setShowRejectModal, showConfirmModal, showRejectModal, addModal, setAddModal, duplicateDate, duplicateAttendance, duplicateModal, loadingDuplicate,
    loadingMove, moveAttendance, moveModal, setDuplicateDate, setDuplicateModal, setMoveModal, dateFrom, setDatefrom, loadingMultiple, multipleModal, setMultipleModal, handleMultipleDelete,
    selectedShifts, repeatModal, setRepeatModal, setSelectedStaff, selectedStaff, filterRoster

}: Props) => {

    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const user = useSelector((state: RootState) => state?.auth?.user);
    // const [selectedStaff, setSelectedStaff] = useState<number>(0);
    const [selectedClient, setSelectedClient] = useState<string[]>([]);
    const [selectedActivity, setSelectedActivity] = useState<string[]>([]);
    // const [dateFrom, setDatefrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [isNightShift, setIsNightShift] = useState(false);
    const [isExceptionalShift, setIsExceptionalShift] = useState(false);
    const [days, setDays] = useState({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
    });
    const [stopDate, setStopDate] = useState("");


    const [repeat, setRepeat] = useState(false);

    const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setDays((prevDays) => ({ ...prevDays, [name]: checked }));
    };


    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchStaff(companyId));
            dispatch(formatClient(companyId));
        }
    }, [user?.companyId]);
    const formattedClients = useSelector(formattedClientData);
    const staff = useSelector(staffData);

    const schema: ZodType<DataType> = z.object({
        startKm: z.string()
            .regex(/^\d+$/, "Must be a number")
            .min(1, 'Start KM is required'),
        endKm: z.string()
            .regex(/^\d+$/, "Must be a number")
            .min(1, 'End KM is required'),
        report: z.string(),
    });




    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<DataType>({
        resolver: zodResolver(schema),
    });

    const onSubmit = async (data: DataType) => {
        const requstBody = {
            ...data,
            clockIn: activity?.dateFrom,
            clockInCheck: true,
            clockOutCheck: true,
            clockOut: activity?.dateTo,
            staffId: activity?.staff?.staffId,
            companyID: user?.companyId
        }
        setLoadingSubmit(true);
        try {
            const { data } = await axiosInstance.post(`/Attendances/mark_attendance?userId=${user?.uid}&shiftId=${activity?.shiftRosterId}`,
                requstBody);
            if (data.status === "Success") {

                if (companyId) {
                    dispatch(fetchShiftRoster(companyId));
                }
                toast.success(data.message)
                setLoadingSubmit(false)
                setMarkAttendanceModal(false);
            }
            setLoadingSubmit(false)
        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("Error Marking Attendance");
            }
            setLoadingSubmit(false)
        }
        finally {
            setLoadingSubmit(false)
        }

    };

    const [users, setUsers] = useState<string>("");
    const [loadingReassign, setLoadingReassign] = useState(false);

    const handleReassign = async (e: React.FormEvent) => {
        e.preventDefault();

        setLoadingReassign(true);
        try {
            const { data } = await axiosInstance.get(`/ShiftRosters/reassign_staff?shiftId=${activity?.shiftRosterId}&staffId=${users}&userId=${user?.uid}`)
            // console.log(data)

            if (data.status === "Success") {
                toast.success(data.message);
                if (companyId) {
                    dispatch(fetchShiftRoster(companyId));
                }
                setLoadingReassign(false);
                setReAssignModal(false);
                setUsers("")
            }
            setLoadingReassign(false)
        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("Error Marking Attendance");
            }
            setLoadingReassign(false)
            setReAssignModal(false);
        }
        finally {
            setLoadingReassign(false)
            setReAssignModal(false);
        }

    }


    const [cancelDate, setCancelDate] = useState<string>("");
    const [loadingCancel, setLoadingCancel] = useState<boolean>(false);


    const cancelShift = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingCancel(true)
        if (cancelDate.trim() === "") {
            return toast.error("Please Provide A Date")
        }
        try {

            const { data } = await axiosInstance.get(`/ShiftRosters/cancel_client_shift?shiftId=${activity?.shiftRosterId}&userId=${user?.uid}&cancellationdate=${cancelDate}`)
            if (data.status === "Success") {
                toast.success(data.message)
                setCancelDate("");
                setCancelShiftModal(false);
                setLoadingCancel(false);
                if (companyId) {
                    dispatch(fetchShiftRoster(companyId));
                }
            }

        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("Error Cancelling Shift");
            }
            setCancelShiftModal(false);
            setLoadingCancel(false);
        }
        finally {
            setCancelShiftModal(false);
            setLoadingCancel(false);
        }
    };

    const [loading, setLoading] = useState(false);

    const handleSubmitShift = async (e: React.FormEvent) => {
        e.preventDefault()

        if (selectedStaff === 0 && selectedClient.length <= 0
        ) {
            return toast.error("Select Either a staff or client")
        }

        if (repeat && stopDate === "") {
            return toast.error("Select a Stop Date");
        }

        try {
            setLoading(true)
            const { data } = await axiosInstance.post(`/ShiftRosters/add_multipleclient_shifts?userId=${user?.uid}`,
                {
                    companyId: companyId,
                    staffId: selectedStaff,
                    dateFrom,
                    dateTo,
                    activities: selectedActivity.join(', '),
                    clientList: selectedClient.join(', '),
                    isNightShift,
                    isExceptionalShift,
                    repeat,
                    monday: days.monday,
                    tuesday: days.tuesday,
                    wednesday: days.wednesday,
                    thursday: days.thursday,
                    friday: days.friday,
                    saturday: days.saturday,
                    sunday: days.sunday,
                    stopDate: stopDate
                }
            )
            // if (companyId) {
            //     dispatch(fetchShiftRoster(companyId));
            // }
            filterRoster();
            setSelectedActivity([]);
            setSelectedClient([]);
            setStopDate("");
            setDateTo("");

            // setSelectedStaff(0);
            setDays({
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false
            })


            toast.success(data.message)
            setAddModal(false)
            setLoading(false)

        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("Error Creating Shift");
            }

            setLoading(false)
        }
        finally {

            setLoading(false)
        }

    }

    const [loadingRepeat, setLoadingRepeat] = useState(false);
    const repeatAttendance = async (e: React.FormEvent) => {
        e.preventDefault();
        // Check if at least one day is selected
        const daysSelected = Object.values(days).some(day => day);

        // If days are selected but stopDate is not provided, show an error and prevent the API call
        if (daysSelected && !stopDate) {
            toast.error("Stop Date is required if any day is selected.");
            return;
        }

        setLoadingRepeat(true);
        try {
            const response = await axiosInstance.post(
                `/ShiftRosters/repeat_shift?userId=${user?.uid}&shiftId=${activity?.shiftRosterId}&Monday=${days.monday}&Tuesday=${days.tuesday}&Wednesday=${days.wednesday}&Thursday=${days.thursday}&Friday=${days.friday}&Saturday=${days.saturday}&Sunday=${days.sunday}&StopDate=${stopDate}`
            );
            const data = response.data;
            toast.success(data.message);
            setLoadingRepeat(false);
            setRepeatModal(false);
            setStopDate("");
            setDays({
                sunday: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false
            })
            if (companyId) {
                dispatch(fetchShiftRoster(companyId));
            }
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("Error duplicating shift");
            }
            setLoadingRepeat(false);
        } finally {
            setLoadingRepeat(false);
        }
    };
    return (
        <>


            {/* AddModal */}
            <Modal
                open={addModal}
                onClose={() => setAddModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Add Shift Roster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='p-2 grid grid-cols-1 gap-4 lg:grid-cols-2' onSubmit={handleSubmitShift}>
                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Staff</label>
                                <InputPicker size="lg" placeholder="--All Staff--"

                                    onChange={value => setSelectedStaff(value)}
                                    data={staff.map(user => ({ label: user.fullName, value: user.staffId }))}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                    value={selectedStaff}
                                />
                            </div>

                            <div>
                                <label className="text-xs text-gray-600 font-semibold">Select Clients</label>
                                <CheckPicker
                                    data={formattedClients} // Ensure this is an array of objects with 'label' and 'value' keys
                                    onChange={setSelectedClient} // Directly pass setSelectedClients here
                                    size="lg"
                                    placeholder="Select clients"
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    value={selectedClient} // Control the component with the selectedClients state

                                />
                            </div>

                            <CustomInput
                                label='Start Date & Time'
                                type='datetime-local'
                                onChange={(e) => setDatefrom(e.target.value)}
                                value={dateFrom}
                                required

                            />

                            <CustomInput
                                label='End  Date &Time'
                                type='datetime-local'
                                onChange={(e) => setDateTo(e.target.value)}
                                value={dateTo}
                                required

                            />
                            <div className='lg:col-span-2'>
                                <label className="text-xs text-gray-600 font-semibold">Activities</label>
                                <CheckPicker
                                    data={activityOptions} // Ensure this is an array of objects with 'label' and 'value' keys
                                    onChange={setSelectedActivity} // Directly pass setSelectedClients here
                                    size="lg"
                                    placeholder="Select Activity"
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    value={selectedActivity} // Control the component with the selectedClients state
                                />
                            </div>

                            <div className="flex items-center">
                                <input id="default-checkbox"
                                    checked={isExceptionalShift}
                                    onChange={() => setIsExceptionalShift(!isExceptionalShift)}
                                    type="checkbox" className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded" />
                                <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Active Night</label>
                            </div>
                            <div className="flex items-center">
                                <input id="default-checkbox"
                                    checked={isNightShift}
                                    onChange={() => setIsNightShift(!isNightShift)}
                                    type="checkbox" className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded" />
                                <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Sleep Over</label>
                            </div>
                            <div className="flex items-center">
                                <input id="default-checkbox"
                                    checked={repeat}
                                    onChange={() => setRepeat(!repeat)}
                                    type="checkbox" className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded" />
                                <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Repeat</label>
                            </div>

                            <div className='lg:col-span-2 '>
                                {repeat &&
                                    <div className='grid grid-cols-1 gap-4'>
                                        <div className='flex gap-2 items-center flex-wrap'>
                                            <p className='font-bold text-sm'>Select Days for Repetition:</p>
                                            {Object.entries(days).map(([day, value]) => (
                                                <label key={day} className={`flex gap-1 items-center text-sm  ${value ? 'font-bold text-black' : 'font-normal'}`}>


                                                    <input
                                                        type="checkbox"
                                                        name={day}
                                                        checked={value}
                                                        onChange={handleCheckboxChange}
                                                        className='w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded'
                                                    />
                                                    {day.charAt(0).toUpperCase() + day.slice(1)}
                                                </label>


                                            ))}
                                        </div>

                                    </div>
                                }
                            </div>

                            {repeat &&
                                <div >
                                    <CustomInput
                                        label='Repetition End Date'
                                        type='datetime-local'
                                        onChange={(e) => setStopDate(e.target.value)}
                                        value={stopDate}

                                    />
                                </div>
                            }
                            <div className='mx-auto text-center mt-3 lg:col-span-2'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary">
                                    {
                                        loading ?
                                            <Spinner /> : " Submit"
                                    }

                                </button>

                            </div>


                        </form>
                    </div>
                </Modal.Body>
            </Modal>








            <Modal open={repeatModal} onClose={() => setRepeatModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Repeat Shift</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={repeatAttendance}>
                        <div className='grid grid-cols-1 gap-4'>
                            <div className='flex gap-2 items-center flex-wrap'>
                                <p className='font-bold text-sm'>Select Days for Repetition:</p>
                                {Object.entries(days).map(([day, value]) => (
                                    <label key={day} className={`flex gap-1 items-center text-sm  ${value ? 'font-bold text-black' : 'font-normal'}`}>


                                        <input
                                            type="checkbox"
                                            name={day}
                                            checked={value}
                                            onChange={handleCheckboxChange}
                                            className='w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded'
                                        />
                                        {day.charAt(0).toUpperCase() + day.slice(1)}
                                    </label>


                                ))}
                            </div>
                            <div >
                                <CustomInput
                                    label='Repetition End Date'
                                    type='datetime-local'
                                    onChange={(e) => setStopDate(e.target.value)}
                                    value={stopDate}

                                />
                            </div>
                        </div>
                        <div className='mx-auto text-center mt-3 lg:col-span-2'>
                            <button type="submit"
                                disabled={loadingRepeat}
                                className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary">
                                {
                                    loadingRepeat ?
                                        <Spinner /> : " Submit"
                                }

                            </button>

                        </div>

                    </form>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
















            <Modal
                open={markAttendanceModal}
                onClose={() => setMarkAttendanceModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Mark Staff Attendance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div>
                                <span className='font-bold text-lg text-black'>
                                    Mark Attendance for {activity?.staff.fullName}

                                </span>
                            </div>
                            <br />
                            <div className="">
                                <div className="flex items-center p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400" role="alert">
                                    <svg className="flex-shrink-0 inline w-3 h-3 me-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                                    </svg>
                                    <span className="sr-only">Info</span>
                                    <div>
                                        <span className='font-bold'> Note:</span> This action should only be taken as a last resort if all effort from the staff to
                                        clock in on the mobile fails and you have notified technical support.
                                        If so click the check box below to proceed.
                                    </div>
                                </div>
                                <div className='flex flex-col gap-1'>
                                    <span className='text-black'>

                                        Attendance for this shift will be marked in respect to the shift start time and end time.
                                    </span>
                                    <span><span className='font-bold'>Clock In Time: </span><span>{dayjs(activity?.dateFrom).format('D MMMM, YYYY h:mm A')} </span></span>
                                    <span><span className='font-bold'>Clock Out Time: </span><span>{dayjs(activity?.dateTo).format('D MMMM, YYYY h:mm A')} </span></span>
                                </div>

                                <div className='grid grid-cols-1 gap-4 lg:grid-cols-2 py-2'>

                                    <CustomInput
                                        label='Start Km (0)'
                                        type='text'
                                        {...register("startKm")}
                                        error={errors?.startKm?.message}
                                    />

                                    <CustomInput
                                        label='End Km (0)'
                                        type='text'
                                        {...register("endKm")}
                                        error={errors?.endKm?.message}

                                    />
                                    <div className='lg:col-span-2 space-y-2'>

                                        <div>
                                            <label htmlFor="Report" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"> Report</label>
                                            <textarea {...register('report')} id="Report" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Write your report here..." defaultValue={""}

                                            />
                                        </div>

                                        <div className="flex items-center mb-4">
                                            <input id="default-checkbox"
                                                checked={isChecked}
                                                onChange={() => setIsChecked(!isChecked)}
                                                type="checkbox" className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded" />
                                            <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> I have done due verification that staff has tried on
                                                the mobile and it fails and issue have been reported to technical support...</label>
                                        </div>


                                    </div>



                                </div>


                            </div>

                            <div className='mx-auto text-center mt-3 lg:col-span-2'>
                                <button type="submit"
                                    disabled={loadingSubmit || !isChecked}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary  disabled:bg-gray-500
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary">

                                    {
                                        loadingSubmit ? <Spinner /> : "Submit"
                                    }


                                </button>

                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>


            <Modal open={reAssignModal} onClose={() => setReAssignModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Reassign Shift</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2' onSubmit={handleReassign}>
                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Staff</label>
                                <InputPicker size="lg" placeholder="--All Staff--"
                                    onChange={value => setUsers(value)}
                                    data={staff.map(user => ({ label: user.fullName, value: user.staffId }))}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                />
                            </div>



                            <div className='flex items-center justify-center mt-3'>


                                <div className='text-center'>
                                    <button type="submit"
                                        disabled={loadingReassign || users === ""}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none disabled:bg-gray-500
dark:focus:ring-primary">{
                                            loadingReassign ? <Spinner /> : "Submit"

                                        } </button>
                                </div>

                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>



            <Modal open={cancelShiftModal} onClose={() => setCancelShiftModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Cancel Shift</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2' onSubmit={cancelShift}>

                            <div>
                                <CustomInput
                                    label='Select Date'
                                    type='date'
                                    onChange={(e) => setCancelDate(e.target.value)}
                                    value={cancelDate}
                                />
                            </div>



                            <div className='flex items-center justify-center mt-3'>


                                <div className='text-center'>
                                    <button type="submit"
                                        disabled={loadingCancel || cancelDate === ""}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none disabled:bg-gray-500
dark:focus:ring-primary">{
                                            loadingCancel ? <Spinner /> : "Submit"

                                        } </button>
                                </div>

                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>


            <Modal open={duplicateModal} onClose={() => setDuplicateModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Duplicate Shift to .....</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='space-y-2'>

                            <div>
                                <CustomInput
                                    label='Select Date'
                                    type='datetime-local'
                                    onChange={(e) => setDuplicateDate(e.target.value)}
                                    value={duplicateDate}
                                />
                            </div>



                            <div className='flex items-center justify-center mt-3'>


                                <div className='text-center'>
                                    <button type="button"
                                        onClick={() => duplicateAttendance(activity?.shiftRosterId as number)}
                                        disabled={loadingDuplicate || duplicateDate === ""}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none disabled:bg-gray-500
dark:focus:ring-primary">{
                                            loadingDuplicate ? <Spinner /> : "Submit"

                                        } </button>
                                </div>

                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal open={moveModal} onClose={() => setMoveModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Move Shift to....</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='space-y-2'>

                            <div>
                                <CustomInput
                                    label='Select Date'
                                    type='datetime-local'
                                    onChange={(e) => setDuplicateDate(e.target.value)}
                                    value={duplicateDate}
                                />
                            </div>



                            <div className='flex items-center justify-center mt-3'>


                                <div className='text-center'>
                                    <button type="button"
                                        onClick={() => moveAttendance(activity?.shiftRosterId as number)}
                                        disabled={loadingMove || duplicateDate === ""}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none disabled:bg-gray-500
dark:focus:ring-primary">{
                                            loadingMove ? <Spinner /> : "Submit"

                                        } </button>
                                </div>

                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>







            {/* Confirm Modal */}
            <ConfirmModal
                open={deleteModal}
                onClose={() => setDeleteModal(false)}
                onConfirm={() => handleDelete(activity?.shiftRosterId as number)}
                title="Delete Shift Roster"
                content={<p className="mb-4 font-bold text-center">Deleting  this shift will permanently delete all attendance & timesheet records. Do you wish to proceed?</p>}
                isLoading={delLoading}
            />

            <ConfirmModal
                open={unMarkModal}
                onClose={() => setUnMarkModal(false)}
                onConfirm={() => unMarkAttendance(activity?.shiftRosterId as number)}
                title="Unmark Attendance"
                content={<p className="mb-4 font-bold text-center">Are you sure you want to Unmark this Attendance?</p>}
                isLoading={loadingUnMark}
            />
            <ConfirmModal
                open={showConfirmModal}
                onClose={() => setShowConfirmModal(false)}
                onConfirm={() => handleConfirm(activity?.shiftRosterId as number)}
                title="Approving Shift Cancellation"
                content={<p className="mb-4 font-bold text-center">Are you sure you want to approve the shift cancellation request?</p>}
                isLoading={loadingConfirm}
            />
            <ConfirmModal
                open={showRejectModal}
                onClose={() => setShowRejectModal(false)}
                onConfirm={() => handleRejectCancelShift(activity?.shiftRosterId as number)}
                title="Reject Shift Cancellation"
                content={<p className="mb-4 font-bold text-center">Are you sure you want to reject the shift cancellation request?</p>}
                isLoading={loadingReject}
            />
            <ConfirmModal
                open={multipleModal}
                onClose={() => setMultipleModal(false)}
                onConfirm={handleMultipleDelete}
                title="Delete Multiple Shift"
                content={<p className="mb-4 font-mediumm text-xl text-center">You're about deleting <span className='text-black text-sm py-1 px-2 bg-secondary rounded'>{selectedShifts.length}</span> shift{selectedShifts.length > 1 ? "s" : ""}?</p>}
                isLoading={loadingMultiple}
            />






        </>
    )
}

export default LargeModal