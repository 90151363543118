import React from "react";

const Form = () => {
  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-5 border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">

        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Date:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" contentEditable></td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Name of participant
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" contentEditable></td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Is the participant providing consent
            </td>
            <td className="whitespace-nowrap items-center px-4 py-2 flex gap-5 text-gray-700 w-96 outline-none">
              <p className="flex items-center gap-2">
                <input id="yes" type="checkbox" />
                <label htmlFor="yes">Yes</label>
              </p>
              <p className="flex items-center gap-2">
                <input id="no" type="checkbox" />
                <label htmlFor="no">No</label>
              </p>
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Is an authorised person providing consent?
            </td>
            <td className="whitespace-nowrap items-center px-4 py-2 flex gap-5 text-gray-700 w-96 outline-none">
              <p className="flex items-center gap-2">
                <input id="yes" type="checkbox" />
                <label htmlFor="yes">Yes</label>
              </p>
              <p className="flex items-center gap-2">
                <input id="no" type="checkbox" />
                <label htmlFor="no">No</label>
              </p>
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              If yes, name of authorised person
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" contentEditable></td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Relationship to the participant
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" contentEditable></td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Form;
