import React from 'react'
import ClientTabForm from '../components/ClientTabForm'
import CustomInput from '../../../../Components/Input/CustomInput'

const DisabilitySupport = () => {
    return (
        <div className='mt-10'>
            <h2 className='font-bold text-xl text-black w-auto'>Disability Support Needs</h2>

            <div className='mt-5 grid grid-cols-1 gap-16 lg:grid-cols-2'>
                <ClientTabForm title='Mobility Related Issues'>
                    <CustomInput
                        label="Staff Name"
                        type="text"
                        // ref={firstName}
                        required
                    />
                </ClientTabForm>
                <ClientTabForm title='Hearing Related Issues'>
                    <CustomInput
                        label="Staff Name"
                        type="text"
                        // ref={firstName}
                        required
                    />
                </ClientTabForm>
                <ClientTabForm title='Vision Related Issues'>
                    <CustomInput
                        label="Staff Name"
                        type="text"
                        // ref={firstName}
                        required
                    />
                </ClientTabForm>
                <ClientTabForm title='Memory Related Issues'>
                    <CustomInput
                        label="Staff Name"
                        type="text"
                        // ref={firstName}
                        required
                    />
                </ClientTabForm>
                <ClientTabForm title='Communication'>
                    <CustomInput
                        label="Staff Name"
                        type="text"
                        // ref={firstName}
                        required
                    />
                </ClientTabForm>











            </div>

        </div>
    )
}

export default DisabilitySupport