import React, { useRef, useState } from "react";
import "./PDFSignature.css";

import ReactSignatureCanvas from "react-signature-canvas";
import { Modal } from "rsuite";
import { MdDraw, MdMergeType, MdOutlineFileUpload } from "react-icons/md";
import { toast } from "react-toastify";

interface SignatureComponentProps {
  onSignatureSave: (blob: Blob) => void;
  text: string;
}

const SignatureComponent: React.FC<SignatureComponentProps> = ({
  onSignatureSave,
  text,
}) => {
  const [signatureMethod, setSignatureMethod] = useState<string>("draw");
  const [typedSignature, setTypedSignature] = useState<string>("");
  const [uploadedSignature, setUploadedSignature] = useState<string | null>(
    null
  );
  const [modalShow, setModalShow] = useState<boolean>(false);
  const sigCanvas = useRef<ReactSignatureCanvas>(null);
  const imgRef = useRef<HTMLInputElement>(null);

  const handleTab = (tab: string) => {
    setSignatureMethod(tab);
    clearSignature();
  };

  const dataURLToBlob = (dataURL: string): Blob => {
    const parts = dataURL.split(";base64,");
    const contentType = parts[0].split(":")[1];
    const raw = window.atob(parts[1]);
    const rawLength = raw.length;
    const uInt8Array = new Uint8Array(rawLength);

    for (let i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: contentType });
  };

  const saveSignature = (): void => {
    let sigBlob: Blob;
    switch (signatureMethod) {
      case "draw":
        if (sigCanvas.current && sigCanvas.current.isEmpty()) {
          toast.error("Please draw a signature before saving.");
          return;
        } else if (sigCanvas.current) {
          sigBlob = dataURLToBlob(
            sigCanvas.current.getTrimmedCanvas().toDataURL("image/png")
          );
        }
        break;
      case "type":
        if (!typedSignature.trim()) {
          toast.error("Please type a signature before saving.");
          return;
        } else {
          // Start of block
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
          if (ctx) {
            canvas.width = 600;
            canvas.height = 200;
            ctx.font = "100px Dancing Script, cursive";
            ctx.fillText(typedSignature, 20, 150);
            sigBlob = dataURLToBlob(canvas.toDataURL("image/png"));
            onSignatureSave(sigBlob);
          }
        } // End of block
        break;
      case "upload":
        if (!uploadedSignature) {
          toast.error("Please upload a signature before saving.");
          return;
        } else {
          sigBlob = dataURLToBlob(uploadedSignature);
          onSignatureSave(sigBlob);
        }
        break;
      default:
        toast.error("Please select a method and provide a signature.");
        break;
    }
    setModalShow(false);
  };

  const clearSignature = (): void => {
    if (signatureMethod === "draw" && sigCanvas.current) {
      sigCanvas.current.clear();
    }
    setTypedSignature("");
    setUploadedSignature(null);
  };

  const handleTypedSignatureChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setTypedSignature(e.target.value);
  };

  const handleUploadSignature = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (ev: ProgressEvent<FileReader>) => {
        if (ev.target?.result) {
          setUploadedSignature(ev.target.result as string);
        }
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <>
      <button
        className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
        type="button"
        onClick={() => setModalShow(true)}
      >
        {text}
      </button>
      <Modal
        open={modalShow}
        onClose={() => setModalShow(false)}
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="flex items-center gap-5 my-2">
              <span
                className={`inline-flex cursor-pointer gap-1 items-center px-2 py-1 rounded ${signatureMethod === "draw" && "bg-secondary text-black"
                  }`}
                onClick={() => handleTab("draw")}
              >
                <MdDraw /> Draw
              </span>
              <span
                className={`inline-flex cursor-pointer gap-1 items-center px-2 py-1 rounded ${signatureMethod === "type" && "bg-secondary text-black"
                  }`}
                onClick={() => handleTab("type")}
              >
                <MdMergeType /> Type
              </span>
              <span
                className={`inline-flex cursor-pointer gap-1 items-center px-2 py-1 rounded ${signatureMethod === "upload" && "bg-secondary text-black"
                  }`}
                onClick={() => handleTab("upload")}
              >
                <MdOutlineFileUpload />
                Upload
              </span>
            </div>
            <div>
              {signatureMethod === "draw" && (
                <div className="d-flex justify-content-center cursor-crosshair align-items-center overflow-hidden">
                  <ReactSignatureCanvas
                    penColor="black"
                    // minWidth={0.5}
                    maxWidth={1.5}
                    canvasProps={{
                      width: 400,
                      height: 200,
                      className: "sigCanvas  bg-custom  rounded",
                    }}
                    ref={sigCanvas}
                  />
                </div>
              )}
              {signatureMethod === "type" && (
                <div className="flex justify-center items-center overflow-hidden">
                  <input
                    style={{ width: 400, height: 200 }}
                    type="text"
                    value={typedSignature}
                    maxLength={15}
                    onChange={handleTypedSignatureChange}
                    className="form-control text-center text-xl bg-custom rounded dancing-font"
                    placeholder="Type Name or Initials here"
                  />
                </div>
              )}
              {signatureMethod === "upload" && (
                <div className="flex justify-center items-center overflow-hidden">
                  <div
                    className=" bg-custom rounded flex justify-center items-center mx-auto"
                    style={{ width: 400, height: 200 }}
                  >
                    <input
                      type="file"
                      onChange={handleUploadSignature}
                      accept="image/png, image/jpeg"
                      ref={imgRef}
                      hidden
                    />
                    {!uploadedSignature && (
                      <button
                        onClick={() => imgRef.current?.click()}
                        className="bg-secondary inline-flex gap-2 px-4 py-2 items-center rounded text-black font-medium "
                      >
                        <MdOutlineFileUpload className="text-lg" /> Upload
                        signature in (.png) format only
                      </button>
                    )}
                    {uploadedSignature && (
                      <div
                        style={{
                          width: 150,
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <span
                          onClick={() => setUploadedSignature(null)}
                          className="py-1 px-2"
                          style={{
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "0.28rem",
                            position: "absolute",
                            top: "0",
                            right: "0",
                            fontSize: "10px",
                            cursor: "pointer",
                          }}
                        >
                          X
                        </span>
                        <img
                          src={uploadedSignature}
                          alt="Uploaded Signature"
                          className="img-thumbnail mt-2 w-100 h-100"
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <button
                        className="btn  btn-danger px-4 btn-block"
                        type="submit"
                        onClick={() => setModalShow(false)}
                    >
                        Close
                    </button> */}
          <button
            className=" text-black bg-custom px-6 py-1 rounded"
            type="submit"
            onClick={clearSignature}
          >
            Clear
          </button>
          <button
            className=" text-white bg-primary px-6 py-1 ml-2 rounded"
            type="submit"
            onClick={saveSignature}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SignatureComponent;
