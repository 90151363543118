import React from "react";
import Contact from "./Contact";
import Consent from "./Consent";
import Box2 from "./Box2";
import Form2 from "./Form2";

const Section = () => {
  return (
    <div className="mt-5">
      <strong className="italic">
        Please tick the following boxes to indicate where you give your consent
      </strong>
      <Consent />

      <Box2 />

      <div className=" p-4 max-[1024px]:w-[100%]  my-5 border border-black ">
        <p className="text-[16px] mb-3">
          Consent for us to share information about you to other providers or
          individuals
        </p>
        <div className="flex items-center gap-3 mt-4 ">
          <input id="con" type="checkbox" />
          <label htmlFor="con" className=" text-[16px]">
            <strong>I give consent</strong> for Maxicare Plus to share my
            information with the following people or services. I understand that
            sharing information may include discussing my support needs, sharing
            support plans and reviewing any incidents that have occurred.
          </label>
        </div>
        <Contact />
        <div className="flex items-center gap-3 mt-4 ">
          <input id="giv" type="checkbox" />
          <label htmlFor="giv" className=" text-[16px]">
            <strong>I do not give consent</strong> for Maxicare Plus to share my
            information with the following people or services:
          </label>
        </div>
        <Form2 />
        <br />
        <br />
        <strong className="my-5">
          Note that may have to disclose some information without your permission when we are required to by law. For example, when we need to ensure your safety or that of others, or when directed to do so by a court of law.
        </strong>
      </div>
    </div>
  );
};

export default Section;
