// authSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

interface User {
  // Define the structure of your user object here.
  email: string;
  username: string;
  profileId: string;
  fullName: string;
  companyId: number;
  uid: string;
  role: string;
  // Add other user-related properties as needed.
}

interface AuthState {
  token: string | null;
  user: User | null;
  isAuthenticated: boolean;
}

interface MyToken {
  username: string;
  email: string;
  jti: string;
  fullName: string;
  uid: string;
  phone: string;
  profileId: string;
  role: string;
  roles: string | User;
  companyId: number;
  exp: number;
  iss: string;
  aud: string;
}
// Create a version of MyToken without the 'password' field
// type MyTokenWithoutPassword = Omit<MyToken, 'password'>;

const initialState: AuthState = {
  token: Cookies.get("authToken") || null,
  user: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ token: string; role: string }>) => {
      const decodedToken = jwtDecode<MyToken>(action.payload.token);
      const role = action.payload.role;
      //   state.token = action.payload.token;
      state.user = decodedToken as User;
      state.isAuthenticated = true;
      Cookies.set("authToken", action.payload.token, {
        expires: 1 / 24,
        // secure: true, // Only send the cookie over HTTPS
        // httpOnly: true, // Prevent client-side JavaScript access
      });
      Cookies.set("role", role, {
        expires: 1 / 24,
        // secure: true, // Only send the cookie over HTTPS
        // httpOnly: true, // Prevent client-side JavaScript access
      });
    },
    logout: (state) => {
      state.token = null;
      state.user = null;
      state.isAuthenticated = false;
      Cookies.remove("authToken", {
        // secure: true,
        // httpOnly: true,
      });
      Cookies.remove("role", {
        // secure: true,
        // httpOnly: true,
      });
      localStorage.removeItem("persist:root");
      localStorage.removeItem("redirectPath");
      localStorage.removeItem("staffRedirectPath");
      sessionStorage.removeItem("claims");
      sessionStorage.removeItem("staffProfile");
      sessionStorage.removeItem("clientProfile");
      sessionStorage.removeItem("adminProfile");
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
