import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface Time {
    company: string;
    companyId: number;
    dateCreated: string;
    dateModified: string;
    eveningEndTimePeriod: string;
    eveningStartTimePeriod: string;
    normalEndTimePeriod: string;
    normalStartTimePeriod: string;
    sleepoverEndTimePeriod: string;
    sleepoverStartTimePeriod: string;
    timePeriodsId: string;
    userCreated: string;
    userModified: string;
}

export interface TimeState {
    loading: boolean;
    data: Array<Time>;
    error: string | undefined;
}
const initialState: TimeState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchTime = createAsyncThunk(
    "Time/fetchTime",
    async (companyId: number) => {
        try {
            const response = await api.fetchTimePeriod(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const timeSlice = createSlice({
    name: 'time',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchTime.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchTime.fulfilled, (state, action: PayloadAction<Array<Time>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchTime.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.time;
export default timeSlice.reducer;


export const timeData = (state: RootState) => state.time.data;
export const timeLoading = (state: RootState) => state.time.loading;
export const timeError = (state: RootState) => state.time.error;