import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
// import { FaFilter } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Spinner from '../../../Components/Spinner';
import { FaFileCsv, FaFileExcel, FaFilePdf, FaFilter } from 'react-icons/fa';
import IconButton from '../../../Components/Buttons/IconButton';
import {
    fetchShiftAttendance,
    filterShiftAttendance,
    shiftAttendanceData,
    shiftAttendanceLoading, shiftAttendanceSplits
} from '../../../store/slices/shiftAttendanceSlice';
import { InputPicker, Modal } from 'rsuite';
import CustomInput from '../../../Components/Input/CustomInput';
import { fetchStaff, staffData } from '../../../store/slices/staffSlice';
import { clientData, fetchClient } from '../../../store/slices/clientSlice';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';
import { Column } from '../../../types/DataType';
interface DataRow {
    attendanceId: number;
    attendanceSplitId: number;
    clockIn: string;
    clockInCheck: boolean;
    clockOut: string;
    clockOutCheck: boolean;
    companyID: number;
    dateCreated: string;
    dateModified: string;
    duration: number;
    eveningDuration: number;
    exceptionalDuration: number;
    nightShift: number;
    normalDuration: number;
    phDuration: number;
    satDuration: number;
    shift: string;
    shiftId: number;
    staffId: number;
    totalKm: number;
    userCreated: string;
    userModified: string;
    shiftRoster: {
        activities: string;
        appointment: string;
        attendId: number;
        attendance: boolean;
        clients: string;
        dateFrom: string;
        dateTo: string;
        profile: {
            profileId: number;
            firstName: string;
            surName: string;
            middleName: string;
            address: string;
            email: string;
            phoneNumber: string;
            fullName: string;
        }
        staff: {
            firstName: string;
            surName: string;
            middleName: string;
            email: string;
            employeeId: string;
            phoneNumber: string;
            fullName: string;
        }
    }
}

function formatDuration(duration: number | undefined): string {
    if (duration) {
        const durationInMilliseconds = duration / 10000; // Convert ticks to milliseconds

        const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;

        return `${hours} Hrs ${minutes} min`;
    }

    return "0 Hrs 0 min";
}


const ShiftAttendance = () => {
    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Staff',
            selector: row => row.shiftRoster?.staff?.fullName,
            sortable: true,
            cell: (row) => <span className="truncate font-bold" style={{ overflow: "hidden", cursor: "pointer" }}
                data-toggle="tooltip" data-placement="top" title={`${row.shiftRoster?.staff?.fullName}`}
            >{row.shiftRoster?.staff?.fullName}</span>
        },
        {

            name: 'Date',
            selector: row => dayjs(row.clockIn).format('ddd, MMMM DD YYYY'),
            sortable: true

        },
        {

            name: 'Start Time',
            selector: row => dayjs(row.clockIn).format('h:mm A'),
            sortable: true,

        },
        {
            name: 'End Date',
            selector: row => dayjs(row.clockOut).format('ddd, MMM DD, h:mm A'),
            sortable: true,
        },
        {
            name: 'Km',
            selector: row => row.totalKm,
            sortable: true,

        },
        {
            name: 'Client',
            selector: row => row.shiftRoster?.clients,
            sortable: true,
            cell: (row) => <span className="truncate font-bold" style={{ overflow: "hidden", cursor: "pointer" }}
                data-toggle="tooltip" data-placement="top" title={`${row.shiftRoster?.clients}`}
            >{row.shiftRoster?.clients}</span>
        },
        {
            name: 'Duration',
            selector: row => formatDuration(row.duration),
            sortable: true,

        }

    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchShiftAttendance(companyId));
            dispatch(fetchStaff(companyId));
            dispatch(fetchClient(companyId));
        }
    }, [dispatch, companyId]);
    const notSplits = useSelector(shiftAttendanceData);
    const shiftReport = useSelector(shiftAttendanceSplits);
    const loadingData = useSelector(shiftAttendanceLoading);
    const staff = useSelector(staffData);
    const clients = useSelector(clientData);
    const [loading, setLoading] = useState<boolean>(false)
    const [loading1, setLoading1] = useState<boolean>(false)
    const [users, setUsers] = useState<string>("")
    const [client, setClient] = useState<string>("")
    const [dateFrom, setDateFrom] = useState<string>("")
    const [dateTo, setDateTo] = useState<string>("")
    const [filterModal, setFilterModal] = useState<boolean>(false);
    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Staff Name: </span>
                    <span> {data.shiftRoster?.staff?.fullName}</span>
                </span>
                <span>
                    <span className='font-bold'>Client Name: </span>
                    <span> {data.shiftRoster?.profile?.fullName}</span>
                </span>

                <span>
                    <span className='font-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>
                <span>
                    <span className='font-bold'>Date Modified: </span>
                    <span>
                        {dayjs(data.dateModified).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>

            </div>
        )
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = shiftReport.filter((item) =>
        item.shiftRoster.staff.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
        item.shiftRoster.profile.fullName.toLowerCase().includes(searchText.toLowerCase())
    );

    const resetFilter = (e: FormEvent) => {
        e.preventDefault();
        companyId && dispatch(fetchShiftAttendance(companyId));
        setUsers("");
        setClient("");
        setDateFrom("");
        setDateTo("");
        setFilterModal(false);
    }

    const filterActivity = (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);

        if (companyId && dateFrom && dateTo) {
            dispatch(filterShiftAttendance({
                companyId: companyId,
                dateFrom: dateFrom,
                dateTo: dateTo,
                staff: users,
                client: client,
                type: ""
            }));
        }
        setLoading(false);
        setFilterModal(false);
    }

    const GeneratePdf = () => {
        setLoading1(true);
        localStorage.setItem("shiftSta", users)
        localStorage.setItem("shiftCli", client)
        localStorage.setItem("shiftDateFrom", dateFrom)
        localStorage.setItem("shiftDateTo", dateTo)
        localStorage.setItem("shiftType", "")
        setTimeout(() => {
            const url = `/pdf/alluser-shiftattendance/gen_rate`;
            window.open(url, '_blank');
            setLoading1(false);
        }, 2000);
    }

    return (
        <>
            <SEO
                title='Shift Attendance'
                description='Shift Attendance'
                name='Promax-Care'
            />
            <Breadcrumb />

            <div className='mt-10'>
                {shiftReport.length <= 0 && loadingData && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Shift Attendance</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By Staff or Client Name" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            <button onClick={() => setFilterModal(true)} id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button>
                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(shiftReport, columns as unknown as Column<DataRow>[], "ShiftAttendance.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(shiftReport, columns as unknown as Column<DataRow>[], "ShiftAttendance")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: shiftReport, filename: 'ShiftAttendance.csv' })}

                                    />
                                </div>
                            </ExportDropdown>


                        </div>

                    </div>
                </div>
                <div className="flex px-2 py-3 justify-evenly items-center">
                    <div><span>Total Duration: </span><span className="font-bold">{formatDuration(notSplits?.totalDuration)}</span></div>
                    <div><span>Total Km: </span><span className="font-bold">{notSplits?.totalKm ? notSplits.totalKm : "0"}</span></div>

                </div>
                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    pagination
                    paginationTotalRows={shiftReport?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />

            </div>


            <Modal open={filterModal} onClose={() => setFilterModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2' onSubmit={filterActivity}>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Staff</label>
                                <InputPicker size="lg" placeholder="--All Staff--"
                                    onChange={value => setUsers(value)}
                                    data={staff.map(user => ({ label: user.fullName, value: user.staffId }))}
                                    value={users}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                />
                            </div>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Client</label>
                                <InputPicker size="lg" placeholder="--All Client--"
                                    onChange={value => setClient(value)}
                                    data={clients.map(user => ({ label: user.fullName, value: user.profileId }))}
                                    value={client}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                />
                            </div>

                            <CustomInput
                                label='Start Date'
                                type='datetime-local'
                                name="dateFrom"
                                onChange={e => setDateFrom(e.target.value)}
                                value={dateFrom}
                                required
                            />
                            <CustomInput
                                label='End Date'
                                type='datetime-local'
                                name="dateTo"
                                onChange={e => setDateTo(e.target.value)}
                                value={dateTo}
                                required
                            />

                            <div className='flex items-center justify-center mt-3 flex-wrap'>

                                <div className='text-center'>
                                    <button type="submit"
                                        onClick={resetFilter}
                                        className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">
                                        Reset Filter
                                    </button>
                                </div>

                                <div className='text-center'>
                                    <button type="submit"
                                        disabled={loading ? true : false}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                            loading ? <Spinner /> : "Load"
                                        }</button>
                                </div>

                                {dateFrom && dateTo ? <div className='text-center'>
                                    <button type="button"
                                        onClick={GeneratePdf}
                                        disabled={loading1}
                                        className="text-white bg-brand hover:bg-brand focus:ring-4 focus:ring-brand 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-brand dark:hover:bg-brand focus:outline-none
dark:focus:ring-primary">{
                                            loading1 ? <Spinner /> : "Generate Shift Attendance Report"
                                        }</button>
                                </div> : ""}
                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ShiftAttendance