import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { FaFileCsv, FaFileExcel, FaFilePdf, FaRegTrashAlt } from 'react-icons/fa';
import { MultiSelect } from "react-multi-select-component";
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
import { toast } from "react-toastify";
import { isAxiosError } from 'axios';
import { Button, InputPicker, Modal, Tooltip, Whisper } from 'rsuite';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import axiosInstance from '../../../store/axiosInstance';
import Spinner from '../../../Components/Spinner';
import TimePeriodModals from '../../../Components/Modals/TimePeriodModals';
import CustomInput from '../../../Components/Input/CustomInput';
import { clientData, fetchClient } from '../../../store/slices/clientSlice';
import { fetchSupportSchedule, supportScheduleData, supportScheduleLoading } from '../../../store/slices/supportScheduleSlice';
import { fetchSupportType, supportTypelateData } from '../../../store/slices/supportTypeSlice';
import IconButton from '../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';
import { Column } from '../../../types/DataType';

interface DataRow {
    companyID: number;
    cost: number;
    dateCreated: string;
    dateModified: string;
    frequency: string;
    itemNumber: string;
    profile: {
        address: string;
        agreementEndDate: string;
        agreementStartDate: string;
        auditApproved: boolean;
        careManager: string;
        clientId: string | null;
        companyId: number | null;
        contactId: string;
        country: string | null;
        culturalBackground: string | null;
        dateCreated: string;
        dateModified: string;
        dateOfBirth: string;
        email: string;
        financialArrangement: string | null;
        firstName: string;
        fullName: string;
        gender: string;
        homePhone: string | null;
        imageFile: string | null;
        imageUrl: string | null;
        indigenousSatatus: string | null;
        isActive: boolean;
        isCompleted: boolean;
        kinAddress: string | null;
        kinCity: string | null;
        kinCountry: string | null;
        kinEmail: string | null;
        kinPhoneNumber: string | null;
        kinPostcode: string | null;
        kinState: string | null;
        middleName: string | null;
        ndisNo: string;
        ndisPlan: string | null;
        ndisPlanNote: string | null;
        nextOfKin: string | null;
        phoneNumber: string;
        preferredLanguage: string | null;
        privacyPreferences: string | null;
        profession: string | null;
        profileId: number;
        referral: string;
        relationship: string | null;
        requireInterpreter: string | null;
        state: string | null;
        suburb: string | null;
        surName: string;
        userCreated: string | null;
        userModified: string | null;
        workPhone: string | null;
        xerolink: string | null;
    };
    profileId: number;
    quantity: number;
    scheduleId: number;
    supportType: string;
    userCreated: string | null;
    userModified: string | null;
}

type Option = {
    label: string;
    value: string;
};
const options = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },



];

const SupportSchedule = () => {

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Participant',
            selector: row => row.profile?.fullName,
            sortable: true,
        },
        {
            name: 'Support Type',
            selector: row => row.supportType,
            sortable: true,
            cell: (row) => <span className="truncate" style={{ overflow: "hidden", cursor: "help" }}
                data-bs-toggle="tooltip" data-bs-placement="top" title={`${row.supportType}`}
            >{row.supportType}</span>
        },
        {
            name: 'Quantity of Service',
            selector: row => row.quantity,
            sortable: true,
            cell: (row) => <span className="truncate" style={{ overflow: "hidden" }}

            >{row.quantity} hours per day</span>
        },
        {
            name: 'Cost of service per hour ($)',
            selector: row => row.cost,
            sortable: true
        },

        {
            name: "Actions",
            cell: (row: DataRow) => (
                <div className="flex gap-4  justify-center " >
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                        <Tooltip>
                            Delete Support Schedule
                        </Tooltip>
                    }>
                        <button onClick={() => delSupportSch(row.scheduleId.toString())} className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                            <FaRegTrashAlt className='text-base text-red-500' />
                        </button>

                    </Whisper>


                </div>

            ),
        },

    ];
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [saveId, setSaveId] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false);
    const [modal1, setModal1] = useState<boolean>(false);
    const [modal2, setModal2] = useState<boolean>(false);
    const [modal3, setModal3] = useState<boolean>(false);
    const [modal4, setModal4] = useState<boolean>(false);
    const [modal5, setModal5] = useState<boolean>(false);
    const [modal6, setModal6] = useState<boolean>(false);
    const [modal7, setModal7] = useState<boolean>(false);
    const quantity = useRef<HTMLInputElement>(null);
    const delSupportSch = (id: string) => {
        setSaveId(id);
        setDeleteModal(true);
    }


    const [addTimeModal, setAddTimeModal] = useState<boolean>(false);

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            // dispatch(fetchTime(companyId));
            dispatch(fetchClient(companyId));
            dispatch(fetchSupportType());
            dispatch(fetchSupportSchedule(companyId));
        }
    }, [dispatch, companyId]);
    const clients = useSelector(clientData);

    const supportSchedule = useSelector(supportScheduleData);
    const loadingData = useSelector(supportScheduleLoading);
    const supportType = useSelector(supportTypelateData);

    const handleSupportSch = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data } = await axiosInstance.post(`/Invoice/delete_schedule/${saveId}`)
            toast.success(data.message);
            setDeleteModal(false);
            companyId && dispatch(fetchSupportSchedule(companyId));
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }

    const [selectedSupportType, setSelectedSupportType] = useState<number>(0);
    const [supportName, setSupportName] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [profileId, setProfileId] = useState<number>(0);
    const [supportNumber, setSupportNumber] = useState<string>('');

    const handleSupportTypeChange = (value: number) => {
        setSelectedSupportType(value);
        const type = supportType.find((type) => type.ndiA_DPAId === value);
        if (type) {
            setSupportName(type.itemName);
            setPrice(type.remote.toString());
            setSupportNumber(type.itemNumber);
        } else {
            setSupportName('');
            setPrice('');
            setSupportNumber('');
        }
    };

    const [selectedSupport, setSelectedSupport] = useState<Option[]>([]);
    const handleSupportSelectionChange = (selectedOptions: Option[]) => {
        setSelectedSupport(selectedOptions);
    };
    const selectedSupportString = selectedSupport.map(option => option.label).join(',');

    const handleSubmit = async () => {
        if (quantity.current?.value === "" || selectedSupportString === "") {
            return toast.error("Incomplete Request")
        }
        const info = {
            profileId,
            supportType: supportName,
            quantity: quantity.current?.value,
            cost: price,
            frequency: selectedSupportString,
            itemNumber: supportNumber,
            companyID: companyId
        }
        try {
            setLoading(true)
            const { data } = await axiosInstance.post(`Invoice/add_schedule_of_support`, info);

            if (data.status === "Success") {
                toast.success(data.message);
                setSupportName('');
                setPrice('');
                setSupportNumber('');
                setProfileId(0);
                setAddTimeModal(false)
                companyId && dispatch(fetchSupportSchedule(companyId));

            } else {
                toast.error(data.message);
            }
            setLoading(false)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Support Type: </span>
                    <span> {data.supportType}</span>
                </span>
                <span>
                    <span className='font-bold'>Frequency of Support: </span>
                    <span> {data.frequency}</span>
                </span>
                <span>
                    <span className='font-bold'>Item Number: </span>
                    <span> {data.itemNumber}</span>
                </span>
                <span>
                    <span className='font-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>

            </div>
        )
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = supportSchedule.filter((item) =>
        item.supportType.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <SEO
                title='Support Schedule - Promax Care'
                description='Support Schedule'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-10'>
                {supportSchedule.length <= 0 && loadingData && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Support Schedule</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By supportType" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(supportSchedule, columns as unknown as Column<DataRow>[], "SupportSchedule.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(supportSchedule, columns as unknown as Column<DataRow>[], "SupportSchedule")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: supportSchedule, filename: 'SupportSchedule.csv' })}

                                    />
                                </div>
                            </ExportDropdown>




                        </div>
                        <button
                            onClick={() => setAddTimeModal(true)}
                            type="button" className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           ">

                            Add Support Schedule
                        </button>
                    </div>
                </div>



                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />





            </div>

            <Modal open={deleteModal} onClose={() => setDeleteModal(false)} role="alertdialog" backdrop="static" autoFocus size={"xs"} >
                <Modal.Header>
                    <Modal.Title className='font-bold text-sm'>Delete Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className="mb-4 font-bold text-lg">Are you sure you want to delete this schedule of support ?</p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button onClick={() => setDeleteModal(false)} className='bg-gray-500 text-white hover:text-white hover:bg-gray-400'>
                                No, Cancel
                            </Button>
                            <Button onClick={handleSupportSch} className='bg-red-500 text-white hover:bg-red-700 hover:text-white'>
                                {
                                    loading ? <Spinner /> : "Yes, I'm sure"
                                }
                            </Button>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>



            <Modal open={addTimeModal} onClose={() => setAddTimeModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Create a Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <form action="" >

                            <div className='space-y-1 p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8'>
                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">Participant</label>
                                    <InputPicker size="lg" placeholder="Select Client / Participant"
                                        onChange={value => setProfileId(value)}
                                        data={clients.map(client => ({ label: client.fullName, value: client.profileId }))}
                                        className='w-full focus:outline-none focus:border-primary text-gray-600'
                                        appearance='subtle'
                                    />
                                </div>

                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">Support Type</label>
                                    <InputPicker size="lg" placeholder="Select Support Type"
                                        data={supportType.map(support => ({ label: support.itemName, value: support.ndiA_DPAId }))}
                                        className='w-full focus:outline-none focus:border-primary text-gray-600'
                                        appearance='subtle'
                                        defaultValue={selectedSupportType}
                                        onChange={handleSupportTypeChange}
                                    />
                                </div>

                                <CustomInput
                                    label='Quantity of Service'
                                    type='number'
                                    ref={quantity}
                                    required
                                    placeholder="Quantity of Service"
                                />


                                <CustomInput
                                    label='Cost of Service per hour ($)'
                                    type='number'
                                    value={price}
                                    placeholder="Cost of Service per hour ($)"
                                    readOnly
                                />

                                <div>
                                    <label htmlFor="support" className="block mb-2 text-xs  text-gray-600 font-semibold dark:text-white">Frequency of Support </label>
                                    <MultiSelect
                                        className='rounded-none h-11 multi-select'
                                        options={options}
                                        value={selectedSupport}
                                        onChange={handleSupportSelectionChange}
                                        labelledBy={"Select multiple"}
                                        isCreatable={true}
                                    />
                                </div>

                                <CustomInput
                                    label='Item Number'
                                    type='text'
                                    value={supportNumber}
                                    placeholder="Item Number"
                                    readOnly
                                />
                            </div>

                        </form>
                        <div className='mx-auto text-center mt-3'>
                            <button type="submit"
                                onClick={handleSubmit}
                                disabled={loading ? true : false}
                                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary">{
                                    loading ? <Spinner /> : "Create"
                                }</button>

                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <TimePeriodModals modal1={modal1} setModal1={setModal1}
                modal2={modal2} setModal2={setModal2}
                modal3={modal3} setModal3={setModal3}
                modal4={modal4} setModal4={setModal4}
                modal5={modal5} setModal5={setModal5}
                modal6={modal6} setModal6={setModal6}
                modal7={modal7} setModal7={setModal7}
            />
        </>
    )
}

export default SupportSchedule