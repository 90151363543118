import React from "react";
import { Navigate, Route, Routes } from "react-router";
import Dashboard from "./Dashboard";
import Profile from "./Profile";
// import Availabilty from './Availability'
import Attendance from "./Attendance";
import ShiftReport from "./ShiftReport";
import ShiftRoster from "./ShiftRoster";
import StaffViewTicket from "./Communication/Ticket/StaffViewTicket";
import Ticket from "./Communication/Ticket";
import TicketTemplate from "./Communication/Ticket/TicketTemplate";
import ChangePassword from "./ChangePassword";
import AttendanceReport from "./AttendanceReport";
import AvailableHoursForm from "./Availability/availableHours";
import ShiftReportForm from "./Dashboard/components/ShiftReportForm";
import ShiftReportList from "./ShiftReportList";
import StaffDailyReoprt from "./StaffDailyReport";
import NewDailyReport from "./NewDailyReport";
import Document from "./Documents";

const StaffLayout = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/staff/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/profile" element={<Profile />} />
        {/* <Route path='/availabilities' element={<Availabilty />} /> */}
        <Route path="/availabilities" element={<AvailableHoursForm />} />
        <Route path="/attendance" element={<Attendance />} />
        <Route path="/progress-note" element={<ShiftReport />} />
        <Route path="/shift-roster" element={<ShiftRoster />} />
        <Route path="/staff-view-ticket" element={<StaffViewTicket />} />
        <Route path="/staff-raise-ticket" element={<Ticket />} />
        <Route
          path="/communication/ticket-template"
          element={<TicketTemplate />}
        />
        <Route path="/change-password" element={<ChangePassword />} />
        <Route path="/attendance-report/:uid" element={<AttendanceReport />} />
        <Route path="/report-form/:uid" element={<ShiftReportForm />} />
        <Route path="/shift-report-form" element={<ShiftReportList />} />
        <Route path="/daily-report" element={<StaffDailyReoprt />} />
        <Route path="/document" element={<Document />} />
        <Route path="/new-daily-report" element={<NewDailyReport />} />
      </Routes>
    </div>
  );
};

export default StaffLayout;
