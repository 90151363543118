import React from 'react';

const SupportPlanReview: React.FC = () => {
  return (
    <div className="p-4">
      <div className='flex justify-between items-center'>
      <h1 className="text-2xl font-bold mb-4">Support plan review meeting</h1>
      <img src="/logo192.png" alt="" />
      </div>
      <table className="w-full border-collapse">
        <tbody>
          <tr>
            <td className="border p-2">Meeting date:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr>
            <td className="border p-2">Attendees:</td>
            <td className="border p-2" contentEditable={true}>1.</td>
          </tr>
          <tr>
            <td className="border p-2"></td>
            <td className="border p-2" contentEditable={true}>2.</td>
          </tr>
          <tr>
            <td className="border p-2"></td>
            <td className="border p-2" contentEditable={true}>3.</td>
          </tr>
          <tr>
            <td className="border p-2"></td>
            <td className="border p-2" contentEditable={true}>4.</td>
          </tr>
          <tr>
            <td className="border p-2"></td>
            <td className="border p-2" contentEditable={true}>5.</td>
          </tr>
          <tr>
            <td className="border p-2"></td>
            <td className="border p-2" contentEditable={true}>6.</td>
          </tr>
          <tr>
            <td className="border p-2">Participant details</td>
            <td className="border p-2"></td>
          </tr>
          <tr>
            <td className="border p-2">Last name:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr>
            <td className="border p-2">First name:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr>
            <td className="border p-2">Preferred name:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr>
            <td className="border p-2">Date of birth:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr>
            <td className="border p-2">Date of support plan:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr className=' bg-gray-200 font-semibold'>
            <td className="border p-2">What’s working well with the plan?</td>
            <td className="border p-2">What’s not working well with the plan?</td>
          </tr>
          <tr>
            <td className="border p-2" contentEditable>1.</td>
            <td className="border p-2" contentEditable>1.</td>
           
          </tr>
          <tr>
            <td className="border p-2" contentEditable>2.</td>
            <td className="border p-2" contentEditable>2.</td>
           
          </tr>
          <tr>
            <td className="border p-2" contentEditable>3.</td>
            <td className="border p-2" contentEditable>3.</td>
           
          </tr>
          <tr>
            <td className="border p-2" contentEditable>4.</td>
            <td className="border p-2" contentEditable>4.</td>
           
          </tr>
          <tr>
            <td className="border p-2" contentEditable>5.</td>
            <td className="border p-2" contentEditable>5.</td>
           
          </tr>
          <tr>
            <td className="border p-2">Goals I have achieved:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr>
            <td className="border p-2">Goals still in progress:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr>
            <td className="border p-2">New goals I want to achieve:</td>
            <td className="border p-2" contentEditable={true}></td>
          </tr>
          <tr>
            <td className="border p-2" colSpan={2}>
              <i>If changes are required to the support plan please complete a new Support Plan template and mark the previous plan as ‘superseded’.</i>
            </td>
          </tr>
          <strong className='mt-8 text-[16px]'>Agreements</strong>
          <tr>
            <td className="border p-2 font-semibold">Participant or representative</td>
            <td className="border p-2 font-semibold">Provider representative</td>
          </tr>
          <tr>
            <td className="border p-2" contentEditable>Name:</td>
            <td className="border p-2" contentEditable>Name and role:</td>
          </tr>
          
          <tr>
            <td className="border p-2" contentEditable>Signature:</td>
            <td className="border p-2" contentEditable>Signature:</td>
          </tr>
          <tr>
            <td className="border p-2" contentEditable>Date:</td>
            <td className="border p-2" contentEditable>Date:</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SupportPlanReview;
