import React from 'react'

interface Props {
    title: string;
    children: React.ReactNode
}
const ClientTabForm = ({ title, children }: Props) => {
    return (
        <div className='border-2 rounded'>
            <div className='bg-gray-200 text-center p-2 flex justify-between items-center text-sm font-bold text-black rounded-t'>
                <span> {title}</span>
            </div>
            <div className='flex gap-2 flex-col  p-2'>
                {children}
            </div>

        </div>
    )
}

export default ClientTabForm