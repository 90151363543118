import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface Admin {
  administratorId: number;
  firstName: string;
  surName: string;
  middleName: string;
  maxStaffId: string;
  address: string;
  email: string;
  phoneNumber: string;
  postcode: string | null;
  profession: string | null;
  gender: string | null;
  imageUrl: string | null;
  imageFile: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  isCompleted: boolean;
  isActive: boolean;
  isDocumentUploaded: boolean;
  dateOfBirth: string | null; // You may want to use Date type if it's a date string
  facebook: string | null;
  twitter: string | null;
  instagram: string | null;
  youtube: string | null;
  linkedIn: string | null;
  github: string | null;
  employmentType: string | null;
  taxFile: string | null;
  position: string | null;
  australianCitizen: boolean | null;
  dateJoined: string | null; // You may want to use Date type if it's a date string
  salary: number;
  payDay: number;
  payRate: number | null;
  offerLetter: string | null;
  bankName: string | null;
  bsb: string | null;
  branch: string | null;
  accountName: string | null;
  accountNumber: string | null;
  nextOfKin: string | null;
  relationship: string | null;
  kinPostcode: string | null;
  kinAddress: string | null;
  kinCountry: string | null;
  kinCity: string | null;
  kinEmail: string | null;
  suburb: string | null;
  kinState: string | null;
  kinPhoneNumber: string | null;
  adm_DesignationsId: number | null;
  adm_Designations: string; // You may want to create an interface for this if it has a fixed structure
  fullName: string;
  companyId: number;
  company: string; // You may want to create an interface for this if it has a fixed structure
  dateCreated: string; // You may want to use Date type if it's a date string
  userCreated: string; // You may want to create an interface for this if it has a fixed structure
  dateModified: string; // You may want to use Date type if it's a date string
  userModified: string; // You may want to create an interface for this if it has a fixed structure
}

export interface AdminState {
  loading: boolean;
  data: Array<Admin>;
  error: string | undefined;
}
const initialState: AdminState = {
  loading: false,
  data: [],
  error: undefined,
}
export const fetchAdmin = createAsyncThunk(
  "admin/fetchAdmin",
  async (companyId: string | number) => {
    try {
      const response = await api.fetchAdminData(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        throw error;
      } else {
        throw error;
      }
    }
  }
)

export const filterAdmin = createAsyncThunk(
  "admin/filterAdmin",
  async ({ companyId, status }: { companyId: number, status: string }) => {
    try {
      const response = await api.filterAdminData(companyId, status);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        throw error;
      } else {
        throw error;
      }
    }
  }
)

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchAdmin.fulfilled, (state, action: PayloadAction<Array<Admin>>) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchAdmin.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(filterAdmin.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(filterAdmin.fulfilled, (state, action: PayloadAction<Array<Admin>>) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(filterAdmin.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {}
})
export const userSelector = (state: RootState) => state.admin;
export default adminSlice.reducer;



// Define selectors to access the profile slice state
export const adminData = (state: RootState) => state.admin.data;
export const adminLoading = (state: RootState) => state.admin.loading;
export const adminError = (state: RootState) => state.admin.error;