import React from 'react'

interface Props {
    content: string;
    handleClear: () => void;
}

const FilterLabel = ({ content, handleClear }: Props) => {
    return (
        <div className="inline-flex items-center h-8 overflow-hidden  bg-gray-300 rounded">
            <span className="px-5 py-1.5 text-[12px] font-medium">
                {content}
            </span>
            <button
                onClick={handleClear}
                className="inline-flex items-center justify-center w-8 h-8 bg-red-500 text-white transition-color hover:bg-red-600 focus:outline-none focus:ring"
                type="button"
            >
                <span className="sr-only"> Close </span>
                <svg
                    className="w-3 h-3"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                    />
                </svg>
            </button>
        </div>
    )
}

export default FilterLabel