import React from 'react'
import RiskImg from "./risk table.png"

const RiskCategory = () => {
    return (
        <div className=' m-auto text-black'>
            <p className='font-semibold text-[25px] text-black'>2.	Risk Category and Consequence Table</p>
            <img className='w-[100%] my-5' src={RiskImg} alt="" />

            <div className="overflow-x-auto border m-auto ">
                <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                    <thead className="ltr:text-left rtl:text-right bg-[#808080b6]">
                        <tr className="divide-x-2">
                            <th className="max-[768px]:whitespace-nowrap px-4 py-2 font-semibold text-black">Consequence Category</th>
                            <th className=" max-[768px]:whitespace-nowrap px-4 py-2 font-semibold text-black">Insignificant</th>
                            <th className=" max-[768px]:whitespace-nowrap px-4 py-2 font-semibold text-black">Minor</th>
                            <th className=" max-[768px]:whitespace-nowrap px-4 py-2 font-semibold text-black">Moderate</th>
                            <th className=" max-[768px]:whitespace-nowrap px-4 py-2 font-semibold text-black">Major</th>
                            <th className=" max-[768px]:whitespace-nowrap px-4 py-2 font-semibold text-black">Extreme</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200">
                        <tr className="divide-x-2 ">
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                Safety- the participant
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	less than 1st aid</li>
                                    <li>o	brief emotional disturbance</li>
                                </ul>
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	1st aid injury</li>
                                    <li>o	Emotional disturbance of &gt; 2 days, no treatment required</li>
                                </ul>
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	Substantial injury needing medical treatment</li>
                                    <li>o	Temp impairment</li>
                                    <li>o	Development of mental illness (exacerbation) requiring treatment</li>
                                </ul>
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	Significant injury causing permanent impairment</li>
                                    <li>o	Severe exacerbation or new mental illness requiring long term treatment</li>
                                </ul>
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	Preventable fatality</li>
                                    <li>o	Risk of violence, abuse, neglect, exploitation or discrimination</li>
                                </ul>
                            </td>
                        </tr>

                        <tr className="divide-x-2 ">
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                Safety- staff and others
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	Nil or minor 1st aid injury</li>
                                    <li>o	Brief emotional disturbance</li>
                                </ul>
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	1st aid injury</li>
                                    <li>o	Psych disturbance of &gt; 2 days, no treatment required</li>
                                </ul>
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	Substantial injury needing medical treatment (including mental health)</li>
                                    <li>o	Temporary impairment</li>
                                </ul>
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	Significant injury causing permanent impairment</li>
                                    <li>o	Severe exacerbation or new mental illness requiring long term treatment</li>
                                </ul>
                            </td>
                            <td className="max-[768px]:whitespace-nowrap  px-4 py-2 font-medium  text-gray-900">
                                <ul className=' leading-6'>
                                    <li>o	Preventable fatality</li>
                                </ul>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default RiskCategory