import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface StaffAvailability {
    companyID: number;
    dateCreated: string;
    dateModified: string;
    days: string;
    fromTimeOfDay: string;
    staffAvailibilityId: number;
    staffId: number;
    toTimeOfDay: string;
    userCreated: string;
    userModified: string;
    staff: {
        staffId: number;
        maxStaffId: string;
        firstName: string;
        surName: string;
        middleName: string;
        fullName: string;
        dateCreated: string;
        userCreated: string;
        dateModified: string;
        userModified: string;
    };
}




export interface StaffAvailabilityState {
    loading: boolean;
    data: Array<StaffAvailability>;
    error: string | undefined;
}
const initialState: StaffAvailabilityState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchStaffAvailability = createAsyncThunk(
    "StaffAvailability/fetchStaffAvailability",
    async (uid: number) => {
        try {
            const response = await api.fetchStaffAvailability(uid);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const staffAvailabilitySlice = createSlice({
    name: 'staffAvailability',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStaffAvailability.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchStaffAvailability.fulfilled, (state, action: PayloadAction<Array<StaffAvailability>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchStaffAvailability.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.staffAvailability;
export default staffAvailabilitySlice.reducer;



// Define selectors to access the profile slice state
export const staffAvailabilityData = (state: RootState) => state.staffAvailability.data;
export const staffAvailabilityLoading = (state: RootState) => state.staffAvailability.loading;
export const staffAvailabilityError = (state: RootState) => state.staffAvailability.error;