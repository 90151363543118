import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface Refferal {
    aboriginal_Torres: null | string;
    activity_group: null | string;
    aggression_History: null | string;
    allergies: null | string;
    behavior_Plan: null | string;
    clientReferralId: number;
    company: null | string;
    companyId: number;
    contactNumber: string;
    culturally_Linguistically: null | string;
    currentResidence: string;
    dateCreated: string;
    dateModified: string;
    de_escalation: string;
    dob: string;
    email: string;
    emergency_Contact: null | string;
    extra_Support: null | string;
    falls_Risk: string;
    fullName: string;
    gender: string;
    guardianDetails: null | string;
    illicit_Substances: string;
    isAccepted: boolean;
    lastName: string;
    lineItem: null | string;
    name: string;
    ndisEndDate: string;
    ndisGoals: null | string;
    ndisNo: string;
    ndisStartDate: string;
    nominatedCarer: null | string;
    participant_Engagement: null | string;
    participant_Interraction: string;
    participant_Providers: null | string;
    pets: null | string;
    physical_Illnesses: string;
    planManager: null | string;
    potential_Risk: null | string;
    providerTravel: null | string;
    psychiatric_Diagnosis: string;
    pto: string;
    public_Trustee: string;
    quote: null | string;
    referralCompany: null | string;
    referralEmail: string;
    referralFullName: string;
    referralLastName: string;
    referralName: string;
    referralPhone: string;
    referralSignature: null | string;
    referralSignatureFile: null | string;
    self_Harm: string;
    staff_Preference: null | string;
    supportCoordinator: string;
    support_Details: string;
    support_Holiday: string;
    support_Time: null | string;
    transport: null | string;
    triggers: null | string;
    ttpRate: string;
    unwell_Presentation: null | string;
    userCreated: null | string;
    userModified: null | string;
}

export interface RefferalState {
    loading: boolean;
    data: Array<Refferal>;
    error: string | undefined;
}
const initialState: RefferalState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchRefferal = createAsyncThunk(
    "Refferal/fetchRefferal",
    async (companyId: number) => {
        try {
            const response = await api.fetchRefferal(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const refferalSlice = createSlice({
    name: 'refferal',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchRefferal.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchRefferal.fulfilled, (state, action: PayloadAction<Array<Refferal>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchRefferal.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.refferal;
export default refferalSlice.reducer;


export const refferalData = (state: RootState) => state.refferal.data;
export const refferalLoading = (state: RootState) => state.refferal.loading;
export const refferalError = (state: RootState) => state.refferal.error;