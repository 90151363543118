import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface ServiceProvider {
    address: string;
    company: string;
    companyId: number;
    email: string;
    fullName: string;
    lastName: string;
    name: string;
    phone: number;
    referralCompany: string;
    serviceProvidersId: string;
}

export interface ServiceProviderState {
    loading: boolean;
    data: Array<ServiceProvider>;
    error: string | undefined;
}
const initialState: ServiceProviderState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchServiceProvider = createAsyncThunk(
    "ServiceProvider/fetchServiceProvider",
    async (companyId: number) => {
        try {
            const response = await api.fetchServiceProvider(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const serviceProviderSlice = createSlice({
    name: 'serviceProvider',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchServiceProvider.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchServiceProvider.fulfilled, (state, action: PayloadAction<Array<ServiceProvider>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchServiceProvider.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.serviceProvider;
export default serviceProviderSlice.reducer;


export const serviceProviderData = (state: RootState) => state.serviceProvider.data;
export const serviceProviderLoading = (state: RootState) => state.serviceProvider.loading;
export const serviceProviderError = (state: RootState) => state.serviceProvider.error;