import React, { useEffect, useState } from 'react'
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { DocumentList } from './data';
import { Link } from 'react-router-dom';
import { fetchDocumentByName } from '../../../../services/fetchServices';
import dayjs from 'dayjs';
import Spinner from '../../../../Components/Spinner';
import { RootState } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { FaEye } from 'react-icons/fa';
import { Tooltip, Whisper } from 'rsuite';

interface DataRow {

    docName: string;
    docLink: string;


}
interface Props {
    uid: string
}

interface DocumentData {
    id: string;                // Unique identifier for the document
    docName: string;           // Display name for the document
    docUrl?: string;           // URL to the document if available
    docDetails?: string;       // Additional details or status
    implementationDate?: string;   // Optional expiration date
    expirationDate?: string;   // Optional expiration date
    documentName: string;      // Name of the document for internal use
    documentUrl?: string;      // URL of the document for internal use
    lastModified?: string;
}

interface DataRow extends DocumentData {
    // If there are additional fields needed specifically in the table, add them here
    status?: string;           // Status might be a computed value or derived from docDetails
    user?: string;             // User associated with the document, if any
    date?: string;             // Date related to the document
}




const ClientDocument = ({ uid }: Props) => {
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [filteredData, setFilteredData] = useState<DataRow[]>([]);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const fetchDocuments = async () => {
            setLoading(true);
            try {
                const promises = DocumentList.map(doc => fetchDocumentByName(doc.docName, Number(uid), Number(user?.companyId)));
                const results = await Promise.all(promises);
                const mergedData = DocumentList.map((doc, index) => ({
                    id: doc.docName,  // Using docName as an ID for demonstration; adjust as necessary
                    docName: doc.docName,
                    docLink: doc.docUrl,
                    docUrl: results[index]?.documentUrl,
                    docDetails: results[index]?.documentFile || 'N/A',
                    implementationDate: results[index]?.implementationDate,
                    expirationDate: results[index]?.expirationDate,
                    documentName: doc.docName,
                    documentUrl: results[index]?.documentUrl,
                    lastModified: results[index]?.dateModified
                    ,
                    // Add or compute any additional DataRow fields as needed
                }));
                setFilteredData(mergedData);
                setLoading(false);
            } catch (error) {
                console.error("Failed to fetch documents:", error);
                setLoading(false);
            }
        };

        fetchDocuments();
    }, []);

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Document Name',
            selector: row => row.docName,
            sortable: true,

        },

        {
            name: 'Last Modified',
            selector: row => row.lastModified ? dayjs(row.lastModified).format('DD-MM-YYYY') : 'N/A',
        },
        // {
        //     name: 'Implementation Date',
        //     selector: row => row.implementationDate ? dayjs(row.implementationDate).format('DD-MM-YYYY') : 'N/A',
        // },
        {
            name: 'Expiration Date',
            selector: row => row.expirationDate ? dayjs(row.expirationDate).format('DD-MM-YYYY') : 'N/A',
        },
        {
            name: 'Status',
            cell: (row) => {
                const status = getStatus(row.implementationDate, row.expirationDate);
                const statusColor = getStatusColor(status);
                return (
                    <span className={`px-4 py-2 rounded font-semibold text-white capitalize bg-${statusColor}`}>
                        {status}
                    </span>
                )
            }
        },


        {
            name: "Actions",
            cell: (row) => (
                <span className="flex gap-4">

                    <div>
                        <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={
                            <Tooltip>
                                Preview Form
                            </Tooltip>
                        }>
                            <Link
                                to={`/app/participant/${row.docLink}/${uid}`}
                                className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                                <FaEye className="text-base cursor-pointer" />
                            </Link>
                        </Whisper>
                    </div>

                </span>
            )

        },



    ];

    function getStatus(implementationDate?: string, expirationDate?: string): string {
        if (!implementationDate && !expirationDate) {
            return 'not available';
        }

        const currentDate = new Date();
        const implementation = implementationDate ? new Date(implementationDate) : null;
        const expiration = expirationDate ? new Date(expirationDate) : null;

        if (implementation && currentDate < implementation) {
            return 'not started';
        } else if (expiration && currentDate > expiration) {
            return 'expired';
        } else {
            return 'active';
        }
    }

    function getStatusColor(status: string): string {
        switch (status) {
            case 'expired':
                return 'red-500';
            case 'not started':
                return 'gray-500';
            case 'active':
                return 'green-500';
            case 'not available':
                return 'gray-300';
            default:
                return 'slate-500';
        }
    }

    interface TableProps extends ExpanderComponentProps<DataRow> {
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<TableProps> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <div>
                    <span className="font-bold">Document Name: </span> {data.docName}
                </div>
            </div>

        );
    };

    return (
        <div className='mt-10'>
            {filteredData.length <= 0 && loading && <Spinner color="#071952" />}
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                    <h2 className='font-bold text-xl text-black w-auto'>Documents</h2>
                </div>

            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                expandableRows
                pagination={false}
                expandableRowsComponent={ExpandableRowComponent}
            />

        </div>
    )
}

export default ClientDocument