import React, { FormEvent, useRef, useState } from "react";
import SEO from "../../../../constants/SEO";
import Breadcrumb from "../../../../Components/label/BreadCrumb";
import Card from "./components/Card";
import CustomInput from "../../../../Components/Input/CustomInput";
import Editor from "../../../../Components/Editor/ReactQuill";
import { Link } from "react-router-dom";
import { InputPicker } from "rsuite";
import { toast } from "react-toastify";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";
import Spinner from "../../../../Components/Spinner";


type DataOption = {
  label: string;
  value: string;
};
const Ticket = () => {

  const [options, setOptions] = useState<string>("");
  // const [editor, setEditor] = useState<string>("");
  const dataOption: DataOption[] = ["Support", "Technical", "Billing", "Others"].map(
    item => ({ label: item, value: item })
  );

  const user = useSelector((state: RootState) => state?.auth?.user);
  const companyId = user?.companyId;
  const subject = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [upLoadFile, setUpLoadFile] = useState<File | null>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      setUpLoadFile(file)
    }
  }

  const [editorValue, setEditorValue] = useState<string>('');
  const handleEditorChange = (value: string) => {
    setEditorValue(value);
  };

  const submitData = async (e: FormEvent) => {
    e.preventDefault();

    if (options === "" || subject?.current?.value === null || editorValue === ""
    ) {
      return toast.error("All Fields must be filled")
    }
    const formData = new FormData();
    formData.append("Subject", subject?.current?.value ?? '');
    formData.append("Description", editorValue);
    formData.append("TicketType", options);
    upLoadFile && formData.append("ImageFIle", upLoadFile);
    formData.append("CompanyId", companyId?.toString() ?? '');

    setLoading(true)
    try {
      const { data } = await axiosInstance.post(`/Tickets/raise_ticket?userId=${user?.uid}`, formData)
      toast.success(data.message);
      setLoading(false);
    } catch (error: unknown) {

      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setLoading(false)
    }
    finally {
      setLoading(false)
    }
  }

  return (

    <>
      <SEO
        title='Raise a Ticket'
        description='Ticket'
        name='Promax-Care'
      />
      <Breadcrumb />

      <div className='mt-5'>
        <div className=''>
          <h2 className=' text-xl text-black w-auto font-bold'>Raise a Ticket</h2>
          <div className='mt-5 grid grid-cols-1 gap-4 lg:grid-cols-4 '>

            <Card
              bg="#FEF6E7"
              title="What Does 'Raise a Ticket' Mean?"
              content="
              Raise a Ticket' is like opening a direct line to our support team. It's a simple way for you to tell us exactly what you need help with. Whether you're facing a problem, have a question, or need assistance with any of our services, this feature is here for you.
              "

            />
            <Card
              bg="#FFECE5"
              title="Tell Us What's Up"
              content="
              whenever you need help. there is our input asking for some details about your issue. Here, you can explain what's going wrong, ask a question, or request help our support team are ready to help.

              "

            />
            <Card
              bg="#E7F6EC"
              title="Send It to Us"
              content="
              After you input the  details and the information about your issue, press 'Submit'. This sends your message straight to the people who can help you best.

              "

            />
            <Card
              bg="#E3EFFC"
              title="We're On It"
              content="
              Once you've sent us your ticket, our support team gets to work. We'll look over what you've told us, figure out the best way to help, and get back to you as soon as we can

              "

            />

          </div>
          <div className="mt-5">
            <h2 className=' text-xl text-black w-auto font-bold'>Use our Preload Template</h2>
            <div className="mt-5 space-y-4">
              <p className="text-black text-sm ">
                Using our preloaded template will give you a head start when lodging a complaint. <Link to={'/app/staff/communication/ticket-template'} className="text-blue-700 underline">Click here to view our template.</Link> Once the template appears in the text fields, you can easily edit it to match the specifics of your complaint. Additionally, if you have a screenshot or any relevant images that support your claim, you are encouraged to upload them along with your submission. This helps us understand and address your concerns more effectively.
              </p>
              <div className='border-2 rounded-lg' >
                <div className='bg-gray-200 text-center p-2 flex justify-between items-center text-sm font-bold text-black rounded '>
                  <span> Raise A Ticket</span>
                </div>

                <div className='p-2 grid grid-cols-1 gap-4'>
                  <div>
                    <label className=" mb-2 text-xs flex gap-1  text-gray-600 font-semibold dark:text-white">Ticket Type</label>
                    <InputPicker size="lg" placeholder=""
                      data={dataOption}
                      className='w-full focus:outline-none focus:border-primary text-gray-600'
                      value={options}
                      appearance='subtle'
                      onChange={(e: string) => setOptions(e)}
                    />
                  </div>
                  <CustomInput
                    label='Subject'
                    type='text'
                    required
                  />
                  <div>
                    <label htmlFor="">Description</label>

                    <Editor
                      placeholder="Write something..."
                      onChange={handleEditorChange as unknown as () => void}
                      value={editorValue}
                    ></Editor>
                    <br />
                  </div>

                  <CustomInput
                    label=''
                    type='file'
                    accept=".pdf,.doc,.docx"
                    onChange={handleFileChange}
                  />

                  <div className='mx-auto text-center mt-3'>
                    <button type="submit"
                      onClick={submitData}
                      className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{loading ? <Spinner /> : "Submit"}</button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Ticket;
