import React, { useState, useRef, useEffect } from "react";
import { MdNotifications } from "react-icons/md";
import { useSelector } from "react-redux";
import {
  fetchInbox,
  inboxData,
  inboxLoading,
} from "../../store/slices/inboxSlice";
import { AppDispatch, RootState, useAppDispatch } from "../../store/store";
import dayjs from "dayjs";
import parse from "html-react-parser";
import Spinner from "../Spinner";
import notiIcon from "../../images/icon/notification.png";

const DropdownNotification = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();

  const trigger = useRef<HTMLButtonElement>(null);
  const dropdown = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const userId = user?.uid;
    if (userId) {
      dispatch(fetchInbox(userId));
    }
  }, []);

  const inbox = useSelector(inboxData);
  const loadingData = useSelector(inboxLoading);

  const filteredInbox = inbox?.filter((item) => item.status === false);

  useEffect(() => {
    const clickHandler = ({ target }: { target: EventTarget | null }) => {
      if (!dropdown.current || !trigger.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target as Node) ||
        trigger.current.contains(target as Node)
      )
        return;
      setDropdownOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }: { keyCode: number }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  return (
    <div className="relative">
      <span
        ref={trigger}
        onClick={() => setDropdownOpen(!dropdownOpen)}
        className="relative flex w-8 h-8 md:h-10 md:w-10 items-center justify-center rounded-full border-[0.5px] border-stroke bg-gray-100 hover:text-primary dark:border-strokedark dark:bg-meta-4 dark:text-white"
      >
        {filteredInbox?.length <= 0 ? (
          ""
        ) : (
          <span className="absolute -top-0.5 right-0 z-1 h-3 w-3 flex justify-center items-center p-2 text-white text-xs rounded-full bg-secondary">
            {filteredInbox?.length}
          </span>
        )}

        <MdNotifications className="duration-300 ease-in-out text-lg md:text-xl" />
      </span>

      <div
        ref={dropdown}
        onFocus={() => setDropdownOpen(true)}
        onBlur={() => setDropdownOpen(false)}
        className={`absolute md:-right-27 mt-2.5 flex h-90  flex-col rounded border border-gray-200 
        transform scale-100
        transition duration-300 ease-in-out bg-gray-100
         shadow-lg dark:border-strokedark dark:bg-slate-800   right-0 w-[17rem] md:w-80 ${
           dropdownOpen === true ? "block" : "hidden"
         }`}
      >
        <div className="px-4 py-3">
          <h5 className="text-sm font-medium text-primary">Notification</h5>
        </div>

        {filteredInbox.length <= 0 && loadingData && <Spinner />}
        {filteredInbox.length <= 0 && !loadingData && (
          <div className="rounded-2xl ">
            <div className="flex flex-col gap-2 items-center justify-center mx-auto pt-5 px-4 md:px-4 xl:px-10 my-3">
              <img src={notiIcon} alt="notification icon" width={120} />

              <strong className="text-center">
                There's currently no unread notifications.
                {/* <small className="font-normal">You will be notified here once there is any activity or anything that needs your attention.</small> */}
              </strong>
            </div>
          </div>
        )}
        {filteredInbox.length > 0 && (
          <ul className="flex  flex-col overflow-y-auto h-[55vh]">
            {filteredInbox.map((item, index) => (
              <li key={index}>
                <div className="flex bg-white rounded-2xl flex-col gap-1 border-t border-gray-200 px-4 py-3 hover:bg-gray-200 dark:border-strokedark dark:hover:bg-meta-4">
                  <h4 className="font-semibold text-sm line-clamp-1">
                    {item.subject}
                  </h4>
                  <p className="text-xs line-clamp-2">{parse(item.content)}</p>
                  <p className="text-xs">
                    {dayjs(item.dateCreated).format("D, MMM. YYYY")}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        )}
        <div className="px-4 py-3">
          <h5 className="text-sm font-medium text-primary">
            View All Notifications.....
          </h5>
        </div>
      </div>
    </div>
  );
};

export default DropdownNotification;
