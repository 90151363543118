import React from "react";
import Form from "./Form";

const Hero = () => {
  return (
    <div>
      <div className="flex justify-end w-[100%]">
        <img
          className="w-24 mb-4 lg:mb-4"
          src="https://profitmax-001-site10.ctempurl.com/Uploads/botosoft-300x183-1-300x183235551864.png"
          alt="Promaxcare Logo"
        />
      </div>
      <h1 className="font-medium text-2xl">Consent Form</h1>
      <Form />
    </div>
  );
};

export default Hero;
