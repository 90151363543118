import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
import { Button, Modal, Tooltip, Whisper } from 'rsuite';
import CustomInput from '../../../Components/Input/CustomInput';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import { fetchHoliday, holidayData, holidayLoading } from '../../../store/slices/holidaySlice';
import Spinner from '../../../Components/Spinner';
import axiosInstance from '../../../store/axiosInstance';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import {
    FaFileCsv,
    FaFileExcel,
    FaFilePdf,
    // FaFilter,
    FaRegEdit,
    FaRegTrashAlt
} from 'react-icons/fa';
import IconButton from '../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';
import { Column } from '../../../types/DataType';
// import { CustomDropdown } from './CustomDropDown';

interface DataRow {
    company: string;
    companyId: number;
    date: string;
    dateCreated: string;
    dateModified: string;
    holidayId: string;
    name: string;
    userCreated: string;
    userModified: string;
}


const PublicHoliday = () => {

    const [ajustAttendance, setAjustAttendanceModal] = useState<boolean>(false);
    const [editHoliModal, setEditHoliModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [saveId, setSaveId] = useState<string>("")
    const holiName = useRef<HTMLInputElement>(null);
    const holiDate = useRef<HTMLInputElement>(null);
    const [saveIds, setSaveIds] = useState<DataRow>()
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchHoliday(companyId));
        }
    }, [dispatch, companyId]);
    const holiday = useSelector(holidayData);
    const loadingData = useSelector(holidayLoading);

    const [loadingClockId, setLoadingClockId] = useState<string | null>(null);
    const [holiDetails, setHoliDetails] = useState<DataRow>(
        {
            company: "",
            companyId: 0,
            date: "",
            dateCreated: "",
            dateModified: "",
            holidayId: "",
            name: "",
            userCreated: "",
            userModified: "",
        }
    )

    const handleClockClick = (date: string) => {
        setLoadingClockId(date);
        // Perform any actions you need when the clock is clicked, for example, the AdjustAttendance function
        AdjustAttendance(date)
            .then(() => {
                setLoadingClockId(null); // Set the loading status back to normal when the action is complete
            })
            .catch((error: unknown) => {
                console.error(error); // Handle errors appropriately if needed
                setLoadingClockId(null); // Set the loading status back to normal in case of an error
            });
    };

    const columns: TableColumn<DataRow>[] = [

        {
            name: 'Holiday Title',
            selector: row => row.name,
        },
        {
            name: 'Adjust Public Holiday',
            cell: (row: DataRow) => (

                <div className="flex gap-4  justify-center " >

                    <button
                        title='Adjust Attendance'
                        onClick={() => handleClockClick(row.date)}
                        disabled={loadingClockId === row.date} // Disable the button only for the clicked clock
                        className="w-full md:w-auto flex items-center justify-center py-1 px-3 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                        <FaRegEdit className='text-base' />
                        {
                            loadingClockId === row.date ? <Spinner /> :
                                "Adjust"
                        }
                    </button>



                </div>

            ),
        },
        {
            name: 'Date',
            selector: row => dayjs(row.date).format('MMMM D'),
        },
        {
            name: 'Date Created',
            selector: row => dayjs(row.dateCreated).format('ddd MMM YYYY HH:mmA')
        },

        {
            name: "Actions",
            cell: (row: DataRow) => (

                <div className="flex gap-4  justify-center " >
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                        <Tooltip>
                            Edit Public Holiday
                        </Tooltip>
                    }>
                        <button onClick={() => EditPublicHoliday(row.holidayId)} className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                            <FaRegEdit className='text-base' />
                        </button>

                    </Whisper>
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                        <Tooltip>
                            Delete Public Holiday
                        </Tooltip>
                    }>
                        <button onClick={() => delHoliday(row)} className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                            <FaRegTrashAlt className='text-base text-red-500' />
                        </button>

                    </Whisper>

                </div>

            ),
        },
    ];

    const EditPublicHoliday = async (id: string) => {
        setSaveId(id);
        setEditHoliModal(true);
        try {
            const { data } = await axiosInstance.get(`/SetUp/holiday_details/${id}`);
            setHoliDetails({ ...data });

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }

    const HandleEdit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        const info = {
            ...holiDetails,
            companyId: companyId

        }
        try {
            const { data } = await axiosInstance.post(`/SetUp/edit_holiday/${saveId}?userId=${user?.uid}`, info);
            toast.success(data.message)
            companyId && dispatch(fetchHoliday(companyId));
            setEditHoliModal(false)
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }

    }

    const CreatePublicHoliday = async (e: FormEvent) => {
        e.preventDefault()
        if (holiName.current?.value === "" || holiDate.current?.value === "") {
            return toast.error("Input Fields cannot be empty")
        }

        setLoading(true)
        const info = {
            name: holiName.current?.value,
            date: holiDate.current?.value,
            companyId: user?.companyId
        }

        try {

            const { data } = await axiosInstance.post(`/SetUp/add_holiday?userId=${user?.uid}`, info);
            toast.success(data.message);
            companyId && dispatch(fetchHoliday(companyId));
            setLoading(false);
            setModal(false);

        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false)
        }
        finally {
            setLoading(false)
        }
    }

    const AdjustAttendance = async (e: string) => {
        setSaveId(e)
        setAjustAttendanceModal(true)
    }

    const SubmitAdjustAttendance = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(`/SetUp/adjust_public_holiday?userId=${user?.uid}&shiftdate=${saveId}&companyId=${user?.companyId}`)
            toast.success(data.message);
            setAjustAttendanceModal(false);
            companyId && dispatch(fetchHoliday(companyId));
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
            setAjustAttendanceModal(false);
        }
        finally {
            setLoading(false);
        }
    }

    const delHoliday = (id: DataRow) => {
        setSaveIds(id);
        setDeleteModal(true);
    }

    const handleDeleteHoliday = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data } = await axiosInstance.post(`/SetUp/delete_holiday/${saveIds?.holidayId}?userId=${user?.uid}`)
            toast.success(data.message);
            setDeleteModal(false);
            companyId && dispatch(fetchHoliday(companyId));
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
            setDeleteModal(false);
        }
        finally {
            setLoading(false);
        }
    }

    const [modal, setModal] = useState<boolean>(false);

    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setHoliDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Name: </span>
                    <span> {data.name}</span>
                </span>
                <span>
                    <span className='font-bold'>Date: </span>
                    <span> {dayjs(data.date).format('MMMM D')}</span>
                </span>
                <span>
                    <span className='font-bold'>Date Modified: </span>
                    <span> {dayjs(data.dateModified).format('ddd MMM YYYY HH:mmA')}</span>
                </span>
            </div>
        )
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = holiday.filter((item) =>
        item.name.toLowerCase().includes(searchText.toLowerCase()) ||
        item.date.toLowerCase().includes(searchText.toLowerCase())
    );


    return (
        <>
            <SEO
                title='Public Holiday - Promax Care'
                description='Public Holiday'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-10'>
                {holiday.length <= 0 && loadingData && <Spinner color='#071952' />}

                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Public Holiday</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By Name or Date" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(holiday, columns as unknown as Column<DataRow>[], "PublicHoliday.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(holiday, columns as unknown as Column<DataRow>[], "PublicHoliday")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: holiday, filename: 'PublicHoliday.csv' })}

                                    />
                                </div>
                            </ExportDropdown>


                        </div>
                        <button
                            onClick={() => setModal(true)}
                            type="button" className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           ">

                            Create New Holiday
                        </button>
                    </div>
                </div>



                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />

            </div>


            <Modal open={deleteModal} onClose={() => setDeleteModal(false)} role="alertdialog" backdrop="static" autoFocus size={"xs"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Delete Public Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className="mb-4 font-bold">Are you sure you want to delete Public Holiday "{saveIds?.name}"?</p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button onClick={() => setDeleteModal(false)} className='bg-gray-500 text-white'>
                                No, cancel
                            </Button>
                            <Button
                                onClick={handleDeleteHoliday}
                                className='bg-red-500 text-white hover:bg-red-700 hover:text-white'>
                                {
                                    loading ? <Spinner /> : "Yes, I'm sure"
                                }
                            </Button>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal open={editHoliModal} onClose={() => setEditHoliModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Edit Public Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2'>
                            <CustomInput
                                label='Holiday Title'
                                type='text'
                                name="name"
                                value={holiDetails?.name}
                                onChange={handleInputChange}
                            />
                            <CustomInput
                                label='Date'
                                type='date'
                                name="date"
                                value={holiDetails?.date}
                                onChange={handleInputChange}
                            />

                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    onClick={HandleEdit} disabled={loading ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Update"
                                    }</button>

                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal open={ajustAttendance} onClose={() => setAjustAttendanceModal(false)} role="alertdialog" backdrop="static" autoFocus size={"xs"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Adjust Attendance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className="mb-4 font-bold">This will adjust and rectify the shift type of all attendances that fell on this day to public holiday .
                            Do you wish to proceed?</p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button onClick={() => setAjustAttendanceModal(false)} className='bg-gray-500 text-white'>
                                No, cancel
                            </Button>
                            <Button
                                onClick={SubmitAdjustAttendance}
                                className='bg-primary text-white hover:bg-primary/80 hover:text-white'>
                                {
                                    loading ? <Spinner /> : "Yes, I'm sure"
                                }
                            </Button>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal open={modal} onClose={() => setModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Create Public Holiday</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="" className='space-y-2'>
                        <CustomInput
                            label='Holiday Title'
                            type='text'
                            ref={holiName}
                            placeholder='Holiday Title'
                        />
                        <CustomInput
                            label='Date'
                            type='date'
                            ref={holiDate}
                            placeholder='Date'
                        />
                        <div className='mx-auto text-center mt-3'>
                            <button type="submit"
                                onClick={CreatePublicHoliday} disabled={loading ? true : false}
                                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                    loading ? <Spinner /> : "Create"
                                }</button>

                        </div>
                    </form>

                </Modal.Body>
                <Modal.Footer>

                </Modal.Footer>
            </Modal>
        </>
    )
}

export default PublicHoliday