import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
// import { FaFilter } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
import { Button, Modal } from 'rsuite';
import CustomInput from '../../../Components/Input/CustomInput';
// import DropdownMenu from '../../../Components/menu/DropdownMenu';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import { fetchFormTemplate, formTemplateData, formTemplateLoading } from '../../../store/slices/formTemplateSlice';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import Spinner from '../../../Components/Spinner';
import axiosInstance from '../../../store/axiosInstance';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import IconButton from '../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';
import { Column } from '../../../types/DataType';
// import { CustomDropdown } from './CustomDropDown';

interface DataRow {
    company: string;
    companyId: number;
    content: string;
    dateCreated: string;
    dateModified: string;
    isCarePlan: boolean
    isEmploymentForm: boolean;
    isGeneral: boolean;
    isIncidentForm: boolean;
    templateId: string;
    templateName: string;
    templateType: string;
    templateUrl: string;
    templateUrlFile: string;
    userCreated: string;
    userModified: string;
}


const FormTemplate = () => {

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchFormTemplate(companyId));
        }
    }, [dispatch, companyId]);

    const formTemplate = useSelector(formTemplateData);
    const loadingData = useSelector(formTemplateLoading);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [saveId, setSaveId] = useState<string>("")

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Template Name',
            selector: row => row.templateName,

        },
        {
            name: 'Template Type',
            selector: row => row.templateType,

        },


        {
            name: 'Is Employment Form',
            selector: row => row.isEmploymentForm,
            cell: row => (
                <input type="checkbox" checked={row.isEmploymentForm} readOnly />
            )
        },
        {
            name: 'Is General',
            selector: row => row.isGeneral,
            cell: row => (
                <input type="checkbox" checked={row.isGeneral} readOnly />
            )
        },
        {
            name: 'Is Incident Form',
            selector: row => row.isIncidentForm,
            cell: row => (
                <input type="checkbox" checked={row.isIncidentForm} readOnly />
            )
        },
    ];

    const delTemplte = (id: string) => {
        setSaveId(id);
        setDeleteModal(true);
    }

    const handleDeleteTemplate = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data } = await axiosInstance.post(`/Templates/delete/${saveId}?userId=${user?.uid}`)
            toast.success(data.message);
            setDeleteModal(false);
            companyId && dispatch(fetchFormTemplate(companyId));
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
            setDeleteModal(false);
        }
        finally {
            setLoading(false);
        }
    }

    const handleView = (templateUrl: string) => {
        window.open(templateUrl, '_blank');
    };

    const [modal, setModal] = useState(false);


    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Template Name: </span>
                    <span> {data.templateName}</span>
                </span>

                <span>
                    <span className='font-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>
                <span>
                    <span className='font-bold'>Date Modified: </span>
                    <span>
                        {dayjs(data.dateModified).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>
                <div className='flex gap-2'>
                    <span className='font-bold'>Actions: </span>
                    {
                        data.templateType === "Editable" ? <Link to={`/app/employee/fillForm/${data.templateId}`} className="btn text-primary" style={{ fontSize: "12px" }}>
                            Edit
                        </Link > : ""
                    } |
                    {
                        data.templateUrl ?
                            <button className="btn text-secondary" style={{ fontSize: "12px" }}
                                onClick={() => handleView(data.templateUrl)}
                            >
                                View
                            </button> :

                            ""
                        // <button className="btn text-secondary" style={{ fontSize: "12px" }}
                        //     // onClick={() => handleDetails(data.templateId)}
                        // >
                        //     {
                        //         loading ?
                        //             <>
                        //                 <span className="spinner-border text-white spinner-border-sm me-2" aria-hidden="true" />
                        //                 Please wait...
                        //             </>
                        //             :
                        //             "View"

                        //     }
                        // </button>
                    }
                    |
                    <button className="btn text-red-500 font-bold" style={{ fontSize: "12px" }}
                        onClick={() => delTemplte(data.templateId)}
                    >

                        Delete
                    </button>
                </div>
            </div>
        )
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = formTemplate.filter((item) =>
        item.templateName.toLowerCase().includes(searchText.toLowerCase())
    );


    return (
        <>
            <SEO
                title='Form Templates - Promax Care'
                description='Form Templates'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-10'>
                {formTemplate.length <= 0 && loadingData && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Form Template</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(formTemplate, columns as unknown as Column<DataRow>[], "FormTemplate.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(formTemplate, columns as unknown as Column<DataRow>[], "FormTemplate")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: formTemplate, filename: 'FormTemplate.csv' })}

                                    />
                                </div>
                            </ExportDropdown>




                        </div>
                        <button
                            onClick={() => setModal(true)}
                            type="button" className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           ">

                            Create Form
                        </button>
                    </div>
                </div>



                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />





            </div>

            <Modal open={deleteModal} onClose={() => setDeleteModal(false)} role="alertdialog" backdrop="static" autoFocus size={"xs"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Delete Form Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className="mb-4 font-bold">Are you sure you want to delete this template ?</p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button onClick={() => setDeleteModal(false)} className='bg-gray-500 text-white'>
                                No, cancel
                            </Button>
                            <Button
                                onClick={handleDeleteTemplate}
                                className='bg-red-500 text-white hover:bg-red-700 hover:text-white'>
                                {
                                    loading ? <Spinner /> : "Yes, I'm sure"
                                }
                            </Button>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal open={modal} onClose={() => setModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Create Form</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <CustomInput
                            label='Title'
                            type='text'
                        />
                        <CustomInput
                            label='Date'
                            type='date'
                        />
                    </form>

                </Modal.Body>
                <Modal.Footer>

                    <Button onClick={() => setModal(false)} className='bg-red-500 text-white'>
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default FormTemplate