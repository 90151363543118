import React, { useEffect, useState } from 'react';
import { CheckPicker, InputPicker, Modal } from 'rsuite';
import { fetchRosterInfo } from '../../../../../services/fetchServices';
import Spinner from '../../../../../Components/Spinner';
import { activityOptions } from './data';
import { toast } from 'react-toastify';
import { Shift, fetchShiftRoster } from '../../../../../store/slices/shiftRosterSlice';
import { AppDispatch, RootState, useAppDispatch } from '../../../../../store/store';
import { useSelector } from 'react-redux';
import { fetchStaff, staffData } from '../../../../../store/slices/staffSlice';
import { formatClient, formattedClientData } from '../../../../../store/slices/clientSlice';
import CustomInput from '../../../../../Components/Input/CustomInput';
import axiosInstance from '../../../../../store/axiosInstance';
import { isAxiosError } from 'axios';



interface Props {
    editId: number;
    editModal: boolean;
    setEditModal: React.Dispatch<React.SetStateAction<boolean>>;
}


const EditRoster = ({ editModal, setEditModal, editId }: Props) => {
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [loading, setLoading] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [shiftOne, setShiftOne] = useState<Shift | null>(null);
    const [selectedStaff, setSelectedStaff] = useState<number>(0);
    const [selectedClient, setSelectedClient] = useState<string[]>([]);
    const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
    const [dateFrom, setDatefrom] = useState("");
    const [dateTo, setDateTo] = useState("");
    const [isNightShift, setIsNightShift] = useState(false);
    const [isExceptionalShift, setIsExceptionalShift] = useState(false);


    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchStaff(companyId));
            dispatch(formatClient(companyId));
        }
    }, [user?.companyId]);
    const formattedClients = useSelector(formattedClientData);
    const staff = useSelector(staffData);


    useEffect(() => {
        const handleFetchRoster = async () => {
            setLoading(true);
            try {
                const data: Shift = await fetchRosterInfo(editId);
                setShiftOne(data);
                const { activities, staff, clients, dateFrom, dateTo, isNightShift, isExceptionalShift }: Shift = await fetchRosterInfo(editId);
                // Assuming activities is a comma-separated string of activity values
                const selectedActivities = activities.split(', ').filter(activity =>
                    activityOptions.some(option => option.value === activity.trim())
                );
                setSelectedActivities(selectedActivities);

                const selectedClients = clients.split(/,\s*/).filter(client =>
                    formattedClients.some(option => option.value === client)
                );
                setSelectedClient(selectedClients);
                setSelectedStaff(staff.staffId);
                setDatefrom(dateFrom);
                setDateTo(dateTo);
                setIsNightShift(isNightShift)
                setIsExceptionalShift(isExceptionalShift)

            } catch (error) {
                console.error('Failed to fetch roster info:', error);
            } finally {
                setLoading(false);
            }
        };

        if (editId && editModal) {
            handleFetchRoster();
        }
    }, [editId, editModal]);

    const handleUpdateActivities = async (e: React.FormEvent) => {
        e.preventDefault();

        const info = {
            ...shiftOne,
            companyID: user?.companyId,
            shiftRosterId: editId,
            staffId: selectedStaff,
            clients: selectedClient.join(', '),
            dateFrom,
            dateTo,
            activities: selectedActivities.join(', '),
            isNightShift,
            isExceptionalShift,

        }
        delete info.profile;
        delete info.staff;
        setLoadingSubmit(true);



        try {
            setLoadingSubmit(true)
            const { data } = await axiosInstance.post(`/ShiftRosters/edit_shift/${editId}?userId=${user?.uid}`, info)
            toast.success(data.message)

            setLoadingSubmit(false);
            if (companyId) {
                dispatch(fetchShiftRoster(companyId));
            }
            setEditModal(false);

        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("Error Editing Shift");
            }

            setLoadingSubmit(false)
        } finally {
            setLoadingSubmit(false);
            setEditModal(false);
        }

    };




    return (
        <Modal open={editModal} onClose={() => setEditModal(false)} backdrop="static" autoFocus size="lg">
            <Modal.Header>
                <Modal.Title className='font-bold'>Edit Shift Roster</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading ? (
                    <Spinner />
                ) : (
                    <div>
                        <form action="" className='p-2 grid grid-cols-1 gap-4 lg:grid-cols-2' onSubmit={handleUpdateActivities}>
                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Staff</label>
                                <InputPicker size="lg" placeholder="--All Staff--"
                                    onChange={value => setSelectedStaff(value)}
                                    data={staff.map(user => ({ label: user.fullName, value: user.staffId }))}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                    value={selectedStaff}
                                />
                            </div>

                            <div>
                                <label className="text-xs text-gray-600 font-semibold">Select Clients</label>
                                <CheckPicker
                                    data={formattedClients} // Ensure this is an array of objects with 'label' and 'value' keys
                                    onChange={setSelectedClient} // Directly pass setSelectedClients here
                                    size="lg"
                                    placeholder="Select clients"
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    value={selectedClient} // Control the component with the selectedClients state
                                    searchable={false}
                                />
                            </div>

                            <CustomInput
                                label='Start Date & Time'
                                type='datetime-local'
                                onChange={(e) => setDatefrom(e.target.value)}
                                value={dateFrom}
                                required

                            />

                            <CustomInput
                                label='End  Date &Time'
                                type='datetime-local'
                                onChange={(e) => setDateTo(e.target.value)}
                                value={dateTo}
                                required


                            />

                            <div className='lg:col-span-2'>
                                <label className="text-xs text-gray-600 font-semibold">Activities</label>
                                <CheckPicker
                                    data={activityOptions}
                                    onChange={setSelectedActivities}
                                    value={selectedActivities}
                                    size="lg"
                                    placeholder="Select Activities"
                                    className="w-full focus:outline-none focus:border-primary text-gray-600"
                                    searchable={false}  // Added to simplify the picker interaction
                                    style={{ width: '100%' }}  // Ensure full width within modal
                                />
                            </div>


                            <div className="flex items-center">
                                <input id="default-checkbox"
                                    checked={isExceptionalShift}
                                    onChange={() => setIsExceptionalShift(!isExceptionalShift)}
                                    type="checkbox" className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded" />
                                <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Active Night</label>
                            </div>
                            <div className="flex items-center">
                                <input id="default-checkbox"
                                    checked={isNightShift}
                                    onChange={() => setIsNightShift(!isNightShift)}
                                    type="checkbox" className="w-3 h-3 text-primary accent-primary bg-gray-100 border-gray-300 rounded" />
                                <label htmlFor="default-checkbox" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"> Sleep Over</label>
                            </div>
                            <div className='mx-auto text-center mt-3 lg:col-span-2'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary">
                                    {
                                        loadingSubmit ?
                                            <Spinner /> : " Update"
                                    }

                                </button>

                            </div>


                        </form>
                    </div>
                )}
            </Modal.Body>

        </Modal>
    );
};

export default EditRoster;
