import React, { useRef } from 'react'
import SupportPlanReview from './__component/Main'
import Breadcrumb from '../../Components/label/BreadCrumb';
import PrintComponent from '../PrintTab';

const SupportPlan = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Breadcrumb />
      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print this Document"
        documentTitle="Support Plan Form"
      />
      <div ref={componentRef} className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white">
        <SupportPlanReview />
      </div>
    </div>
  )
}

export default SupportPlan