import React, { useState, useEffect } from "react";

interface PopupProps {
  launchDate: string;
  onLaunch: () => void;
}

interface TimeLeft {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds?: number;
}

const CountdownPopup: React.FC<PopupProps> = ({ launchDate, onLaunch }) => {
  const calculateTimeLeft = (): TimeLeft => {
    const difference = +new Date(launchDate) - +new Date();
    let timeLeft: TimeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState<TimeLeft>(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      const time = calculateTimeLeft();
      setTimeLeft(time);
      if (Object.keys(time).length === 0) {
        clearInterval(timer);
        onLaunch();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [launchDate, onLaunch]);

  // Hidden function to bypass the countdown for testing
  const handleBypassCountdown = () => {
    console.log("Bypassing countdown for testing...");
    onLaunch();
  };

  // Add keyboard shortcut to bypass countdown
  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      if (event.ctrlKey && event.key === "b") {
        handleBypassCountdown();
      }
    };

    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      {timeLeft.days !== undefined ? (
        <div className="fixed inset-0 z-[999999] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
          <div className="bg-primary h-screen z-[999999] w-screen">
            <div className="flex flex-col items-center justify-center w-full h-full gap-4 sm:gap-12">
              <h1 className="lg:text-5xl text-2xl font-bold text-white text-center px-2">
                COUNTDOWN TO LAUNCH
              </h1>
              <div className="flex justify-center gap-3 sm:gap-8">
                <div className="flex flex-col gap-5 relative">
                  <div className="h-16 w-16 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-white rounded-lg">
                    <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -left-[6px] rounded-full bg-primary"></div>
                    <span className="lg:text-7xl sm:text-6xl text-3xl font-semibold text-primary">
                      {timeLeft.hours}
                    </span>
                    <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-primary"></div>
                  </div>
                  <span className="text-gray-50 text-xs sm:text-2xl text-center font-medium">
                    {timeLeft.hours === 1 ? "Hour" : "Hours"}
                  </span>
                </div>
                <div className="flex flex-col gap-5 relative">
                  <div className="h-16 w-16 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-white rounded-lg">
                    <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -left-[6px] rounded-full bg-primary"></div>
                    <span className="lg:text-7xl sm:text-6xl text-3xl font-semibold text-primary">
                      {timeLeft.minutes}
                    </span>
                    <div
                      className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-primary cursor-pointer"
                      onClick={handleBypassCountdown}
                    ></div>
                  </div>
                  <span className="text-gray-50 text-xs sm:text-2xl text-center capitalize">
                    {timeLeft.minutes === 1 ? "Minute" : "Minutes"}
                  </span>
                </div>
                <div className="flex flex-col gap-5 relative">
                  <div className="h-16 w-16 sm:w-32 sm:h-32 lg:w-40 lg:h-40 flex justify-between items-center bg-white rounded-lg">
                    <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -left-[6px] rounded-full bg-primary"></div>
                    <span className="lg:text-7xl sm:text-6xl text-3xl font-semibold text-primary">
                      {timeLeft.seconds}
                    </span>
                    <div className="relative h-2.5 w-2.5 sm:h-3 sm:w-3 -right-[6px] rounded-full bg-primary"></div>
                  </div>
                  <span className="text-gray-50 text-xs sm:text-2xl text-center capitalize">
                    {timeLeft.seconds === 1 ? "Second" : "Seconds"}
                  </span>
                </div>
              </div>
              <p className="text-white text-base text-center">
                Hold tight as we are preparing to launch the Promax Care{" "}
                <span className="px-2 py-1 bg-secondary text-base font-bold text-black rounded">
                  2.0
                </span>
              </p>
            </div>
          </div>
        </div>
      ) : (
        <span>Launch time has arrived!</span>
      )}
    </>
  );
};

export default CountdownPopup;
