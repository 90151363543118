import React from 'react'

const HomeVisit = () => {
    return (
        <div className=' m-auto text-black'>
            <p className='font-semibold text-[25px] text-black'>5.	Home visits risk assessment</p>



            <div className="overflow-x-auto border border-gray-300">
                <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
                    <thead className="ltr:text-left rtl:text-right">
                        <tr className='divide-gray-200 divide-x-2 bg-gray-500'>
                            <th className="px-4 py-2 text-white font-semibold">Access to property</th>
                            <th className="px-4 py-2 text-white font-semibold">Yes / No</th>
                            <th className="px-4 py-2 text-white font-semibold">Risk rating</th>
                            <th className="px-4 py-2 text-white font-semibold">Actions / response plan</th>
                        </tr>
                    </thead>

                    <tbody className="divide-y divide-gray-200">
                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Is the property hard to locate?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Is it safely lit and accessible?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Are there access risks? E.g., slippery paths?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Is there mobile phone reception?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2 bg-gray-500'>
                            <th className="px-4 py-2 text-white font-semibold">Animals and pets</th>
                            <th className="px-4 py-2 text-white font-semibold">Yes / No</th>
                            <th className="px-4 py-2 text-white font-semibold">Risk rating</th>
                            <th className="px-4 py-2 text-white font-semibold">Actions / response plan</th>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Are there potentially dangerous animals onsite? E.g. dogs, snakes</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Are animals secured?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2 bg-gray-500'>
                            <th className="px-4 py-2 text-white font-semibold">Hazards inside the property</th>
                            <th className="px-4 py-2 text-white font-semibold">Yes / No</th>
                            <th className="px-4 py-2 text-white font-semibold">Risk rating</th>
                            <th className="px-4 py-2 text-white font-semibold">Actions / response plan</th>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Are there slips, trips or falls risks?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Are smoke detectors in place and working?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Is there an electrical cut-off safety switch? </td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Is the bathroom and toilet accessible? </td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2 bg-gray-500'>
                            <th className="px-4 py-2 text-white font-semibold">Occupants of the property</th>
                            <th className="px-4 py-2 text-white font-semibold">Yes / No</th>
                            <th className="px-4 py-2 text-white font-semibold">Risk rating</th>
                            <th className="px-4 py-2 text-white font-semibold">Actions / response plan</th>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Does the participant live in a shared household?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Are there residents who smoke inside the house?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Do the participant or other house occupants have a known history of violence or aggression? </td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Are there known weapons or firearms in the house?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>

                        <tr className='divide-gray-200 divide-x-2'>
                            <td className="px-4 py-2 font-medium text-gray-900">Is there known substance abuse by occupants or visitors?</td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                            <td className="px-4 py-2 text-gray-700" contentEditable></td>
                        </tr>
                    </tbody>
                </table>
            </div>



        </div>
    )
}

export default HomeVisit