import React from "react";
import MainLink from "./MainLink";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import {
  MdCalendarViewWeek,
  MdEditNote,
  MdNoteAlt,
  MdOutlineDateRange,
  MdOutlineDocumentScanner,
  MdOutlineFolderOpen
} from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa";

interface Props {
  open: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StaffLink = ({ open, setSidebarOpen }: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  return (
    <>

      {user?.role === "Staff" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<MdOutlineDateRange className="text-gray-400 text-lg" />}
          title="My Availabilities"
          link="/app/staff/availabilities"
          open={open}
        />
      ) : (
        ""
      )}
      {user?.role === "Staff" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<FaRegCalendarCheck className="text-gray-400 text-base" />}
          title="My Attendance"
          link="/app/staff/attendance"
          open={open}
        />
      ) : (
        ""
      )}
      {user?.role === "Staff" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<MdNoteAlt className="text-gray-400 text-lg" />}
          title="My Progress Note"
          link="/app/staff/progress-note"
          open={open}
        />
      ) : (
        ""
      )}
      {user?.role === "Staff" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<MdEditNote className="text-gray-400 text-lg" />}
          title="My Daily Report"
          link="/app/staff/daily-report"
          open={open}
        />
      ) : (
        ""
      )}
      {user?.role === "Staff" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<MdCalendarViewWeek className="text-gray-400 text-lg" />}
          title="My Shift Roster"
          link="/app/staff/shift-roster"
          open={open}
        />
      ) : (
        ""
      )}
      {user?.role === "Staff" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<MdOutlineDocumentScanner className="text-gray-400 text-lg" />}
          title="My Shift Report"
          link="/app/staff/shift-report-form"
          open={open}
        />
      ) : (
        ""
      )}
      {user?.role === "Staff" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<MdOutlineFolderOpen className="text-gray-400 text-lg" />}
          title="My Documents"
          link="/app/staff/document"
          open={open}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default StaffLink;
