import React, { useEffect, useState } from "react";
import CustomInput from "../../../Components/Input/CustomInput";
import Spinner from "../../../Components/Spinner";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import { useNavigate, useParams } from "react-router-dom";
import { StaffAtendance } from "../../../store/slices/staffAttendaceSlice";
import dayjs from "dayjs";
import FallBack from "../../../Pages/FallBack";

const AttendanceReport = () => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { uid } = useParams();

  const [attenDetails, setAttenDetails] = useState<StaffAtendance>({
    attendanceId: 0,
    clockIn: "",
    duration: 0,
    clockOut: "",
    dateCreated: "",
    startKm: 0,
    endKm: 0,
    report: "",
    imageUrl: "",
    staff: {
      staffId: 0,
      maxStaffId: "",
      firstName: "",
      surName: "",
      middleName: "",
      fullName: "",
      dateCreated: "",
      userCreated: "",
      dateModified: "",
      userModified: "",
    },
  });
  const [loadingDetail, setLoadingDetail] = useState(true);
  const handleFetchDetails = async () => {
    try {
      const { data } = await axiosInstance.get(`/Attendances/${uid}`);
      setAttenDetails({ ...data });
      setLoadingDetail(false);
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        setLoadingDetail(false);
        // throw error;
      } else {
        toast.error("An error occurred");
        setLoadingDetail(false);
      }
    } finally {
      setLoadingDetail(false);
    }
  };
  useEffect(() => {
    handleFetchDetails();
  }, []);

  const [upLoadDoc, setUpLoad] = useState<File | null>(null);
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUpLoad(file);
    }
  };
  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setAttenDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const info = {
      ...attenDetails,
      ...(upLoadDoc ? { imageFile: upLoadDoc } : {}),
    };

    setLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/Attendances/edit/${uid}?userId=${user?.uid}`,
        info
      );
      toast.success(data.message);
      setLoading(false);
      navigate("/staff/attendance");
      // FetchData()
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("Error ");
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <SEO
        title={` Staff Attendance Report`}
        description="Staff"
        name="Promax-Care"
      />
      <Breadcrumb showNavigation={false} />

      <div className="flex justify-between items-center flex-wrap">
        <h2 className="font-bold text-2xl text-black w-auto">
          Attendance Report
        </h2>
      </div>

      {loadingDetail ? (
        <FallBack />
      ) : (
        <div className="my-5">
          <form
            action=""
            onSubmit={handleSubmit}
            className="space-y-1  items-center shadow-md rounded-md  bg-white"
          >
            <div className="p-4 grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 ">
              <CustomInput
                label="Clock In"
                type="text"
                value={dayjs(attenDetails.clockIn).format(
                  "MMMM D, YYYY h:mm A"
                )}
                readOnly
              />
              <CustomInput
                label="Clock Out"
                type="text"
                value={dayjs(attenDetails.clockOut).format(
                  "MMMM D, YYYY h:mm A"
                )}
                readOnly
              />
              <CustomInput
                label="Starting KiloMetre (Km)"
                type="text"
                name="startKm"
                value={attenDetails.startKm}
                onChange={handleInputChange}
              />
              <CustomInput
                label="Ending KiloMetre (Km)"
                type="text"
                name="endKm"
                value={attenDetails.endKm}
                onChange={handleInputChange}
              />

              <div className="lg:col-span-2">
                <CustomInput
                  label="Image"
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  placeholder="Image"
                />
              </div>

              <div className="lg:col-span-3">
                <label htmlFor="report" className="font-bold">
                  Additional Report{" "}
                  <span className="text-green-500" style={{ fontSize: "12px" }}>
                    This could be reasons why you were late or information you
                    which your admin to be aware of
                  </span>{" "}
                </label>
                <textarea
                  id="report"
                  rows={5}
                  autoComplete="off"
                  name="report"
                  value={attenDetails.report || ""}
                  onChange={handleInputChange}
                  className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                  defaultValue={""}
                />
              </div>

              <div className="flex flex-wrap lg:col-span-3">
                <button
                  type="submit"
                  disabled={loading}
                  className="text-white bg-primary h 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                >
                  {loading ? <Spinner /> : "Update"}
                </button>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AttendanceReport;
