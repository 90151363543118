import React from 'react'

const Active = () => {
  return (
    <div>
        <p className="mt-5 text-black"><span className="underline">Active files</span> – tick to indicate the document has been sighted and is current:</p>
        <div>
            <p className="flex gap-2 items-center my-3 text-black"><input type="checkbox" id="sign" /> <label htmlFor="sign">Signed service agreement</label></p>
            <p className="flex gap-2 items-center my-3 text-black"><input type="checkbox" id="consent" /> <label htmlFor="consent">Consent Form</label></p>
            <p className="flex gap-2 items-center my-3 text-black"><input type="checkbox" id="support" /> <label htmlFor="support">Support plan</label></p>
            <p className="flex gap-2 items-center my-3 text-black"><input type="checkbox" id="safety" /> <label htmlFor="safety">Safety Plan</label></p>
            <p className="flex gap-2 items-center my-3 text-black"><input type="checkbox" id="manage" /> <label htmlFor="manage">Medication Management Plan (if applicable)</label></p>
            <p className="flex gap-2 items-center my-3 text-black"><input type="checkbox" id="chart" /> <label htmlFor="chart">Medication Chart (if applicable)</label></p>
            <p className="flex gap-2 items-center my-3 text-black"><input type="checkbox" id="plan" /> <label htmlFor="plan">Other supporting plans – refer table below</label></p>
        </div>
    </div>
  )
}

export default Active