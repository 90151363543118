import React from 'react'
import { InputPicker, Modal } from 'rsuite'
import CustomInput from '../../../../../Components/Input/CustomInput'
import { Staff } from '../../../../../store/slices/staffSlice';
import { Client } from '../../../../../store/slices/clientSlice';
import Spinner from '../../../../../Components/Spinner';
// import CustomSelect from '../../../../../Components/Input/CustomSelect';
interface Props {

    filterModal: boolean,
    setFilterModal: React.Dispatch<React.SetStateAction<boolean>>;
    staff: Staff[];
    clients: Client[];
    dateFrom: string;
    dateTo: string;
    setDateFrom: React.Dispatch<React.SetStateAction<string>>
    setDateTo: React.Dispatch<React.SetStateAction<string>>
    users: number;
    client: number;
    setUsers: (staffId: number) => void;
    setClient: (clientId: number) => void;
    loadingNotice: boolean;
    SendRosterNotice: () => Promise<void>;
    resetFilter: () => void;
}
const ShiftRosterModal = ({ filterModal, setFilterModal, clients, staff,
    dateFrom, dateTo, setClient, setUsers, SendRosterNotice, loadingNotice, users, client,
    setDateFrom, setDateTo, resetFilter



}: Props) => {



    return (
        <>


            <Modal open={filterModal} onClose={() => setFilterModal(false)} autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Filter Shift Roster</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2'>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Staff</label>
                                <InputPicker size="lg" placeholder="--All Staff--"
                                    onChange={value => setUsers(value)}
                                    data={staff.map(user => ({ label: user.fullName, value: user.staffId }))}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                    value={users}
                                />
                            </div>
                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select Client</label>
                                <InputPicker size="lg" placeholder="--All Client--"
                                    onChange={value => setClient(value)}
                                    data={clients.map(user => ({ label: user.fullName, value: user.profileId }))}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                    value={client}
                                />
                            </div>

                            <CustomInput
                                label='Start Date'
                                type='date'
                                name="dateFrom"
                                value={dateFrom}
                                onChange={(e) => setDateFrom(e.target.value)}

                            />
                            <CustomInput
                                label='End Date'
                                type='date'
                                name="dateTo"
                                value={dateTo}
                                onChange={(e) => setDateTo(e.target.value)}
                            />

                            <div className='flex items-center justify-center mt-3'>

                                <div className='text-center'>
                                    <button type="button"
                                        onClick={resetFilter}
                                        className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">
                                        Reset Filter
                                    </button>
                                </div>

                                <div className='text-center'>
                                    <button type="button"
                                        onClick={() => setFilterModal(false)}
                                        className="text-white bg-primary hover:bg-primary/80
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 transition duration-300 transform active:scale-95 ease-in-out">
                                        Done
                                    </button>
                                </div>
                                <div className='text-center'>
                                    <button type="button"
                                        onClick={SendRosterNotice}
                                        className="text-black bg-gray-300 border  hover:bg-gray-500/80 focus:ring-4 
font-medium rounded-md text-md px-4 text-xs md:text-sm py-2.5 me-2 mt-2">
                                        {
                                            loadingNotice ? <Spinner /> : "  Send Roster Notification"

                                        }

                                    </button>
                                </div>
                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default ShiftRosterModal