import dayjs from "dayjs";
import React from "react";
import { ClientShift } from "../../../../store/slices/clientShiftSlice";

interface CardProps {


    activity: ClientShift;
    onShiftClick: () => void;
}

const ShiftCard = ({

    //   onEdit,
    activity,
    onShiftClick,

}: CardProps) => {



    return (
        <div

            className={`border-2 cursor-default border-gray-200  ${activity.status === "Pending" ? "bg-secondary " : activity.status === "Cancelled" ? "bg-[#f62d51] text-white" : "bg-white text-black"} rounded-lg px-1 py-2 w-full flex flex-col relative `}>

            <div
                onClick={onShiftClick}
            >
                <small
                    className={`absolute top-2 w-2 h-2 right-2 items-center text-sm hover:cursor-pointer border  p-1  rounded-full             
               
                `
                    }
                >



                </small>
                <span className="text-xs font-bold">
                    {dayjs(activity.dateFrom).format("h:mm A")} - {dayjs(activity.dateTo).format("h:mm A")}
                </span>
                <div className="truncate ... ">
                    <small className="font-bold">Staff:</small>
                    &nbsp;
                    <small>{activity.staff?.fullName}</small>
                </div>
                <div className="truncate ... ">
                    <small className="font-bold">Client(s):</small>
                    &nbsp;
                    <small>{activity.clients}</small>
                </div>
                <div className="truncate ... ">
                    <small className="font-bold">Activity:</small>
                    &nbsp;
                    <small>{activity.activities}</small>
                </div>
            </div>



        </div >
    );
};

export default ShiftCard;
