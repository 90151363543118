import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface FormTemplate {
    company: string;
    companyId: number;
    content: string;
    dateCreated: string;
    dateModified: string;
    isCarePlan: boolean
    isEmploymentForm: boolean;
    isGeneral: boolean;
    isIncidentForm: boolean;
    templateId: string;
    templateName: string;
    templateType: string;
    templateUrl: string;
    templateUrlFile: string;
    userCreated: string;
    userModified: string;
}

export interface FormTemplateState {
    loading: boolean;
    data: Array<FormTemplate>;
    error: string | undefined;
}
const initialState: FormTemplateState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchFormTemplate = createAsyncThunk(
    "FormTemplate/fetchFormTemplate",
    async (companyId: number) => {
        try {
            const response = await api.fetchFormTemplate(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const formTemplateSlice = createSlice({
    name: 'formTemplate',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchFormTemplate.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchFormTemplate.fulfilled, (state, action: PayloadAction<Array<FormTemplate>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchFormTemplate.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.formTemplate;
export default formTemplateSlice.reducer;


export const formTemplateData = (state: RootState) => state.formTemplate.data;
export const formTemplateLoading = (state: RootState) => state.formTemplate.loading;
export const formTemplateError = (state: RootState) => state.formTemplate.error;