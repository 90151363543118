import React from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import { MdOutlineAccessAlarm } from "react-icons/md";
import { DaySlots, TimeSlot } from "../availableHours";
import Spinner from "../../../../Components/Spinner";

interface Props {
  handleAddTimeSlot: (day: string) => void;
  day: string;
  availableHours: DaySlots;
  handleChangeTime: (
    day: string,
    index: number,
    field: keyof TimeSlot,
    id: string,
    value: string
  ) => void;
  handleDeleteTimeSlot: (
    day: string,
    index: number,
    id: string
  ) => Promise<void>;
  overlapErrors: {
    [key: string]: boolean[];
  };
  index: number;
}

const DayAvailabilityForm = ({
  availableHours,
  day,
  handleAddTimeSlot,
  handleChangeTime,
  handleDeleteTimeSlot,
}: Props) => {
  return (
    <>
      <div className="py-4 w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-8 items-start">
        <div className="flex gap-4 items-center justify-between lg:justify-start">
          <span>
            <MdOutlineAccessAlarm className="text-xl" />
          </span>
          <h4 className="text-lg font-bold text-primary uppercase">{day}</h4>

          <button
            onClick={() => handleAddTimeSlot(day)}
            className=" tracking-tight text-gray-400 hover:text-gray-800 p-2 lg:w-[10%] text-lg border block lg:hidden"
          >
            <FaPlus />
          </button>
        </div>
        <div className="lg:col-span-2 flex items-start">
          <div className="flex justify-between flex-wrap items-center lg:w-[90%]">
            {availableHours[day] ? (
              <div className="flex flex-col gap-2 items-center">
                {availableHours[day].map((slot, index) => (
                  <div key={index} className="flex items-center gap-2">
                    {slot.loading && <Spinner />}
                    <div className="relative">
                      <input
                        type="time"
                        value={slot.startTime}
                        id="time"
                        className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        min="07:00"
                        max="18:00"
                        defaultValue="00:00"
                        onChange={(e) =>
                          handleChangeTime(
                            day,
                            index,
                            "startTime",
                            slot.id,
                            e.target.value
                          )
                        }
                      />
                    </div>
                    -
                    <div className="relative">
                      <input
                        type="time"
                        value={slot.endTime}
                        id="time"
                        className="bg-gray-50 border leading-none border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2.5 py-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        min="09:00"
                        max="18:00"
                        defaultValue="00:00"
                        onChange={(e) =>
                          handleChangeTime(
                            day,
                            index,
                            "endTime",
                            slot.id,
                            e.target.value
                          )
                        }
                      />
                    </div>
                    <button
                      title="Delete"
                      onClick={() => handleDeleteTimeSlot(day, index, slot.id)}
                      className="p-2 text-xs  text-red-500 "
                    >
                      <FaTimes />
                    </button>
                  </div>
                ))}
              </div>
            ) : (
              <span className="py-1 px-2 text-black bg-gray-300 rounded">
                Unavailable
              </span>
            )}
          </div>

          <button
            onClick={() => handleAddTimeSlot(day)}
            className=" tracking-tight  rounded text-gray-400 hover:text-gray-800 lg:w-[10%] text-lg  p-2 hidden lg:block"
          >
            <FaPlus />
          </button>
        </div>
      </div>
    </>
  );
};

export default DayAvailabilityForm;
