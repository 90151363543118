import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { AppDispatch, RootState, useAppDispatch } from "../../store/store";
import { useSelector } from "react-redux";
import {
  chartData,
  chartLoading,
  fetchChartData,
} from "../../store/slices/chartSlice";

ChartJS.register(ArcElement, Tooltip, Legend);

const PieChart = () => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  const dispatch: AppDispatch = useAppDispatch();
  const [value, setSelectedValue] = useState<string>("y");

  useEffect(() => {
    const companyId = user?.companyId;
    if (companyId) {
      dispatch(fetchChartData({ value, companyId }));
    }
  }, [dispatch, value]);

  // const chart = useSelector(chartData);
  const isLoading = useSelector(chartLoading);
  const result = useSelector(chartData);
  const staffNames = result?.map((item) => item.staffName);
  // const durations = result?.map((item) => item.duration);
  // console.log(durations)
  let durations: number[];
  if (result && result.length > 0) {
    durations = result.map((item) => item.duration);
  } else {
    durations = [0, 1];
  }

  const data = {
    labels: staffNames,
    datasets: [
      {
        label: "Staff Duration",
        data: durations,
        backgroundColor: [
          "#071952",
          "#405189",
          '#FCBA34',
        ],
        borderWidth: 2,
      },
    ],
  };

  const handlePeriodChange = (period: string) => {
    setSelectedValue(period);
  };

  return (
    <div className="bg-white p-2 rounded-lg h-96">
      <div className="flex columns-1 justify-between items-start">
        <span className="font-semibold text-sm">
          {value === "y"
            ? "Yearly"
            : value === "m"
              ? "Monthly"
              : value === "w"
                ? "Weekly"
                : ""}{" "}
          Staff Duration Chart
        </span>
        <select
          value={value}
          onChange={(e) => handlePeriodChange(e.target.value)}
          id="duration"
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded outline-none block px-2 py-1 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
        >
          <option value="y">Yearly</option>
          <option value="m">Monthly</option>
          <option value="w">Weekly</option>
        </select>
      </div>
      <div className="flex justify-center items-center mt-10">
        {result.length <= 0 && isLoading ? (
          <div className="shapes-7"></div>
        ) : (
          <div
            style={{ width: "250px", height: "250px" }}
            className="flex justify-content-center"
          >
            <Doughnut
              data={data}
              options={{ plugins: { legend: { display: false } } }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PieChart;
