import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface Role {
    companyId: number;
    dateCreated: string;
    dateModified: string;
    role: string;
    uRolesId: number;
    userCreated: string;
    userModified: string;
}

export interface RoleState {
    loading: boolean;
    data: Array<Role>;
    error: string | undefined;
}
const initialState: RoleState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchRole = createAsyncThunk(
    "Role/fetchRole",
    async (companyId: number) => {
        try {
            const response = await api.fetchRole(companyId);
            return response.roles;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const roleSlice = createSlice({
    name: 'role',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchRole.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchRole.fulfilled, (state, action: PayloadAction<Array<Role>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchRole.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.role;
export default roleSlice.reducer;


export const roleData = (state: RootState) => state.role.data;
export const roleLoading = (state: RootState) => state.role.loading;
export const roleError = (state: RootState) => state.role.error;