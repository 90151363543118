import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../../Components/menu/ExportDropdown';
import IconButton from '../../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../../services/reusableFunc';
import { Column } from '../../../../types/DataType';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { AppDispatch, RootState, useAppDispatch } from '../../../../store/store';
import { useSelector } from 'react-redux';
import { clientScheduleData, clientScheduleLoading, fetchClientSchedule } from '../../../../store/slices/clientScheduleSlice';
import dayjs from 'dayjs';
import { Button, InputPicker, Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import { MultiSelect } from 'react-multi-select-component';
import Spinner from '../../../../Components/Spinner';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../store/axiosInstance';
import { isAxiosError } from 'axios';

interface DataRow {
    activities: string;
    clientScheduleId: number;
    companyID: number;
    dateCreated: string;
    dateModified: string;
    days: string;
    fromTimeOfDay: string;
    profileId: number
    toTimeOfDay: string
    userCreated: string
    userModified: string;

}

const options = [
    { label: "Medication Supervision", value: "Medication Supervision" },
    { label: "Medication administering", value: "Medication administering" },
    { label: "Personal Support", value: "Personal Support" },
    { label: "Domestic Cleaning", value: "Domestic Cleaning" },
    { label: "Transport", value: "Transport" },
    { label: "Dog training", value: "Dog training" },
    { label: "Install phone", value: "Install phone" },
    { label: "Welfare check", value: "Welfare check" },
    { label: "Support Groceries shopping", value: "Support Groceries shopping" },
    { label: "Pick up", value: "Pick up" },
    { label: "Baby sitting", value: "Baby sitting" },
    { label: "Taking to solicitors appointment", value: "Taking to solicitors appointment" },
    { label: "Meal Preparation", value: "Meal Preparation" },
    { label: "Shopping", value: "Shopping" },
    { label: "Groceries Transport", value: "Groceries Transport" },
    { label: "Domestics Social Support", value: "Domestics Social Support" },
];

interface ClientScheduleProps {
    uid: string; // Define the type of uid as string
}

type DataOption = {
    label: string;
    value: string;
};

const ClientSchedule: React.FC<ClientScheduleProps> = ({ uid }) => {


    const convertTo12HourFormat = (time24h: string): string => {
        const [hoursStr, minutesStr] = time24h.split(':');
        let hours = parseInt(hoursStr, 10);
        const minutes = parseInt(minutesStr, 10);
        let suffix = 'AM';

        if (hours >= 12) {
            suffix = 'PM';
            hours = hours - 12;
        }

        if (hours === 0) {
            hours = 12;
        }

        return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${suffix}`;
    };

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Days',
            selector: row => row.days,
            sortable: true,
        },
        {
            name: 'From Time of Day',
            selector: row => convertTo12HourFormat(row.fromTimeOfDay),
            sortable: true,
        },
        {
            name: 'To Time of Day',
            selector: row => convertTo12HourFormat(row.toTimeOfDay),
            sortable: true
        },
        {
            name: 'Activities',
            selector: row => row.activities,
            sortable: true
        },
        {
            name: 'Date Created',
            selector: row => dayjs(row.dateCreated).format('DD/MM/YYYY HH:mm:ss'),
            sortable: true
        }


    ];

    const [days, setDays] = useState<string>("");
    const [addModal, setAddModal] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (uid) {
            dispatch(fetchClientSchedule(Number(uid)));
        }
    }, [dispatch, uid]);
    const clientSchedule = useSelector(clientScheduleData);
    const loadingData = useSelector(clientScheduleLoading);

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = clientSchedule.filter((item) =>
        item.activities.toLowerCase().includes(searchText.toLowerCase())
    );

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const dataOpt: DataOption[] = ['Monday', 'Tuesday', 'Wednessday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map(
        item => ({ label: item, value: item })
    );

    const dateFrom = useRef<HTMLInputElement>(null);
    const dateTo = useRef<HTMLInputElement>(null);
    const [editDays, setEditDays] = useState<string>("");
    const [saveId, setSaveId] = useState<number>(0)
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [newActivities, setNewactivities] = useState<DataOption[]>([])
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [scheduleDetails, setScheduleDetails] = useState<DataRow>(
        {
            activities: "",
            clientScheduleId: 0,
            companyID: 0,
            dateCreated: "",
            dateModified: "",
            days: "",
            fromTimeOfDay: "",
            profileId: 0,
            toTimeOfDay: "",
            userCreated: "",
            userModified: ""

        }
    )

    const handleActivitiesChange = (selected: DataOption[] | null) => {
        if (selected) {
            setNewactivities(selected);
        }
    };
    const [selectedSchedule, setSelectedSchedule] = useState<DataOption[]>([]);
    const handleSupportSelectionChange = (selectedOptions: DataOption[]) => {
        setSelectedSchedule(selectedOptions);
    };
    const selectedScheduleString = selectedSchedule.map(option => option.label).join(',');
    const selectedActivitiesString = newActivities.map(option => option.label).join(',');

    const handleSubmit = async () => {
        if (selectedScheduleString === "") {
            return toast.error("Incomplete Request")
        }
        const info = {
            profileId: uid,
            days: days,
            fromTimeOfDay: dateFrom.current?.value,
            toTimeOfDay: dateTo.current?.value,
            activities: selectedScheduleString,
            companyID: companyId
        }
        try {
            setLoading(true)
            const { data } = await axiosInstance.post(`/ClientSchedules/add_client_schedule?userId=${user?.uid}`, info);

            if (data.status === "Success") {
                toast.success(data.message);
                setAddModal(false);
                uid && dispatch(fetchClientSchedule(Number(uid)));

            } else {
                toast.error(data.message);
            }
            setLoading(false)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setScheduleDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const EditDetail = async (id: number) => {
        setSaveId(id);
        setShowModal(true);
        try {
            const { data } = await axiosInstance.get(`/ClientSchedules/get_schedule/${id}`);
            setScheduleDetails({ ...data });
            setEditDays(data.days || "")
            setNewactivities(
                data?.activities
                    .split(/,\s*/)
                    .map((activity: string) => ({ label: activity, value: activity }))
            );
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
        }
    }

    const handleEdit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        const info = {
            ...scheduleDetails,
            // clientScheduleId: saveId,
            // profileId: uid,
            days: editDays,
            // fromTimeOfDay: editAvail.fromTimeOfDay,
            // toTimeOfDay: editAvail.toTimeOfDay,
            activities: selectedActivitiesString,
            companyId: companyId

        }
        try {
            const { data } = await axiosInstance.post(`/ClientSchedules/edit/${saveId}?userId=${user?.uid}`, info);
            toast.success(data.message)
            uid && dispatch(fetchClientSchedule(Number(uid)));
            setShowModal(false)
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }

    }

    const deleteSchedule = (id: number) => {
        setSaveId(id);
        setDeleteModal(true);
    }

    const handleDeleteSchedule = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data } = await axiosInstance.post(`/ClientSchedules/delete/${saveId}`)
            toast.success(data.message);
            setDeleteModal(false);
            uid && dispatch(fetchClientSchedule(Number(uid)));
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
            setDeleteModal(false);
        }
        finally {
            setLoading(false);
        }
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Activities: </span>
                    <span> {data.activities}</span>
                </span>
                <span>
                    <span className='font-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>
                <span>
                    <span className='font-bold'>Date Modified: </span>
                    <span>
                        {dayjs(data.dateModified).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>
                <div className='flex gap-2'>
                    <span className='font-bold'>Actions: </span>
                    <button className="btn text-primary font-bold" style={{ fontSize: "12px" }}
                        onClick={() => EditDetail(data.clientScheduleId)}
                    >
                        Edit
                    </button>
                    |
                    <button className="btn text-red-500 font-bold" style={{ fontSize: "12px" }}
                        onClick={() => deleteSchedule(data.clientScheduleId)}
                    >

                        Delete
                    </button>
                </div>

            </div>
        )
    }





    return (
        <div className='mt-10'>
            {clientSchedule.length <= 0 && loadingData && <Spinner color='#071952' />}
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                    <h2 className='font-bold text-xl text-black w-auto'>Schedule</h2>

                    <div className="relative w-full lg:col-span-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BsSearch />
                        </div>
                        <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
                    </div>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                    <div className="flex items-center space-x-3 w-full md:w-auto">

                        {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                            <FaFilter />
                            Filter
                        </button> */}
                        <ExportDropdown >
                            <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <IconButton
                                    icon={<FaFilePdf className='text-red-500' />}
                                    title="Export as PDF"
                                    onClick={() => handlePDFDownload(clientSchedule, columns as unknown as Column<DataRow>[], "ClientSchedule.pdf")}

                                />
                                <IconButton
                                    icon={<FaFileExcel className='text-green-600' />}
                                    title="Export as Excel Sheet"

                                    onClick={() => exportToExcel(clientSchedule, columns as unknown as Column<DataRow>[], "ClientSchedule")}

                                />
                                <IconButton
                                    icon={<FaFileCsv className='text-green-500' />}
                                    title="Export as CSV"
                                    onClick={() => downloadCSV({ data: clientSchedule, filename: 'ClientSchedule.csv' })}

                                />
                            </div>
                        </ExportDropdown>


                    </div>
                    <button type="button"
                        onClick={() => setAddModal(true)}
                        className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
    transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
   ">

                        Add New Schedule
                    </button>

                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                expandableRows
                expandableRowsComponent={ExpandableRowComponent}
                pagination
                paginationTotalRows={clientSchedule?.length}
            // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

            />

            <Modal open={addModal} onClose={() => setAddModal(false)} backdrop="static" autoFocus size={"lg"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Add Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <form action="" onSubmit={handleSubmit}>

                            <div className='space-y-1 p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8'>
                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">Days</label>
                                    <InputPicker size="lg" placeholder="Select days"
                                        onChange={(e: string) => setDays(e)}
                                        value={days}
                                        data={dataOpt}
                                        className='w-full focus:outline-none focus:border-primary text-gray-600'
                                        appearance='subtle'
                                    />
                                </div>

                                <div>
                                    <label htmlFor="support" className="block mb-2 text-xs  text-gray-600 font-semibold dark:text-white">Activities </label>
                                    <MultiSelect
                                        className='rounded-none h-11 multi-select'
                                        options={options}
                                        value={selectedSchedule}
                                        onChange={handleSupportSelectionChange}
                                        labelledBy={"Select multiple"}
                                        isCreatable={true}
                                    />
                                </div>

                                <CustomInput
                                    label='From Time of Day'
                                    type='time'
                                    ref={dateFrom}
                                    required
                                    placeholder="From Time of Day"
                                />


                                <CustomInput
                                    label='To Time of Day'
                                    type='time'
                                    ref={dateTo}
                                    placeholder="To Time of Day"
                                    required
                                />
                            </div>

                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Add"
                                    }</button>

                            </div>
                        </form>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal
                open={showModal} onClose={() => setShowModal(false)}
                backdrop="static" autoFocus size={"lg"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Edit Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <form action="" onSubmit={handleEdit}>

                            <div className='space-y-1 p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8'>
                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">Days</label>
                                    <InputPicker size="lg" placeholder="Select days"
                                        value={editDays}
                                        onChange={(value) => setEditDays(value)}
                                        data={dataOpt}
                                        className='w-full focus:outline-none focus:border-primary text-gray-600'
                                        appearance='subtle'
                                    />
                                </div>

                                <div>
                                    <label htmlFor="support" className="block mb-2 text-xs  text-gray-600 font-semibold dark:text-white">Activities </label>
                                    <MultiSelect
                                        className='rounded-none h-11 multi-select'
                                        options={options}
                                        value={newActivities}
                                        onChange={handleActivitiesChange}
                                        labelledBy={"Select multiple"}
                                        isCreatable={true}
                                    />
                                </div>

                                <CustomInput
                                    label='From Time of Day'
                                    type='time'
                                    value={scheduleDetails.fromTimeOfDay || ''}
                                    onChange={handleInputChange}
                                    name='fromTimeOfDay'
                                    required
                                    placeholder="From Time of Day"
                                />

                                <CustomInput
                                    label='To Time of Day'
                                    type='time'
                                    value={scheduleDetails.toTimeOfDay || ''}
                                    onChange={handleInputChange}
                                    name='toTimeOfDay'
                                    placeholder="To Time of Day"
                                    required
                                />
                            </div>

                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Update"
                                    }</button>

                            </div>
                        </form>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal open={deleteModal} onClose={() => setDeleteModal(false)} role="alertdialog" backdrop="static" autoFocus size={"xs"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Delete Schedule</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className="mb-4 font-bold">Are you sure you want to delete this Schedule?</p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button onClick={() => setDeleteModal(false)} className='bg-gray-500 text-white'>
                                No, cancel
                            </Button>
                            <Button
                                onClick={handleDeleteSchedule}
                                className='bg-red-500 text-white hover:bg-red-700 hover:text-white'>
                                {
                                    loading ? <Spinner /> : "Yes, I'm sure"
                                }
                            </Button>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ClientSchedule