import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { isAxiosError } from "axios";
import api from "../../api";
import { RootState } from "../../store";
import { Staff } from "../staffSlice";

export interface StaffDailyReport {
    staffAttendanceId: number,
    report: string,
    clockIn: string,
    clockInCheck: boolean,
    clockOutCheck: boolean,
    clockOut: string,
    duration: number,
    inLongitude: number,
    inLatitude: number,
    startKm: number,
    endKm: number,
    staffId: number,
    staff: Staff,
    imageURL: string,
    imageFIle: string,
    companyId: number,
    dateCreated: string,
    userCreated: string,
    dateModified: string,
    userModified: string



}

export interface StaffDailyReportState {
    loading: boolean;
    data: Array<StaffDailyReport>;
    error: string | undefined;
}
const initialState: StaffDailyReportState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchStaffDailyReport = createAsyncThunk(
    "StaffDailyReport/fetchStaffDailyReport",
    async (uid: number) => {
        try {
            const response = await api.fetchStaffDailyReport(uid);
            return response
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const staffDailyReportSlice = createSlice({
    name: 'staffDailyReport',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStaffDailyReport.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchStaffDailyReport.fulfilled, (state, action: PayloadAction<Array<StaffDailyReport>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchStaffDailyReport.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.staffDailyReport;
export default staffDailyReportSlice.reducer;


export const staffDailyReportData = (state: RootState) => state.staffDailyReport.data;
export const staffDailyReportLoading = (state: RootState) => state.staffDailyReport.loading;
export const staffDailyReportError = (state: RootState) => state.staffDailyReport.error;