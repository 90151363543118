import React from 'react'
import { Link } from 'react-router-dom';

type Props = {
    url: string;
    title: string;
    handleClose: () => void;
}

const DropdownUserLink: React.FC<Props> = ({ url, title, handleClose }) => {
    return (
        <Link
            to={url}
            className='text-sm font-normal hover:bg-gray-100 p-2 text-gray-500 duration-300 no-underline 
            outline-none border-none ease-in-out hover:no-underline
             hover:text-primary '
            onClick={handleClose}
        >
            {title}
        </Link>
    )
}

export default DropdownUserLink