import React, { useRef } from "react";
import Hero from "./___component/Hero";
import Section from "./___component/Section";
import Section2 from "./___component/Section2";
import Section3 from "./___component/Section3";
import SignatureForm from "./___component/SignatureForm";
import Breadcrumb from "../../Components/label/BreadCrumb";
import PrintComponent from "../PrintTab";

const ConsentForm = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Breadcrumb />
      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print this Document"
        documentTitle="Consent Form"
      />
      <div ref={componentRef} className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white">
        <Hero />
        <Section />
        <Section2 />
        <Section3 />
        <SignatureForm />
        <div className="my-5">
          <p className="font-semibold">Other ways of seeking your consent</p>
          <p className="my-3">Please note that there are other ways we might seek your consent e.g. verbally via email, or text. Examples include:</p>
          <ul>
            <li>•	Seeking consent verbally before commencing personal care routines</li>
            <li>•	Seeking consent over email or text to share your information with another provider that is not listed on the above form.</li>
          </ul>
          <p className="my-3">In the instance we obtain your consent verbally via email or text we will record your consent in your progress notes.</p>
        </div>
      </div>
    </div>
  );
};

export default ConsentForm;
