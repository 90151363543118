import React, { useCallback } from 'react'
import Breadcrumb from '../../label/BreadCrumb'
import SEO from '../../../constants/SEO'
import fileImg from '../../../images/icon/EmptyState.png';

const UploadClientViaXero = () => {
    const [file, setFile] = React.useState<File | null>(null);

    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Handler for file input changes
        if (event.target.files && event.target.files.length > 0) {
            setFile(event.target.files[0]);
        }
    };

    const onDrop = useCallback((event: React.DragEvent<HTMLDivElement>) => {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
        event.stopPropagation();

        if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
            setFile(event.dataTransfer.files[0]);
            event.dataTransfer.clearData();
        }
    }, []);

    const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
        // Prevent default behavior (Prevent file from being opened)
        event.preventDefault();
    };

    return (
        <>
            <SEO
                title='Upload Clients'
                description='Client'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-5 space-y-3'>
                <h2 className='font-bold text-xl text-black w-auto'>Import Via Xero</h2>
                <p className='text-sm text-black'>
                    To import client details via Xero, please ensure your Xero file is formatted correctly according to our specifications.
                    Include essential details such as First Name, Last Name, Email, Phone Number, and Role. Begin the process by dragging and dropping your Xero file into the upload area or use the browse option to locate and select your file.
                    Make sure all required fields are accurately filled out to facilitate a smooth import process.

                </p>
                <button className='underline text-blue-700 font-bold '>
                    Download Xero Formatting Guide
                </button>
                <div className=' border-2 border-purple-700 border-dashed flex justify-center flex-col items-center rounded py-10 space-y-2'


                    onDrop={onDrop}
                    onDragOver={onDragOver}
                >

                    <img src={fileImg} alt="file upload icon"
                        width={200}
                        height={300}
                    />


                    <strong>Drag and Drop to Upload</strong>
                    <strong>or</strong>
                    <input
                        type="file"
                        onChange={onFileChange}
                        style={{ display: 'none' }}
                        id="fileInput"
                        accept=".xlsx, .xls"
                    />
                    <label htmlFor="fileInput" className='bg-gray-300 text-black px-12 rounded shadow-sm py-2 cursor-pointer'>
                        Browse
                    </label>



                    {file &&
                        <div className='bg-white flex justify-center items-center p-2 rounded relative'>
                            <button
                                onClick={() => setFile(null)}
                                className='absolute w-5 h-5  rounded-full bg-red-500 text-white -top-3 -right-2'>&times;</button>
                            <p>File ready to be uploaded: {file.name}</p>

                        </div>
                    }

                </div>
                <div className='mx-auto text-center mt-3 lg:col-span-2'>
                    <button type="submit"
                        // disabled={loading1 ? true : false}
                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary">

                        {/* {
                                    loading1 ? <Spinner /> : "Submit"
                                } */}
                        Submit

                    </button>

                </div>

            </div>
        </>
    )
}

export default UploadClientViaXero