import dayjs from "dayjs";
import React from "react";
import { Tooltip, Whisper } from "rsuite";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
interface Props {
  timeFrom: string;
  timeTo: string;
  staff: string;
  client: string;
  // task: string;
  onSelect: () => void; // Function to call when Delete is clicked
  getActivityStatus(activity: Shift): string;
  activity: Shift;
}
const ShiftLabel = ({
  client,
  staff,
  onSelect,
  timeFrom,
  timeTo,
  getActivityStatus,
  activity,
}: Props) => {
  return (
    <Whisper
      placement="auto"
      controlId="control-id-hover"
      trigger="hover"
      speaker={
        <Tooltip>
          <div className="flex flex-col  text-white">
            <span className="text-xs font-bold">
              {dayjs(timeFrom).format("h:mm A")} -{" "}
              {dayjs(timeTo).format("h:mm A")}
            </span>
            <span className="truncate ... text-white text-xs  cursor-pointer">
              <small className="font-bold">Staff:</small>
              &nbsp;
              <small className="!capitalize">{staff}</small>
            </span>
            <span className="truncate ... text-white text-xs  cursor-pointer">
              <small className="font-bold">Clients:</small>
              &nbsp;
              <small className="!capitalize">{client}</small>
            </span>
          </div>
        </Tooltip>
      }
    >
      <span
        className={`truncate ... bg-white text-xs p-1  border-l-4 flex flex-col
        ${getActivityStatus(activity) === "Present"
            ? "border-green-500"
            : getActivityStatus(activity) === "Absent"
              ? "border-red-500"
              : "border-secondary"
          }
        
        `}
        onClick={onSelect}
      >
        <span className="truncate ... font-bold text-xs  cursor-pointer">
          <small className="font-bold">Staff:</small>
          &nbsp;
          <small className="!capitalize">{staff}</small>
        </span>
        <span className="truncate ...  text-xs  cursor-pointer">
          <small className="font-bold">Clients:</small>
          &nbsp;
          <small className="!capitalize">{client}</small>
        </span>
      </span>
    </Whisper>
  );
};

export default ShiftLabel;
