import React, { useEffect, useRef, useState } from "react";
import FormHero from "./ReportForm/FormHero";
import { Shift } from "../../../../store/slices/shiftRosterSlice";
import EditFormPartA from "./ReportForm/EditFormPartA";
import { ShiftReport } from "../../../../store/slices/StaffSlices/staffReportSlice";
import { fetchReportInfo } from "../../../../services/fetchServices";
import EditFormPartB from "./ReportForm/EditFormPartB";
import axiosInstance from "../../../../store/axiosInstance";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import Spinner from "../../../../Components/Spinner";
import { useNavigate } from "react-router-dom";
import PrintComponent from "../../../../participantDocument/PrintTab";
interface Props {
    shiftDetail: Shift | null;
    reportId: string | null;
    uid: string | undefined;
}

const EditShiftReportForm = ({ shiftDetail, reportId, uid }: Props) => {
    const [shiftReportDetail, setShiftReportDetail] = useState<ShiftReport>({
        urgentMatters: "",
        medicationGiven: "",
        medicationSigned: "",
        medicationAvailable: "",
        medicatioErrors: "",
        isMealManagementPlan: true,
        details_IfNotMealMaganementPlan: "",
        isDrinkingProblem: true,
        details_IfProblemExist: "",
        isHealthIssues: true,
        details_IfHealthIssuesExist: "",
        goal_Progress: "",
        contactFamily: "",
        isIncident: true,
        details_IfIsIncident: "",
        isBehaviourConcerned: true,
        details_ifIsBehaviourConcerned: "",
        shiftRosterId: 0,
        companyID: 0,
        dateCreated: "",
        dateModified: "",
        shiftReportId: 0,
        userCreated: "",
        userModified: "",
    });

    const handleFetchReportInfo = async () => {
        const data = await fetchReportInfo(Number(reportId), Number(uid));
        setShiftReportDetail({ ...data });
    };

    useEffect(() => {
        handleFetchReportInfo();
    }, [uid]);

    // const handleInputChange = (
    //     e: React.ChangeEvent<
    //         HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    //     >
    // ) => {
    //     const { name, value } = e.target;
    //     setShiftReportDetail((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    // };


    const handleInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>
    ) => {
        const target = e.target as HTMLInputElement; // Treat all inputs as HTMLInputElement for simplicity
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setShiftReportDetail((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const SubmitForm = async (e: React.FormEvent) => {
        e.preventDefault();

        setLoading(true);
        const info = {
            ...shiftReportDetail,
            shiftRosterId: uid,
            companyID: user?.companyId,
        };

        try {
            const { data } = await axiosInstance.post(
                `/ShiftReports/edit/${shiftReportDetail.shiftReportId}?userId=${user?.uid}`,
                info
            );
            toast.success(data.message);
            setLoading(false);
            navigate(`/app/staff/shift-report-form`);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };



    const componentRef = useRef<HTMLDivElement>(null);
    return (
        <>
            <PrintComponent
                componentRef={componentRef}
                triggerLabel="Print this Document"
                documentTitle="Shift Report"
            />
            <form action="" onSubmit={SubmitForm}>
                <div className="mx-auto  max-w-2xl py-4" ref={componentRef}>
                    <FormHero shiftDetail={shiftDetail} />
                    <EditFormPartA
                        handleInputChange={handleInputChange}
                        shiftReportDetail={shiftReportDetail}
                    />
                    <EditFormPartB
                        handleInputChange={handleInputChange}
                        shiftReportDetail={shiftReportDetail}
                        shiftDetail={shiftDetail}
                    />
                </div>
                <div className="mx-auto text-center mt-3 lg:col-span-2">
                    <button
                        type="submit"
                        disabled={loading}
                        className="text-white bg-primary hover:bg-primary focus:ring-4 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"
                    >
                        {
                            loading ?
                                <Spinner /> : " Update"
                        }

                    </button>
                </div>
            </form>
        </>
    );
};

export default EditShiftReportForm;
