import React from "react";

const PlanForm = () => {
  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
          <tr className="divide-x-2">
            <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
              Name of plan
            </th>
            <th className=" px-4 divide-x-2 py-2 font-semibold text-gray-900">
              Tick to indicate plan has been sighted, signed and is current
            </th>
          </tr>
        </thead>

        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="flex gap-2 items-center px-4 py-2 text-gray-700 w-[100%] outline-none">
              <span>1.</span>
              <input className="w-[100%] outline-none" type="text" />
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <input type="checkbox" />
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="flex gap-2 items-center px-4 py-2 text-gray-700 w-[100%] outline-none">
              <span>2.</span>
              <input className="w-[100%] outline-none" type="text" />
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <input type="checkbox" />
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="flex gap-2 items-center px-4 py-2 text-gray-700 w-[100%] outline-none">
              <span>3.</span>
              <input className="w-[100%] outline-none" type="text" />
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <input type="checkbox" />
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="flex gap-2 items-center px-4 py-2 text-gray-700 w-[100%] outline-none">
              <span>4.</span>
              <input className="w-[100%] outline-none" type="text" />
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <input type="checkbox" />
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="flex gap-2 items-center px-4 py-2 text-gray-700 w-[100%] outline-none">
              <span>5.</span>
              <input className="w-[100%] outline-none" type="text" />
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <input type="checkbox" />
            </td>
          </tr>



        </tbody>
      </table>
    </div>
  );
};

export default PlanForm;
