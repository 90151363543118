
import React, { ReactNode } from 'react';
import Dropdown from '../dropdown';
import { BsThreeDotsVertical } from "react-icons/bs";

function DotDropDown(props: { transparent?: boolean; children: ReactNode; }) {
    const { transparent, children } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <Dropdown
            button={
                <button
                    onClick={() => setOpen(!open)}
                    className="absolute top-0 right-0 items-center text-black text-sm hover:cursor-pointer border  p-1 bg-white rounded-md transition duration-300 transform active:scale-95 ease-in-out ">

                    <BsThreeDotsVertical />
                </button>

            }
            animation={'origin-top-right transition-all duration-300 ease-in-out'}
            classNames={`${transparent ? 'top-8' : 'top-5'} right-0 w-max`}
        >
            <div className="z-[999] w-max rounded bg-white  px-2 py-1 text-xs shadow-xl flex flex-col gap-1 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">

                {children}
            </div>
        </Dropdown>
    );
}

export default DotDropDown;
