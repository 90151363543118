import React from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import DisabilitySupport from "../../Admin/Profiles/clientTab/DisabilitySupport";

const DisableNeeds = () => {


    return (
        <>
            <SEO title={` Disability Needs`} description="Client" name="Promax-Care" />
            <Breadcrumb showNavigation={false} />

            <div>
                <DisabilitySupport />
            </div>

        </>
    );
};

export default DisableNeeds;
