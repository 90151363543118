import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface AllStaffAttendance {
    clockIn: string;
    clockInCheck: boolean;
    clockOut: string;
    clockOutCheck: boolean;
    companyId: number;
    dateCreated: string;
    dateModified: string;
    duration: number;
    endKm: number;
    imageFIle: string;
    imageURL: string;
    inLatitude: number;
    inLongitude: number;
    report: string;
    staffAttendanceId: number;
    staffId: number;
    startKm: number;
    userCreated: string;
    userModified: string;
    staff: {
        fullName: string;
    }
}

export interface AllStaffAttendanceState {
    loading: boolean;
    data: Array<AllStaffAttendance>;
    error: string | undefined;
}
const initialState: AllStaffAttendanceState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchAllStaffAttendance = createAsyncThunk(
    "AllStaffAttendance/fetchAllStaffAttendance",
    async (companyId: number) => {
        try {
            const response = await api.fetchAllStaffAttendances(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const allStaffAttendanceSlice = createSlice({
    name: 'allStaffAttendance',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchAllStaffAttendance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchAllStaffAttendance.fulfilled, (state, action: PayloadAction<Array<AllStaffAttendance>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchAllStaffAttendance.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.allStaffAttendance;
export default allStaffAttendanceSlice.reducer;


export const allStaffAttendanceData = (state: RootState) => state.allStaffAttendance.data;
export const allStaffAttendanceLoading = (state: RootState) => state.allStaffAttendance.loading;
export const allStaffAttendanceError = (state: RootState) => state.allStaffAttendance.error;