import React from 'react'

const Participant = () => {
    return (
        <div className="mx-auto my-10 text-black">
            <strong className='text-[18px]'>Participants who live alone</strong>
            <p className='my-3 '>These questions relate to the Additional Condition imposed under section 73G of the NDIS Act for providers of assistance with daily personal activities to participants who live alone. Do not complete if this doesn’t apply.</p>
            <p>Does the participant live alone and receive assistance with daily personal activities from us?</p>
            <div className='flex items-center gap-3 my-3'>
                <input id='yo' type="checkbox" />
                <label htmlFor="yo">Yes</label>
                <input id='yop' type="checkbox" />
                <label htmlFor="yop">No</label>
                <span>If yes, move to the next question below. If no, move to the next section.</span>
            </div>
            <div className='my-3'>
                <span>Is the participant receiving, from any other NDIS provider, supports or services that involve regular, face-to-face contact with the participant: </span>
                <input id='yo' type="checkbox" />
                <label htmlFor="yo">Yes</label>
                <input id='yop' type="checkbox" />
                <label htmlFor="yop">No</label>
            </div>

            <p className='my-3'>If no, do one of more other of the following risk factors apply (tick all those that apply)</p>

            <div>
                <div className='my-3'>
                    <input id='yo' type="checkbox" />
                    <label className='ml-3' htmlFor="yo">The participant or the participant’s plan indicates that the participant has limited or no regular, face-to-face contact with relatives, friends or other people with whom the participant is well-acquainted.</label>
                </div>
                <div className='my-3'>
                    <input id='yo' type="checkbox" />
                    <label className='ml-3' htmlFor="yo">Without the assistance of another person the participant has limited or no physical mobility.</label>
                </div>
                <div className='my-3'>
                    <input id='yo' type="checkbox" />
                    <label className='ml-3' htmlFor="yo">The participant uses equipment to enable them to be physically mobile or to facilitate   their physical mobility.</label>
                </div>
                <div className='my-3'>
                    <input id='yo' type="checkbox" />
                    <label className='ml-3' htmlFor="yo">Without the assistance of another person the participant has limited or no ability to communicate with others.</label>
                </div>
                <div className='my-3'>
                    <input id='yo' type="checkbox" />
                    <label className='ml-3' htmlFor="yo">The participant uses equipment to enable or facilitate communication with others, including to enable or facilitate the use of a phone or other device.</label>
                </div>
            </div>

            <div>
                If the response is yes to any of the above, risk management strategies must be included on the Safety Management Plan below.
            </div>
        </div>
    )
}

export default Participant