import React, { useState } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import axiosInstance from "../../../store/axiosInstance";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const ClientHealthSupport = () => {
    // const [loading, setLoading] = useState(false);
    const [currentHealthState, setCurrentHealthState] = useState("");
    const [additionalSupport, setAdditionalSupport] = useState("");
    const [medication, setMedication] = useState("");
    const [medicationDuration, setMedicationDuration] = useState("");
    const [details, setDetails] = useState("");
    const clientProfileString = sessionStorage.getItem('clientProfile')
    const clientProfile = JSON.parse(clientProfileString as string)
    const user = useSelector((state: RootState) => state?.auth?.user);

    const info = {
        profileId: clientProfile.profileId,
        healthIssues: currentHealthState,
        supportDetails: additionalSupport,
        requireMedication: medication,
        support: details,
        healthPlan: medicationDuration,
       }



    const addHealth = async (e: React.FormEvent) => {
        e.preventDefault();

        if (currentHealthState === "" || additionalSupport === "" || details === "") {
            return toast.error("Input Fields cannot be empty")
        }
        const response = await axiosInstance.post(`/HealthSupports/get_all?clientId=${user?.uid}`, info);
        console.log(response);
    }

    // Edit


    return (
        <>
        <ToastContainer />  
            <SEO title="Health Support Needs" description="Client" name="Promax-Care" />
            <div className="flex justify-between items-center flex-wrap">
                <Breadcrumb />
            </div>
            <div className="space-y-4 py-12 font-['Rubik']">
                <div className="border border-gray-300 w-[100%] rounded-md bg-white ">
                    <div className=" bg-[#f7f7f7] py-1 px-5 text-white rounded-md">
                        <h3 className="text-[#1f1f1f] text-[16px] font-semibold">
                            Health Support Needs
                        </h3>
                    </div>

                    <section className="py-3 px-5 max-[688px]:flex-col flex items-start justify-between flex-wrap">
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%]">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="desc"
                            >
                                Describe any ongoing health issues you have, including mental
                                health issues.
                            </label>
                            <textarea
                                id="desc"
                                onChange={(e) => setCurrentHealthState(e.target.value)}
                                value={currentHealthState}
                                className="border border-gray-200 outline-none p-1 mt-2 rounded-md"
                            ></textarea>
                        </div>
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%] max-[688px]:mt-6">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="add"
                            >
                                Is additional support for these issues? If so, please provide
                                detail
                            </label>
                            <textarea
                                id="add"
                                onChange={(e) => setAdditionalSupport(e.target.value)}
                                className="border border-gray-200 outline-none p-1 mt-2 rounded-md"
                            ></textarea>
                        </div>
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%] mt-6">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="iss"
                            >
                                Medication Required?
                            </label>
                            <select
                                id="iss"
                                className="border border-gray-200 outline-none p-2 mt-2 rounded-md"
                                onChange={(e) => setMedication(e.target.value)}
                            >
                                <option hidden disabled selected>
                                    Select...
                                </option>
                                <option value={"true"}>Yes</option>
                                <option value={"false"}>No</option>
                            </select>
                        </div>
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%] mt-6">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="hea"
                            >
                                How Often do you require medication?
                            </label>
                            <select
                                id="iss"
                                onChange={(e) => setMedicationDuration(e.target.value)}
                                className="border border-gray-200 outline-none p-2 mt-2 rounded-md"
                            >
                                <option hidden disabled selected>
                                    Please Select
                                </option>
                                <option value={"Prompt Required"}>Prompt Required</option>
                                <option value={"Assistance Required"}>
                                    Assistance Required
                                </option>
                                <option value={"Administration Required"}>
                                    Administration Required
                                </option>
                            </select>
                        </div>
                        <div className="flex-col flex w-[48%] max-[688px]:w-[100%] mt-6">
                            <label
                                className="font-normal text-[16px] text-[#1f1f1f]"
                                htmlFor="hea"
                            >
                                Provide details of your medication and treatment plan
                            </label>
                            <textarea
                                id="hea"
                                onChange={(e) => setDetails(e.target.value)}
                                className="border border-gray-200 outline-none p-1 mt-2 rounded-md"
                            ></textarea>
                        </div>
                    </section>
                    <button onClick={(e) => addHealth(e)} className="my-3 mx-5 py-2 px-6 rounded-md bg-[#405189] text-white font-semibold">
                        Save
                    </button>
                </div>
            </div>
        </>
    );
};

export default ClientHealthSupport;
