import React from 'react';
import { Routes, Route } from 'react-router-dom';
import FallBack from '../FallBack';
import RefferalPdf from './RefferalPdf';


const PDFLayout = () => {
    const StaffTimesheetPdf = React.lazy(() => import("./StaffTimeSheetPdf"));
    const TimesheetForAll = React.lazy(() => import("./AllTimeSheetPdf"));
    const UngroupedInvoicePDF = React.lazy(() => import("./UngroupedInvoicePdf"));
    const GroupedInvoicePDF = React.lazy(() => import("./GroupInvoicePdf"));
    const ShiftAttendancePdf = React.lazy(() => import("./ShiftAttendancePdf"));

    return (
        <div>
            {/* Auth layout components */}
            <React.Suspense fallback={<FallBack />}>
                <Routes>
                    <Route path={`/staff-timesheet/:staff/:dateFrom/:dateTo`} element={<StaffTimesheetPdf />} />
                    <Route path={`/all-staff-timesheet/:dateFrom/:dateTo`} element={<TimesheetForAll />} />
                    <Route path={`/loadUngroupedInvoice-pdf/gen_rate`} element={<UngroupedInvoicePDF />} />
                    <Route path={`/loadGroupedInvoice-pdf/gen_rate`} element={<GroupedInvoicePDF />} />
                    <Route path={`/alluser-shiftattendance/gen_rate`} element={<ShiftAttendancePdf />} />
                    <Route path={`/refferal-pdf/:uid`} element={<RefferalPdf />} />

                </Routes>
            </React.Suspense>
        </div>
    );
};

export default PDFLayout;
