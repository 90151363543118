import React from 'react'
import { MdOutlineDateRange, MdPerson } from 'react-icons/md'


interface Props {
    client: string | undefined;
    dateFrom: string | undefined;
    dateTo: string | undefined;
    dateModified: string | undefined;
    onEdit: () => void;
    onView: () => void;
}
const ReportCard = ({ client, dateModified, onEdit, dateFrom, dateTo }: Props) => {


    return (
        <div className=" bg-white rounded-md shadow p-2 border-s-4 border-brand overflow-clip">

            <table >

                <tbody >


                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            <MdPerson className='text-primary' />
                            Client:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{client}</td>

                    </tr>

                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            <MdOutlineDateRange className='text-gray-400' />
                            Clock In:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{dateFrom}
                        </td>

                    </tr>
                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            <MdOutlineDateRange className='text-gray-400' />
                            Clock Out:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{dateTo}
                        </td>

                    </tr>
                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            <MdOutlineDateRange className='text-gray-400' />
                            Date Modified:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{dateModified}
                        </td>

                    </tr>
                </tbody>
            </table>
            <div className='flex  gap-3 items-center py-2 pl-2'>
                {/* <button className='bg-gray-300 px-6 py-2 rounded-md text-primary border border-primary text-xs font-normal' onClick={onView} >View Form</button> */}
                <button className='bg-primary px-6 py-2 rounded-md text-white text-xs fornt-normal' onClick={onEdit}>View Form</button>
            </div>

        </div>
    )
}

export default ReportCard