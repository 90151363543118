import React from "react";

const SkillForm = () => {
  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border  border-gray-300 ">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Step 1
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <p>
                My learning goal: <input type="text" className="border border-gray-300 outline-none" />
              </p>
              <p>
                Support or equipment I need: <input type="text" className="border border-gray-300 outline-none" />
              </p>
              <p>
                When I want to achieve this step: <input type="text" className="border border-gray-300 outline-none" />
              </p>
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <p>
                <input type="checkbox" /> I am still working on it
              </p>
              <p className="mt-4">
                <input type="checkbox" /> I can do this!  
              </p>

            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
              <p>Date: <input type="text" className="border border-gray-300 outline-none" /></p>
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Step 2
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <p>
                My learning goal: <input type="text" className="border border-gray-300 outline-none" />
              </p>
              <p>
                Support or equipment I need: <input type="text" className="border border-gray-300 outline-none" />
              </p>
              <p>
                When I want to achieve this step: <input type="text" className="border border-gray-300 outline-none" />
              </p>
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <p>
                <input type="checkbox" /> I am still working on it
              </p>
              <p className="mt-4">
                <input type="checkbox" /> I can do this!  
              </p>

            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
              <p>Date: <input type="text" className="border border-gray-300 outline-none" /></p>
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Step 3
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <p>
                My learning goal: <input type="text" className="border border-gray-300 outline-none" />
              </p>
              <p>
                Support or equipment I need: <input type="text" className="border border-gray-300 outline-none" />
              </p>
              <p>
                When I want to achieve this step: <input type="text" className="border border-gray-300 outline-none" />
              </p>
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <p>
                <input type="checkbox" /> I am still working on it
              </p>
              <p className="mt-4">
                <input type="checkbox" /> I can do this!  
              </p>

            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
              <p>Date: <input type="text" className="border border-gray-300 outline-none" /></p>
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Step 4
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <p>
                My learning goal: <input type="text" className="border border-gray-300 outline-none" />
              </p>
              <p>
                Support or equipment I need: <input type="text" className="border border-gray-300 outline-none" />
              </p>
              <p>
                When I want to achieve this step: <input type="text" className="border border-gray-300 outline-none" />
              </p>
            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <p>
                <input type="checkbox" /> I am still working on it
              </p>
              <p className="mt-4">
                <input type="checkbox" /> I can do this!  
              </p>

            </td>
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              <textarea className="w-[100%] h-28 border border-gray-300 outline-none"></textarea>
              <p>Date: <input type="text" className="border border-gray-300 outline-none" /></p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SkillForm;
