import React from "react";
import { Navigate, Route, Routes } from "react-router";
import Message from "./Message";
import Ticket from "./Ticket";
import TicketTemplate from "./Ticket/TicketTemplate";
import ViewTicket from "./Ticket/ViewTicket";
import TicketDetails from "./Ticket/ViewTicket/TicketDetails";

const CommunicationLayout = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/app/communication/ticket" replace />} />
        <Route path="/message" element={<Message />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/ticket-template" element={<TicketTemplate />} />
        <Route path="/view-ticket" element={<ViewTicket />} />
        <Route path="/view-ticket/:uid" element={<TicketDetails />} />
      </Routes>
    </div>
  );
};

export default CommunicationLayout;
