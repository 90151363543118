import React, { FormEvent, useEffect, useState } from 'react'
import SEO from '../../../constants/SEO'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import { toast } from 'react-toastify'
import axiosInstance from '../../../store/axiosInstance'
import { isAxiosError } from 'axios'
import Spinner from '../../../Components/Spinner'
import { useNavigate, useParams } from 'react-router-dom'

interface ClaimType {
    claimType: string;
    isSelected: boolean;
}
const Claims = () => {

    // const [claimsT, setClaimsT] = useState<ClaimType[]>([
    //     { claimType: 'Super Admin Dashboard', isSelected: false },
    //     { claimType: 'Admin Dashboard', isSelected: false },
    //     { claimType: 'Staff Dashboard', isSelected: false },
    //     { claimType: 'Account Users', isSelected: false },
    //     { claimType: 'View Activity Logs', isSelected: false },
    //     { claimType: 'Delete Account Users', isSelected: false },
    //     { claimType: 'Reset User Password', isSelected: false },
    //     { claimType: 'Edit User Email', isSelected: false },
    //     { claimType: 'Add & Edit Role', isSelected: false },
    //     { claimType: 'Add Administrator', isSelected: false },
    //     { claimType: 'Edit Administrator', isSelected: false },
    //     { claimType: 'View Administrator', isSelected: false },
    //     { claimType: 'Delete Administrator', isSelected: false },
    //     { claimType: 'Activate & Deactivate Administrator', isSelected: false },
    //     { claimType: 'Add Staff', isSelected: false },
    //     { claimType: 'Edit Staff', isSelected: false },
    //     { claimType: 'View Staff', isSelected: false },
    //     { claimType: 'Delete Staff', isSelected: false },
    //     { claimType: 'Activate & Deactivate Staff', isSelected: false },
    //     { claimType: 'Add Client', isSelected: false },
    //     { claimType: 'Edit Client', isSelected: false },
    //     { claimType: 'View Client', isSelected: false },
    //     { claimType: 'Delete Client', isSelected: false },
    //     { claimType: 'Activate & Deactivate Client', isSelected: false },
    //     { claimType: 'Add & Edit Department', isSelected: false },
    //     { claimType: 'Add & Edit Designation', isSelected: false },
    //     { claimType: 'Add & Delete Public Holiday', isSelected: false },
    //     { claimType: 'Add, Edit & Delete Support of Schedule', isSelected: false },
    //     { claimType: 'Add Shift Roster', isSelected: false },
    //     { claimType: 'Edit Shift Roster', isSelected: false },
    //     { claimType: 'View Shift Roster', isSelected: false },
    //     { claimType: 'View Shift Roster Calendar', isSelected: false },
    //     { claimType: 'Delete Shift Roster', isSelected: false },
    //     { claimType: 'Mark Attendances', isSelected: false },
    //     { claimType: 'Edit Attendances', isSelected: false },
    //     { claimType: 'View Attendances', isSelected: false },
    //     { claimType: 'Delete Attendances', isSelected: false },
    //     { claimType: 'Generate Timesheet', isSelected: false },
    //     { claimType: 'View Shift Attendance', isSelected: false },
    //     { claimType: 'Adjust Attendances', isSelected: false },
    //     { claimType: 'View Progress Report', isSelected: false },
    //     { claimType: 'Generate Progress Report', isSelected: false },
    //     { claimType: 'Load Invoices', isSelected: false },
    //     { claimType: 'Accept & Reject Documents', isSelected: false },
    //     { claimType: 'Send Messages to all', isSelected: false },
    //     { claimType: 'View Tickets', isSelected: false },
    //     { claimType: 'Respond to Tickets', isSelected: false },
    //     { claimType: 'View Referrals', isSelected: false },
    //     { claimType: 'Accept & Migrate Client', isSelected: false },
    //     { claimType: 'View & Assign Admin Roster', isSelected: false },
    //     { claimType: 'Add Application Tasks', isSelected: false },
    //     { claimType: 'Auditing', isSelected: false },
    //     { claimType: 'Add, View, Edit & Delete Template', isSelected: false },
    //     { claimType: 'View Registrar', isSelected: false },
    //     { claimType: 'Support Coordinator', isSelected: false },
    //     { claimType: 'Staff Daily Report', isSelected: false },
    //     { claimType: 'HR', isSelected: false }
    // ]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loading1, setLoading1] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectedClaims, setSelectedClaims] = useState<ClaimType[]>([]);
    const { uid } = useParams()
    const [claims, setClaims] = useState<ClaimType[]>([]);
    const [userName, setUserName] = useState<string>("");

    const FetchClaims = async () => {
        setLoading(true)
        try {
            const { data } = await axiosInstance.get(`/Account/get_user_claims?userId=${uid}`)
            // console.log(data);
            setClaims(data.userClaims.claims);
            setLoading(false)

        } catch (error) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false)
        }

        try {
            const { data } = await axiosInstance.get(`/Account/get_a_user?userId=${uid}`)
            setUserName(data.email)
        } catch (error) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
        }

    }
    useEffect(() => {

        FetchClaims()
    }, []);

    const handleCheckboxChange = (index: number) => {
        const updatedClaims = claims.map((claim, idx) => {
            if (idx === index) {
                return { ...claim, isSelected: !claim.isSelected };
            }
            return claim;
        });
        setClaims(updatedClaims);

        // Update selectedClaims state after claims state updates
        setSelectedClaims(updatedClaims.filter(claim => claim.isSelected));
    };



    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setLoading1(true)

        const info = {
            userId: uid,
            claims: selectedClaims
        }

        try {
            const { data } = await axiosInstance.post(`/Account/add_claims_to_user`, info)
            toast.success(data.message);
            setLoading1(false);
            navigate(`/app/user/all-user`);
        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false)
        }
        finally {
            setLoading1(false)
        }
    }

    return (
        <>
            <SEO
                title='Claims'
                description='Claims'
                name='Promax-Care'
            />
            <Breadcrumb />

            {loading && (
                <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                    <div className="relative w-auto max-w-lg mx-auto">
                        <div
                            className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                        >
                            <Spinner color="#071952" />
                        </div>
                    </div>

                    <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
                </div>
            )}
            <div className='mt-10'>
                <div className=''>
                    <h2 className='font-bold text-2xl text-black w-auto'>Manage User Priviledges </h2>

                </div>
                <div className='mt-5'>
                    <h2 className='font-bold text-lg text-black w-auto'>User Claims for {userName}</h2>
                    <div className='grid grid-cols-1 gap-4 lg:grid-cols-4 2xl:grid-cols-5 mt-5'>
                        {claims.map((claim, index) => (
                            <span key={index} className=''>
                                <input
                                    type="checkbox"
                                    checked={claim.isSelected}
                                    onChange={() => handleCheckboxChange(index)}
                                /> &nbsp;
                                <span className='font-bold'>
                                    {claim.claimType}
                                </span>
                            </span>
                        ))}
                    </div>

                </div>
                <div className='mx-auto text-center mt-3 lg:col-span-2'>
                    <button type="submit"
                        onClick={handleSubmit}
                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
            font-medium rounded-md text-md px-16 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
            dark:focus:ring-primary"> {
                            loading1 ? <Spinner /> : "Submit"
                        }

                    </button>

                </div>
            </div>
        </>
    )
}

export default Claims