import React, { useEffect, useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
// import LogoIcon from '../../images/logo/logo-icon.svg'
// import { Link } from 'react-router-dom';
// import DropdownMessage from './DropdownMessage';
import DropdownUser from './DropdownUser';
// import DropdownNotification from './DropdownNotification';
// import { BsSearch } from 'react-icons/bs';
import { TbLayoutSidebarLeftCollapseFilled } from 'react-icons/tb';
import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import DropdownNotification from './DropdownNotification';
import Spinner from '../Spinner';
import { NavigationProps } from '../../types/Navigation';
import { CompanyDetail } from '../../types/AuthData';
import { fetchCompany } from '../../services/fetchServices';

const Header: React.FC<NavigationProps> = (props) => {
    const user = useSelector((state: RootState) => state?.auth?.user);

    const [currentTime, setCurrentTime] = useState(new Date());
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(interval);
    }, []);



    const options = { timeZone: 'Australia/Sydney' };
    const timeString = currentTime.toLocaleTimeString('en-AU', options);


    const [companyOne, setCompanyOne] = useState<CompanyDetail | null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const handleCompanyFetch = async () => {
        setLoading(true);
        if (user?.companyId) {
            const data = await fetchCompany(user?.companyId);
            setCompanyOne(data)
        }
        setLoading(false);
    }
    useEffect(() => {
        handleCompanyFetch()
    }, []);

    // const companyOneDate = new Date(companyOne?.expirationDate);



    const companyOneDate = companyOne?.expirationDate && new Date(companyOne?.expirationDate);

    // Get the current date in Australia (assuming Australian Eastern Standard Time)
    const australiaTimeZoneOffset = 10; // UTC+10 for Australian Eastern Standard Time

    // Get the current date in Australia
    const currentDateInAustralia = new Date();
    currentDateInAustralia.setHours(currentDateInAustralia.getHours() + australiaTimeZoneOffset);


    const currentDateInAustraliaNumeric = currentDateInAustralia.getTime();
    const companyOneDateNumeric: number = companyOneDate ? companyOneDate.getTime() : 0; // Ensure companyOneDate is not undefined

    // Calculate the difference in milliseconds
    const timeDifference = currentDateInAustraliaNumeric - companyOneDateNumeric;


    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));
    // Convert milliseconds to days

    // Define your rendering logic
    let displayText;
    if (daysDifference <= 0) {
        // Free trial not expired yet
        displayText = `${companyOne?.packages.package}: ${Math.abs(daysDifference)} days left`;
    } else {
        // Free trial has expired
        displayText = 'Free trial: Expired';
    }

    return (
        <header className='sticky top-0 !z-50
        md:!z-50 lg:!z-50  flex w-full bg-white dark:bg-slate-900 border-b'>
            <div className='flex flex-grow items-center justify-between py-4 px-4  md:px-6 2xl:px-11'>
                <div className='flex items-center gap-2 sm:gap-4'>
                    <button
                        aria-controls='sidebar'
                        aria-expanded={props.sidebarOpen}
                        onClick={(e) => {
                            e.stopPropagation();
                            props.setSidebarOpen(!props.sidebarOpen);
                        }}
                        className='!z-50
                        md:!z-50 lg:!z-50 xl:!z-0 block rounded-sm border border-stroke  p-1.5  dark:border-strokedark dark:bg-slate-800 lg:hidden'
                    >
                        {
                            props.sidebarOpen ?

                                <FaTimes className='text-xl' />
                                :


                                <FaBars className='text-xl' />

                        }
                    </button>

                    <TbLayoutSidebarLeftCollapseFilled className={`bg-white text-dark-purple lg:block hidden
                text-3xl rounded  -right-3 top-9 border border-dark-purple cursor-pointer ${!props.open && "rotate-180"}`}
                        onClick={() => props.setOpen && props.setOpen(!props.open)}
                    />
                    <div className='hidden md:flex lg:flex flex-col'>
                        <div className=' text-sm capitalize text-black dark:text-white'>
                            Welcome Back,
                        </div>
                        <div className=' text-sm text-black dark:text-white font-bold'>{user?.fullName}</div>
                    </div>
                    <div className='block md:hidden '>
                        {companyOne?.companyLogo && (
                            <img src={companyOne?.companyLogo} width={50} alt="logo" />
                        )}
                    </div>
                </div>

                <div className='font-bold hidden sm:flex'>
                    {timeString} in Sydney-AU
                </div>

                <div className='flex items-center sm:gap-3 md:gap-6 lg:gap-9' >

                    <div className='hidden w-fit md:block lg:block font-bold'>
                        <span>{displayText}</span>
                    </div>

                    {/* <div className='hidden w-fit sm:flex lg:flex items-center gap-2'>
                        <span><svg width="40" height="24" viewBox="0 0 40 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="24" rx="12" fill="#E4E7EC" />
                            <rect x="1.53906" y="1.50098" width="21.5385" height="21" rx="10.5" fill="white" />
                        </svg>
                        </span>
                        <span>Live</span>
                    </div> */}
                    {
                        loading && <Spinner />
                    }

                    <div>
                        <span>
                            <svg width="2" height="32" viewBox="0 0 2 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 0V32" stroke="#E4E7EC" />
                            </svg>

                        </span>
                    </div>

                    <div className='flex items-center gap-3 2xsm:gap-7 h-7'>

                        <DropdownNotification />

                        {/* <!-- User Area --> */}
                        <DropdownUser />
                        {/* <!-- User Area --> */}
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
