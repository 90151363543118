import React from "react";

const SignatureForm = () => {
  return (
    <div>
      <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-9 border-gray-300">
        <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
            <tr className="divide-x-2">
              <th className=" px-4 py-2 font-semibold text-gray-900">
                Signature of participant / authorised representative
              </th>
              <th className=" px-4 py-2 font-semibold text-gray-900">
                Signature, representative, Maxicare Plus
              </th>
            </tr>
          </thead>

          <tbody className="divide-y divide-gray-200">
            <tr className="divide-x-2">
              <td
                contentEditable
                className=" px-4 h-28 py-2 text-gray-700 w-[50%] outline-none"
              ></td>
              <td
                contentEditable
                className=" px-4 h-28 py-2 text-gray-700 w-[60%] outline-none"
              ></td>
            </tr>
            <tr className="divide-x-2">
              <td
                className=" px-4 h-28 py-2 text-gray-700 w-[50%] outline-none"
              >
                <strong>Name of participant / authorised representative</strong>
                <textarea className="w-[100%] h-28"></textarea>

              </td>
              <td
                className=" px-4 h-28 py-2 text-gray-700 w-[60%] outline-none"
              >
                <strong>Name of representative</strong>
                <textarea className="w-[100%] h-28"></textarea>

              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  );
};

export default SignatureForm;
