import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../api";
import { isAxiosError } from "axios";
import { Client } from "../clientSlice";
import { RootState } from "../../store";

export interface Representative {
    assistanceId: number;
    profileId: number;
    profile: Client;
    personel: string;
    fullName: string;
    phone: number;
    email: string;
    dateCreated: string;
    userCreated: string;
    dateModified: string;
    userModified: string;
}


export interface ClientRepState {
    loading: boolean;
    data: Array<Representative>;
    error: string | undefined;
}
const initialState: ClientRepState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchClientRepresentative = createAsyncThunk(
    "ClientRep/fetchClientRepresentative",
    async (clientId: number) => {
        try {
            const response = await api.fetchClientRepresentative(clientId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const clientRepSlice = createSlice({
    name: 'clientRep',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchClientRepresentative.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchClientRepresentative.fulfilled, (state, action: PayloadAction<Array<Representative>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchClientRepresentative.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.clientRep;
export default clientRepSlice.reducer;


export const clientRepData = (state: RootState) => state.clientRep.data;
export const clientRepLoading = (state: RootState) => state.clientRep.loading;
export const clientRepError = (state: RootState) => state.clientRep.error;