import React from 'react'
import { FaRegEdit } from 'react-icons/fa';
interface Props {
    label: string;
    showEditIcon?: boolean;
    onEdit?: () => void;
}

const CardHeader = ({ label, showEditIcon, onEdit }: Props) => {
    return (
        <div className='bg-gray-200 text-center h-11 flex justify-center items-center text-sm font-bold text-black rounded-t-lg relative'>
            {showEditIcon && <button onClick={onEdit} className='absolute top-1 right-2 p-2 rounded-full bg-white'><FaRegEdit /></button>}
            {label}
        </div>
    )
}

export default CardHeader