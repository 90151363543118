import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import Timesheet from './Timesheet'
import ShiftAttendance from './ShiftAttendance'
import ProgressReport from './ProgressReport'
import Invoice from './Invoice'
import SavedInvoice from './SavedInvoice'
import ViewSavedInvoice from './ViewSavedInvoice'
import StaffDailyReport from './StaffDailyReport'
import AdminDailyReport from './AdminDailyReport'
import StaffShiftReport from './StaffShiftReport'
import ShiftReportView from '../Profiles/staffTab/ShiftReportView'

const ReportLayout = () => {


    return (
        <div>

            <Routes>
                <Route path="/" element={<Navigate to="/app/reports/attendance-reports" replace />} />
                <Route path='/attendance-reports' element={<Timesheet />} />
                <Route path='/shift-reports' element={<ShiftAttendance />} />
                <Route path='/progress-reports' element={<ProgressReport />} />
                <Route path='/invoice' element={<Invoice />} />
                <Route path='/saved-invoice' element={<SavedInvoice />} />
                <Route path='/view-saved-invoice/:from/:to/:uid' element={<ViewSavedInvoice />} />
                <Route path='/staff-reports' element={<StaffDailyReport />} />
                <Route path='/admin-reports' element={<AdminDailyReport />} />
                <Route path='/shift-form' element={<StaffShiftReport />} />
                <Route path='/view-shift-report/:id/:uid' element={<ShiftReportView />} />


            </Routes>

        </div>
    )
}

export default ReportLayout