import React, { useState, useRef, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import axiosInstance from "../../store/axiosInstance";
import Spinner from "../Spinner";
import { MdSend } from "react-icons/md";

interface ChatMessage {
  sender: "bot" | "user";
  text: string;
}

interface ChatInterfaceProps {
  onClose: () => void;
}

const ChatInterface: React.FC<ChatInterfaceProps> = ({ onClose }) => {
  const [messages, setMessages] = useState<ChatMessage[]>([
    { sender: "bot", text: "Welcome! How can I assist you today?" },
  ]);
  const [inputValue, setInputValue] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const sendMessage = async (message: string) => {
    if (message.trim() === "") return;

    const userMessage: ChatMessage = { sender: "user", text: message.trim() };
    setMessages([...messages, userMessage]);
    setInputValue("");
    setLoading(true);
    setError(null);

    try {
      const botResponse = await fetchChatGPTResponse(userMessage.text);
      setMessages((prevMessages) => [
        ...prevMessages,
        { sender: "bot", text: botResponse },
      ]);
    } catch (err) {
      setError("Failed to fetch response from the server. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const fetchChatGPTResponse = async (message: string): Promise<string> => {
    const { data } = await axiosInstance(
      `/Tickets/support_bot?prompt=${encodeURIComponent(message)}`
    );
    return data.response;
  };

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const promptArr: string[] = [
    "What is Shift Report",
    "How to Create a Shift",
    "Progress Report",
    "TimeSheet",
    "Invoicing",
  ];

  // const copyToClipboard = (text: string) => {
  //     navigator.clipboard.writeText(text);
  //     toast.update("Response copied to clipboard!");
  // };

  // const FeedbackButtons: React.FC<{ text: string }> = ({ text }) => (
  //     <div className="flex gap-2 mt-1">
  //         <button className="p-1 text-white hover:text-green-500" title="Good response">
  //             <FaThumbsUp />
  //         </button>
  //         <button className="p-1 text-white hover:text-red-500" title="Not good enough">
  //             <FaThumbsDown />
  //         </button>
  //         <button className="p-1 text-white hover:text-blue-500" title="Copy response" onClick={() => copyToClipboard(text)}>
  //             <FaCopy />
  //         </button>
  //     </div>
  // );

  return (
    <div className="fixed bottom-12 right-4 w-[20rem] md:w-[22rem] h-[75vh] bg-white shadow-2xl rounded-lg flex flex-col z-[400]">
      <div className="px-2 py-4 border-b flex justify-between items-center bg-brand text-white rounded-t-lg">
        <div className="flex gap-1 items-center">
          <span className="w-8 h-8 rounded-full overflow-hidden">
            {" "}
            <img src="/simaGpt.jpeg" className="w-full h-full" />
          </span>
          <span className="flex gap-1 items-center">
            <small className="w-2 h-2 rounded-full bg-green-400" />{" "}
            <small className="text-green-400"> Online</small>
          </span>
        </div>
        <button className="p-0.5 border text-sm rounded-full" onClick={onClose}>
          <FaTimes />
        </button>
      </div>

      <div className="flex flex-col flex-grow p-2 overflow-y-auto overflow-x-hidden ">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`p-2 max-w-xs relative text-sm ${
              msg.sender === "bot" ? "text-left" : "text-right"
            }`}
          >
            <div
              className={`inline-block p-2 break-words ${
                msg.sender === "bot"
                  ? "bg-brand text-white rounded-r-lg rounded-bl-lg"
                  : "bg-gray-200 rounded-l-lg rounded-br-lg"
              }`}
            >
              {msg.text}
              {/* {msg.sender === 'bot' && <FeedbackButtons text={msg.text} />} */}
            </div>
          </div>
        ))}
        {loading && (
          <div className="p-2">
            <div className="inline-block px-4 py-2 bg-gray-200 rounded-r-lg rounded-bl-lg">
              <Spinner color="#071952" />
            </div>
          </div>
        )}
        {error && <div className="p-2 text-center text-red-500">{error}</div>}
        <div ref={messagesEndRef} />
      </div>
      <div className="p-2 border-t drop-shadow-sm">
        <div className="flex gap-2 items-center horizontal-scroll scroll-smooth scrollbar-hide md:px-0">
          {promptArr.map((item, index) => (
            <button
              key={index}
              className="bg-gray-100 shadow-sm border rounded text-xs px-2 py-1 whitespace-nowrap transition duration-300 transform active:scale-95 ease-in-out"
              onClick={() => sendMessage(item)}
            >
              {item}
            </button>
          ))}
        </div>

        <div className="relative mt-2">
          <input
            type="text"
            className="w-full border font-serif border-gray-200 bg-gray-200 rounded px-2 py-2.5 focus:outline-none"
            placeholder="Type your message..."
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={(e) => e.key === "Enter" && sendMessage(inputValue)}
          />
          <span className="absolute inset-y-0 end-0 grid p-2  place-content-center bg-gray-200  rounded">
            <button
              type="button"
              onClick={() => sendMessage(inputValue)}
              className="text-gray-600 hover:text-gray-700 text-lg"
            >
              <MdSend />
            </button>
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChatInterface;
