import React from "react";
import dayjs from "dayjs";
import { FaPlus } from "react-icons/fa";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
import Card from "./Card";
import {
  DragDropContext,
  Draggable,
  DraggableProvided,
  DropResult,
  Droppable,
  DroppableStateSnapshot,
} from "react-beautiful-dnd";

interface Props {
  shift: Shift[];
  getActivityStatus(activity: Shift): string;
  today: string;
  daysOfWeek: dayjs.Dayjs[];
  handleMarkAttendance: (activity: Shift) => void;
  handleDeleteAttendance: (activity: Shift) => void;
  handleUnmarkAttendance: (activity: Shift) => void;
  handleConfirmAttendance: (activity: Shift) => void;
  handleRejectAttendance: (activity: Shift) => void;
  handleCancelClientAttendance: (activity: Shift) => void;
  handleReAssignAttendance: (activity: Shift) => void;
  handleDuplicateAttendance: (activity: Shift) => void;
  handleMoveAttendance: (activity: Shift) => void;
  handleRepeatAttendance: (activity: Shift) => void;
  handleEdit: (shiftId: number) => void;
  hasRequiredClaims: (claimType: string) => boolean;
  selectedShifts: number[];
  onSelectShift: (shiftId: number, isSelected: boolean) => void;
  multiple: boolean;
  handleShiftClick: (shift: Shift) => void;
}

const WeekView = ({
  shift,
  getActivityStatus,
  today,
  daysOfWeek,
  handleMarkAttendance,
  handleDeleteAttendance,
  handleUnmarkAttendance,
  handleConfirmAttendance,
  handleRejectAttendance,
  handleCancelClientAttendance,
  handleReAssignAttendance,
  handleDuplicateAttendance,
  handleMoveAttendance,
  handleEdit,
  hasRequiredClaims,
  selectedShifts,
  onSelectShift,
  multiple,
  handleRepeatAttendance,
  handleShiftClick,
}: Props) => {
  const activitiesByDay =
    daysOfWeek.map((day) =>
      shift.filter(
        (activity) =>
          dayjs(activity.dateFrom).format("YYYY-MM-DD") ===
          day.format("YYYY-MM-DD")
      )
    ) || [];

  const onDragEnd = (result: DropResult): void => {
    const { source, destination } = result;
    if (
      !destination ||
      (source.droppableId === destination.droppableId &&
        source.index === destination.index)
    ) {
      return;
    }

    const sourceIndex = parseInt(source.droppableId);
    const destinationIndex = parseInt(destination.droppableId);

    const sourceActivities = activitiesByDay[sourceIndex];
    const destinationActivities = activitiesByDay[destinationIndex];

    // Remove the item from the source list
    const [removed] = sourceActivities.splice(source.index, 1);
    // Insert the item into the destination list
    destinationActivities.splice(destination.index, 0, removed);

    // Create a new array reflecting the changes
    // const newActivitiesByDay = activitiesByDay.map((list, index) => {
    //   if (index === sourceIndex) {
    //     return sourceActivities;
    //   } else if (index === destinationIndex) {
    //     return destinationActivities;
    //   } else {
    //     return list;
    //   }
    // });

    // // Update your state here
    // console.log(newActivitiesByDay);
    console.log(source, destination);

    // setActivitiesByDay(newActivitiesByDay);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="grid grid-cols-1 lg:grid-cols-7 ">
        {daysOfWeek.map((day, i) => (
          <Droppable droppableId={String(i)} key={i}>
            {(provided, snapshot: DroppableStateSnapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className={`border border-gray-300 ${
                  snapshot.isDraggingOver && "bg-secondary/10"
                }`}
              >
                <div
                  className={` text-center p-1 flex justify-between items-center text-xs font-semibold text-black border-b border-t border-gray-300`}
                >
                  <span>{day.format("ddd")}.</span>{" "}
                  <span className="text-xs p-1  text-green-700 border rounded border-green-700 cursor-pointer">
                    Shifts:
                    {activitiesByDay[i].length}
                  </span>{" "}
                  <button
                    //   onClick={() => setAddModal(true)}
                    className="text-xs text-white p-1 bg-brand rounded"
                  >
                    {" "}
                    <FaPlus />
                  </button>
                </div>
                <div className="p-1">
                  <small
                    className={`font-bold text-brand ${
                      day.format("ddd, MMM D, YYYY") === today
                        ? "border bg-secondary text-white py-1 px-2 rounded"
                        : ""
                    }`}
                  >
                    {day.format("MMM D")}
                  </small>
                </div>

                {/* {activitiesByDay[index].map((activity, index) => ( */}
                <div className="p-1 flex flex-col gap-1 h-[74vh] overflow-y-auto">
                  {activitiesByDay[i].length > 0 &&
                    activitiesByDay[i].map((activity, index) => (
                      <Draggable
                        key={activity.shiftRosterId}
                        draggableId={`shift-${activity.shiftRosterId}`}
                        index={index}
                      >
                        {(provided: DraggableProvided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Card
                              timeFrom={activity.dateFrom}
                              timeTo={activity.dateTo}
                              staff={activity.staff.fullName}
                              client={activity.clients}
                              // task={activity.activities}
                              onEdit={() => handleEdit(activity.shiftRosterId)}
                              activity={activity}
                              getActivityStatus={getActivityStatus}
                              handleMarkAttendance={handleMarkAttendance}
                              handleDeleteAttendance={handleDeleteAttendance}
                              handleUnmarkAttendance={handleUnmarkAttendance}
                              handleConfirmAttendance={handleConfirmAttendance}
                              handleRejectAttendance={handleRejectAttendance}
                              handleCancelClientAttendance={
                                handleCancelClientAttendance
                              }
                              handleReAssignAttendance={
                                handleReAssignAttendance
                              }
                              handleDuplicateAttendance={
                                handleDuplicateAttendance
                              }
                              handleMoveAttendance={handleMoveAttendance}
                              hasRequiredClaims={hasRequiredClaims}
                              isSelected={selectedShifts.includes(
                                activity.shiftRosterId
                              )}
                              onSelectShift={onSelectShift}
                              multiple={multiple}
                              handleRepeatAttendance={handleRepeatAttendance}
                              handleShiftClick={handleShiftClick}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
        ))}
      </div>
    </DragDropContext>
  );
};

export default WeekView;
