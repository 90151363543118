import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface Document {
  company: string;
  companyId: number
  dateCreated: string;
  dateModified: string;
  documentFile: string;
  documentId: number;
  documentName: string;
  documentUrl: string;
  expirationDate: string;
  implementationDate: string;
  isArchived: boolean
  rejectDeadline: string;
  rejectReason: string;
  status: string;
  user: string;
  userCreated: string;
  userId: number;
  userModified: string;
  userRole: string;
  verify: boolean;
}





export interface DocumentState {
  loading: boolean;
  data: Array<Document>;
  error: string | undefined;
}
const initialState: DocumentState = {
  loading: false,
  data: [],
  error: undefined,
}
export const fetchDocument = createAsyncThunk(
  "Document/fetchDocument",
  async (companyId: string | number) => {
    try {
      const response = await api.fetchDocumentData(companyId);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        throw error;
      } else {
        throw error;
      }
    }
  }
)

export const filterDocument = createAsyncThunk(
  "Document/filterDocument",
  async ({ companyId, dateFrom, dateTo, sta, admin, status, role, archive }: { companyId: string | number, dateFrom: string, dateTo: string, sta: string, admin: string, status: string, role: string, archive: boolean }) => {
    try {
      const response = await api.filterDocumentData(companyId, dateFrom, dateTo, sta, admin, status, role, archive);
      return response;
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        throw error;
      } else {
        throw error;
      }
    }
  }
)

const documentSlice = createSlice({
  name: 'document',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDocument.fulfilled, (state, action: PayloadAction<Array<Document>>) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchDocument.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
    builder.addCase(filterDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(filterDocument.fulfilled, (state, action: PayloadAction<Array<Document>>) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(filterDocument.rejected, (state, action) => {
      state.loading = false;
      state.data = [];
      state.error = action.error.message;
    });
  },
  reducers: {}
})
export const userSelector = (state: RootState) => state.document;
export default documentSlice.reducer;



// Define selectors to access the profile slice state
export const documentData = (state: RootState) => state.document.data;
export const documentLoading = (state: RootState) => state.document.loading;
export const documentError = (state: RootState) => state.document.error;