import React, { FormEvent } from 'react'
import { Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import Spinner from '../../../../Components/Spinner';
import { StaffDataRow } from '../../../../types/AuthData';
interface Props {
    othersModal: boolean;
    setOthersModal: React.Dispatch<React.SetStateAction<boolean>>
    editedProfile: StaffDataRow
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    submitData: (e: FormEvent) => Promise<void>
    loading1: boolean;
}
const StaffOtherComp = ({ othersModal, setOthersModal, editedProfile, handleInputChange, submitData, loading1 }: Props) => {
    return (
        <>
            <Modal
                open={othersModal}
                onClose={() => setOthersModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Other Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={submitData}>
                            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 ">
                                <CustomInput
                                    label="Instagram"
                                    type="text"
                                    name='instagram'
                                    value={editedProfile.instagram || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Twitter"
                                    type="text"
                                    name='twitter'
                                    value={editedProfile.twitter || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Facebook"
                                    type="text"
                                    name='facebook'
                                    value={editedProfile.facebook || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="LinkedIn"
                                    type="email"
                                    name='linkedIn'
                                    value={editedProfile.linkedIn || ""}
                                    onChange={handleInputChange}
                                />

                            </div>

                            <div className='flex items-center justify-center'>
                                <button type="submit"
                                    disabled={loading1 ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading1 ? <Spinner /> : "Update"
                                    }</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default StaffOtherComp