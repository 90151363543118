import React from "react";

interface Props {
    color?: string;
}
function Spinner({ color = "#9CA3AF" }: Props) {
    return (

        // <div aria-label="Loading..." role="status" className="flex justify-center items-center">
        //     <svg width={24} height={24} fill="none" stroke="currentColor" strokeWidth="1.5" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round"
        //         xmlns="http://www.w3.org/2000/svg" className={`animate-spin w-6 h-6 stroke-gray-300  text-${color}`}>
        //         <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12">
        //         </path>
        //     </svg>
        // </div>
        <div aria-label="Loading..." role="status" className="flex justify-center items-center">
            <svg width={24} height={24} fill="none" stroke={color} strokeWidth="1.5" viewBox="0 0 24 24" strokeLinecap="round" strokeLinejoin="round"
                xmlns="http://www.w3.org/2000/svg" className="animate-spin w-6 h-6">
                <path d="M12 3v3m6.366-.366-2.12 2.12M21 12h-3m.366 6.366-2.12-2.12M12 21v-3m-6.366.366 2.12-2.12M3 12h3m-.366-6.366 2.12 2.12">
                </path>
            </svg>
        </div>

    )
}

export default Spinner