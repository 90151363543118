import React from "react";
import { Client } from "../../../store/slices/clientSlice";
import dayjs from "dayjs";

interface Props {
  clientDetail: Client | null;
  Provider_Name: React.RefObject<HTMLTableCellElement>;
  Provider_Reg_No: React.RefObject<HTMLTableCellElement>;
}
const NDISForm = ({ clientDetail, Provider_Name, Provider_Reg_No }: Props) => {
  return (
    <div className="mt-5">
      <h4 className="text-xl font-semibold text-gray-800 dark:text-white mb-4">
        NDIS Service Agreement
      </h4>
      <h3 className="text-black font-bold">
        <strong>PARTIES TO THE AGREEMENT</strong>
      </h3>

      <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-2 border-gray-300">
        <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
          <tbody className="divide-y divide-gray-200">
            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                NDIS provider name:
              </td>
              <td
                className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
                ref={Provider_Name}
                contentEditable
              ></td>
            </tr>
            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                NDIS provider registration number:
              </td>
              <td
                className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
                ref={Provider_Reg_No}
                contentEditable
              ></td>
            </tr>

            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                NDIS participant name:
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                {clientDetail?.fullName}
              </td>
            </tr>

            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                NDIS participant number:
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                {clientDetail?.ndisNo}
              </td>
            </tr>
            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Date of birth:
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                {clientDetail?.dateOfBirth
                  ? dayjs(clientDetail?.dateOfBirth).format("DD/MM/YYYY")
                  : "N/A"}
              </td>
            </tr>
            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Date agreement commences:
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                {clientDetail?.agreementStartDate
                  ? dayjs(clientDetail?.agreementStartDate).format("DD/MM/YYYY")
                  : ""}
              </td>
            </tr>
            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                Date agreement ends:
              </td>
              <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
                {clientDetail?.agreementEndDate
                  ? dayjs(clientDetail?.agreementEndDate).format("DD/MM/YYYY")
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NDISForm;
