import React, { ChangeEvent, useEffect, useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
// import { FaFilter } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { fetchSupportType, supportTypeLoading, supportTypelateData } from '../../../store/slices/supportTypeSlice';
import Spinner from '../../../Components/Spinner';
import IconButton from '../../../Components/Buttons/IconButton';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';
import { Column } from '../../../types/DataType';

interface DataRow {
    dateCreated: string;
    dateModified: string;
    itemName: string;
    itemNumber: string;
    national: number;
    ndiA_DPAId: number;
    remote: number;
    unit: string;
    userCreated: string;
    userModified: string;
    veryRemote: number;
}

const SupportType = () => {

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Item Number',
            selector: row => row.itemNumber,

        },
        {
            name: 'Item Name',
            selector: row => row.itemName,
            sortable: true,
            cell: (row) => <span className="truncate" style={{ overflow: "hidden", cursor: "pointer" }}
                data-toggle="tooltip" data-placement="top" title={`${row.itemName}`}
            >{row.itemName}</span>

        },

        {
            name: 'Unit',
            selector: row => row.unit,
        },
        {
            name: 'National',
            selector: row => row.national,
        },
        {
            name: 'Remote',
            selector: row => row.remote,
        },
        {
            name: 'Very Remote',
            selector: row => row.veryRemote,
        }

    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchSupportType());
        }
    }, []);
    const supportType = useSelector(supportTypelateData);
    const loadingData = useSelector(supportTypeLoading);

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='fw-bold'>Item Name: </span>
                    <span> {data.itemName}</span>
                </span>

                <span>
                    <span className='fw-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>
                <span>
                    <span className='fw-bold'>Date Modified: </span>
                    <span>
                        {dayjs(data.dateModified).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>

            </div>
        )
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = supportType.filter((item) =>
        item.itemName.toLowerCase().includes(searchText.toLowerCase())
    );


    return (
        <>
            <SEO
                title='Support Type - Promax Care'
                description='Support Type'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-10'>
                {supportType.length <= 0 && loadingData && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Support Type</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By itemName" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(supportType, columns as unknown as Column<DataRow>[], "SupportType.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(supportType, columns as unknown as Column<DataRow>[], "SupportType")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: supportType, filename: 'SupportType.csv' })}

                                    />
                                </div>
                            </ExportDropdown>




                        </div>

                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    pagination
                    paginationTotalRows={supportType?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />

            </div>


        </>
    )
}

export default SupportType