import React from 'react'
import notiIcon from '../../../../images/icon/notification.png'

const Notification = () => {
    return (
        <div className="border-2 rounded-2xl bg-white shadow  " >
            <div className="bg-gray-100 text-center p-2 flex justify-between items-center text-sm font-semibold rounded-t-xl ">
                <span>
                    Notifications/Activity
                </span>{" "}
            </div>
            <div className="flex flex-col gap-2 items-center justify-center mx-auto pt-14 px-4 md:px-4 xl:px-10 my-3">
                <img src={notiIcon} alt="notification icon"
                    width={120}

                />


                <strong className="text-center">
                    You are all caught up on notifications. You will be notified here once there is any activity or anything that needs your attention.
                </strong>
            </div>

        </div>
    )
}

export default Notification