import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface SupportType {
    dateCreated: string;
    dateModified: string;
    itemName: string;
    itemNumber: string;
    national: number;
    ndiA_DPAId: number;
    remote: number;
    unit: string;
    userCreated: string;
    userModified: string;
    veryRemote: number;
}

export interface SupportTypeState {
    loading: boolean;
    data: Array<SupportType>;
    error: string | undefined;
}
const initialState: SupportTypeState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchSupportType = createAsyncThunk(
    "SupportType/fetchSupportType",
    async () => {
        try {
            const response = await api.fetchSupportType();
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const supportTypeSlice = createSlice({
    name: 'supportType',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchSupportType.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchSupportType.fulfilled, (state, action: PayloadAction<Array<SupportType>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchSupportType.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.supportType;
export default supportTypeSlice.reducer;


export const supportTypelateData = (state: RootState) => state.supportType.data;
export const supportTypeLoading = (state: RootState) => state.supportType.loading;
export const supportTypeError = (state: RootState) => state.supportType.error;