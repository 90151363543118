import React from "react";
import ContactDetails from "./ContactDetails";
import ParticipantDetailForm from "./ParticipantDetailForm";
import AuthorisedRepForm from "./AuthorisedRepForm";
import { Client } from "../../../store/slices/clientSlice";
import { Staff } from "../../../store/slices/staffSlice";
import { CompanyDetail } from "../../../types/AuthData";
import { NdisForm, Representative } from "../../../types/DataType";
import dayjs from "dayjs";
import { FaTimes } from "react-icons/fa";
import SignatureComponent from "../../../Components/Signature";

interface Props {
  paymentText: string[];
  handlePaymentChange: (option: string) => void;
  paymentOptions: string[];
  handleSignatureSave: (signatureBlob: Blob) => void;
  signature: Blob | null;
  clientDetail: Client | null;
  setSignature: React.Dispatch<React.SetStateAction<Blob | null>>;
  editedNdisForm: NdisForm;
  handleInputChange: (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement
    >
  ) => void;
  careManager: Staff | null;
  companyOne: CompanyDetail | null | undefined;
  clientRep: Representative | null;
  handleDateChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const EditedPayments = ({
  paymentText,
  handlePaymentChange,
  paymentOptions,
  signature,
  clientDetail,
  setSignature,
  careManager,
  companyOne,
  clientRep,
  editedNdisForm,
  handleSignatureSave,
  handleDateChange,
}: Props) => {
  return (
    <div>
      <div className="mt-5">
        <strong className="text-black">PAYMENTS </strong>
        <fieldset className="mt-5">
          <legend className="sr-only">Payment Options</legend>
          <div className="space-y-4">
            {paymentText.map((item, i) => {
              return (
                <div key={i}>
                  <input
                    type="checkbox"
                    id={`checkbox-${i}`}
                    value={paymentText}
                    onChange={() => handlePaymentChange(item)}
                    checked={(paymentOptions || []).includes(item)}
                    className="mr-2"
                  />
                  <label className="cursor-pointer" htmlFor={`checkbox-${i}`}>
                    {item}
                  </label>
                </div>
              );
            })}
          </div>
        </fieldset>
      </div>
      <div>
        <p className="text-black leading-8 mt-5">
          Note: For the purposes of GST legislation, the parties confirm in this
          service agreement that:
        </p>
        <ul className="list-disc leading-8 pl-10">
          <li className="text-black">
            A supply of supports under this service agreement is a supply of one
            or more of the reasonable and necessary supports specified in the
            statement included, under subsection 33(2) of the{" "}
            <span className="underline">
              National Disability Insurance Scheme Act 2013
            </span>{" "}
            (NDIS Act), in the participant’s NDIS plan currently in effect under
            section 37 of the NDIS Act; and
          </li>
          <li className="text-black">
            The participant’s NDIS plan is expected to remain in effect during
            the period the supports are provided.
          </li>
        </ul>
      </div>
      <div className="mt-5">
        <strong className="text-black">CHANGES TO THIS AGREEMENT </strong>
        <p className="text-black leading-8">
          If changes to the supports are required, the parties agree to discuss
          and review this service agreement. Any changes to this Agreement will
          be in writing, signed, and dated by both parties.
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">CANCELLATION POLICY </strong>
        <p className="text-black leading-8">
          A cancellation is a short notice cancellation if you: do not arrive
          for a scheduled support within a reasonable time, or are not present
          at the agreed place and within a reasonable time when we travel to
          deliver the support; or have given less than (7 days) notice. Where we
          are provided with a short notice cancellation (or you are not present
          for your scheduled appointment) we will claim 100% of the agreed fee
          associated with the planned appointment unless we agree otherwise. You
          will not be charged if we can reallocate your appointment to another
          participant. If there are several short notice cancellations, we will
          discuss this with you to ensure you are satisfied with the service you
          a receiving or support you to transition to another provider if
          required.
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">
          TEMPORARY SUSPENSION OF SUPPORTS{" "}
        </strong>
        <p className="text-black leading-8">
          We may need to ask you to take a short break from our service if: we
          are unable to provide support that meets your needs, or we believe
          that a lack of commitment is likely to prevent goals being attained.
          If you continue to not engage in sessions, we also reserve the right
          to cancel this agreement – refer Ending this Service Agreement. you
          act in a way that makes it unsafe for you, your support workers or
          others, but we think that you can change the unsafe behaviour with
          support. We will immediately tell you, or your representative if we
          are suspending supports and we will work out how to address the
          problem with you.
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">ENDING THIS SERVICE AGREEMENT </strong>
        <p className="text-black leading-8">
          Should either party (you or us) wish to end this service agreement
          they must give four weeks’ notice in writing unless there has been a
          breach of the agreement and both parties agree to waive the notice
          period. {companyOne?.companyName} reserves the right to end the
          agreement in the instance that: our staff are placed at an
          unacceptable risk of harm by you or a family member, carer or
          representative and we don’t believe that additional supports would
          reduce the risk of harm; and/or you continue to not engage in supports
          despite being given the opportunity to do so, and it is likely that
          the identified goals will not be achieved.
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">
          FEEDBACK, COMPLAINTS AND DISPUTES{" "}
        </strong>
        <p className="text-black leading-8">
          We aim to provide safe and respectful services to you. If you wish to
          provide feedback or are not happy with the services provided, you or
          your representative can contact:
        </p>
      </div>
      <div className="mt-2">
        <strong className="text-black">
          ({companyOne?.companyName}) on ({companyOne?.companyEmail},
          {companyOne?.companyPhone}).{" "}
        </strong>
      </div>
      <div className="mt-5">
        <p className="text-black leading-8">
          If you are not satisfied or want to raise a complaint externally, you
          can contact the NDIS Quality and Safeguards Commission by: Phoning
          1800 035 544 (free call from landlines) or TTY 133 677. Interpreters
          can be arranged.{" "}
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">CHANGE OF CIRCUMSTANCES </strong>
        <p className="text-black leading-8">
          You or your representative agree to immediately notify us if your NDIS
          Plan is replaced by a new plan or you stop being a participant in the
          NDIS.{" "}
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">RECORD KEEPING </strong>
        <p className="text-black leading-8">
          {companyOne?.companyName} is required to keep accurate records of
          support provided to you. All staff are responsible for ensuring that
          personal health information is protected against loss, misuse, and
          unauthorised modification, access or disclosure.
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">SUB-CONTRACTING </strong>
        <p className="text-black leading-8">
          The {companyOne?.companyName} may sub-contract all or any part of our
          rights and obligations but acknowledge that we always remain liable to
          you for services provided.
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">RISK AND LIABILITY </strong>
        <p className="text-black leading-8">
          You agree to give us sufficient information, such as health
          information, to enable us to provide safe services to you. You
          understand that {companyOne?.companyName} takes no responsibility for
          any personal injury or loss and damage if the information provided by
          you is insufficient or inaccurate.
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">RELEVANT LAWS </strong>
        <p className="text-black leading-8">
          The agreement is deemed to be made in Insert relevant state/territory.
          Nothing in these terms and conditions is intended to have the effect
          of contravening any applicable provisions of the Competition and
          Consumer Act 2010 or the Fair Trading Acts in each of the States and
          Territories of Australia.
        </p>
      </div>
      <div className="mt-5">
        <strong className="text-black">CONTACT DETAILS </strong>
        <p className="text-black leading-8">Participant’s contact details</p>
        <ContactDetails clientDetail={clientDetail} />
      </div>
      <div className="mt-5">
        <strong className="text-black">
          Participant representative contact details:{" "}
        </strong>
        <ParticipantDetailForm clientRep={clientRep} />
      </div>
      <div className="mt-5">
        <strong className="text-black">
          {companyOne?.companyName} authorised representative contact details:{" "}
        </strong>
        <AuthorisedRepForm careManager={careManager} />
      </div>

      <div className="  mt-9 border-gray-300 py-2 ">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <div>
            <p className="font-semibold">Implementation Date</p>

            <div>
              <input
                type="date"
                id="implementationDate"
                name="implementationDate"
                value={
                  editedNdisForm.implementationDate
                    ? editedNdisForm.implementationDate.split("T")[0]
                    : ""
                }
                onChange={handleDateChange}
                className="w-full rounded-md border p-2.5 border-gray-200 shadow-sm sm:text-sm outline-none"
              />
            </div>
          </div>
          <div>
            <p className="font-semibold">Expiration Date</p>

            <div>
              <input
                type="date"
                id="expirationDate"
                name="expirationDate"
                value={
                  editedNdisForm.expirationDate
                    ? editedNdisForm.expirationDate.split("T")[0]
                    : ""
                }
                onChange={handleDateChange}
                className="w-full rounded-md border p-2.5 border-gray-200 shadow-sm sm:text-sm outline-none"
              />
            </div>
          </div>
        </div>
        <div></div>
      </div>

      <div className=" rounded-lg  mt-7  ">
        <strong className="text-black">Agreement Signatures</strong>
        <p className="text-sm mt-3">
          This service agreement has been explained to me and I agree to the
          terms and conditions.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border mt-5  p-2">
          <div>
            <p>Name of Participant/Participant’s representative</p>
            <p>
              <input
                type="text"
                value={clientDetail?.fullName}
                className=" w-80 border-b border-0 border-b-black text-black font-bold outline-none"
                readOnly
              />
              Date: {dayjs(editedNdisForm.dateCreated).format("D, MMM  YYYY")}
            </p>
          </div>

          <div>
            <p>Signature of Participant/Participant’s representative</p>
            <div className="flex gap-2 ">
              {!signature && (
                <img
                  src={editedNdisForm.signatureUrl}
                  alt="Participant Signature"
                  className="img-thumbnail  block "
                  style={{
                    display: "block",
                    width: "150px",
                  }}
                />
              )}

              {!signature && !editedNdisForm.signatureUrl && (
                <SignatureComponent
                  onSignatureSave={handleSignatureSave}
                  text="Sign here"
                />
              )}
            </div>
            <div className="flex gap-2 ">
              {signature && (
                <img
                  src={URL.createObjectURL(signature)}
                  alt="Participant Signature"
                  className="img-thumbnail  block "
                  style={{
                    display: "block",
                    width: "150px",
                  }}
                />
              )}
              {signature && (
                <button
                  className=" text-base text-red-600"
                  type="button"
                  onClick={() => setSignature(null)}
                >
                  <FaTimes className="text-lg border p-1" />
                </button>
              )}
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 border mt-5  p-2">
          <div>
            <p>Name of {companyOne?.companyName} representative</p>
            <p>
              <input
                type="text"
                value={careManager?.fullName}
                className=" w-80 border-b border-0 border-b-black text-black font-bold outline-none"
                readOnly
              />
              Date: {dayjs(editedNdisForm.dateCreated).format("D, MMM  YYYY")}
            </p>
          </div>
          <div>
            <p>Signature of {companyOne?.companyName} representative</p>

            <div className="flex gap-2 ">
              {careManager?.signatureUrl && (
                <img
                  src={careManager?.signatureUrl}
                  alt="company Signature"
                  className="img-thumbnail block"
                  style={{
                    display: "block",
                    width: "150px",
                  }}
                />
              )}
            </div>
          </div>
        </div>
        <div></div>
      </div>

      <div className="mt-5">
        <strong className="text-black">Attachments</strong>

        <div className="mt-5 flex items-center gap-1">
          <input
            type="checkbox"
            className=" border-b border-0 border-b-black outline-none"
          />
          <p className="text-black leading-8">
            A copy of the Participant’s NDIS plan is attached with their
            agreement
          </p>
        </div>
      </div>
    </div>
  );
};

export default EditedPayments;
