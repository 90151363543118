import React from 'react'

const Check = () => {
  return (
    <div>
        <div className="my-5">
          <p className="flex items-center my-2 gap-3">
            <input id="supp" type="checkbox" />
            <label htmlFor="supp">
              <strong>I give consent for</strong> Maxicare Plus to provide the
              following assistance to help me with my money:
            </label>
          </p>
          <div className="flex items-center my-2">
            <span>1.</span>
            <input
              className="w-[100%] outline-none p-1 border border-gray-300"
              type="text"
            />
          </div>
          <div className="flex items-center my-2">
            <span>2.</span>
            <input
              className="w-[100%] outline-none p-1 border border-gray-300"
              type="text"
            />
          </div>
          <div className="flex items-center my-2">
            <span>3.</span>
            <input
              className="w-[100%] outline-none p-1 border border-gray-300"
              type="text"
            />
          </div>
        </div>
    </div>
  )
}

export default Check