import React, { useState } from "react";
import { Modal } from "rsuite";

interface Props {
  latitude: number;
  longitude: number;
}

const LocationMapModal = ({ latitude, longitude }: Props) => {
  const [showModal, setShowModal] = useState(false);

  const embedUrl = `https://maps.google.com/maps?q=${latitude},${longitude}&hl=en;&z=14&output=embed`;

  const openModal = () => {
    setShowModal(true);
  };

  return (
    <div>
      <small
        onClick={openModal}
        className="cursor-pointer px-2 py-1 text-xs bg-primary text-white rounded flex  truncate justify-center items-center"
      >
        Show on Google map
      </small>

      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        backdrop="static"
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">
            Location of time clocked in
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="flex justify-center items-center">
            <iframe
              width="500"
              height="370"
              frameBorder="0"
              style={{ border: 0 }}
              src={embedUrl}
              title="Location Map"
            ></iframe>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default LocationMapModal;
