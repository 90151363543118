import React from "react";
import ProfileTable from "./ProfileTable";
import { CompanyDetail } from "../../../types/AuthData";
import { Client } from "../../../store/slices/clientSlice";
import man from "../../../images/user/man.png";
interface Props {
  companyOne: CompanyDetail | null | undefined;
  clientDetail: Client | null
}
const Hero = ({ companyOne, clientDetail }: Props) => {
  return (
    <div>
      <div className="flex justify-end">
        <img
          className="w-24  mb-4 lg:mb-4"
          src={companyOne?.companyLogo}
          alt="Company Logo"
        />
      </div>
      <div>
        <p className="text-[20px] text-black font-semibold">Participant profile</p>

        <div className="w-40 h-40 border overflow-hidden">
          <img src={clientDetail?.imageUrl || man} alt="" />
        </div>
        <div className=" w-[100%]">
          <ProfileTable clientDetail={clientDetail} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
