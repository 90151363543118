import React from 'react'

const Form2 = () => {
  return (
    <div>
      <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-5 border-gray-300">
        <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">
          <thead className="ltr:text-left rtl:text-right bg-[#b9b9b95e]">
            <tr className="divide-x-2">
              <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900"></th>
              <th className="whitespace-nowrap px-4 py-2 font-semibold text-gray-900">
                Please provide name of person or provider and their contact
                details
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                1
              </td>
              <td
                className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
                contentEditable
              ></td>
            </tr>

            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                2
              </td>
              <td
                className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
                contentEditable
              ></td>
            </tr>

            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                3
              </td>
              <td
                className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
                contentEditable
              ></td>
            </tr>
            <tr className="divide-x-2">
              <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
                4
              </td>
              <td
                className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none"
                contentEditable
              ></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default Form2