
// export const thisYear = new Date().getFullYear();
// export const thisMonth = new Date().getMonth();

const getAustralianTime = (timeZone = 'Australia/Sydney') => {
    // Correctly specifying the types for year and month as literals
    const options: Intl.DateTimeFormatOptions = {
        timeZone,
        year: 'numeric',  // Correct type instead of string
        month: '2-digit'  // Correct type instead of string
    };

    const formatter = new Intl.DateTimeFormat('en-AU', options);
    const [month, year] = formatter.format(new Date()).split('/');

    return {
        thisYear: parseInt(year, 10),
        thisMonth: parseInt(month, 10) - 1 // Adjust because getMonth() is zero-indexed
    };
};

export const { thisYear, thisMonth } = getAustralianTime();
// console.log(thisYear, thisMonth);



export const convertTo12HourFormat = (time24h: string): string => {
    const [hoursStr, minutes] = time24h.split(':');
    let hours = parseInt(hoursStr, 10); // Convert hours part to number
    let suffix = 'AM';

    if (hours >= 12) {
        suffix = 'PM';
        if (hours > 12) {
            hours -= 12; // Convert from 24h to 12h format only if hours > 12
        }
    }

    if (hours === 0) {
        hours = 12; // Handle midnight case
    }

    return `${hours}:${minutes} ${suffix}`;
};

export function formatDuration(duration: number) {
    if (duration) {
        const durationInMilliseconds = duration / 10000; // Convert ticks to milliseconds

        const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;

        return `${hours} Hrs ${minutes} min`;
    }

    return "0 Hrs 0 min"; // Return an empty string if duration is not available
}