import React from "react";

const Hero = () => {
  return (
    <div className="overflow-x-auto border m-auto my-4">
      <div className="w-100% font-medium text-[25px] text-center bg-[gainsboro] text-black">
        Participant Safety Plan
      </div>
      <table className="min-w-full divide-y-2 divide-gray-200 bg-white text-sm">
        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2 flex">
            <td className="md:whitespace-nowrap px-4 py-2 font-medium flex w-[50%]  text-gray-900">
              <span>Participant Name:</span>
              <input
                className="w-[100%] border border-gray-100 outline-none"
                type="text"
              />
            </td>
            <td className="md:whitespace-nowrap px-4 py-2 font-medium flex w-[50%]  text-gray-900">
              <span>Date of birth:</span>
              <input
                className="w-[100%] border border-gray-100 outline-none"
                type="text"
              />
            </td>
          </tr>
          <tr className="divide-x-2 flex">
            <td className="md:whitespace-nowrap px-4 py-2 font-medium flex w-[50%]  text-gray-900">
              <span>Address:</span>
              <input
                className="w-[100%] border border-gray-100 outline-none"
                type="text"
              />
            </td>
            <td className="md:whitespace-nowrap px-4 py-2 font-medium flex w-[50%]  text-gray-900">
              <span>Person responsible or guardian:</span>
              <input
                className="w-[100%] border border-gray-100 outline-none"
                type="text"
              />
            </td>
          </tr>
          <tr className="divide-x-2 flex">
            <td className="md:whitespace-nowrap px-4 py-2 font-medium flex w-[50%]  text-gray-900">
              <span>Date completed:</span>
              <input
                className="w-[100%] border border-gray-100 outline-none"
                type="text"
              />
            </td>
            <td className="md:whitespace-nowrap px-4 py-2 font-medium flex w-[50%]  text-gray-900">
              <span>Completed by:</span>
              <input
                className="w-[100%] border border-gray-100 outline-none"
                type="text"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default Hero;
