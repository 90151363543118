import React from "react";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
import { Modal } from "rsuite";
import dayjs from "dayjs";
interface Props {
  selectedShift: Shift | null;
  FillReport: (shiftId: number, id: number) => void;
  showModal: boolean;
  setShowModal: (value: React.SetStateAction<boolean>) => void;
}
const ViewRoster = ({
  showModal,
  FillReport,
  selectedShift,
  setShowModal,
}: Props) => {
  return (
    <>
      <Modal
        open={showModal}
        onClose={() => setShowModal(false)}
        autoFocus
        size={"sm"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Shift Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedShift && (
            <>
              <table>
                <tbody>
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      Status:
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {" "}
                      <span
                        className={`px-3 py-1 rounded font-bold text-white ${selectedShift.status === "Pending"
                          ? "bg-secondary"
                          : selectedShift.status === "Cancelled"
                            ? "bg-red-500"
                            : "bg-green-600"
                          }`}
                      >
                        {selectedShift.status}
                      </span>
                    </td>
                  </tr>

                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      Staff:
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {selectedShift.staff.fullName}
                    </td>
                  </tr>
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      Clients:
                    </td>
                    <td className=" px-2 py-1 text-gray-700 whitespace-normal">
                      {selectedShift.clients}
                    </td>
                  </tr>
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      From:
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {dayjs(selectedShift?.dateFrom).format(
                        "D MMMM, YYYY h:mm A"
                      )}{" "}
                    </td>
                  </tr>
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      To:
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {" "}
                      {dayjs(selectedShift?.dateTo).format(
                        "D MMMM, YYYY h:mm A"
                      )}{" "}
                    </td>
                  </tr>
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      Activities:
                    </td>
                    <td className="px-2 py-1 whitespace-normal text-gray-700">
                      {" "}
                      {selectedShift.activities}
                    </td>
                  </tr>
                  {selectedShift.reason && (
                    <tr className="text-sm truncate ...">
                      <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                        Reason:
                      </td>
                      <td className=" px-2 py-1 text-gray-700">
                        {" "}
                        {selectedShift.reason}
                      </td>
                    </tr>
                  )}
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      Date Created:
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {dayjs(selectedShift.dateCreated).format(
                        "MMMM D, YYYY h:mm A"
                      )}
                    </td>
                  </tr>
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      User Created:
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {selectedShift.userCreated}
                    </td>
                  </tr>
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      Date Modified
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {dayjs(selectedShift.dateModified).format(
                        "MMMM D, YYYY h:mm A"
                      )}
                    </td>
                  </tr>
                  <tr className="text-sm truncate ...">
                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                      User Modified
                    </td>
                    <td className=" px-2 py-1 text-gray-700">
                      {selectedShift.userModified}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="flex items-center justify-center mt-3">
                {selectedShift.isShiftReportSigned ? (
                  <div className="text-center">
                    <button
                      type="submit"
                      onClick={() =>
                        FillReport(
                          selectedShift.reportId,
                          selectedShift.shiftRosterId
                        )
                      }
                      // disabled={loadingFilter ? true : false}
                      className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                    >
                      View Shift Report
                    </button>
                  </div>
                ) : (
                  <div className="text-center">
                    <button
                      type="button"
                      disabled
                      className="text-gray-500 bg-gray-300/45  border 
font-medium rounded-md text-md px-4 text-xs md:text-sm py-2 me-2 mt-2 "
                    >
                      Shift Report Not Available
                    </button>
                  </div>
                )}
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ViewRoster;
