import React, { ReactNode } from 'react';
import Dropdown from '../dropdown';
import { FaDownload } from 'react-icons/fa';

function ExportDropdown(props: { transparent?: boolean; children: ReactNode }) {
    const { transparent, children } = props;
    const [open, setOpen] = React.useState(false);
    return (
        <Dropdown
            button={
                <button
                    onClick={() => setOpen(!open)}
                    id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                    <FaDownload />
                    Export
                </button>
            }
            animation={'origin-top-right transition-all duration-300 ease-in-out'}
            classNames={`${transparent ? 'top-8' : 'top-11'} right-0 w-max`}
        >
            {children}
        </Dropdown>
    );
}

export default ExportDropdown;
