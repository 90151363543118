import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import AdminDocument from './Admin'
import StaffDocument from './Staff'
import ClientDocument from './Client'


const DocumentLayout = () => {


    return (
        <div>

            <Routes>
                <Route path="/" element={<Navigate to="/app/document/administrators" replace />} />
                <Route path='/administrators' element={<AdminDocument />} />
                <Route path='/staff' element={<StaffDocument />} />
                <Route path='/client' element={<ClientDocument />} />



            </Routes>

        </div>
    )
}

export default DocumentLayout