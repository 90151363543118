import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../../Components/menu/ExportDropdown';
import IconButton from '../../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../../services/reusableFunc';
import { Column } from '../../../../types/DataType';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { AppDispatch, RootState, useAppDispatch } from '../../../../store/store';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { Button, InputPicker, Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import Spinner from '../../../../Components/Spinner';
import { toast } from 'react-toastify';
import axiosInstance from '../../../../store/axiosInstance';
import { isAxiosError } from 'axios';
import { clientDailyLivingData, clientDailyLivingLoading, fetchClientDailyLiving } from '../../../../store/slices/clientDailyLivingSlice';

interface DataRow {
    activities: string;
    dailyLivingId: number;
    dateCreated: string;
    dateModified: string;
    details: string;
    profile: {
        profileId: number,
        firstName: string;
        surName: string;
        middleName: string;
        clientId: number;
        email: string;
        phoneNumber: string;
        gender: string;
        fullName: string;
    }
    profileId: number;
    support: string;
    supportLevel: string;
    userCreated: string;
    userModified: string;

}



interface ClientDailyLivingProps {
    uid: string; // Define the type of uid as string
}

type DataOption = {
    label: string;
    value: string;
};

const ClientDailyLiving: React.FC<ClientDailyLivingProps> = ({ uid }) => {



    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Activities',
            selector: row => row.activities,
            sortable: true,
        },
        {
            name: 'Support',
            selector: row => row.support,
            sortable: true,
        },
        {
            name: 'Level of Support',
            selector: row => row.supportLevel,
            sortable: true
        },
        {
            name: 'Details',
            selector: row => row.details,
            sortable: true
        },
        {
            name: 'Date Created',
            selector: row => dayjs(row.dateCreated).format('DD/MM/YYYY HH:mm:ss'),
            sortable: true
        }


    ];

    const [days, setDays] = useState<string>("");
    const [editDays, setEditDays] = useState<string>("");
    const [editSupport, setEditSupport] = useState<string>("");
    const [support, setSuppoort] = useState<string>("");
    const [details, setDetails] = useState<string>("");
    const [addModal, setAddModal] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (uid) {
            dispatch(fetchClientDailyLiving(Number(uid)));
        }
    }, [dispatch, companyId, uid]);
    const clientDaily = useSelector(clientDailyLivingData);
    const loadingData = useSelector(clientDailyLivingLoading);

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = clientDaily.filter((item) =>
        item.activities.toLowerCase().includes(searchText.toLowerCase())
    );

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const dataOpt: DataOption[] = ['No help required', 'Aids used', 'Prompting required',
        'Some support required', 'Full physical support required'].map(
            item => ({ label: item, value: item })
        );

    const dataOpt1: DataOption[] = ['None of the time', 'All the time', 'Prompting required',
        'During active times'].map(
            item => ({ label: item, value: item })
        );

    const activity = useRef<HTMLInputElement>(null);
    const [saveId, setSaveId] = useState<number>(0)
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [dailyLivingDetails, setDailyLivingDetails] = useState<DataRow>(
        {
            activities: "",
            dailyLivingId: 0,
            dateCreated: "",
            dateModified: "",
            details: "",
            profile: {
                profileId: 0,
                firstName: "",
                surName: "",
                middleName: "",
                clientId: 0,
                email: "",
                phoneNumber: "",
                gender: "",
                fullName: "",
            },
            profileId: 0,
            support: "",
            supportLevel: "",
            userCreated: "",
            userModified: "",

        }
    )

    const handleSubmit = async () => {

        const info = {
            profileId: uid,
            activities: activity.current?.value,
            support: days,
            supportLevel: support,
            details: details,
        }
        try {
            setLoading(true)
            const { data } = await axiosInstance.post(`/DailyLivings`, info);

            if (data.status === "Success") {
                toast.success(data.message);
                setAddModal(false);
                uid && dispatch(fetchClientDailyLiving(Number(uid)));

            } else {
                toast.error(data.message);
            }
            setLoading(false)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false)
        } finally {
            setLoading(false)
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setDailyLivingDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const EditDetail = async (id: number) => {
        setSaveId(id);
        setShowModal(true);
        try {
            const { data } = await axiosInstance.get(`/DailyLivings/${id}`);
            setDailyLivingDetails({ ...data });
            setEditDays(data.support)
            setEditSupport(data.supportLevel)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
        }
    }

    const handleEdit = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        const info = {
            ...dailyLivingDetails,
            profileId: uid,
            support: editDays,
            supportLevel: editSupport,

        }
        try {
            const { data } = await axiosInstance.post(`/DailyLivings/edit/${saveId}`, info);
            toast.success(data.message)
            uid && dispatch(fetchClientDailyLiving(Number(uid)));
            setShowModal(false)
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }

    }

    const deleteSchedule = (id: number) => {
        setSaveId(id);
        setDeleteModal(true);
    }

    const handleDeleteSchedul = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data } = await axiosInstance.post(`/DailyLivings/delete/${saveId}`)
            toast.success(data.message);
            setDeleteModal(false);
            uid && dispatch(fetchClientDailyLiving(Number(uid)));
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
            setDeleteModal(false);
        }
        finally {
            setLoading(false);
        }
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Activities: </span>
                    <span> {data.activities}</span>
                </span>
                <span>
                    <span className='font-bold'>Date Created: </span>
                    <span>
                        {dayjs(data.dateCreated).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>
                <span>
                    <span className='font-bold'>Date Modified: </span>
                    <span>
                        {dayjs(data.dateModified).format('DD/MM/YYYY HH:mm:ss')}
                    </span>
                </span>
                <div className='flex gap-2'>
                    <span className='font-bold'>Actions: </span>
                    <button className="btn text-primary font-bold" style={{ fontSize: "12px" }}
                        onClick={() => EditDetail(data.dailyLivingId)}
                    >
                        Edit
                    </button>
                    |
                    <button className="btn text-red-500 font-bold" style={{ fontSize: "12px" }}
                        onClick={() => deleteSchedule(data.dailyLivingId)}
                    >

                        Delete
                    </button>
                </div>

            </div>
        )
    }





    return (
        <div className='mt-10'>
            {clientDaily.length <= 0 && loadingData && <Spinner color='#071952' />}
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                    <h2 className='font-bold text-xl text-black w-auto'>Daily Living</h2>

                    <div className="relative w-full lg:col-span-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BsSearch />
                        </div>
                        <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
                    </div>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                    <div className="flex items-center space-x-3 w-full md:w-auto">

                        {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                            <FaFilter />
                            Filter
                        </button> */}
                        <ExportDropdown >
                            <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <IconButton
                                    icon={<FaFilePdf className='text-red-500' />}
                                    title="Export as PDF"
                                    onClick={() => handlePDFDownload(clientDaily, columns as unknown as Column<DataRow>[], "ClientDailyLiving.pdf")}

                                />
                                <IconButton
                                    icon={<FaFileExcel className='text-green-600' />}
                                    title="Export as Excel Sheet"

                                    onClick={() => exportToExcel(clientDaily, columns as unknown as Column<DataRow>[], "ClientDailyLiving")}

                                />
                                <IconButton
                                    icon={<FaFileCsv className='text-green-500' />}
                                    title="Export as CSV"
                                    onClick={() => downloadCSV({ data: clientDaily, filename: 'ClientDailyLiving.csv' })}

                                />
                            </div>
                        </ExportDropdown>


                    </div>
                    <button type="button"
                        onClick={() => setAddModal(true)}
                        className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
    transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
   ">

                        Add New Daily Living
                    </button>

                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                expandableRows
                expandableRowsComponent={ExpandableRowComponent}
                pagination
                paginationTotalRows={clientDaily?.length}
            // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

            />

            <Modal open={addModal} onClose={() => setAddModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Add Daily Living</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <form action="" onSubmit={handleSubmit}>

                            <div className='space-y-2'>

                                <CustomInput
                                    label='Activities'
                                    type='text'
                                    ref={activity}
                                    required
                                />

                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">Support</label>
                                    <InputPicker size="lg" placeholder="Select"
                                        onChange={(e: string) => setDays(e)}
                                        value={days}
                                        data={dataOpt}
                                        className='w-full focus:outline-none focus:border-primary text-gray-600'
                                        appearance='subtle'
                                    />
                                </div>

                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">How often do you require supervision or support throughout the day?</label>
                                    <InputPicker size="lg" placeholder="Select "
                                        onChange={(e: string) => setSuppoort(e)}
                                        value={support}
                                        data={dataOpt1}
                                        className='w-full focus:outline-none focus:border-primary text-gray-600'
                                        appearance='subtle'
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="report" className='font-bold'>Details </label>
                                    <textarea id="report" rows={4}
                                        autoComplete="off"
                                        onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setDetails(e.target.value)}
                                        required
                                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" defaultValue={""} />
                                </div>
                            </div>

                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Add"
                                    }</button>

                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal
                open={showModal} onClose={() => setShowModal(false)}
                backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Edit Daily Living & Night Support</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>

                        <form action="" onSubmit={handleEdit}>

                            <div className='space-y-2'>

                                <CustomInput
                                    label='Activities'
                                    type='text'
                                    name='activities'
                                    value={dailyLivingDetails.activities || ""}
                                    onChange={handleInputChange}
                                    required
                                    placeholder="Activities"
                                />

                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">Support</label>
                                    <InputPicker size="lg" placeholder="Select"
                                        value={editDays}
                                        onChange={(value) => setEditDays(value)}
                                        data={dataOpt}
                                        className='w-full focus:outline-none focus:border-primary text-gray-600'
                                        appearance='subtle'
                                    />
                                </div>

                                <div>
                                    <label className=" text-xs text-gray-600 font-semibold">How often do you require supervision or support throughout the day?</label>
                                    <InputPicker size="lg" placeholder="Select"
                                        value={editSupport}
                                        onChange={(value) => setEditSupport(value)}
                                        data={dataOpt1}
                                        className='w-full focus:outline-none focus:border-primary text-gray-600'
                                        appearance='subtle'
                                    />
                                </div>

                                <div className="">
                                    <label htmlFor="details" className='font-bold'>Details </label>
                                    <textarea id="details" rows={4}
                                        autoComplete="off"
                                        name='details'
                                        value={dailyLivingDetails.details || ""}
                                        onChange={handleInputChange}
                                        required
                                        className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" defaultValue={""} />
                                </div>
                            </div>

                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    disabled={loading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Update"
                                    }</button>

                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal open={deleteModal} onClose={() => setDeleteModal(false)} role="alertdialog" backdrop="static" autoFocus size={"xs"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Delete Daily Living & Night Support</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className="mb-4 font-bold">Are you sure you want to delete this?</p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button onClick={() => setDeleteModal(false)} className='bg-gray-500 text-white'>
                                No, cancel
                            </Button>
                            <Button
                                onClick={handleDeleteSchedul}
                                className='bg-red-500 text-white hover:bg-red-700 hover:text-white'>
                                {
                                    loading ? <Spinner /> : "Yes, I'm sure"
                                }
                            </Button>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ClientDailyLiving