import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { IoIosArrowRoundBack } from 'react-icons/io'

interface Props {
    showNavigation?: boolean; // Optional, can be true or false
}

const Breadcrumb = ({ showNavigation = true }: Props) => { // Default set to true
    const location = useLocation();
    const navigate = useNavigate();

    const pathSegments = location.pathname.split('/').filter(segment => segment !== '');

    return (
        <div className='flex gap-2 items-center text-sm'>
            <button className="p-1 rounded bg-white border border-gray-200 transition duration-300 transform active:scale-95 ease-in-out"
                onClick={() => navigate(-1)}
            >
                <IoIosArrowRoundBack className='text-primary  text-xl' />
            </button>
            Go Back |
            {showNavigation && <>
                {pathSegments.map((segment, index) => (
                    <span key={index} className='hidden md:block'>
                        {index > 0 && ' / '}
                        {/* <Link className='capitalize' to={`/${pathSegments.slice(0, index + 1).join('/')}`}>{segment}</Link> */}
                        <span className='capitalize' >{segment}</span>
                    </span>
                ))}
            </>}
        </div>
    );
};

export default Breadcrumb;
