import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../Components/label/BreadCrumb";
import SEO from "../../../../constants/SEO";
import Card from "./components/Card";
import calendarIcon from "../../../../images/icon/calendar.png";
import { FaChevronLeft, FaChevronRight, FaPlus } from "react-icons/fa";
import { thisMonth, thisYear } from "../../../../services/reusableValue";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import {
  Shift,
  fetchShiftRoster,
  filterShiftRoster,
  shiftData,
  shiftLoading,
} from "../../../../store/slices/shiftRosterSlice";
import {
  AppDispatch,
  RootState,
  useAppDispatch,
} from "../../../../store/store";
import { useSelector } from "react-redux";
import Spinner from "../../../../Components/Spinner";
import { Loader } from "rsuite";
import MonthView from "./components/MonthView";
import WeekView from "./components/WeekView";
import { MdCalendarMonth, MdDeleteSweep, MdFilterList } from "react-icons/md";
import ShiftRosterModal from "./components/Modal";
import { fetchStaff, staffData } from "../../../../store/slices/staffSlice";
import { clientData, fetchClient } from "../../../../store/slices/clientSlice";
import { toast } from "react-toastify";
import axiosInstance from "../../../../store/axiosInstance";
import { isAxiosError } from "axios";
import LargeModal from "./components/LargeModal";
import EditRoster from "./components/EditRoster";
import { claimsTypes } from "../../../../types/AuthData";
import ButtonWithDetail from "./components/ButtonWithDetail";
import { useNavigate } from "react-router-dom";
import ViewRoster from "./components/ViewRoster";
import FilterLabel from "./components/filterLabel";

const ShiftRoster = () => {
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.tz.setDefault("Australia/Sydney");
  const [currentMonth, setCurrentMonth] = useState<number>(thisMonth);
  const [currentYear, setCurrentYear] = useState<number>(thisYear);
  const [currentDay, setCurrentDay] = useState<string>(
    dayjs().tz().format("ddd, MMM D, YYYY")
  );
  const user = useSelector((state: RootState) => state?.auth?.user);
  const today: string = dayjs().tz().format("ddd, MMM D, YYYY");
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const handleNext = () => {
    // Calculate the next month and year
    const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
    const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;

    // Update state to display the next month
    setCurrentMonth(nextMonth);
    setCurrentYear(nextYear);
  };

  const handleDayClick = (day: string) => {
    // Handle click event for each day
    setCurrentDay(day);
    // alert(day)
  };

  const handlePrevious = () => {
    // Calculate the previous month and year
    const previousMonth = currentMonth === 0 ? 11 : currentMonth - 1;
    const previousYear = currentMonth === 0 ? currentYear - 1 : currentYear;

    // Update state to display the previous month
    setCurrentMonth(previousMonth);
    setCurrentYear(previousYear);
  };

  // Get the first day of the month
  const firstDayOfMonth = dayjs().year(currentYear).month(currentMonth).date(1);

  // Get the weekday index of the first day of the month (0 for Sunday, 1 for Monday, etc.)
  const startDayOfWeek = firstDayOfMonth.day();

  // Get the number of days in the month
  const daysInMonth = firstDayOfMonth.daysInMonth();

  const daysArray: string[] = [];

  // Fill up empty spaces with faded dates for the previous month
  const previousMonthDays = firstDayOfMonth.subtract(startDayOfWeek, "day");
  for (let i = 0; i < startDayOfWeek; i++) {
    daysArray.push(previousMonthDays.add(i, "day").format("ddd, MMM D, YYYY"));
  }

  // Populate the days of the month
  for (let i = 1; i <= daysInMonth; i++) {
    daysArray.push(dayjs(firstDayOfMonth).date(i).format("ddd, MMM D, YYYY"));
  }

  // Fill up remaining days with faded dates for the next month
  const remainingDays =
    daysArray.length > 35 ? 42 - daysArray.length : 35 - daysArray.length;
  for (let i = 0; i < remainingDays; i++) {
    // Changed starting index to 0
    daysArray.push(
      firstDayOfMonth.add(daysInMonth + i, "day").format("ddd, MMM D, YYYY")
    );
  }

  const companyId = user?.companyId;

  const staff = useSelector(staffData);
  const clients = useSelector(clientData);
  const [dateFrom, setDateFrom] = useState<string>("");
  const [dateTo, setDateTo] = useState<string>("");
  const [users, setUsers] = useState<number>(0);
  const [client, setClient] = useState<number>(0);

  useEffect(() => {
    if (companyId) {
      dispatch(fetchStaff(companyId));
      dispatch(fetchClient(companyId));
    }

  }, [user?.companyId]);

  useEffect(() => {
    filterRoster();

  }, [users, client]);
  const filterRoster = () => {
    if (companyId) {
      dispatch(
        filterShiftRoster({
          companyId: companyId as number,
          dateFrom: dateFrom,
          dateTo: dateTo,
          staff: users,
          client: client,
        })
      );
    }
  };

  const shift = useSelector(shiftData);
  const loading = useSelector(shiftLoading);
  // const shift: Shift[] = shiftRes || [];


  // const [selectedClient, setSelectedClient] = useState<FormattedClient[]>([]);

  const activitiesForToday = Array.isArray(shift)
    ? shift.filter(
      (activity) =>
        dayjs(activity.dateFrom).format("ddd, MMM D, YYYY") === currentDay
    )
    : [];

  const activitiesByDay =
    daysArray.map((day) =>
      shift.filter(
        (activity) =>
          dayjs(activity.dateFrom).format("ddd, MMM D, YYYY") === day
      )
    ) || [];

  const [calendarView, setCalendarView] = useState<string>("month");
  const [showModal, setShowModal] = useState(false);
  // const [restModal, setRestModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
  // const [selectedDayShifts, setSelectedDayShifts] = useState<{
  //   shifts: Shift[];
  //   date: string;
  // }>({ shifts: [], date: "" });

  // const handleShowMoreClick = (dayShifts: Shift[], dayDate: string) => {
  //   setSelectedDayShifts({ shifts: dayShifts, date: dayDate });
  //   setRestModal(true);
  // };

  const [selectedActivity, setSelectedActivity] = useState<Shift>();
  const FillReport = (shiftId: number, id: number) => {
    navigate(`/app/reports/view-shift-report/${shiftId}/${id}`);
  };

  function getActivityStatus(activity: Shift) {
    const nowInAustraliaTime = dayjs().tz().format("YYYY-MM-DD HH:mm:ss");
    const activityDateFrom = dayjs(activity.dateFrom).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const activityDateTo = dayjs(activity.dateTo).format("YYYY-MM-DD HH:mm:ss");

    if (activityDateFrom > nowInAustraliaTime) {
      return "Upcoming";
    } else if (activityDateTo < nowInAustraliaTime) {
      return activity.attendance === true ? "Present" : "Absent";
    } else if (
      activityDateTo < nowInAustraliaTime ||
      activity.attendance === true
    ) {
      return "Present";
    } else if (
      activityDateTo < nowInAustraliaTime ||
      activity.attendance === false
    ) {
      return "Absent";
    } else if (activity.status === "Pending") {
      return "Pending";
    } else {
      return "Active";
    }
  }

  const handleShiftClick = (shift: Shift) => {
    setSelectedShift(shift);
    setShowModal(true);
  };

  const [editModal, setEditModal] = useState(false);
  const [editId, setEditId] = useState(0);

  const handleEdit = (shiftId: number) => {
    setEditId(shiftId);
    setEditModal(true);
  };

  //weekView...
  const [currentDate, setCurrentDate] = useState(dayjs().tz());
  const daysOfWeek = [
    currentDate.subtract(3, "day"),
    currentDate.subtract(2, "day"),
    currentDate.subtract(1, "day"),
    currentDate,
    currentDate.add(1, "day"),
    currentDate.add(2, "day"),
    currentDate.add(3, "day"),
  ];

  const handleNextClick = () => {
    setCurrentDate(currentDate.add(6, "day"));
  };

  const handlePrevClick = () => {
    setCurrentDate(currentDate.subtract(6, "day"));
  };
  const [loadingNotice, setLoadingNotice] = useState(false);



  const resetFilter = () => {
    setFilterModal(false);
    companyId && dispatch(fetchShiftRoster(companyId));
    setDateFrom("");
    setDateTo("");
    setUsers(0);
    setClient(0);
    setSelectedStaffName("");
    setSelectedClientName("");
  };

  const SendRosterNotice = async (): Promise<void> => {
    // Changed from Promise<undefined> to Promise<void>
    if (dateFrom.trim() === "" || dateTo.trim() === "") {
      toast.error("Select Start Date and End Date");
      return;
    } else {
      setLoadingNotice(true);

      try {
        const { data } = await axiosInstance.get(
          `/ShiftRosters/send_notification?userId=${user?.uid}&staffId=${users}&clientId=${client}&fromDate=${dateFrom}&toDate=${dateTo}`
        );
        toast.success(data.message);
        setLoadingNotice(false);
        setFilterModal(false);
      } catch (error) {
        toast.error("Ooops!😔 Error Occurred");
        console.error(error);
        setLoadingNotice(false);
      }
    }
  };

  const [deleteModal, setDeleteModal] = useState(false);
  const [delLoading, setDelLoading] = useState(false);
  const handleDelete = async (shiftId: number) => {
    setDelLoading(true);
    try {
      const { data } = await axiosInstance.post(
        `/ShiftRosters/delete/${shiftId}?userId=${user?.uid}`
      );
      if (data.status === "Success") {
        toast.success(data.message);
        setDelLoading(false);
        setDeleteModal(false);
        if (companyId) {
          dispatch(fetchShiftRoster(companyId));
        }
      } else {
        toast.error(data.message);
        setDelLoading(false);
        setDeleteModal(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("Error deleting shift");
      }
      setDeleteModal(false);
      setDelLoading(false);
    }
  };

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  const handleConfirm = async (shiftId: number) => {
    setLoadingConfirm(true);
    try {
      const response = await axiosInstance.get(
        `/ShiftRosters/cancel_shift?userId=${user?.uid}&shiftId=${shiftId}`
      );
      const data = response.data;
      toast.success(data.message);
      setLoadingConfirm(false);
      setShowConfirmModal(false);
      if (companyId) {
        dispatch(fetchShiftRoster(companyId));
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("Error deleting shift");
      }
      setLoadingConfirm(false);
      setShowConfirmModal(false);
    } finally {
      setLoadingConfirm(false);
      setShowConfirmModal(false);
    }
  };

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);

  const handleRejectCancelShift = async (shiftId: number) => {
    setLoadingReject(true);
    try {
      const data = await axiosInstance.get(
        `/ShiftRosters/reject_shift_cancellation?userId=${user?.uid}&shiftId=${shiftId}`
      );

      if (data.data.status === "Success") {
        toast.success(data.data.message);
        setLoadingReject(false);
        setShowRejectModal(false);
        if (companyId) {
          dispatch(fetchShiftRoster(companyId));
        }
      } else {
        toast.error(data.data.message);
        setLoadingReject(false);
        setShowRejectModal(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("Error deleting shift");
      }
      setLoadingReject(false);
      setShowRejectModal(false);
    } finally {
      setLoadingReject(false);
      setShowRejectModal(false);
    }
  };
  const [markAttendanceModal, setMarkAttendanceModal] = useState(false);
  const [reAssignModal, setReAssignModal] = useState(false);
  const [cancelShiftModal, setCancelShiftModal] = useState(false);

  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [moveModal, setMoveModal] = useState(false);
  const [loadingMove, setLoadingMove] = useState(false);
  const [repeatModal, setRepeatModal] = useState(false);

  const handleMarkAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setMarkAttendanceModal(true);
  };
  const handleDeleteAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setDeleteModal(true);
  };
  const handleUnmarkAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setUnMarkModal(true);
  };
  const handleConfirmAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setShowConfirmModal(true);
  };
  const handleRejectAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setShowRejectModal(true);
  };
  const handleReAssignAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setReAssignModal(true);
  };
  const handleCancelClientAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setCancelShiftModal(true);
  };
  const handleDuplicateAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setDuplicateModal(true);
    setDuplicateDate("");
  };
  const handleMoveAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setMoveModal(true);
    setDuplicateDate("");
  };
  const handleRepeatAttendance = (activity: Shift) => {
    setSelectedActivity(activity);
    setRepeatModal(true);
  };

  //Duplicate
  //Move
  //Repeat.
  const [duplicateDate, setDuplicateDate] = useState("");

  const duplicateAttendance = async (shiftId: number) => {
    setLoadingDuplicate(true);
    try {
      const response = await axiosInstance.post(
        `/ShiftRosters/duplicate_shift?userId=${user?.uid}&shiftId=${shiftId}&date=${duplicateDate}`
      );
      const data = response.data;
      toast.success(data.message);
      setLoadingDuplicate(false);
      setDuplicateModal(false);
      setDuplicateDate("");
      if (companyId) {
        dispatch(fetchShiftRoster(companyId));
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("Error duplicating shift");
      }
      setLoadingDuplicate(false);
    } finally {
      setLoadingDuplicate(false);
    }
  };
  const moveAttendance = async (shiftId: number) => {
    setLoadingMove(true);
    try {
      const response = await axiosInstance.post(
        `/ShiftRosters/move_shift?userId=${user?.uid}&shiftId=${shiftId}&date=${duplicateDate}`
      );
      const data = response.data;
      toast.success(data.message);
      setLoadingMove(false);
      setMoveModal(false);
      setDuplicateDate("");
      if (companyId) {
        dispatch(fetchShiftRoster(companyId));
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("Error moving shift");
      }
      setLoadingMove(false);
    } finally {
      setLoadingMove(false);
    }
  };

  const [unMarkModal, setUnMarkModal] = useState(false);
  const [loadingUnMark, setLoadingUnmark] = useState(false);

  const unMarkAttendance = async (shiftId: number) => {
    setLoadingUnmark(true);

    try {
      const data = await axiosInstance.get(
        `/Attendances/unmark_attendance?userId=${user?.uid}&shiftId=${shiftId}`
      );
      if (data.data.status === "Success") {
        toast.success(data.data.message);
        if (companyId) {
          dispatch(fetchShiftRoster(companyId));
        }
        setLoadingUnmark(false);
        setUnMarkModal(false);
      } else {
        toast.error(data.data.message);
        setLoadingUnmark(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("Error revoking shift");
      }
      setLoadingUnmark(false);
    }
  };

  const [dateFro, setDatefrom] = useState("");
  const handleDayShow = (day: string) => {
    // Handle click event for each day
    const date = dayjs(day, "ddd, MMM D, YYYY");

    // Format the date to 'YYYY-MM-DDTHH:mm:ss' (ISO 8601 format suitable for datetime-local input)
    // Assuming the time part is at 00:00:00 (midnight) if not specified
    // alert(date.format('YYYY-MM-DDT00:00:00'));
    setDatefrom(date.format("YYYY-MM-DDT00:00:00"));
    setAddModal(true);
  };

  const handleAddShift = () => {
    setDatefrom("");
    setAddModal(true);
  };

  const staffClaimsString = sessionStorage.getItem("claims");
  const claims: claimsTypes[] = staffClaimsString
    ? JSON.parse(staffClaimsString)
    : [];
  const hasRequiredClaims = (claimType: string): boolean => {
    return claims.some((claim) => claim.value === claimType);
  };

  const [multiple, setMultiple] = useState(false);

  const [selectedShifts, setSelectedShifts] = useState<number[]>([]);

  const handleSelectShift = (shiftId: number, isSelected: boolean) => {
    setSelectedShifts((prevSelected) =>
      isSelected
        ? [...prevSelected, shiftId]
        : prevSelected.filter((id) => id !== shiftId)
    );
  };
  const [multipleModal, setMultipleModal] = useState(false);
  const [loadingMultiple, setLoadingMultiple] = useState(false);

  const handleMultipleDelete = async () => {
    setLoadingMultiple(true);
    try {
      const data = await axiosInstance.get(
        `/ShiftRosters/delete_multiple_shift?userId=${user?.uid
        }&values=${selectedShifts.join(", ")}`
      );
      if (data.data.status === "Success") {
        toast.success(data.data.message);
        if (companyId) {
          dispatch(fetchShiftRoster(companyId));
        }
        setLoadingMultiple(false);
        setMultipleModal(false);
        setMultiple(false);
        setSelectedShifts([]);
      } else {
        toast.error(data.data.message);
        setLoadingMultiple(false);
      }
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("Error deleting multiple shift");
      }
      setLoadingMultiple(false);
    }
  };
  const [selectedStaffName, setSelectedStaffName] = useState("");
  const [selectedClientName, setSelectedClientName] = useState("");
  const handleStaffChange = (staffId: number) => {
    setUsers(staffId);
    const staffName =
      staff.find((user) => user.staffId === staffId)?.fullName || "";
    setSelectedStaffName(staffName);
  };

  // Handler to update the selected client name
  const handleClientChange = (clientId: number) => {
    setClient(clientId);
    const clientName =
      clients.find((user) => user.profileId === clientId)?.fullName || "";
    setSelectedClientName(clientName);
  };

  return (
    <>
      <SEO title="Shift Roster" description="Shift Roster" name="Promax-Care" />
      <Breadcrumb />

      <div className="mt-5">
        <div className="flex justify-between items-center flex-wrap">
          <h2 className="font-bold text-2xl text-black w-auto">Shift Roster</h2>
          {user?.role === "CompanyAdmin" ||
            user?.role === "Administrator" ||
            hasRequiredClaims("Add Shift Roster") ? (
            <div>
              <button
                type="button"
                onClick={handleAddShift}
                className="py-2 px-2 bg-primary text-white  disabled:bg-slate-400 inline-flex gap-2 items-center
                 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80  rounded font-normal
                "
              >
                <small className="bg-secondary p-1 rounded">
                  <FaPlus />
                </small>
                ADD NEW SHIFT
              </button>
            </div>
          ) : (
            ""
          )}
        </div>

        <div className="my-2 py-2 flex items-center gap-3 ">
          {selectedStaffName && (
            <FilterLabel
              content={selectedStaffName}
              handleClear={() => {
                setUsers(0);
                setSelectedStaffName("");
              }}
            />
          )}
          {selectedClientName && (
            <FilterLabel
              content={selectedClientName}
              handleClear={() => {
                setClient(0);
                setSelectedClientName("");
              }}
            />
          )}
        </div>
        <div className="my-2">
          {shift.length <= 0 && loading && (
            <div className="bg-white p-2">
              <Spinner color="#071952" />
              <p className="text-center"> Loading Shifts</p>
            </div>
          )}
        </div>

        <div className="mt-2  pb-10 grid grid-cols-1 gap-2 xl:grid-cols-5 ">
          {calendarView === "month" && (
            <div className="border-2 rounded-lg bg-white order-last xl:order-first  ">
              <div className="bg-gray-100 text-center p-1 flex justify-between items-center text-xs font-bold text-black rounded-t-lg ">
                <span>
                  {" "}
                  {currentDay.split(", ")[0]}, {currentDay.split(", ")[1]}
                </span>{" "}
                <span
                  className={`rounded p-1 text-xs text-black bg-green-100 !capitalize ${currentDay
                    ? "border bg-secondary text-primary py-1 px-2 rounded"
                    : ""
                    }`}
                >
                  No of shifts ({activitiesForToday.length})
                </span>
              </div>
              <div className="flex gap-2 flex-col   p-1 h-[120vh] overflow-y-auto">
                {activitiesForToday.length > 0 ? (
                  activitiesForToday.map((d) => (
                    <Card
                      key={d.shiftRosterId}
                      activity={d}
                      timeFrom={d.dateFrom}
                      timeTo={d.dateTo}
                      staff={d.staff.fullName}
                      client={d.clients}
                      // task={d.activities}
                      onEdit={() => handleEdit(d.shiftRosterId)}
                      getActivityStatus={getActivityStatus}
                      handleMarkAttendance={handleMarkAttendance}
                      handleDeleteAttendance={handleDeleteAttendance}
                      handleUnmarkAttendance={handleUnmarkAttendance}
                      handleConfirmAttendance={handleConfirmAttendance}
                      handleRejectAttendance={handleRejectAttendance}
                      handleCancelClientAttendance={
                        handleCancelClientAttendance
                      }
                      handleReAssignAttendance={handleReAssignAttendance}
                      handleDuplicateAttendance={handleDuplicateAttendance}
                      handleMoveAttendance={handleMoveAttendance}
                      hasRequiredClaims={hasRequiredClaims}
                      isSelected={selectedShifts.includes(d.shiftRosterId)}
                      onSelectShift={handleSelectShift}
                      multiple={multiple}
                      handleRepeatAttendance={handleRepeatAttendance}
                      handleShiftClick={handleShiftClick}
                    />
                  ))
                ) : (
                  <div className="flex flex-col gap-2 items-center justify-center mx-auto pt-20">
                    <img src={calendarIcon} alt="calendar icon" width={100} />

                    <strong className="text-center">
                      No Shift Available for{" "}
                      <span className="text-secondary">{currentDay}</span> Click
                      on other days to view the list..{" "}
                    </strong>
                  </div>
                )}
              </div>
            </div>
          )}
          <div
            className={`border-2 rounded-lg ${calendarView === "month" ? "lg:col-span-4" : "lg:col-span-5"
              }  `}
          >
            <div className="bg-white flex justify-between items-center flex-wrap p-2 ">
              <div className=" text-center flex gap-4 items-center text-sm font-bold text-black rounded-t-lg flex-wrap">
                <span
                  className={`md:hidden rounded py-1 px-2 font-normal text-black border border-gray-200 inline-flex gap-1 items-center ${calendarView === "month" && "bg-gray-200"
                    } !capitalize cursor-pointer`}
                  onClick={() => setCalendarView("month")}
                >
                  <MdCalendarMonth /> Day
                </span>
                <span
                  className={`hidden rounded py-1 px-2 font-normal text-black border border-gray-200 md:inline-flex gap-1 items-center ${calendarView === "month" && "bg-gray-200"
                    } !capitalize cursor-pointer`}
                  onClick={() => setCalendarView("month")}
                >
                  <MdCalendarMonth /> Month
                </span>
                <span
                  className={`rounded py-1 px-2 font-normal text-black border border-gray-200 inline-flex gap-1 items-center ${calendarView === "week" && "bg-gray-200"
                    } !capitalize cursor-pointer`}
                  onClick={() => setCalendarView("week")}
                >
                  <MdCalendarMonth /> Week
                </span>
                {calendarView === "month" && (
                  <span className="border  flex justify-between items-center bg-gray-100 rounded">
                    <button
                      onClick={handlePrevious}
                      className="py-2 px-1 bg-gray-100 text-gray-400"
                    >
                      <FaChevronLeft />
                    </button>
                    {dayjs().month(currentMonth).format("MMMM")} {currentYear}
                    <button
                      onClick={handleNext}
                      className="py-2 px-1 bg-gray-100 text-gray-400"
                    >
                      <FaChevronRight />
                    </button>
                  </span>
                )}
                {calendarView === "week" && (
                  <span className="border  flex justify-between gap-3 items-center bg-gray-100 rounded">
                    <button
                      onClick={handlePrevClick}
                      className="py-2 px-1 bg-gray-100 text-gray-400"
                    >
                      <FaChevronLeft />
                    </button>
                    {currentDate.format("MMM YYYY")}
                    <button
                      onClick={handleNextClick}
                      className="py-2 px-1 bg-gray-100 text-gray-400"
                    >
                      <FaChevronRight />
                    </button>
                  </span>
                )}
                <span
                  className={`rounded py-2 bg-primary px-2 text-xs font-normal text-white border border-gray-200 inline-flex gap-1 items-center  !capitalize cursor-pointer`}
                  onClick={() => setFilterModal(true)}
                >
                  <MdFilterList /> Filter | Send Notification
                </span>
                {loading && <Loader size="md" />}
              </div>

              <div className="flex gap-2 items-center">
                {selectedShifts.length > 0 && (
                  <button
                    onClick={() => {
                      setSelectedShifts([]);
                      setMultiple(false);
                    }}
                    className="rounded py-2 bg-secondary px-2 font-semibold text-gray-700 text-xs inline-flex gap-1 items-center  !capitalize cursor-pointer"
                  >
                    Clear Selected
                  </button>
                )}
                {selectedShifts.length <= 0 && (
                  <ButtonWithDetail
                    title="NB: Multiple Delete only applies to upcoming & unattended shift"
                    buttonContent={
                      <span
                        className={`rounded py-1.5 bg-red-700 px-2 font-normal text-white border border-gray-200 text-xs inline-flex gap-1 items-center  !capitalize cursor-pointer`}
                        onClick={() => {
                          setMultiple(!multiple);
                          setCalendarView("week");
                        }}
                      >
                        {!multiple ? " Delete Multiple" : "Close"}
                      </span>
                    }
                  />
                )}
                {multiple && selectedShifts.length > 0 && (
                  <span
                    className={`rounded py-2 bg-red-700 px-2 font-normal text-white   inline-flex gap-1 items-center  !capitalize cursor-pointer relative`}
                    onClick={() => setMultipleModal(true)}
                  >
                    {selectedShifts.length > 0 && (
                      <small className="absolute -top-1 -right-1 p-2 text-xs text-black rounded-full w-2 h-2 justify-center flex items-center bg-secondary">
                        {selectedShifts.length}
                      </small>
                    )}
                    <MdDeleteSweep /> Delete Selected
                  </span>
                )}
              </div>
            </div>
            {calendarView === "month" && (
              <MonthView
                today={today}
                currentDay={currentDay}
                activitiesByDay={activitiesByDay}
                currentMonth={currentMonth}
                daysArray={daysArray}
                handleShiftClick={handleShiftClick}
                // handleShowMoreClick={handleShowMoreClick}
                setAddModal={setAddModal}
                handleDayClick={handleDayClick}
                handleDayShow={handleDayShow}
                getActivityStatus={getActivityStatus}
                duplicateAttendance={duplicateAttendance}
                duplicateDate={duplicateDate}
                setDuplicateDate={setDuplicateDate}
              />
            )}
            {calendarView === "week" && (
              <WeekView
                shift={shift}
                getActivityStatus={getActivityStatus}
                today={today}
                daysOfWeek={daysOfWeek}
                handleMarkAttendance={handleMarkAttendance}
                handleDeleteAttendance={handleDeleteAttendance}
                handleUnmarkAttendance={handleUnmarkAttendance}
                handleConfirmAttendance={handleConfirmAttendance}
                handleRejectAttendance={handleRejectAttendance}
                handleCancelClientAttendance={handleCancelClientAttendance}
                handleReAssignAttendance={handleReAssignAttendance}
                handleDuplicateAttendance={handleDuplicateAttendance}
                handleMoveAttendance={handleMoveAttendance}
                handleEdit={handleEdit}
                hasRequiredClaims={hasRequiredClaims}
                selectedShifts={selectedShifts}
                onSelectShift={handleSelectShift}
                multiple={multiple}
                handleRepeatAttendance={handleRepeatAttendance}
                handleShiftClick={handleShiftClick}
              />
            )}
          </div>
        </div>
      </div>

      <ViewRoster
        FillReport={FillReport}
        selectedShift={selectedShift}
        setShowModal={setShowModal}
        showModal={showModal}
      />

      <LargeModal
        addModal={addModal}
        setAddModal={setAddModal}
        markAttendanceModal={markAttendanceModal}
        setMarkAttendanceModal={setMarkAttendanceModal}
        reAssignModal={reAssignModal}
        setReAssignModal={setReAssignModal}
        cancelShiftModal={cancelShiftModal}
        setCancelShiftModal={setCancelShiftModal}
        activity={selectedActivity}
        deleteModal={deleteModal}
        handleDelete={handleDelete}
        setDeleteModal={setDeleteModal}
        delLoading={delLoading}
        loadingUnMark={loadingUnMark}
        setUnMarkModal={setUnMarkModal}
        unMarkAttendance={unMarkAttendance}
        unMarkModal={unMarkModal}
        handleConfirm={handleConfirm}
        loadingConfirm={loadingConfirm}
        setShowConfirmModal={setShowConfirmModal}
        showConfirmModal={showConfirmModal}
        showRejectModal={showRejectModal}
        setShowRejectModal={setShowRejectModal}
        loadingReject={loadingReject}
        handleRejectCancelShift={handleRejectCancelShift}
        duplicateAttendance={duplicateAttendance}
        duplicateDate={duplicateDate}
        duplicateModal={duplicateModal}
        loadingDuplicate={loadingDuplicate}
        loadingMove={loadingMove}
        moveAttendance={moveAttendance}
        moveModal={moveModal}
        setDuplicateDate={setDuplicateDate}
        setDuplicateModal={setDuplicateModal}
        setMoveModal={setMoveModal}
        setDatefrom={setDatefrom}
        dateFrom={dateFro}
        handleMultipleDelete={handleMultipleDelete}
        loadingMultiple={loadingMultiple}
        multipleModal={multipleModal}
        selectedShifts={selectedShifts}
        setMultipleModal={setMultipleModal}
        repeatModal={repeatModal}
        setRepeatModal={setRepeatModal}
        setSelectedStaff={handleStaffChange}
        selectedStaff={users}
        filterRoster={filterRoster}
      />

      <ShiftRosterModal
        filterModal={filterModal}
        setFilterModal={setFilterModal}
        clients={clients}
        dateFrom={dateFrom}
        setDateFrom={setDateFrom}
        dateTo={dateTo}
        setDateTo={setDateTo}
        setClient={handleClientChange}
        setUsers={handleStaffChange}
        staff={staff}
        SendRosterNotice={SendRosterNotice}
        loadingNotice={loadingNotice}
        users={users}
        client={client}
        resetFilter={resetFilter}
      />

      <EditRoster
        editId={editId}
        editModal={editModal}
        setEditModal={setEditModal}
      />
    </>
  );
};

export default ShiftRoster;
