import React, { ReactNode, useState } from 'react';

interface SidebarLinkGroupProps {
  children: (handleClick: () => void, subOpen: boolean) => ReactNode;
}

const SidebarLinkGroup = ({ children }: SidebarLinkGroupProps) => {
  const [subOpen, setSubOpen] = useState(false);

  const handleClick = () => {
    setSubOpen(!subOpen);
  };

  return <li>{children(handleClick, subOpen)}</li>;
};

export default SidebarLinkGroup;
