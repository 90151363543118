import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import api from '../api';
import { RootState } from '../store';
import { isAxiosError } from 'axios';

// Define a type for the staff profile
export type StaffProfile = {
    staffId: number;
    maxStaffId: string;
    firstName: string;
    surName: string;
    middleName: string;
    aboutMe: string;
    address: string;
    postcode: string | null;
    employeeId: string | null;
    email: string;
    phoneNumber: string;
    profession: string | null;
    gender: string;
    imageUrl: string;
    imageFile: string | null;
    country: string | null;
    state: string | null;
    city: string | null;
    isCompleted: boolean;
    isActive: boolean;
    auditApproved: boolean;
    dateOfBirth: string; // Use appropriate Date type or string
    facebook: string | null;
    twitter: string | null;
    instagram: string | null;
    youtube: string | null;
    linkedIn: string | null;
    github: string | null;
    employmentType: string | null;
    taxFile: string | null;
    position: string | null;
    level: string;
    australianCitizen: boolean | null;
    dateJoined: string | null; // Use appropriate Date type or string
    salary: number;
    payDay: number;
    payRate: number | null;
    bankName: string | null;
    bsb: string | null;
    branch: string | null;
    accountName: string | null;
    accountNumber: string | null;
    nextOfKin: string;
    relationship: string;
    kinPostcode: string | null;
    kinAddress: string;
    kinCountry: string;
    kinCity: string | null;
    kinEmail: string | null;
    suburb: string | null;
    kinState: string;
    kinPhoneNumber: string | null;
    isDocumentUploaded: boolean;
    isAdmin: boolean;
    adm_DesignationsId: number | null;
    adm_Designations: string | null;
    offerLetter: string | null;
    handbook: string | null;
    superForm: string | null;
    supportPosition: string | null;
    companyId: number;
    company: string | null;
    xerolink: string;
    fullName: string;
    dateCreated: string; // Use appropriate Date type or string
    userCreated: string | null;
    dateModified: string; // Use appropriate Date type or string
    userModified: string | null;
    signatureUrl: string | null,
    signatureFile: string | null,
};

// Define the types for your slice state
export interface StaffProfileInterface {
    data: StaffProfile | null;
    isLoading: boolean;
    error: string | null;
}

// Define the initial state
const initialState: StaffProfileInterface = {
    data: null,
    isLoading: false,
    error: null,
};

// Define the asynchronous thunk action
export const fetchStaffProfile = createAsyncThunk<StaffProfile, string>(
    'profile/fetchProfile',
    async (id: string) => {
        try {
            const response = await api.fetchStaffInfo(id);
            return response; // Assuming the API returns the data directly
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
);

// Create the profile slice
const profileSlice = createSlice({
    name: 'profile',
    initialState,
    reducers: {
        // Define any specific actions related to the profile (if needed)
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchStaffProfile.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchStaffProfile.fulfilled, (state, action) => {
                state.isLoading = false;
                state.data = action.payload;
                state.error = null;
            })
            .addCase(fetchStaffProfile.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload as string; // Ensure error is processed as a string
            });
    },
});

export const profileActions = profileSlice.actions;
export default profileSlice.reducer;

// Define selectors to access the profile slice state
export const selectProfileData = (state: RootState) => state.staffProfile.data;
export const selectProfileLoading = (state: RootState) => state.staffProfile.isLoading;
export const selectProfileError = (state: RootState) => state.staffProfile.error;
