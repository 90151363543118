import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../store/store';
import axiosInstance from '../../../store/axiosInstance';
import { toast } from 'react-toastify';
import { isAxiosError } from 'axios';
import { Modal } from 'rsuite';
import Spinner from '../../../Components/Spinner';

const ResponsePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const user = useSelector((state: RootState) => state?.auth?.user);

    const [loading, setLoading] = useState<boolean>(true);
    const [modal, setModal] = useState<boolean>(false)
    const [downloadModal, setDownloadModal] = useState<boolean>(false)
    const [saveLink, setSaveLink] = useState<string>("");

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('code');
        const state = searchParams.get('state');

        // Handle the code and state as needed
        const handleResponse = async () => {
            setModal(true)
            setLoading(true)
            try {
                const { data } = await axiosInstance.get(`/SetUp/xero_auth?userId=${user?.uid}&code=${code}&state=${state}`);
                if (data.status === "Success") {
                    toast.success(data.message)
                    setLoading(false)
                    setModal(false)
                    navigate(`/app/dashboard`);

                } else if (data.status === "Warning") {
                    setDownloadModal(true)
                    toast.warning(data.message);
                    setSaveLink(data.link);
                    // data.link
                }
                else {
                    toast.error(data.message);
                    setLoading(false);
                    setModal(false);
                    navigate(`/app/dashboard`);

                }
            }
            catch (error: unknown) {
                if (isAxiosError(error)) {
                    toast.error(error.response?.data?.message);
                    toast.error(error.response?.data?.title);
                    // throw error;
                } else {
                    toast.error("Error Occured");
                }

                setLoading(false)
                setModal(false);
                navigate(`/app/dashboard`);
            }
            finally {
                setLoading(false); // Stop loading after response
                setModal(false);

            }
        }

        // Redirect to another page after handling the response URL
        handleResponse();
    }, [history]);


    return (
        <>
            {/* {saveLink && <a href={saveLink} download={true} >Click to Download</a>} */}
            <Modal open={modal}
                size={"sm"}
            // onHide={() => setModal(false)}
            >
                <Modal.Header>
                    <Modal.Title>Creating Xero Connection...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        {loading && <div>
                            <button
                                type='button'
                                disabled={loading}
                                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                    <div className='flex gap-2'>
                                        <Spinner /> Please wait...This might take a while..
                                    </div>
                                }
                            </button>
                        </div>}
                    </div>
                </Modal.Body>

            </Modal>

            <Modal open={downloadModal}
                size={"sm"}
            // onHide={() => setDownloadModal(false)}
            >
                <Modal.Header>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='flex flex-wrap gap-2'>
                        <a href={saveLink} download={true}>
                            <button
                                type="button"
                                className="text-white bg-gray-500 hover:bg-gray-500 focus:ring-4 focus:ring-gray-500 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-gray-500 dark:hover:bg-gray-500 focus:outline-none
dark:focus:ring-primary">
                                Click to Download
                            </button>
                        </a>

                        <a href="/app/dashboard">
                            <button
                                type="button"
                                className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-bold rounded-md text-md px-6 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">
                                Home
                            </button>
                        </a>
                    </div>
                </Modal.Body>

            </Modal>
        </>
    )
}

export default ResponsePage