import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface StaffAtendance {
    attendanceId: number;
    clockIn: string;
    duration: number;
    clockOut: string;
    dateCreated: string;
    startKm: number;
    endKm: number;
    report: string;
    imageUrl: string;
    staff: {
        staffId: number;
        maxStaffId: string;
        firstName: string;
        surName: string;
        middleName: string;
        fullName: string;
        dateCreated: string;
        userCreated: string;
        dateModified: string;
        userModified: string;
    };
}




export interface StaffAttendanceState {
    loading: boolean;
    data: Array<StaffAtendance>;
    error: string | undefined;
}
const initialState: StaffAttendanceState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchStaffAttendance = createAsyncThunk(
    "StaffAtendance/fetchStaffAttendance",
    async (uid: number) => {
        try {
            const response = await api.fetchStaffAttendance(uid);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const staffAttendanceSlice = createSlice({
    name: 'staffAttendance',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStaffAttendance.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchStaffAttendance.fulfilled, (state, action: PayloadAction<Array<StaffAtendance>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchStaffAttendance.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.staffAttendance;
export default staffAttendanceSlice.reducer;



// Define selectors to access the profile slice state
export const staffAttendanceData = (state: RootState) => state.staffAttendance.data;
export const staffAttendanceLoading = (state: RootState) => state.staffAttendance.loading;
export const staffAttendanceError = (state: RootState) => state.staffAttendance.error;