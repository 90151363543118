import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
// import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { FaFileCsv, FaFileExcel, FaFilePdf, FaRegEdit } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
// import DropdownMenu from '../../../Components/menu/DropdownMenu';
import { toast } from "react-toastify";
import { isAxiosError } from 'axios';
import { Button, Modal, Tooltip, Whisper } from 'rsuite';
// import CustomInput from '../../../Components/Input/CustomInput';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import { fetchTime, timeData, timeLoading } from '../../../store/slices/timeSlice';
import dayjs from 'dayjs';
import { FaRegTrashAlt } from 'react-icons/fa';
import axiosInstance from '../../../store/axiosInstance';
import Spinner from '../../../Components/Spinner';
import TimePeriodModals from '../../../Components/Modals/TimePeriodModals';
import CustomInput from '../../../Components/Input/CustomInput';
import IconButton from '../../../Components/Buttons/IconButton';
import { Column } from '../../../types/DataType';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';

interface DataRow {
    company: string;
    companyId: number;
    dateCreated: string;
    dateModified: string;
    eveningEndTimePeriod: string;
    eveningStartTimePeriod: string;
    normalEndTimePeriod: string;
    normalStartTimePeriod: string;
    sleepoverEndTimePeriod: string;
    sleepoverStartTimePeriod: string;
    timePeriodsId: string;
    userCreated: string;
    userModified: string;
}

const TimePeriod = () => {

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'Normal StartTime',
            selector: row => dayjs(row.normalStartTimePeriod).format('h:mm:ss A'),
        },
        {
            name: 'Normal EndTime',
            selector: row => dayjs(row.normalEndTimePeriod).format('h:mm:ss A'),
        },
        {
            name: 'Evening StartTime',
            selector: row => dayjs(row.eveningStartTimePeriod).format('h:mm:ss A'),
        },
        {
            name: 'Evening EndTime',
            selector: row => dayjs(row.eveningEndTimePeriod).format('h:mm:ss A'),
        },
        {
            name: 'Sleepover StartTime',
            selector: row => dayjs(row.sleepoverStartTimePeriod).format('h:mm:ss A'),
        },
        {
            name: 'Sleepover StartEnd',
            selector: row => dayjs(row.sleepoverEndTimePeriod).format('h:mm:ss A'),
        },

        {
            name: "Actions",
            cell: (row: DataRow) => (
                <div className="flex gap-4  justify-center " >

                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                        <Tooltip>
                            Edit Time Period
                        </Tooltip>
                    }>
                        <button onClick={() => EditTimePeriod(row.timePeriodsId)} className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                            <FaRegEdit className='text-base' />
                        </button>

                    </Whisper>
                    <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                        <Tooltip>
                            Delete Time Period
                        </Tooltip>
                    }>
                        <button onClick={() => delTimePeriod(row.timePeriodsId)} className='flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline'>
                            <FaRegTrashAlt className='text-base text-red-500' />
                        </button>

                    </Whisper>


                </div>

            ),
        },

    ];
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    // const [addInterModal, setAddInterModal] = useState<boolean>(false);
    const [editTimeModal, setEditTimeModal] = useState<boolean>(false);
    const [saveId, setSaveId] = useState<string>("")
    const [loading, setLoading] = useState<boolean>(false);
    const [modal1, setModal1] = useState<boolean>(false);
    const [modal2, setModal2] = useState<boolean>(false);
    const [modal3, setModal3] = useState<boolean>(false);
    const [modal4, setModal4] = useState<boolean>(false);
    const [modal5, setModal5] = useState<boolean>(false);
    const [modal6, setModal6] = useState<boolean>(false);
    const [modal7, setModal7] = useState<boolean>(false);
    const startTime = useRef<HTMLInputElement>(null);
    const endTime = useRef<HTMLInputElement>(null);
    const eveningStart = useRef<HTMLInputElement>(null);
    const eveningEnd = useRef<HTMLInputElement>(null);
    const sleepOverStart = useRef<HTMLInputElement>(null);
    const sleepOverEnd = useRef<HTMLInputElement>(null);
    const [timeDetails, setTimeDetails] = useState<DataRow>({
        company: "",
        companyId: 0,
        dateCreated: "",
        dateModified: "",
        eveningEndTimePeriod: "",
        eveningStartTimePeriod: "",
        normalEndTimePeriod: "",
        normalStartTimePeriod: "",
        sleepoverEndTimePeriod: "",
        sleepoverStartTimePeriod: "",
        timePeriodsId: "",
        userCreated: "",
        userModified: "",
    })

    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setTimeDetails((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const delTimePeriod = (id: string) => {
        setSaveId(id);
        setDeleteModal(true);
    }

    const EditTimePeriod = async (id: string) => {
        setSaveId(id);
        setEditTimeModal(true);
        try {
            const { data } = await axiosInstance.get(`/TimePeriods/get_timeperiod_details/${id}`);
            setTimeDetails({ ...data });

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);

        } finally {
            setLoading(false);
        }
    }
    const [addTimeModal, setAddTimeModal] = useState<boolean>(false);

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchTime(companyId));
        }
    }, [dispatch, companyId]);
    const time = useSelector(timeData);
    const loadingData = useSelector(timeLoading);

    const handleDeleteTimePeriod = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true)
        try {
            const { data } = await axiosInstance.post(`/TimePeriods/delete/${saveId}?userId=${user?.uid}`)
            toast.success(data.message);
            setDeleteModal(false);
            companyId && dispatch(fetchTime(companyId));
            setLoading(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }
        finally {
            setLoading(false);
        }
    }


    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <span>
                    <span className='font-bold'>Normal Start Time Period: </span>
                    <span> {dayjs(data.normalStartTimePeriod).format('h:mm:ss A')}</span>
                </span>
                <span>
                    <span className='font-bold'>Normal End Time Period: </span>
                    <span> {dayjs(data.normalEndTimePeriod).format('h:mm:ss A')}</span>
                </span>
                <span>
                    <span className='font-bold'>Evening Start Time Period: </span>
                    <span> {dayjs(data.eveningStartTimePeriod).format('h:mm:ss A')}</span>
                </span>
                <span>
                    <span className='font-bold'>Evening EndTime Period: </span>
                    <span> {dayjs(data.eveningEndTimePeriod).format('h:mm:ss A')}</span>
                </span>
                <span>
                    <span className='font-bold'>Sleepover StartTime Period: </span>
                    <span> {dayjs(data.sleepoverStartTimePeriod).format('h:mm:ss A')}</span>
                </span>
                <span>
                    <span className='font-bold'>Sleepover End Time Period: </span>
                    <span> {dayjs(data.sleepoverEndTimePeriod).format('h:mm:ss A')}</span>
                </span>

            </div>
        )
    }


    const HandleEdit = async (e: FormEvent) => {
        e.preventDefault();

        setLoading(true)

        const info = {
            // timePeriodsId: saveId,
            ...timeDetails,
            companyId: companyId

        }
        try {
            const { data } = await axiosInstance.post(`/TimePeriods/edit/${saveId}?userId=${user?.uid}`, info);
            toast.success(data.message)
            companyId && dispatch(fetchTime(companyId));
            setEditTimeModal(false)
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false);
        }

    }

    const PostAvail = async (e: FormEvent) => {
        e.preventDefault()
        if (startTime.current?.value === "" || endTime.current?.value === "" ||
            eveningStart.current?.value === "" || eveningEnd.current?.value === "" ||
            sleepOverStart.current?.value === "" || sleepOverEnd.current?.value === "") {
            return toast.error("Input Fields cannot be empty")
        }

        setLoading(true)
        const info = {

            normalStartTimePeriod: startTime.current?.value,
            normalEndTimePeriod: endTime.current?.value,
            eveningStartTimePeriod: eveningStart.current?.value,
            eveningEndTimePeriod: eveningEnd.current?.value,
            sleepoverStartTimePeriod: sleepOverStart.current?.value,
            sleepoverEndTimePeriod: sleepOverEnd.current?.value,
            companyId: user?.companyId
        }

        try {

            const { data } = await axiosInstance.post(`/TimePeriods/add_timeperiod?userId=${user?.uid}`, info);
            toast.success(data.message);
            setLoading(false);
            setAddTimeModal(false);
            companyId && dispatch(fetchTime(companyId));

        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading(false)
        }
        finally {
            setLoading(false)
        }
    }

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = time.filter((item) =>
        item.normalStartTimePeriod.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <SEO
                title='Time Period - Promax Care'
                description='Time Period'
                name='Promax-Care'
            />
            {/* <Breadcrumb /> */}
            <div className='mt-10'>
                {time.length <= 0 && loadingData && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Time Period</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By Normal StartTime Period" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            {/* <button id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button> */}
                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(time, columns as unknown as Column<DataRow>[], "Time-Period.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(time, columns as unknown as Column<DataRow>[], "Time-Period")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: time, filename: 'Time-Period.csv' })}

                                    />
                                </div>
                            </ExportDropdown>




                        </div>
                        {time.length <= 0 && <button
                            onClick={() => setAddTimeModal(true)}
                            type="button" className="px-4 py-2 bg-primary text-white  disabled:bg-slate-400
                            transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
                           ">

                            Create Time Period
                        </button>}
                    </div>
                </div>



                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />





            </div>

            <Modal open={deleteModal} onClose={() => setDeleteModal(false)} role="alertdialog" backdrop="static" autoFocus size={"xs"} >
                <Modal.Header>
                    <Modal.Title className='font-bold text-sm'>Delete Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className="mb-4 font-bold text-lg">Are you sure you want to delete this Time Period ?</p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button onClick={() => setDeleteModal(false)} className='bg-gray-500 text-white hover:text-white hover:bg-gray-400'>
                                No, Cancel
                            </Button>
                            <Button onClick={handleDeleteTimePeriod} className='bg-red-500 text-white hover:bg-red-700 hover:text-white'>
                                {
                                    loading ? <Spinner /> : "Yes, I'm sure"
                                }
                            </Button>
                        </div>

                    </div>


                </Modal.Body>
                <Modal.Footer>


                </Modal.Footer>
            </Modal>

            <Modal open={editTimeModal} onClose={() => setEditTimeModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Edit Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='py-3 border-b border-gray-500 flex justify-between flex-wrap items-center'>
                            <span className='font-bold text-base text-black'>Time Periods</span>
                            <small className=' text-brand underline cursor-pointer text-end' onClick={() => setModal1(true)}>What are Time Periods?</small>
                        </div>
                        <form action="" className='space-y-1 p-2'>
                            <div>
                                <CustomInput
                                    label='Normal Start Time Period'
                                    type='datetime-local'
                                    name="normalStartTimePeriod"
                                    value={timeDetails?.normalStartTimePeriod}
                                    onChange={handleInputChange}
                                    placeholder="Normal Start Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal2(true)}>See Normal Start Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Normal End Time Period'
                                    type='datetime-local'
                                    name="normalEndTimePeriod"
                                    value={timeDetails?.normalEndTimePeriod}
                                    onChange={handleInputChange}
                                    placeholder="Normal End Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal3(true)}>See Normal End Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Evening Start Time Period'
                                    type='datetime-local'
                                    name="eveningStartTimePeriod"
                                    value={timeDetails?.eveningStartTimePeriod}
                                    onChange={handleInputChange}
                                    placeholder="Evening Start Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal4(true)}>See Evening Start Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Evening End Time Period'
                                    type='datetime-local'
                                    name="eveningEndTimePeriod"
                                    value={timeDetails?.eveningEndTimePeriod}
                                    onChange={handleInputChange}
                                    placeholder="Evening End Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal5(true)}>See Evening End Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Sleepover Start Time Period'
                                    type='datetime-local'
                                    name="sleepoverStartTimePeriod"
                                    value={timeDetails.sleepoverStartTimePeriod}
                                    onChange={handleInputChange}
                                    placeholder="Sleepover Start Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal6(true)}>See Sleepover Start Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Sleepover End Time Period'
                                    type='datetime-local'
                                    name="sleepoverEndTimePeriod"
                                    value={timeDetails.sleepoverEndTimePeriod}
                                    onChange={handleInputChange}
                                    placeholder="Sleepover End Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal7(true)}>See Sleepover End Time (NDIS Standard)</small>
                                </div>
                            </div>

                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    onClick={HandleEdit} disabled={loading ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Update"
                                    }</button>

                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>

                    {/* <Button onClick={() => setAddTimeModal(false)} className='bg-red-500 text-white'>
                        Cancel
                    </Button> */}
                </Modal.Footer>
            </Modal>

            <Modal open={addTimeModal} onClose={() => setAddTimeModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Add Time Period</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className='py-3 border-b border-gray-500 flex justify-between flex-wrap items-center'>
                            <span className='font-bold text-base text-black'>Time Periods</span>
                            <small className=' text-brand underline cursor-pointer text-end' onClick={() => setModal1(true)}>What are Time Periods?</small>
                        </div>
                        <form action="" className='space-y-1 p-2'>
                            <div>
                                <CustomInput
                                    label='Normal Start Time Period'
                                    type='datetime-local'
                                    ref={startTime}
                                    placeholder="Normal Start Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal2(true)}>See Normal Start Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Normal End Time Period'
                                    type='datetime-local'
                                    ref={endTime}
                                    placeholder="Normal End Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal3(true)}>See Normal End Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Evening Start Time Period'
                                    type='datetime-local'
                                    ref={eveningStart}
                                    placeholder="Evening Start Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal4(true)}>See Evening Start Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Evening End Time Period'
                                    type='datetime-local'
                                    ref={eveningEnd}
                                    placeholder="Evening End Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal5(true)}>See Evening End Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Sleepover Start Time Period'
                                    type='datetime-local'
                                    ref={sleepOverStart}
                                    placeholder="Sleepover Start Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal6(true)}>See Sleepover Start Time (NDIS Standard)</small>
                                </div>
                            </div>
                            <div>
                                <CustomInput
                                    label='Sleepover End Time Period'
                                    type='datetime-local'
                                    ref={sleepOverEnd}
                                    placeholder="Sleepover End Time Period"
                                />
                                <div className='flex justify-end mt-1'>
                                    <small className='text-right text-brand underline cursor-pointer' onClick={() => setModal7(true)}>See Sleepover End Time (NDIS Standard)</small>
                                </div>
                            </div>

                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    onClick={PostAvail} disabled={loading ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading ? <Spinner /> : "Add"
                                    }</button>

                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>

                    {/* <Button onClick={() => setAddTimeModal(false)} className='bg-red-500 text-white'>
                        Cancel
                    </Button> */}
                </Modal.Footer>
            </Modal>

            <TimePeriodModals modal1={modal1} setModal1={setModal1}
                modal2={modal2} setModal2={setModal2}
                modal3={modal3} setModal3={setModal3}
                modal4={modal4} setModal4={setModal4}
                modal5={modal5} setModal5={setModal5}
                modal6={modal6} setModal6={setModal6}
                modal7={modal7} setModal7={setModal7}
            />
        </>
    )
}

export default TimePeriod