import React from "react";
import { Navigate, Route, Routes } from "react-router";
import ClientDashboard from "./Dashboard";
import ClientProfile from "./Profile";
import ClientRep from "./Representative";
import ClientHealthSupport from "./HealthSupportNeeds";
import ClientShiftRoster from "./ShiftRoster";
import DisableNeeds from "./DisableNeeds";
import Aids from "./Aids";
import DailyLiving from "./DailyLiving";
import ClientBehaviourSupport from "./BehaviourSupport";
import ClientCommunitySupport from "./CommunitySupport";

const ClientLayout = () => {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Navigate to="/client/dashboard" replace />} />
        <Route path="/dashboard" element={<ClientDashboard />} />
        <Route path="/profile" element={<ClientProfile />} />
        <Route path="/representative" element={<ClientRep />} />
        <Route path="/health-support" element={<ClientHealthSupport />} />
        <Route path="/roster" element={<ClientShiftRoster />} />

        <Route path="/disability-needs" element={<DisableNeeds />} />
        <Route path="/aid-equip" element={<Aids />} />
        <Route path="/daily-living" element={<DailyLiving />} />
        <Route path="/behaviour-support" element={<ClientBehaviourSupport />} />
        <Route path="/community-support" element={<ClientCommunitySupport />} />
      </Routes>
    </div>
  );
};

export default ClientLayout;
