import React, { FormEvent } from 'react'
import { Modal } from 'rsuite';
import CustomInput from '../../../../Components/Input/CustomInput';
import Spinner from '../../../../Components/Spinner';
import { AdminDataRow } from '../../../../types/AuthData';
interface Props {
    bankModal: boolean;
    setBankModal: React.Dispatch<React.SetStateAction<boolean>>
    editedProfile: AdminDataRow
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => void
    submitData: (e: FormEvent) => Promise<void>
    loading1: boolean;
}
const AdminBankComp = ({ bankModal, setBankModal, editedProfile, handleInputChange, submitData, loading1 }: Props) => {
    return (
        <>
            <Modal
                open={bankModal}
                onClose={() => setBankModal(false)}
                backdrop="static"
                autoFocus
                size={"lg"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Edit Bank Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" onSubmit={submitData}>
                            <div className="p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8 ">
                                <CustomInput
                                    label="Account Name"
                                    type="text"
                                    name='accountName'
                                    value={editedProfile.accountName || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Bank Name"
                                    type="text"
                                    name='bankName'
                                    value={editedProfile.bankName || ""}
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="Account Number"
                                    type="text"
                                    name='accountNumber'
                                    value={editedProfile.accountNumber || ""}
                                    required
                                    onChange={handleInputChange}
                                />
                                <CustomInput
                                    label="BSB"
                                    type="text"
                                    name='bsb'
                                    value={editedProfile.bsb || ""}
                                    onChange={handleInputChange}
                                />
                                <div className='lg:col-span-2'>
                                    <CustomInput
                                        label="Branch"
                                        type="text"
                                        name='branch'
                                        value={editedProfile.branch || ""}
                                        onChange={handleInputChange}
                                    />
                                </div>

                            </div>

                            <div className='flex items-center justify-center'>
                                <button type="submit"
                                    disabled={loading1 ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading1 ? <Spinner /> : "Update"
                                    }</button>
                            </div>
                        </form>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default AdminBankComp