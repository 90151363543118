import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface ClientSchedule {
    activities: string;
    clientScheduleId: number;
    companyID: number;
    dateCreated: string;
    dateModified: string;
    days: string;
    fromTimeOfDay: string;
    profile: {
        profileId: number;
        firstName: string;
        surName: string;
        middleName: string | null;
        clientId: number;
        contactId: string;
        address: string;
        email: string;
        phoneNumber: string;
        gender: string;
        imageUrl: string | null;
        imageFile: string | null;
        country: string;
        state: string;
        city: string;
        isCompleted: boolean;
        isActive: boolean;
        dateOfBirth: string; // You may want to use Date type if it's a date string
        homePhone: string | null;
        workPhone: string | null;
        profession: string | null;
        culturalBackground: string | null;
        preferredLanguage: string | null;
        requireInterpreter: boolean | null;
        indigenousSatatus: string | null;
        ndisPlan: string | null;
        ndisPlanNote: string | null;
        privacyPreferences: string | null;
        financialArrangement: string | null;
        ndisNo: string;
        agreementStartDate: string; // You may want to use Date type if it's a date string
        agreementEndDate: string; // You may want to use Date type if it's a date string
        nextOfKin: string | null;
        relationship: string | null;
        kinPostcode: string | null;
        kinAddress: string | null;
        kinCountry: string | null;
        kinCity: string | null;
        kinEmail: string | null;
        suburb: string | null;
        kinState: string | null;
        kinPhoneNumber: string | null;
        fullName: string;
        dateCreated: string; // You may want to use Date type if it's a date string
        userCreated: string;
        dateModified: string; // You may want to use Date type if it's a date string
        userModified: string;
    }
    profileId: number
    toTimeOfDay: string
    userCreated: string
    userModified: string;
}


export interface ClientScheduleState {
    loading: boolean;
    data: Array<ClientSchedule>;
    error: string | undefined;
}
const initialState: ClientScheduleState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchClientSchedule = createAsyncThunk(
    "ClientSchedule/fetchClientSchedule",
    async (uid: number) => {
        try {
            const response = await api.fetchClientSchedule(uid);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)


const clientScheduleSlice = createSlice({
    name: 'clientSchedule',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchClientSchedule.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchClientSchedule.fulfilled, (state, action: PayloadAction<Array<ClientSchedule>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchClientSchedule.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.clientSchedule;
export default clientScheduleSlice.reducer;



// Define selectors to access the profile slice state
export const clientScheduleData = (state: RootState) => state.clientSchedule.data;
export const clientScheduleLoading = (state: RootState) => state.clientSchedule.loading;
export const clientScheduleError = (state: RootState) => state.clientSchedule.error;