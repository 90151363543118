import React from "react";
import fileImg from "../../../../images/icon/EmptyState.png";

const Message = () => {
  return (
    <div className="  flex justify-center flex-col items-center my-5 rounded py-10 space-y-2">
      <img src={fileImg} alt="file upload icon" width={200} height={300} />

      <strong className="text-xl">No Data Available</strong>
    </div>
  );
};

export default Message;
