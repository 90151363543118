import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import SEO from "../../../constants/SEO";
import fileImg from '../../../images/icon/EmptyState.png'
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import { StaffProfileTypes } from "../../../types/AuthData";
import DailyReportCard from "./components/Card";
import dayjs from "dayjs";
import { formatDuration } from "../../../services/reusableValue";
import Spinner from "../../../Components/Spinner";
import PaginationComponent from "../../../Components/CustomPagination";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { Modal } from "rsuite";
import { fetchStaffDailyReport, StaffDailyReport, staffDailyReportData, staffDailyReportLoading } from "../../../store/slices/StaffSlices/staffDailyReportSlice";
import CustomInput from "../../../Components/Input/CustomInput";

const StaffDailyReoprt = () => {
    const user = useSelector((state: RootState) => state?.auth?.user);
    const navigate = useNavigate();
    const [pageNumber, setPageNumber] = useState(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [loading1, setLoading1] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const staffProfileString = sessionStorage.getItem("staffProfile");
    const staffProfile: StaffProfileTypes = staffProfileString
        ? JSON.parse(staffProfileString)
        : null;
    const dispatch: AppDispatch = useAppDispatch();
    // const companyId = user?.companyId;
    useEffect(() => {
        dispatch(fetchStaffDailyReport(staffProfile.staffId));
    }, [dispatch]);

    const [editModal, setEditModal] = useState<boolean>(false);
    const [attenDetails, setAttenDetails] = useState<StaffDailyReport | null>(null);

    const handleDetails = async (id: number) => {
        // setSelectedShift(id);
        setLoading(true);
        setShowModal(true);
        try {
            const { data } = await axiosInstance.get(`/StaffAttendances/get_attendance_record/${id}`);
            setAttenDetails({ ...data });
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
                setLoading(false);
            } else {
                toast.error("An error occurred");
                setLoading(false);
            }
        } finally {
            setLoading(false);
        }
    };
    const [clockIn, setClockIn] = useState<string>("")
    const [clockOut, setClockOut] = useState<string>("")
    const [startKm, setStartKm] = useState<string>("")
    const [endKm, setEndKm] = useState<string>("")
    const [report, setReport] = useState<string>("")

    const handleEditReport = async (id: number) => {
        setLoading(true);
        setEditModal(true);
        try {
            const { data } = await axiosInstance.get(`/StaffAttendances/get_attendance_record/${id}`);
            setAttenDetails({ ...data });
            setClockIn(data.clockIn)
            setClockOut(data.clockOut)
            setStartKm(data.startKm)
            setEndKm(data.endKm)
            setReport(data.report)
            setLoading(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
                setLoading(false);
            } else {
                toast.error("An error occurred");
                setLoading(false);
            }
        } finally {
            setLoading(false);
        }
    };
    const handleNewAddReport = () => {
        navigate(`/app/staff/new-daily-report`);
    };

    const staffDaily = useSelector(staffDailyReportData);
    const loadingData = useSelector(staffDailyReportLoading);

    const itemsPerPage = 12;
    const pageCount = Math.ceil(staffDaily.length / itemsPerPage);
    const displayData = staffDaily.slice(
        pageNumber * itemsPerPage,
        (pageNumber + 1) * itemsPerPage
    );

    const topOfPageRef = useRef<HTMLInputElement>(null);

    const handlePageClick = (selectedItem: { selected: number }) => {
        setPageNumber(selectedItem.selected);
        topOfPageRef.current?.scrollIntoView({
            behavior: "smooth",
        });
    };

    const [upLoadDoc, setUpLoad] = useState<File | null>(null);

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setUpLoad(file);
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        setLoading1(true);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position: GeolocationPosition) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;

                    const formData = new FormData();
                    formData.append("StaffAttendanceId", attenDetails?.staffAttendanceId.toString() ?? "");
                    formData.append("StartKm", startKm);
                    formData.append("EndKm", endKm);
                    formData.append("Report", report);
                    formData.append("ClockIn", clockIn);
                    formData.append("ClockOut", clockOut);
                    formData.append("InLongitude", longitude.toString());
                    formData.append("InLatitude", latitude.toString());
                    upLoadDoc && formData.append("ImageFile", upLoadDoc);
                    user?.companyId && formData.append("companyId", user.companyId.toString());
                    formData.append("staffId", staffProfile.staffId.toString());

                    try {
                        // Now you have valid coordinates or default 0, proceed to clock in
                        const { data } = await axiosInstance.post(
                            `/StaffAttendances/edit/${attenDetails?.staffAttendanceId}?userId=${user?.uid}`, formData
                        );
                        toast.success(data.message);
                        navigate("/app/staff/new-daily-report")
                        setLoading1(false)
                    } catch (error: unknown) {
                        console.log(error);
                        if (isAxiosError(error)) {
                            toast.error(error.response?.data?.message);
                            toast.error(error.response?.data?.title);
                            // throw error;
                        } else {
                            toast.error("An error occurred");
                        }
                        setLoading1(false)
                    }

                    finally {
                        setLoading1(false)
                    }
                }
            )
        }

    };

    return (
        <>
            <SEO title={` Staff Daily Report`} description="Staff" name="Promax-Care" />
            <Breadcrumb showNavigation={false} />

            <div className="flex justify-between">
                <div
                    className="flex justify-between items-center flex-wrap"
                    ref={topOfPageRef}
                >
                    <h2 className="font-bold text-2xl text-black w-auto">Daily Report</h2>
                </div>
                <div>
                    <button onClick={handleNewAddReport} className='bg-primary px-6 py-3 rounded-md text-white text-xs fornt-normal'>
                        Add New Report
                    </button>
                </div>
            </div>

            {staffDaily.length <= 0 && loadingData && (
                <Spinner color="#071952" />
            )}

            {
                !loadingData && staffDaily.length <= 0 && (

                    <div className=' border border-dashed flex justify-center flex-col items-center my-5 rounded py-10 space-y-2'


                    >

                        <img src={fileImg} alt="file upload icon"
                            width={200}
                            height={300}
                        />


                        <strong className="text-xl">No Data Available</strong>






                    </div>
                )
            }
            <div className="my-5">
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 ">
                    {staffDaily.length > 0 &&
                        displayData.map((item) => (
                            <DailyReportCard
                                key={item.staffAttendanceId}
                                staff={item.staff.fullName}
                                clockIn={dayjs(item.clockIn).format("D/M/YYYY h:mm A")}
                                clockOut={
                                    !item.clockOut
                                        ? "In Shift"
                                        : dayjs(item.clockOut).format("D/M/YYYY h:mm A")
                                }
                                duration={formatDuration(item.duration)}
                                onDetails={() => handleDetails(item.staffAttendanceId)}
                                onAddReport={() => handleEditReport(item.staffAttendanceId)}
                            />
                        ))}
                </div>

                {pageCount > 1 && (
                    <PaginationComponent
                        pageCount={pageCount}
                        onPageChange={handlePageClick}
                    />
                )}
                <br />
            </div>

            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                backdrop="static"
                autoFocus
                size={"sm"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Daily Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? (
                        <Spinner />
                    ) : (
                        <div className="">
                            <tr className="text-sm truncate ...">
                                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                    Clock In:
                                </td>
                                <td className=" px-2 py-1 text-gray-700">
                                    {dayjs(attenDetails?.clockIn).format("MMMM D, YYYY h:mm A")}
                                </td>
                            </tr>
                            <tr className="text-sm truncate ...">
                                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                    Clock Out:
                                </td>
                                <td className=" px-2 py-1 text-gray-700">
                                    {dayjs(attenDetails?.clockOut).format("MMMM D, YYYY h:mm A")}
                                </td>
                            </tr>
                            <tr className="text-sm truncate ...">
                                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                    Duration:
                                </td>
                                <td className=" px-2 py-1 text-gray-700">
                                    {" "}
                                    {attenDetails?.duration && formatDuration(attenDetails?.duration)}
                                </td>
                            </tr>
                            <tr className="text-sm truncate ...">
                                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                    Kilometre:
                                </td>
                                <td className=" px-2 py-1 text-gray-700">
                                    {" "}
                                    {attenDetails?.endKm && attenDetails?.endKm - attenDetails?.startKm} Km
                                </td>
                            </tr>
                            <tr className="text-sm truncate ...">
                                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                    Report:
                                </td>
                                <td className=" px-2 py-1 text-gray-700">
                                    {" "}
                                    {attenDetails?.report}
                                </td>
                            </tr>
                            {attenDetails?.imageURL && (
                                <tr className="text-sm truncate ...">
                                    <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                        Image:
                                    </td>
                                    <td className=" px-2 py-1 text-gray-700">
                                        {" "}
                                        <div style={{ width: "300px", height: "200px" }}>
                                            <img src={attenDetails?.imageURL} alt="" width={"100%"} />
                                        </div>
                                    </td>
                                </tr>
                            )}
                            <tr className="text-sm truncate ...">
                                <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                    Date Created:
                                </td>
                                <td className=" px-2 py-1 text-gray-700">
                                    {" "}
                                    {dayjs(attenDetails?.dateCreated).format(
                                        "MMMM D, YYYY h:mm A"
                                    )}
                                </td>
                            </tr>
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>

            <Modal open={editModal} onClose={() => setEditModal(false)} backdrop="static" autoFocus size={"lg"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Edit Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading ? <Spinner /> : (
                        <div>
                            <form action="">
                                <div className='p-2 grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-8'>

                                    <CustomInput
                                        label='Clock In'
                                        type='datetime-local'
                                        name="clockIn"
                                        value={clockIn || ""}
                                        onChange={(e) => setClockIn(e.target.value)}
                                    />

                                    <CustomInput
                                        label='Clock Out'
                                        type='datetime-local'
                                        name="clockOut"
                                        value={clockOut}
                                        onChange={(e) => setClockOut(e.target.value)}
                                    />

                                    <CustomInput
                                        label='Starting Kilometre (km)'
                                        type='number'
                                        name="startKm"
                                        value={startKm || ""}
                                        onChange={(e) => setStartKm(e.target.value)}
                                    />

                                    <CustomInput
                                        label='Ending Kilometre (km)'
                                        type='text'
                                        name="endKm"
                                        value={endKm || ""}
                                        onChange={(e) => setEndKm(e.target.value)}
                                    />

                                    <div className="lg:col-span-2">
                                        <CustomInput
                                            label="Image"
                                            type="file"
                                            accept=".pdf,.doc,.docx"
                                            onChange={handleFileChange}
                                            placeholder="Image"
                                        />
                                    </div>

                                    <div className="lg:col-span-3">
                                        <label htmlFor="report" className="font-bold">
                                            Day Report{" "}
                                        </label>
                                        <textarea
                                            id="report"
                                            rows={5}
                                            autoComplete="off"
                                            name="report"
                                            className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400"
                                            value={report || ""}
                                            onChange={(e) => setReport(e.target.value)}
                                        />

                                    </div>
                                </div>

                                <div className='mx-auto text-center mt-3'>
                                    <button type="submit"
                                        onClick={handleSubmit}
                                        disabled={loading1}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                            loading1 ? <Spinner /> : "Update"
                                        }</button>

                                </div>
                            </form>

                        </div>)}

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default StaffDailyReoprt;
