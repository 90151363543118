import React from "react";
interface Props {
  medicationGiven: React.RefObject<HTMLTableCellElement>
  medicationSigned: React.RefObject<HTMLTableCellElement>
  medicationAvailable: React.RefObject<HTMLTableCellElement>
  medicatioErrors: React.RefObject<HTMLTableCellElement>
  urgentMatters: React.RefObject<HTMLTextAreaElement>
}

const FormPartA = ({ medicatioErrors, medicationAvailable, medicationGiven, medicationSigned, urgentMatters }: Props) => {
  return (
    <table className="divide-y-2 divide-gray-300 bg-white text-sm rounded-lg border w-full border-gray-300 mb-6">
      <tbody className="divide-y divide-gray-200 w-full">
        <tr className="w-full">
          <td className="px-4 py-2 font-medium text-red-600" colSpan={2}>
            URGENT MATTER ALERTS
          </td>
        </tr>
        <tr className="w-full">
          <td className="px-4 py-2 w-full" colSpan={2}>
            <textarea
              ref={urgentMatters}
              name=""
              id=""
              className="border w-full p-2 focus:outline-none resize-none"
            />
          </td>
        </tr>
        <tr className="divide-x-2">
          <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Medications Given:
          </td>
          <td
            ref={medicationGiven}
            className="md:whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"
            contentEditable
          ></td>
        </tr>

        <tr className="divide-x-2">
          <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Medications Signed For:
          </td>
          <td
            ref={medicationSigned}
            className="md:whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"
            contentEditable
          ></td>
        </tr>

        <tr className="divide-x-2">
          <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Medications Available for the Next 30:
          </td>
          <td
            ref={medicationAvailable}
            className="md:whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"
            contentEditable
          ></td>
        </tr>
        <tr className="divide-x-2 h-8">
          <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900"></td>
          <td className="md:whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"></td>
        </tr>
        <tr className="divide-x-2">
          <td className="md:whitespace-nowrap px-4 py-2 font-medium text-gray-900">
            Details of Any Medication Errors:
          </td>
          <td
            ref={medicatioErrors}
            className="md:whitespace-nowrap px-4 py-2 text-gray-700 w-full outline-none"
            contentEditable
          ></td>
        </tr>
      </tbody>
    </table>
  );
};

export default FormPartA;
