import React from 'react';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon: React.ReactNode;  // ReactNode allows any React element
    title: string;
}

const IconButton: React.FC<ButtonProps> = ({ icon, title, ...props }) => {
    return (
        <button className={`hover:bg-gray-100 p-1 inline-flex cursor-pointer items-center gap-2 text-gray-600 hover:font-medium ${props.className}`} {...props}>
            <span>{icon}</span>
            <span> {title}</span>
        </button>
    );
};

export default IconButton;