import { isAxiosError } from "axios";
import axiosInstance from "../store/axiosInstance";
import { toast } from "react-toastify";

export const fetchPackages = async () => {
    try {
        const { data } = await axiosInstance.get("/Packages/get_all_packages");

        return data || [];

    } catch (error: unknown) {

        if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // throw error;
        } else {
            toast.error("Error fetching  company");
        }


    }
};
export const fetchCompany = async (companyId: number) => {
    try {
        const { data } = await axiosInstance.get(`/Companies/get_company/${companyId}`);

        return data.company || null;

    } catch (error: unknown) {

        if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // throw error;
        } else {
            toast.error("Error fetching  company");
        }


    }
};

export const fetchStaffInfo = async (uid: string | undefined) => {
    try {

        const { data } = await axiosInstance.get(`/Staffs/${uid}`)
        return data || null


    } catch (error: unknown) {

        if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // throw error;
        } else {
            toast.error("Error fetching  staff");
        }


    }
}
export const fetchClientInfo = async (uid: string | undefined) => {
    try {

        const { data } = await axiosInstance.get(`/Profiles/${uid}`)
        return data || null


    } catch (error: unknown) {

        if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // throw error;
        } else {
            toast.error("Error fetching  Client");
        }


    }
}
export const FetchClientSupportSchedule = async (uid: string | undefined) => {
    try {
        const { data } = await axiosInstance.get(
            `/Invoice/get_client_support_schedules?clientId=${uid}`
        );
        return data;

    } catch (error) {
        console.log(error);
    }
};


export const fetchRosterInfo = async (uid: number | undefined) => {
    try {

        const { data } = await axiosInstance.get(`/ShiftRosters/${uid}`)
        return data || null


    } catch (error: unknown) {

        if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // throw error;
        } else {
            toast.error("Error fetching  roster");
        }


    }
}
export const fetchReportInfo = async (id: number, uid: number | undefined) => {
    try {

        const { data } = await axiosInstance.get(`/ShiftReports/get_shiftreport_details/${id}?shiftId=${uid}`)
        return data || null


    } catch (error: unknown) {
        console.log(error);

        return null


    }
}
export const fetchDocumentByName = async (name: string, clientId: number, companyId: number) => {
    try {

        const { data } = await axiosInstance.get(`/Documents/get_document_by_name?name=${name}&clientId=${clientId}&companyId=${companyId}`);

        return data.staffDocument || null


    } catch (error: unknown) {

        return null


    }
}
export const fetchNDISdoc = async (clientId: number, companyId: number, name: string,) => {
    try {
        const { data } = await axiosInstance.get(`/NDISForms/get_ndisform_info?clientId=${clientId}&companyId=${companyId}&name=${name}`);

        return data || null


    } catch (error: unknown) {

        return null


    }
}

export const fetchClientManagerProfle = async (companyId: number, name: string,) => {
    try {
        const { data } = await axiosInstance.get(
            `/Profiles/get_care_manager?companyId=${companyId}&name=${name}`,

        );
        return data;
    } catch (error) {
        console.log(error);
    }
};

export const fetchClientRepresentativeData = async (id: number) => {
    try {
        const { data } = await axiosInstance.get(
            `/Representatives/get_all?clientId=${id}`,
        );
        return data;

    } catch (error) {
        console.log(error);
    }
};
export const fetchStaffDocument = async (id: number | string) => {
    try {
        const { data } = await axiosInstance.get(
            `/Documents/get_all_staff_documents?staffId=${id}`,
        );
        return data.staffDocuments;

    } catch (error) {
        console.log(error);
    }
};
export const fetchTicketDetails = async (id: number | string) => {
    try {
        const { data } = await axiosInstance.get(
            `/Tickets/${id}`,
        );
        return data || null;

    } catch (error) {
        console.log(error);
        return null
    }
};

export const fetchClientPercentage = async (id: number | string) => {
    try {

        const data = await axiosInstance.get(`/Profiles/get_client_progress_score?profileId=${id}`);
        return data.data.percentage;
    } catch (error) {
        console.log(error);
        return 0
    }
}
