import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface CareManager {
    companyID: string;
    concurrencyStamp: string;
    email: string;
    emailConfirmed: boolean;
    firstName: string;
    fullName: string;
    id: string;
    isAdmin: boolean;
    isClient: boolean;
    isDenied: boolean;
    isGlobalAdmin: boolean;
    isStaff: boolean;
    lastName: string;
    normalizedEmail: string;
    normalizedUserName: string;
    phoneNumber: string;
    role: string;
    userName: string;
}

export interface CareMangerState {
    loading: boolean;
    data: Array<CareManager>;
    error: string | undefined;
}
const initialState: CareMangerState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchCareManager = createAsyncThunk(
    "CareManager/fetchCareManager",
    async (companyId: number) => {
        try {
            const response = await api.fetchCareManager(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const careManagerSlice = createSlice({
    name: 'careManager',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchCareManager.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchCareManager.fulfilled, (state, action: PayloadAction<Array<CareManager>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchCareManager.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.careManager;
export default careManagerSlice.reducer;


export const careManagerData = (state: RootState) => state.careManager.data;
export const careManagerLoading = (state: RootState) => state.careManager.loading;
export const careManagerError = (state: RootState) => state.careManager.error;