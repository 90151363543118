import React from 'react'
import { Tooltip, Whisper } from 'rsuite'
interface Props {
    title: string;
    buttonContent: JSX.Element
}

const ButtonWithDetail = ({ title, buttonContent }: Props) => {
    return (
        <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={
            <Tooltip>
                {title}
            </Tooltip>
        }>

            {buttonContent}

        </Whisper>
    )
}

export default ButtonWithDetail