import React, { FormEvent, useEffect, useRef, useState } from 'react';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import WeekView from './components/WeekView';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { fetchStaffShift, staffShiftData, staffShiftLoading } from '../../../store/slices/staffShiftSlice';
import { StaffProfileTypes } from '../../../types/AuthData';
import SEO from '../../../constants/SEO';
import Breadcrumb from '../../../Components/label/BreadCrumb';
import Spinner from '../../../Components/Spinner';
import { Shift } from '../../../store/slices/shiftRosterSlice';
import { Modal } from 'rsuite';
import { fetchRosterInfo } from '../../../services/fetchServices';
import { toast } from 'react-toastify';
import axiosInstance from '../../../store/axiosInstance';
import { isAxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import CustomInput from '../../../Components/Input/CustomInput';
import { ConfirmModal } from '../../Admin/RosteringManagement/ShiftRoster/components/ConfirmModal';
import DayView from './components/DayView';



const ShiftRoster = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("Australia/Sydney");
    const dispatch: AppDispatch = useAppDispatch();

    const [today] = useState<string>(
        dayjs().tz().format("ddd, MMM D, YYYY")
    );
    //weekView...
    const [currentDate, setCurrentDate] = useState(dayjs().tz());
    const daysOfWeek = [
        // currentDate.subtract(3, 'day'),
        // currentDate.subtract(2, 'day'),
        // currentDate.subtract(1, 'day'),
        currentDate,
        currentDate.add(1, 'day'),
        currentDate.add(2, 'day'),
        currentDate.add(3, 'day'),
        currentDate.add(4, 'day'),
        currentDate.add(5, 'day'),
        currentDate.add(6, 'day'),
    ];

    const staffProfileString = sessionStorage.getItem('staffProfile');
    const staffProfile: StaffProfileTypes = staffProfileString ? JSON.parse(staffProfileString) : null;
    const user = useSelector((state: RootState) => state?.auth?.user);

    useEffect(() => {

        dispatch(fetchStaffShift(staffProfile.staffId));

    }, [dispatch]);

    const shift = useSelector(staffShiftData);
    const loading = useSelector(staffShiftLoading);


    function getActivityStatus(activity: Shift) {
        const nowInAustraliaTime = dayjs().tz().format('YYYY-MM-DD HH:mm:ss');
        const activityDateFrom = dayjs(activity.dateFrom).subtract(5, 'minutes').format('YYYY-MM-DD HH:mm:ss');
        const activityDateTo = dayjs(activity.dateTo).format('YYYY-MM-DD HH:mm:ss');

        if (activityDateFrom > nowInAustraliaTime) {
            return 'Upcoming';
        }
        if (activity.status === "Cancelled") {
            return 'Cancelled'
        }
        else if (activityDateTo < nowInAustraliaTime && activity.attendance === true && activity.isEnded === true) {
            return 'Present'
        }
        else if (activityDateTo < nowInAustraliaTime && activity.attendance === false) {
            return "Absent"
        }
        else if (activityDateTo < nowInAustraliaTime || activity.attendance === true && activity.isEnded === false) {
            return 'In Shift'
        }
        else if (activity.attendance === true && activity.isEnded === true) {
            return 'Present'
        }
        else {
            // console.log();
            return 'Clock-In';
        }
    }


    const handleNextClick = () => {
        setCurrentDate(currentDate.add(6, 'day'));
    };

    const handlePrevClick = () => {
        setCurrentDate(currentDate.subtract(6, 'day'));
    };

    const [selectedShift, setSelectedShift] = useState<Shift | null>(null);
    const [showModal, setShowModal] = useState(false);
    const handleShiftClick = (shift: Shift) => {
        setSelectedShift(shift);
        setShowModal(true);
    };

    const [saveShiftId, setSaveShiftId] = useState<number>(0);
    const [editLoading, setEditLoading] = useState<boolean>(false);
    const [reasonModal, setReasonModal] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [clockOutModal, setClockOutModal] = useState<boolean>(false);
    const [showKmModal, setShowKmModal] = useState<boolean>(false);
    const startKm = useRef<HTMLInputElement>(null);
    const endKm = useRef<HTMLInputElement>(null);

    const GetDetail = async (shiftId: number) => {
        setSaveShiftId(shiftId);
        setEditLoading(true);
        setReasonModal(true);
        try {
            const { reason }: Shift = await fetchRosterInfo(shiftId);
            setReason(reason);
            setEditLoading(false);
        } catch (error) {
            console.error("Failed to fetch roster info:", error);
        } finally {
            setEditLoading(false);
        }
    };

    const submitReason = async (e: FormEvent) => {
        e.preventDefault();

        if (reason === "") {
            return toast.error("Input Fields cannot be empty");
        }

        setIsLoading(true);

        try {
            const { data } = await axiosInstance.get(
                `/ShiftRosters/shift_cancellation?userId=${user?.uid}&reason=${reason}&shiftid=${saveShiftId}`
            );
            toast.success(data.message);
            setIsLoading(false);
            setReasonModal(false);
            dispatch(fetchStaffShift(staffProfile.staffId));
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const handleClockIn = async (shift: Shift) => {
        setIsLoading(true);
        setSelectedShift(shift);
        setSaveShiftId(shift.shiftRosterId);
        let latitude = 0; // Default latitude
        let longitude = 0; // Default longitude

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async (position: GeolocationPosition) => {
                    latitude = position.coords.latitude;
                    longitude = position.coords.longitude;

                    try {
                        // Now you have valid coordinates or default 0, proceed to clock in
                        const { data } = await axiosInstance.get(
                            `/Attendances/clock_in?userId=${user?.uid}&shiftId=${shift.shiftRosterId}&lat=${latitude}&lng=${longitude}`
                        );
                        toast.success(data.message);
                        setShowMessageModal(true); // Only show modal on successful location fetch and API call
                    } catch (error: unknown) {
                        if (isAxiosError(error)) {
                            toast.error(error.response?.data?.message);
                            toast.error(error.response?.data?.title);
                            // throw error;
                        } else {
                            toast.error("An error occurred");
                        }
                    }

                    setIsLoading(false); // End loading once everything is done
                },
                (error: GeolocationPositionError) => {
                    toast.error(`Error getting location: ${error.message}`);
                    completeClockIn(shift.shiftRosterId, latitude, longitude); // Try to clock in with default coordinates
                }
            );
        } else {
            toast.error("Geolocation is not supported by this browser.");
            completeClockIn(shift.shiftRosterId, latitude, longitude); // Try to clock in with default coordinates
        }
    };

    const completeClockIn = async (
        shiftId: number,
        latitude: number,
        longitude: number
    ) => {
        try {
            const { data } = await axiosInstance.get(
                `/Attendances/clock_in?userId=${user?.uid}&shifted=${shiftId}&lat=${latitude}&lng=${longitude}`
            );
            toast.success(data.message);
            setShowModal(true);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
        }

        setIsLoading(false); // Ensure loading state is cleared regardless of outcome
    };

    const closeModal = () => {
        setShowMessageModal(false);
        navigate(`/app/staff/report-form/${saveShiftId}`);
        // setShowKmModal(true);
    };

    const navigate = useNavigate();

    const submitKm = async (e: FormEvent) => {
        e.preventDefault();
        if (startKm.current?.value === "" || endKm.current?.value === "") {
            return toast.error("Input Fields cannot be empty");
        }

        setIsLoading(true);

        try {
            const { data } = await axiosInstance.get(
                `/ShiftRosters/fill_mileage?shiftId=${saveShiftId}&startKm=${startKm.current?.value}&endKm=${endKm.current?.value}`
            );
            toast.success(data.message);
            dispatch(fetchStaffShift(Number(staffProfile?.staffId)));
            setIsLoading(false);
            setShowKmModal(false)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const ClockOut = async (shiftId: number) => {
        setSaveShiftId(shiftId)
        setClockOutModal(true)
    }

    const HandleClockOut = async () => {

        setEditLoading(true);

        try {

            const { data } = await axiosInstance.get(`/Attendances/clock_out?userId=${user?.uid}&shiftId=${saveShiftId}`);
            toast.success(data.message);
            setEditLoading(false);
            setClockOutModal(false);
            setShowKmModal(true)
        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setEditLoading(false)
        }
        finally {
            setEditLoading(false)
        }
    };

    const FillReport = (shiftId: number, id: number) => {
        navigate(`/app/staff/report-form/${shiftId}`)
        localStorage.setItem('staffReportId', id.toString())
    }


    return (
        <>
            <SEO
                title={` Staff shift Roster`}
                description='Staff'
                name='Promax-Care'
            />
            <Breadcrumb showNavigation={false} />

            <div className={`border-2 rounded-lg my-5`}>
                <div className="my-2">
                    {shift.length <= 0 && loading && <div className="bg-white p-2">
                        <Spinner color="#071952" />
                        <p className="text-center"> Loading Shifts</p>
                    </div>}

                </div>

                <div className="bg-white flex justify-between items-center flex-wrap p-2 ">
                    <div className=" text-center flex gap-4 items-center text-sm font-bold text-black rounded-t-lg flex-wrap mb-2">
                        {/* <span className={`rounded py-1 px-2 font-normal text-black border border-gray-200 inline-flex gap-1 items-center ${calendarView === "month" && "bg-gray-200"} !capitalize cursor-pointer`} onClick={() => setCalendarView('month')}><MdCalendarMonth /> Month</span>
                <span className={`rounded py-1 px-2 font-normal text-black border border-gray-200 inline-flex gap-1 items-center ${calendarView === "week" && "bg-gray-200"} !capitalize cursor-pointer`} onClick={() => setCalendarView('week')}><MdCalendarMonth /> Week</span>
                 */}
                        <span className="border  flex justify-between gap-3 items-center bg-gray-100 rounded">
                            <button
                                onClick={handlePrevClick}
                                className="py-2 px-1 bg-gray-100 text-gray-400"
                            >
                                <FaChevronLeft />
                            </button>
                            {currentDate.format('MMM YYYY')}
                            <button
                                onClick={handleNextClick}
                                className="py-2 px-1 bg-transparent text-gray-400"
                            >
                                <FaChevronRight />
                            </button>
                        </span>


                    </div>


                </div>


                <div className='hidden lg:block  '>
                    <WeekView
                        shift={shift}
                        daysOfWeek={daysOfWeek}
                        today={today}
                        getActivityStatus={getActivityStatus}
                        handleShiftClick={handleShiftClick}
                        GetDetail={GetDetail}
                        handleClockIn={handleClockIn}
                        loading={isLoading}
                        ClockOut={ClockOut}
                    />
                </div>

                <div className='block lg:hidden'>
                    <DayView
                        shift={shift}
                        daysOfWeek={daysOfWeek}
                        today={today}
                        getActivityStatus={getActivityStatus}
                        handleShiftClick={handleShiftClick}
                        GetDetail={GetDetail}
                        handleClockIn={handleClockIn}
                        loading={isLoading}
                        ClockOut={ClockOut}

                    />
                </div>
            </div>
            <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
                backdrop="static"
                autoFocus
                size={"sm"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Shift Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {selectedShift && (
                        <>
                            <table>
                                <tbody>
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            Status:
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700">
                                            {" "}
                                            <span
                                                className={`px-3 py-1 rounded font-bold text-white ${selectedShift.status === "Pending"
                                                    ? "bg-secondary"
                                                    : selectedShift.status === "Cancelled"
                                                        ? "bg-red-500"
                                                        : "bg-green-600"
                                                    }`}
                                            >
                                                {selectedShift.status}
                                            </span>
                                        </td>
                                    </tr>

                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            Staff:
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700">
                                            {selectedShift.staff.fullName}
                                        </td>
                                    </tr>
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            Clients:
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700 whitespace-normal">
                                            {selectedShift.clients}
                                        </td>
                                    </tr>
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            From:
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700">
                                            {dayjs(selectedShift?.dateFrom).format(
                                                "D MMMM, YYYY h:mm A"
                                            )}{" "}
                                        </td>
                                    </tr>
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            To:
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700">
                                            {" "}
                                            {dayjs(selectedShift?.dateTo).format(
                                                "D MMMM, YYYY h:mm A"
                                            )}{" "}
                                        </td>
                                    </tr>
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            Activities:
                                        </td>
                                        <td className="px-2 py-1 whitespace-normal text-gray-700">
                                            {" "}
                                            {selectedShift.activities}
                                        </td>
                                    </tr>
                                    {selectedShift.reason && (
                                        <tr className="text-sm truncate ...">
                                            <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                                Reason:
                                            </td>
                                            <td className=" px-2 py-1 text-gray-700">
                                                {" "}
                                                {selectedShift.reason}
                                            </td>
                                        </tr>
                                    )}
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            Date Created:
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700">
                                            {dayjs(selectedShift.dateCreated).format(
                                                "MMMM D, YYYY h:mm A"
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            User Created:
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700">
                                            {selectedShift.userCreated}
                                        </td>
                                    </tr>
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            Date Modified
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700">
                                            {dayjs(selectedShift.dateModified).format(
                                                "MMMM D, YYYY h:mm A"
                                            )}
                                        </td>
                                    </tr>
                                    <tr className="text-sm truncate ...">
                                        <td className=" px-2 py-1  inline-flex items-center gap-1 font-bold">
                                            User Modified
                                        </td>
                                        <td className=" px-2 py-1 text-gray-700">
                                            {selectedShift.userModified}
                                        </td>
                                    </tr>

                                </tbody>
                            </table>
                            <div className='flex items-center justify-center mt-3'>


                                {selectedShift.isShiftReportSigned ?
                                    <div className='text-center'>
                                        <button type="submit"
                                            onClick={() => FillReport(selectedShift.shiftRosterId, selectedShift.reportId)}
                                            className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">
                                            View Shift Report

                                        </button>
                                    </div> :
                                    <div className='text-center'>
                                        <button type="button"
                                            disabled
                                            className="text-black bg-white border border-brand hover:bg-white/45 focus:ring-4 focus:ring-brand 
font-medium rounded-md text-md px-4 text-xs md:text-sm py-2.5 me-2 mt-2 dark:bg-brand dark:hover:bg-brand focus:outline-none
dark:focus:ring-brand">
                                            Shift Report Not Available

                                        </button>
                                    </div>}
                            </div>
                        </>
                    )}
                </Modal.Body>
            </Modal>

            <Modal
                open={reasonModal} onClose={() => setReasonModal(false)}
                backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Request to Cancel Shift</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editLoading ? (
                        <Spinner />
                    ) : (

                        <form action="" onSubmit={submitReason}>
                            <div className="py-2">
                                <label htmlFor="report" className='font-bold'>Please provide reasons for cancelling shift </label>
                                <textarea id="report" rows={3}
                                    name='report'
                                    autoComplete="off"
                                    value={reason || ""}
                                    onChange={(e) => setReason(e.target.value)}
                                    className="w-full p-2 text-sm outline-none text-gray-900 border border-gray-300 dark:bg-gray-800 focus:ring-0 dark:text-white dark:placeholder-gray-400" defaultValue={""} />
                            </div>
                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    disabled={isLoading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
                                    font-medium rounded-md text-md px-12 py-2.5 me-2 mb-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
                                    dark:focus:ring-primary">{
                                        isLoading ? <Spinner /> : "Submit"
                                    }</button>

                            </div>
                        </form>

                    )}

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal
                open={showMessageModal}
                // onClose={() => setShowMessageModal(false)}
                backdrop="static"
                autoFocus
                size={"sm"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold"></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="my-4 text-center">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-16 shrink-0 fill-[#333] inline" viewBox="0 0 512 512">
                            <path d="M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z" data-original="#000000" />
                            <path d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z" data-original="#000000" />
                        </svg>
                        <h4 className="text-2xl text-[#333] font-semibold mt-6">Good Job, {staffProfile.firstName}</h4>
                        <p className="text-sm text-gray-500 mt-4">You have successfully clocked into the shift. Please remember to fill out your shift report form before clocking out
                            to avoid errors or being unable to clock into your next shift.</p>
                    </div>
                    <div className='mx-auto text-center mt-3'>
                        <button type="button"
                            onClick={closeModal}
                            className="px-4 py-2.5 rounded text-white text-sm font-semibold border-none outline-none bg-primary hover:bg-primary">Proceed</button>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal
                open={showKmModal}
                // onClose={() => setShowKmModal(false)}
                backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Enter Mileage Details</Modal.Title>
                </Modal.Header>
                <p style={{ fontSize: "11px" }} className='mt-2'>allows you to input the starting and ending kilometers of your journey. This information is
                    essential for tracking the distance traveled, which is important for various purposes such as reimbursement claims, vehicle maintenance, and travel expense records</p>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2'>

                            <CustomInput
                                label='Starting Km'
                                type='text'
                                name="fromTimeOfDay"
                                ref={startKm}
                            />
                            <CustomInput
                                label='Ending Km'
                                type='text'
                                name="fromTimeOfDay"
                                ref={endKm}
                            />


                            <div className='mx-auto text-center mt-3'>
                                <button
                                    onClick={submitKm}
                                    disabled={isLoading}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        isLoading ? <Spinner /> : "Submit"
                                    }</button>

                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <ConfirmModal
                open={clockOutModal}
                onClose={() => setClockOutModal(false)}
                onConfirm={HandleClockOut}
                title="Clock Out"
                content={<p className="mb-4 font-bold text-center">This will automatically clock you out. Do you wish to proceed?</p>}
                isLoading={editLoading}
            />
        </>
    )
}

export default ShiftRoster