import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { Column } from '../types/DataType';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { unparse } from 'papaparse';


export function exportToExcel<T>(data: T[], columns: Column<T>[], filename: string = 'data.xlsx') {
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Sheet1');

    // Add headers
    const headers = columns.map(column => column.name);
    sheet.addRow(headers);

    // Add data
    data.forEach(dataRow => {
        const values = columns.map(column => {
            if (typeof column.selector === 'function') {
                return column.selector(dataRow);
            }
            return dataRow[column.selector as keyof T];
        });
        sheet.addRow(values);
    });

    // Generate Excel file
    workbook.xlsx.writeBuffer().then(buffer => {
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        saveAs(blob, filename);
    });
}


export function handlePDFDownload<T extends object>(data: T[], columns: Column<T>[], filename: string = 'data.pdf') {
    const doc = new jsPDF('portrait', 'pt', 'A4');
    doc.setFontSize(10);
    const headers = columns.map(column => typeof column.name === 'string' ? column.name : 'N/A');  // Handle non-string headers

    const dataValues: (string | number)[][] = data.map(dataRow =>
        columns.map(column => {
            const rawValue = typeof column.selector === "function" ? column.selector(dataRow) : dataRow[column.selector];
            // Check and convert all values to string in a way that TypeScript recognizes as safe
            if (typeof rawValue === 'string' || typeof rawValue === 'number') {
                return rawValue.toString();
            } else if (rawValue === null || rawValue === undefined) {
                return '';
            } else {
                // Fallback for complex objects, potentially not needed if data is always simple
                return JSON.stringify(rawValue);
            }
        })
    );

    autoTable(doc, {
        head: [headers],
        body: dataValues,
        startY: 20,

    });

    doc.save(filename);
}

interface DownloadCSVProps<T> {
    data: T[];
    filename: string;
}

export function downloadCSV<T>({ data, filename }: DownloadCSVProps<T>): void {
    const csvData = unparse(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}