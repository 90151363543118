import React, { ChangeEvent, useEffect, useState } from 'react'
import DataTable, { ExpanderComponentProps, TableColumn } from 'react-data-table-component';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../../Components/menu/ExportDropdown';
import IconButton from '../../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../../services/reusableFunc';
import { Column } from '../../../../types/DataType';
import { FaFileCsv, FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState, useAppDispatch } from '../../../../store/store';
import dayjs from 'dayjs';
import Spinner from '../../../../Components/Spinner';
import { fetchStaffAttendance, staffAttendanceData, staffAttendanceLoading } from '../../../../store/slices/staffAttendaceSlice';
import moment from 'moment';

interface DataRow {
    attendanceId: number;
    clockIn: string;
    duration: number;
    clockOut: string;
    dateCreated: string;
    staff: {
        staffId: number;
        maxStaffId: string;
        firstName: string;
        surName: string;
        middleName: string;
        fullName: string;
        dateCreated: string;
        userCreated: string;
        dateModified: string;
        userModified: string;
    };
}

function formatDuration(duration: number | null | undefined): string {
    if (duration) {
        const durationInMilliseconds = duration / 10000; // Convert ticks to milliseconds
        const durationInMinutes = Math.floor(durationInMilliseconds / (1000 * 60));
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;

        return `${hours} Hrs ${minutes} min`;
    }

    return "0 Hrs 0 min"; // Return "0 Hrs 0 min" if duration is not available
}


interface StaffShitProps {
    uid: string; // Define the type of uid as string
}


const TimeSheet: React.FC<StaffShitProps> = ({ uid }) => {

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'ClockIn',
            selector: row => dayjs(row.clockIn).format('DD/MM/YYYY h:mm A'),
            sortable: true,
        },
        {
            name: 'Duration',
            selector: row => formatDuration(row.duration),
            sortable: true,

        },
        {
            name: 'Clock-Out',
            selector: row => row.clockOut ? dayjs(row.clockOut).format('DD/MM/YYYY h:mm A') : 'In Shift',
            cell: row => !row.clockOut ? (<span className='bg-secondary text-white py-1 px-2 rounded-2'>In Shift</span>) : dayjs(row.clockOut).format('DD/MM/YYYY h:mm A'),
            sortable: true,
        },
        {
            name: 'Date',
            selector: row => moment(row.dateCreated).format('lll'),
            sortable: true,

        }


    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (uid) {
            dispatch(fetchStaffAttendance(Number(uid)));
        }
    }, [dispatch, companyId, uid]);
    const staffAttendance = useSelector(staffAttendanceData);
    const loadingData = useSelector(staffAttendanceLoading);

    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };

    const filteredData = staffAttendance.filter((item) =>
        item.staff?.fullName.toLowerCase().includes(searchText.toLowerCase())
    );

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }


    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {

        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <div ><span className='font-bold'>Staff: </span>{data.staff.fullName}</div>
                <div><span className='font-bold'>ClockIn: </span>{moment(data.clockIn).format('lll')}</div>
                <div><span className='font-bold'>ClockOut: </span>{moment(data.clockOut).format('lll')}</div>
                <div><span className='font-bold'>Date Created: </span>{moment(data.dateCreated).format('lll')}</div>

            </div>
        )
    }


    return (
        <div className='mt-10'>
            {loadingData && <Spinner color='#071952' />}
            <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                    <h2 className='font-bold text-xl text-black w-auto'>TimeSheet</h2>

                    <div className="relative w-full lg:col-span-2">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                            <BsSearch />
                        </div>
                        <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search" />
                    </div>
                </div>
                <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                    <div className="flex items-center space-x-3 w-full md:w-auto">
                        <ExportDropdown >
                            <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                <IconButton
                                    icon={<FaFilePdf className='text-red-500' />}
                                    title="Export as PDF"
                                    onClick={() => handlePDFDownload(staffAttendance, columns as unknown as Column<DataRow>[], "TimeSheet.pdf")}

                                />
                                <IconButton
                                    icon={<FaFileExcel className='text-green-600' />}
                                    title="Export as Excel Sheet"

                                    onClick={() => exportToExcel(staffAttendance, columns as unknown as Column<DataRow>[], "TimeSheet")}

                                />
                                <IconButton
                                    icon={<FaFileCsv className='text-green-500' />}
                                    title="Export as CSV"
                                    onClick={() => downloadCSV({ data: staffAttendance, filename: 'TimeSheet.csv' })}

                                />
                            </div>
                        </ExportDropdown>


                    </div>


                </div>
            </div>

            <DataTable
                columns={columns}
                data={filteredData}
                expandableRows
                expandableRowsComponent={ExpandableRowComponent}
                pagination
                paginationTotalRows={staffAttendance?.length}
            // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

            />

        </div>
    )
}

export default TimeSheet