import React from 'react'
import { MdOutlineDateRange } from 'react-icons/md'
import { AiOutlineClockCircle } from 'react-icons/ai'

interface Props {
    staff: string;
    clockIn: string;
    duration: string;
    clockOut: string;
    onAddReport: () => void;
    onDetails: () => void;
}
const AttendanceCard = ({ clockIn, duration, clockOut, onDetails, onAddReport }: Props) => {


    return (
        <div className=" bg-white rounded-md shadow p-2 border-s-4 border-brand overflow-x-hidden">

            <table >

                <tbody >
                    {/* <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            <MdPerson className='text-gray-400' />
                            Staff:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{staff}</td>
                    </tr> */}

                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            <AiOutlineClockCircle className='text-gray-400' />
                            Clock-in:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{clockIn}</td>

                    </tr>

                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            <AiOutlineClockCircle className='text-gray-400' />
                            Clock-out:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{clockOut}
                        </td>

                    </tr>
                    <tr className='text-sm truncate ...'>
                        <td className="whitespace-nowrap px-2 py-1  inline-flex items-center gap-1 font-bold">
                            <MdOutlineDateRange className='text-gray-400' />
                            Duration:
                        </td>
                        <td className="whitespace-nowrap px-2 py-1 text-gray-700">{duration}
                        </td>

                    </tr>
                </tbody>
            </table>
            <div className='flex  gap-3 items-center py-2 pl-2'>
                <button className='bg-gray-300 px-6 py-2 rounded-md text-primary border border-primary text-xs font-normal' onClick={onDetails} >Details</button>
                <button className='bg-primary px-6 py-2 rounded-md text-white text-xs fornt-normal' onClick={onAddReport}>Add Report</button>
            </div>

        </div>
    )
}

export default AttendanceCard