import React from 'react'

const Authority = () => {
  return (
    <div>
      <div className=" p-4 max-[1024px]:w-[100%]  my-3 border border-black ">
        <p className="font-semibold">
          Authority for Maxicare Plus to hold house key or access code
        </p>
        <p className="flex items-center my-4 gap-3">
          <input id="clre" type="checkbox" />
          <label htmlFor="clre">
            <strong>I give consent</strong> for Maxicare Plus to access my home
            using my house key or access code.
          </label>
        </p>
      </div>
    </div>
  )
}

export default Authority