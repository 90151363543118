import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface StaffTimeSheet {
    staff: boolean;
    client: boolean;
    totalDuration: number;
    totalAgreedDuration: number;
    totalAmount: number;
    normalDuration: number;
    eveningDuration: number;
    exceptionalDuration: number;
    satDuration: number;
    sunDuration: number;
    phDuration: number;
    totalKm: number;
    nightShift: number;
    staffName: string;
    fromDate: string;
    toDate: string;
    clientName: string;
    xeroUploadLink: string;
}

export interface StaffTimeSheetState {
    loading: boolean;
    data: StaffTimeSheet | null; // Change data to object
    error: string | undefined;
}

const initialState: StaffTimeSheetState = {
    loading: false,
    data: null,
    error: undefined,
};

export const fetchStaffTimeSheet = createAsyncThunk(
    "StaffTimeSheet/fetchStaffTimeSheet",
    async ({ user, staff, dateFrom, dateTo }: { user: string, staff: string, dateFrom: string, dateTo: string }) => {
        try {
            const response = await api.fetchStaffTimeSheet(user, staff, dateFrom, dateTo);
            // Convert array to object using reduce
            const dataObject = response.timesheet.reduce((acc: Record<string, StaffTimeSheet>, item: StaffTimeSheet) => {
                acc[item.staffName] = item;
                return acc;
            }, {});
            return dataObject;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
);

const staffTimeSheetSlice = createSlice({
    name: 'staffTimeSheet',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchStaffTimeSheet.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchStaffTimeSheet.fulfilled, (state, action) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchStaffTimeSheet.rejected, (state, action) => {
            state.loading = false;
            state.data = null;
            state.error = action.error.message;
        });
    }
});

// .addCase(fetchShiftAttendance.pending, (state) => {
//     state.loading = true;
// })
// .addCase(fetchShiftAttendance.fulfilled, (state, action) => {
//     state.loading = false;
//     state.data = action.payload.shiftAttendance; // Set shiftAttendance data
//     // You may want to set attendanceSplits as well
//     state.attendanceSplits = action.payload.attendanceSplits;
// })
// .addCase(fetchShiftAttendance.rejected, (state, action) => {
//     state.loading = false;
//     state.data = null; // Reset data to null
//     state.error = action.error.message;
// });

export const userSelector = (state: RootState) => state.staffTimeSheet;
export default staffTimeSheetSlice.reducer;

export const staffTimeSheetData = (state: RootState) => state.staffTimeSheet.data;
export const staffTimeSheetLoading = (state: RootState) => state.staffTimeSheet.loading;
export const staffTimeSheetError = (state: RootState) => state.staffTimeSheet.error;
