import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

interface User {
    accessFailedCount: number;
    companyID: number;
    concurrencyStamp: string;
    email: string;
    emailConfirmed: boolean;
    firstName: string;
    fullName: string;
    id: string;
    isAdmin: boolean;
    isClient: boolean;
    isDenied: boolean;
    isGlobalAdmin: boolean;
    isStaff: boolean;
    lastName: string;
    lockoutEnabled: string;
    lockoutEnd: string;
    normalizedEmail: string;
    normalizedUserName: string;
    otp: string;
    passwordHash: string;
    phoneNumber: string;
    phoneNumberConfirmed: boolean;
    role: string;
    securityStamp: string;
    token: string;
    twoFactorEnabled: boolean;
    userName: string;
}

export interface UserState {
    loading: boolean;
    data: Array<User>;
    error: string | undefined;
}
const initialState: UserState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchUser = createAsyncThunk(
    "User/fetchUser",
    async (companyId: number) => {
        try {
            const response = await api.fetchUser(companyId);
            return response;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)

const userSlice = createSlice({
    name: 'user',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchUser.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchUser.fulfilled, (state, action: PayloadAction<Array<User>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.user;
export default userSlice.reducer;


export const userData = (state: RootState) => state.user.data;
export const userLoading = (state: RootState) => state.user.loading;
export const userError = (state: RootState) => state.user.error;