import React from "react";
import { Client } from "../../../store/slices/clientSlice";
import dayjs from "dayjs";
interface Props {
  clientDetail: Client | null
}
const ProfileTable = ({ clientDetail }: Props) => {
  return (
    <div className="overflow-x-auto max-[1024px]:w-[100%] rounded-lg border mt-5 border-gray-300 ">
      <table className="min-w-full divide-y-2 divide-gray-300 bg-white text-sm">

        <tbody className="divide-y divide-gray-200">
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Participant name:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" >{clientDetail?.fullName}</td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Date of birth
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">{clientDetail?.dateOfBirth ? dayjs(clientDetail.dateOfBirth).format('DD/MM/YYYY') : ""}</td>
          </tr>

          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Gender:
            </td>
            <td className="whitespace-nowrap  px-4 py-2 text-gray-700 w-96 outline-none">
              {clientDetail?.gender}
              {/* <p className="flex items-center gap-2">
                <input id="male" type="checkbox" />
                <label htmlFor="male">Male</label>
              </p>
              <p className="flex items-center gap-2">
                <input id="female" type="checkbox" />
                <label htmlFor="female">Female</label>
              </p>
              <p className="flex items-center gap-2">
                <input id="Other" type="checkbox" />
                <label htmlFor="Other">Other</label>
              </p> */}
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Contact number:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              {clientDetail?.phoneNumber}
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Emergency name:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none" >
              {clientDetail?.nextOfKin}
            </td>
          </tr>
          <tr className="divide-x-2">
            <td className="whitespace-nowrap px-4 py-2 font-medium text-gray-900">
              Emergency contact:
            </td>
            <td className="whitespace-nowrap px-4 py-2 text-gray-700 w-96 outline-none">
              {clientDetail?.kinPhoneNumber}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProfileTable;
