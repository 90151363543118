import React, { FormEvent, useEffect, useRef, useState } from "react";
import SEO from "../../../constants/SEO";
import { StaffProfileTypes } from "../../../types/AuthData";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { useSelector } from "react-redux";
import {
    StaffShift,
    fetchStaffShift,
    staffShiftData,
    staffShiftLoading,
} from "../../../store/slices/staffShiftSlice";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { toast } from "react-toastify";
import {
    fetchStaffProfile,
    selectProfileData,
} from "../../../store/slices/staffProfileSlice";
import Notification from "./components/Notification";
import Upcoming from "./components/Upcoming";
import TodayShift from "./components/TodayShift";
import Modals from "./components/Modals";
import ProfileCard from "./components/ProfileCard";
// import TrackingCard from "./components/TrackingCard";
import EmptyShift from "./components/EmptyShift";
import axiosInstance from "../../../store/axiosInstance";
import { isAxiosError } from "axios";
import { fetchRosterInfo } from "../../../services/fetchServices";
import { useNavigate } from "react-router-dom";
import Spinner from "../../../Components/Spinner";
import { ConfirmModal } from "../../Admin/RosteringManagement/ShiftRoster/components/ConfirmModal";
// import { CiStickyNote } from 'react-icons/ci';

const Dashboard = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("Australia/Sydney");
    const nowInAustraliaTime = dayjs().tz().format("YYYY-MM-DD");
    const staffProfileString = sessionStorage.getItem("staffProfile");
    const staffProfile: StaffProfileTypes = staffProfileString
        ? JSON.parse(staffProfileString)
        : null;

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    useEffect(() => {
        if (staffProfile.staffId) {
            dispatch(fetchStaffShift(Number(staffProfile?.staffId)));
            dispatch(fetchStaffProfile(staffProfile?.staffId.toString()));
        }
    }, [dispatch, staffProfile.staffId]);

    const staffShift = useSelector(staffShiftData);
    const profile = useSelector(selectProfileData);

    const loading = useSelector(staffShiftLoading);
    const startKm = useRef<HTMLInputElement>(null);
    const endKm = useRef<HTMLInputElement>(null);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [reasonModal, setReasonModal] = useState<boolean>(false);
    const [clockOutModal, setClockOutModal] = useState<boolean>(false);
    const [showMessageModal, setShowMessageModal] = useState<boolean>(false);
    const [showKmModal, setShowKmModal] = useState<boolean>(false);
    const [selectedShift, setSelectedShift] = useState<StaffShift | null>(null);
    const [saveShiftId, setSaveShiftId] = useState<number>(0);
    const tomorrow = dayjs().tz().add(1, "day").startOf("day");
    const [reason, setReason] = useState<string>("");

    // Filtering and sorting the shifts
    const filteredAndSortedShifts = staffShift
        .filter((shift) =>
            dayjs(shift.dateFrom).isAfter(tomorrow.subtract(1, "second"))
        )
        .sort((a, b) => dayjs(a.dateFrom).diff(dayjs(b.dateFrom)));

    //todayShift
    const allTodayShifts = staffShift
        .filter(
            (shift: StaffShift) =>
                dayjs(shift.dateFrom).format("YYYY-MM-DD") === nowInAustraliaTime ||
                (shift.attendance && shift.attendId > 0 && !shift.isEnded)
        )
        .sort(
            (a: StaffShift, b: StaffShift) =>
                new Date(b.dateFrom).getTime() - new Date(a.dateFrom).getTime()
        );

    const handleShiftClick = (shift: StaffShift) => {
        setSelectedShift(shift);
        setShowModal(true);
    };

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [editLoading, setEditLoading] = useState<boolean>(false);

    const handleClockIn = async (shift: StaffShift) => {
        setIsLoading(true);
        setSelectedShift(shift);
        setSaveShiftId(shift.shiftRosterId);

        // Check for Geolocation permission before accessing the user's location
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state === 'granted' || result.state === 'prompt') {
                // Permission is granted or prompt will be shown, get the current position
                navigator.geolocation.getCurrentPosition(
                    position => performClockIn(position, shift),
                    error => {
                        toast.error(`Error getting location: ${error.message}`);
                        // Even if error, try to complete clock in with default coordinates
                        completeClockIn(shift.shiftRosterId, 0, 0);
                    }
                );
            } else if (result.state === 'denied') {
                toast.error('Location permission denied');
                // Optionally, handle as if location is not available
                completeClockIn(shift.shiftRosterId, 0, 0);
            }
        }).catch(error => {
            console.error('Error checking geolocation permission:', error);
            setIsLoading(false);
        });
    };

    const performClockIn = async (position: GeolocationPosition, shift: StaffShift) => {
        const latitude = position.coords.latitude;
        const longitude = position.coords.longitude;

        try {
            const { data } = await axiosInstance.get(
                `/Attendances/clock_in?userId=${user?.uid}&shiftId=${shift.shiftRosterId}&lat=${latitude}&lng=${longitude}`
            );
            toast.success(data.message);
            setShowMessageModal(true); // Show modal on success
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const completeClockIn = async (shiftId: number, latitude: number, longitude: number) => {
        try {
            const { data } = await axiosInstance.get(
                `/Attendances/clock_in?userId=${user?.uid}&shiftId=${shiftId}&lat=${latitude}&lng=${longitude}`
            );
            toast.success(data.message);
            setShowModal(true); // Show modal on success
        } catch (error) {
            handleError(error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleError = (error: unknown) => {
        if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
        } else {
            toast.error("An error occurred");
        }
    };


    const closeModal = () => {
        setShowMessageModal(false);
        navigate(`/app/staff/report-form/${saveShiftId}`);
        // setShowKmModal(true);
    };

    const navigate = useNavigate();

    const submitKm = async (e: FormEvent) => {
        e.preventDefault();
        if (startKm.current?.value === "" || endKm.current?.value === "") {
            return toast.error("Input Fields cannot be empty");
        }

        setIsLoading(true);

        try {
            const { data } = await axiosInstance.get(
                `/ShiftRosters/fill_mileage?shiftId=${saveShiftId}&startKm=${startKm.current?.value}&endKm=${endKm.current?.value}`
            );
            toast.success(data.message);
            dispatch(fetchStaffShift(Number(staffProfile?.staffId)));
            setIsLoading(false);
            setShowKmModal(false)
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const EditDetail = async (shiftId: number) => {
        setSaveShiftId(shiftId);
        setEditLoading(true);
        setReasonModal(true);
        try {
            const { reason }: StaffShift = await fetchRosterInfo(shiftId);
            setReason(reason);
            setEditLoading(false);
        } catch (error) {
            console.error("Failed to fetch roster info:", error);
        } finally {
            setEditLoading(false);
        }
    };


    const ClockOut = async (shiftId: number) => {
        setSaveShiftId(shiftId)
        setClockOutModal(true)
    }

    const HandleClockOut = async () => {

        setEditLoading(true);

        try {

            const { data } = await axiosInstance.get(`/Attendances/clock_out?userId=${user?.uid}&shiftId=${saveShiftId}`);
            toast.success(data.message);
            setEditLoading(false);
            setClockOutModal(false);
            setShowKmModal(true)
        } catch (error: unknown) {

            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setEditLoading(false)
        }
        finally {
            setEditLoading(false)
        }
    };

    const submitReason = async (e: FormEvent) => {
        e.preventDefault();

        if (reason === "") {
            return toast.error("Input Fields cannot be empty");
        }

        setIsLoading(true);

        try {
            const { data } = await axiosInstance.get(
                `/ShiftRosters/shift_cancellation?userId=${user?.uid}&reason=${reason}&shiftid=${saveShiftId}`
            );
            toast.success(data.message);
            setIsLoading(false);
            setReasonModal(false);
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    function getActivityStatus(activity: StaffShift): string {
        const nowInAustraliaTime = dayjs()
            .tz("Australia/Sydney")
            .format("YYYY-MM-DD HH:mm:ss");
        const activityDateFrom = dayjs(activity.dateFrom)
            .subtract(10, "minutes")
            .format("YYYY-MM-DD HH:mm:ss");
        const activityDateTo = dayjs(activity.dateTo).format("YYYY-MM-DD HH:mm:ss");

        if (activityDateFrom > nowInAustraliaTime) {
            return "Upcoming";
        } else if (activity.status === "Cancelled") {
            return "Cancelled";
        } else if (
            activityDateTo < nowInAustraliaTime &&
            activity.attendance &&
            activity.isEnded
        ) {
            return "Present";
        } else if (activityDateTo < nowInAustraliaTime && !activity.attendance) {
            return "Absent";
        } else if (
            activityDateTo < nowInAustraliaTime ||
            (activity.attendance && !activity.isEnded)
        ) {
            return "Shift In progress";
        } else if (activity.attendance && activity.isEnded) {
            return "Present";
        } else {
            return "Clock-In";
        }
    }

    return (
        <>
            <SEO title="Dashboard" description="Dashboard" name="Promax-Care" />
            <div className="my-5 md:my-10 grid grid-cols-1 gap-4 lg:gap-8 md:gap-6 md:grid-cols-2 xl:grid-cols-3">
                <div className="space-y-4 order-last md:order-first">
                    <ProfileCard profile={profile} />

                    {/* <TrackingCard /> */}
                </div>

                {loading && (
                    <div className="fixed  inset-0 z-[9999]  flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none">
                        <div className="relative w-auto max-w-lg mx-auto">
                            <div
                                className="relative z-[999] flex justify-center items-center p-4  duration-500 ease-in-out bg-white
border-0 rounded-lg shadow-lg outline-none focus:outline-none"
                            >
                                <Spinner color="#071952" />
                            </div>
                        </div>

                        <div className="fixed inset-0 z-40 bg-gray-300 duration-500 ease-in-out backdrop-blur-xl opacity-80"></div>
                    </div>
                )}

                {allTodayShifts.length > 0 ? (
                    allTodayShifts.map((activity, index) => (
                        <TodayShift
                            key={index}
                            activity={activity}
                            getActivityStatus={getActivityStatus}
                            handleClockIn={handleClockIn}
                            isLoading={isLoading}
                            loading={loading}
                            EditDetail={EditDetail}
                            ClockOut={ClockOut}
                        />
                    ))
                ) : (
                    <EmptyShift />
                )}

                <Notification />
                {filteredAndSortedShifts.length > 0 && (
                    <Upcoming
                        filteredAndSortedShifts={filteredAndSortedShifts}
                        handleShiftClick={handleShiftClick}
                    />
                )}
            </div>
            <Modals
                selectedShift={selectedShift}
                setShowModal={setShowModal}
                showModal={showModal}
                loading={isLoading}
                startKm={startKm}
                endKm={endKm}
                staffProfile={staffProfile}
                setShowKmModal={setShowKmModal}
                setShowMessageModal={setShowMessageModal}
                showKmModal={showKmModal}
                showMessageModal={showMessageModal}
                submitKm={submitKm}
                closeModal={closeModal}
                setReason={setReason}
                reason={reason}
                setReasonModal={setReasonModal}
                reasonModal={reasonModal}
                submitReason={submitReason}
                editLoading={editLoading}
            />

            <ConfirmModal
                open={clockOutModal}
                onClose={() => setClockOutModal(false)}
                onConfirm={HandleClockOut}
                title="Clock Out"
                content={<p className="mb-4 font-bold text-center">This will automatically clock you out. Do you wish to proceed?</p>}
                isLoading={editLoading}
            />
        </>
    );
};

export default Dashboard;
