import React, { useState } from "react";
import customer from '../images/icon/customer-care.png';
import { Tooltip, Whisper } from "rsuite";
import ChatInterface from "./Chat";

const Support: React.FC = () => {
    const [isChatOpen, setIsChatOpen] = useState<boolean>(false);

    const toggleChat = () => {
        setIsChatOpen(!isChatOpen);
    };

    return (
        <div className=" z-[300]">
            <Whisper placement="auto" controlId="control-id-hover" trigger="hover" speaker={
                <Tooltip>
                    <span>Chat with Sima</span>
                </Tooltip>
            }>
                <div onClick={toggleChat} className="cursor-pointer fixed bottom-10  right-0 md:right-4 rounded-full p-2">
                    <img src={customer} alt="customer" className="w-12 lg:w-16 " />
                </div>
            </Whisper>
            {isChatOpen && <ChatInterface onClose={toggleChat} />}
        </div>
    );
};

export default Support;
