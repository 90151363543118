import React, { ChangeEvent, FormEvent, useEffect, useRef, useState } from 'react'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import SEO from '../../../constants/SEO'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { FaFileCsv, FaFileExcel, FaFilePdf, FaFilter } from 'react-icons/fa';
import { BsSearch } from 'react-icons/bs';
import ExportDropdown from '../../../Components/menu/ExportDropdown';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { activityData, activityLoading, fetchActivity, filterActivityLogs } from '../../../store/slices/activitySlice';
import Spinner from '../../../Components/Spinner';
import { InputPicker, Modal } from 'rsuite';
import CustomInput from '../../../Components/Input/CustomInput';
import { fetchUser, userData } from '../../../store/slices/userSlice';
import IconButton from '../../../Components/Buttons/IconButton';
import { downloadCSV, exportToExcel, handlePDFDownload } from '../../../services/reusableFunc';
import { Column } from '../../../types/DataType';

interface DataRow {
    activity: string;
    activityLogId: string;
    company: string;
    companyId: string;
    dateCreated: string;
    dateModified: string;
    ipAddress: string;
    macAddress: string;
    medium: string;
    user: string;
    userCreated: string;
    userModified: string;
}

const ActivityLog = () => {

    const columns: TableColumn<DataRow>[] = [
        {
            name: 'User',
            selector: row => row.user,
            sortable: true
        },
        {
            name: 'Activity',
            selector: row => row.activity,
            sortable: true
        },
        {
            name: 'Date Created',
            selector: row => dayjs(row.dateCreated).format('DD-MM-YYYY h:mm A'),
            sortable: true
        },

    ];

    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchActivity(companyId));
            dispatch(fetchUser(companyId));
        }
    }, [dispatch, companyId]);
    const activity = useSelector(activityData);
    const loading = useSelector(activityLoading);
    const allUsers = useSelector(userData)
    const [loading1, setLoading1] = useState<boolean>(false);
    const [filterModal, setFilterModal] = useState<boolean>(false);
    const [users, setUsers] = useState<string>("")
    const dateFrom = useRef<HTMLInputElement>(null);
    const dateTo = useRef<HTMLInputElement>(null);

    const filterActivity = (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true);

        if (companyId && dateFrom.current && dateTo.current) {
            dispatch(filterActivityLogs({
                companyId: companyId,
                fromDate: dateFrom.current.value,
                toDate: dateTo.current.value,
                user: users,
            }));
        }
        setLoading1(false);
        setFilterModal(false)
        setUsers("")
    }

    const resetFilter = (e: FormEvent) => {
        e.preventDefault();
        companyId && dispatch(fetchActivity(companyId));
        setFilterModal(false)
        setUsers("")
    }

    interface Props extends ExpanderComponentProps<DataRow> {
        // currently, props that extend ExpanderComponentProps must be set to optional.
        someTitleProp?: string;
    }

    const ExpandableRowComponent: React.FC<Props> = ({ data }) => {
        return (
            <div className="p-2 flex flex-col gap-2 text-xs">
                <div ><span className='fw-bold'>Activity: </span> {data.activity}</div>
                <div><span className='fw-bold'>Medium: </span>{data.medium}</div>

            </div>
        )
    }


    const [searchText, setSearchText] = useState<string>("");

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchText(event.target.value);
    };
    const filteredData = activity.filter((item) =>
        item?.user.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.activity.toLowerCase().includes(searchText.toLowerCase()) ||
        item?.dateCreated.toLowerCase().includes(searchText.toLowerCase())
    );

    return (
        <>
            <SEO
                title='Activity Log'
                description='Activity Log'
                name='Promax-Care'
            />
            <Breadcrumb />
            <div className='mt-10'>
                {activity.length <= 0 && loading && <Spinner color='#071952' />}
                <div className="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 py-4">
                    <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-2 items-center ">

                        <h2 className='font-bold text-xl text-black w-auto'>Activity Log</h2>

                        <div className="relative w-full lg:col-span-2">
                            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                <BsSearch />
                            </div>
                            <input type="text" id="simple-search" onChange={handleSearch} className="bg-gray-200 border outline-none border-gray-300 text-gray-900 text-sm rounded focus:ring-primary focus:border-primary-500 block w-full pl-10 p-2 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500" placeholder="Search By User or Activity or Date" />
                        </div>
                    </div>
                    <div className="w-full md:w-auto flex flex-col md:flex-row space-y-2 md:space-y-0 items-stretch md:items-center justify-end md:space-x-3 flex-shrink-0">

                        <div className="flex items-center space-x-3 w-full md:w-auto">

                            <button onClick={() => setFilterModal(true)} id="actionsDropdownButton" className="w-full md:w-auto flex items-center justify-center py-2 px-4 gap-2 text-sm font-medium text-primary focus:outline-none bg-white rounded border border-primary hover:bg-gray-100 hover:text-primary-700 focus:z-10 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700" type="button">
                                <FaFilter />
                                Filter
                            </button>
                            <ExportDropdown >
                                <div className="z-[999] w-max rounded bg-white px-2 py-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                    <IconButton
                                        icon={<FaFilePdf className='text-red-500' />}
                                        title="Export as PDF"
                                        onClick={() => handlePDFDownload(activity, columns as unknown as Column<DataRow>[], "ActivityLog.pdf")}

                                    />
                                    <IconButton
                                        icon={<FaFileExcel className='text-green-600' />}
                                        title="Export as Excel Sheet"

                                        onClick={() => exportToExcel(activity, columns as unknown as Column<DataRow>[], "ActivityLog")}

                                    />
                                    <IconButton
                                        icon={<FaFileCsv className='text-green-500' />}
                                        title="Export as CSV"
                                        onClick={() => downloadCSV({ data: activity, filename: 'ActivityLog.csv' })}

                                    />
                                </div>
                            </ExportDropdown>



                        </div>

                    </div>
                </div>

                <DataTable
                    columns={columns}
                    data={filteredData}
                    expandableRows
                    expandableRowsComponent={ExpandableRowComponent}
                    pagination
                    paginationTotalRows={activity?.length}
                // expandableRowsComponentProps={{ "someTitleProp": someTitleProp }}

                />

            </div>

            <Modal open={filterModal} onClose={() => setFilterModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <form action="" className='space-y-2'>

                            <div>
                                <label className=" text-xs text-gray-600 font-semibold">Select User</label>
                                <InputPicker size="lg" placeholder="--All User--"
                                    onChange={value => setUsers(value)}
                                    data={allUsers.map(user => ({ label: user.fullName, value: user.fullName }))}
                                    className='w-full focus:outline-none focus:border-primary text-gray-600'
                                    appearance='subtle'
                                />
                            </div>
                            <CustomInput
                                label='Start Date'
                                type='date'
                                name="dateFrom"
                                ref={dateFrom}

                            />
                            <CustomInput
                                label='End Date'
                                type='date'
                                name="dateTo"
                                ref={dateTo}

                            />

                            <div className='flex items-center justify-center mt-3'>

                                <div className='text-center'>
                                    <button type="submit"
                                        onClick={resetFilter}
                                        className="text-black bg-white border border-primary hover:bg-white/45 focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-8 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">
                                        Reset Filter
                                    </button>
                                </div>

                                <div className='text-center'>
                                    <button type="submit"
                                        onClick={filterActivity}
                                        disabled={loading1 ? true : false}
                                        className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                            loading1 ? <Spinner /> : "Load"
                                        }</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ActivityLog