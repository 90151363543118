import React, { FormEvent, useEffect, useState } from "react";
import SEO from "../../../constants/SEO";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import { Avatar, Modal } from "rsuite";
import CardHeader from "./components/CardHeader";
import CardNameTag from "./components/CardNameTag";
import axiosInstance from "../../../store/axiosInstance";
import FallBack from "../../../Pages/FallBack";
import man from "../../../images/user/man.png";
import Spinner from "../../../Components/Spinner";
import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import { RootState } from "../../../store/store";
import { useSelector } from "react-redux";
import AdminEditComp from "./components/AdminEditComp";
import { AdminDataRow } from "../../../types/AuthData";
import AdminEmergComp from "./components/AdminEmergComp";
import AdminBankComp from "./components/AdminBankComp";
import AdminOtherComp from "./components/AdminOthersComp";
import AdminEmployComp from "./components/AdminEmployComp";
import EditDropDown from "../../../Components/menu/EditDropDown";
import WebcamCapture from "./components/CapturePicture";
import CustomInput from "../../../Components/Input/CustomInput";
import { Admin } from "../../../store/slices/adminSlice";
import dayjs from "dayjs";

const AdministratorProfile = () => {
    const adminProfileString = sessionStorage.getItem("adminProfile");
    const adminProfile: Admin = adminProfileString
        ? JSON.parse(adminProfileString)
        : null;
    const [loading, setLoading] = useState<boolean>(false);
    const [personalInfoModal, setPersonalInfoModal] = useState<boolean>(false);
    const [emergencyModal, setEmergencyModal] = useState<boolean>(false);
    const [othersModal, setOthersModal] = useState<boolean>(false);
    const [employModal, setEmployModal] = useState<boolean>(false);
    const [bankModal, setBankModal] = useState<boolean>(false);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const [editedProfile, setEditedProfile] = useState<AdminDataRow>({
        accountName: "",
        accountNumber: 0,
        address: "",
        administratorId: 0,
        bankName: "",
        branch: "",
        bsb: "",
        city: "",
        companyId: 0,
        country: "",
        dateCreated: "",
        dateModified: "",
        dateOfBirth: "",
        email: "",
        employmentType: "",
        facebook: "",
        firstName: "",
        fullName: "",
        gender: "",
        github: "",
        imageFile: "",
        imageUrl: "",
        instagram: "",
        isActive: false,
        kinAddress: "",
        kinCity: "",
        kinCountry: "",
        kinEmail: "",
        kinPhoneNumber: 0,
        kinPostcode: "",
        kinState: "",
        linkedIn: "",
        maxStaffId: "",
        middleName: "",
        nextOfKin: "",
        offerLetter: "",
        payDay: 0,
        payRate: 0,
        phoneNumber: 0,
        position: "",
        postcode: "",
        profession: "",
        relationship: "",
        salary: 0,
        state: "",
        suburb: "",
        surName: "",
        taxFile: "",
        twitter: "",
        userCreated: "",
        userModified: "",
        youtube: "",
    });
    const FetchAdmin = async () => {
        try {
            setLoading(true);
            const { data } = await axiosInstance.get(`/Administrators/${adminProfile.administratorId}`);
            // console.log(data);
            //   setStaffOne(data)
            setEditedProfile({ ...data });
            setLoading(false);
        } catch (error) {
            // console.log(error);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        FetchAdmin();
    }, []);




    const [loading1, setLoading1] = useState<boolean>(false);
    const [isCameraModalOpen, setCameraModalOpen] = useState(false);
    const [isFileModalOpen, setFileModalOpen] = useState(false);
    const [photoModal, setPhotoModal] = useState(false);
    const [upLoadPic, setUpLoadPic] = useState<File | null>(null);

    const handleCapture = async (imageBlob: Blob | null) => {
        if (imageBlob) {
            // Convert Blob to File
            const imageFile = new File([imageBlob], "captured-image.jpg", {
                type: imageBlob.type, // Preserving the MIME type
                lastModified: Date.now(), // Setting last modification time
            });

            if (imageFile) {
                setLoading1(true);
                const formData = new FormData();

                if (imageFile) {
                    formData.append("imageFile", imageFile);
                }
                for (const key in editedProfile) {
                    const value = editedProfile[key as keyof typeof editedProfile];
                    if (value === null) {
                        formData.append(key, ""); // Pass empty string if value is null
                    } else {
                        formData.append(key, value.toString());
                    }
                }

                try {
                    const { data } = await axiosInstance.post(
                        `/Administrators/edit/${adminProfile.administratorId}?userId=${user?.uid}`,
                        formData
                    );
                    toast.success(data.message);
                    setLoading1(false);
                    setCameraModalOpen(false);
                    FetchAdmin();
                } catch (error: unknown) {
                    if (isAxiosError(error)) {
                        toast.error(error.response?.data?.message);
                        toast.error(error.response?.data?.title);
                        // throw error;
                    } else {
                        toast.error("An error occurred");
                    }
                    setLoading1(false);
                } finally {
                    setLoading1(false);
                }
            }
        } else {
            console.log("No image captured");
            setCameraModalOpen(false);
        }
    };

    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];
        if (file) {
            setUpLoadPic(file);
        }
    };

    const handleInputChange = (
        e: React.ChangeEvent<
            HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
    ) => {
        const { name, value } = e.target;
        setEditedProfile((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const submitData = async (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true);
        const formData = new FormData();

        if (upLoadPic) {
            formData.append("imageFile", upLoadPic);
        }

        for (const key in editedProfile) {
            const value = editedProfile[key as keyof typeof editedProfile];
            if (value === null) {
                formData.append(key, ""); // Pass empty string if value is null
            } else {
                formData.append(key, value.toString());
            }
        }

        try {
            const { data } = await axiosInstance.post(
                `/Administrators/edit/${adminProfile.administratorId}?userId=${user?.uid}`,
                formData
            );
            toast.success(data.message);
            setLoading1(false);
            setPersonalInfoModal(false);
            setEmergencyModal(false);
            setBankModal(false);
            setOthersModal(false);
            setEmployModal(false);
            setFileModalOpen(false);
            FetchAdmin();
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
        } finally {
            setLoading1(false);
        }
    };

    return (
        <>
            <SEO
                title="Administrators"
                description="Administrator"
                name="Promax-Care"
            />
            <div className="flex justify-between items-center flex-wrap">
                <Breadcrumb showNavigation={false} />

            </div>

            <div>
                {loading ? (
                    <FallBack />
                ) : (
                    <div className="space-y-8 py-8">
                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 xl:grid-cols-4">
                            <div className="border-2 rounded-lg">
                                <CardHeader label="Profile" />
                                <div className="flex gap-2 flex-col justify-center items-center py-4">
                                    <div className="flex flex-col gap-1 items-center">
                                        <Avatar
                                            size="lg"
                                            circle
                                            src={editedProfile.imageUrl || man}
                                            alt="@simonguo"
                                            style={{ marginTop: 8, cursor: "pointer" }}
                                            onClick={() => setPhotoModal(true)}
                                        />
                                        <EditDropDown>
                                            <div className="z-[999] w-max rounded bg-white  p-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                                                <button
                                                    onClick={() => setCameraModalOpen(true)}
                                                    className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                                                >
                                                    Take Photo
                                                </button>

                                                <button
                                                    onClick={() => setFileModalOpen(true)}
                                                    className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                                                >
                                                    Choose Photo
                                                </button>
                                            </div>
                                        </EditDropDown>
                                    </div>
                                    {/* <Avatar size="xxl" circle /> */}
                                    <p className="font-bold text-sm text-black">
                                        {editedProfile.fullName}
                                    </p>
                                    <p className=" text-sm text-black">
                                        <span className="font-bold">Admin No:</span>{" "}
                                        <span>
                                            {!editedProfile.maxStaffId
                                                ? "N/A"
                                                : editedProfile.maxStaffId}
                                        </span>
                                    </p>
                                    <p className=" text-sm ">{editedProfile.phoneNumber}</p>

                                </div>
                            </div>
                            <div className=" lg:col-span-2 space-y-2">
                                <div className="border-2 rounded-lg ">
                                    <CardHeader
                                        label="Emergency Contact"
                                        showEditIcon
                                        onEdit={() => setEmergencyModal(true)}
                                    />

                                    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 p-4">
                                        <CardNameTag label="Name" name={editedProfile?.nextOfKin} />
                                        <CardNameTag
                                            label="Phone No"
                                            name={editedProfile?.kinPhoneNumber?.toString()}
                                        />
                                        <CardNameTag label="City" name={editedProfile.kinCity} />
                                        <CardNameTag
                                            label="Relationship"
                                            name={editedProfile.relationship}
                                        />
                                        <CardNameTag
                                            label="Country"
                                            name={editedProfile.kinCountry}
                                        />
                                        <CardNameTag
                                            label="Postal Code"
                                            name={editedProfile.kinPostcode}
                                        />
                                        <CardNameTag label="Email" name={editedProfile.kinEmail} />
                                        <CardNameTag label="State" name={editedProfile.kinState} />
                                    </div>
                                </div>

                                <div className="border-2 rounded-lg ">
                                    <CardHeader
                                        label="  Bank Information"

                                    />

                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-3 p-4">
                                        <CardNameTag
                                            label="Bank name"
                                            name={editedProfile.bankName}
                                        />
                                        <CardNameTag
                                            label="Account name"
                                            name={editedProfile.accountName}
                                        />
                                        <CardNameTag
                                            label="Account number"
                                            name={editedProfile?.accountNumber?.toString()}
                                        />
                                        <CardNameTag label="Branch" name={editedProfile.branch} />
                                        <CardNameTag label="Bsb" name={editedProfile.bsb} />
                                    </div>
                                </div>
                            </div>
                            <div className="border-2 rounded-lg">
                                <CardHeader
                                    label="Personal Information"
                                    showEditIcon
                                    onEdit={() => setPersonalInfoModal(true)}
                                />
                                <div className="flex gap-2 flex-col justify-start items-start p-4">
                                    <CardNameTag
                                        label="Phone No"
                                        name={editedProfile?.phoneNumber?.toString()}
                                    />
                                    <CardNameTag label="Email" name={editedProfile.email} />
                                    <CardNameTag label="Gender" name={editedProfile.gender} />
                                    <CardNameTag
                                        label="Date of Birth"
                                        name={dayjs(editedProfile.dateOfBirth).format("DD-MM-YYYY") || "N/A"}
                                    />
                                    <CardNameTag
                                        label="Nationality"
                                        name={editedProfile.country}
                                    />
                                    <CardNameTag label="State" name={editedProfile.state} />
                                    <CardNameTag label="Address" name={editedProfile.address} />
                                </div>
                            </div>
                        </div>

                        <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
                            <div className="border-2 rounded-lg ">
                                <CardHeader
                                    label="Employment Details"

                                />

                                <div className="grid grid-cols-1 gap-4 md:grid-cols-2  p-4">
                                    <CardNameTag
                                        label="Employment Type"
                                        name={editedProfile.employmentType}
                                    />
                                    <CardNameTag
                                        label="Pay Rate"
                                        name={editedProfile?.payRate?.toString()}
                                    />
                                    {/* <CardNameTag label='Date Joined' name={editedProfile.} /> */}
                                    {/* <CardNameTag label='Earning Rate' name={editedProfile.earningRate} /> */}
                                    <CardNameTag
                                        label="Salary"
                                        name={editedProfile.salary.toLocaleString()}
                                    />
                                    {/* <CardNameTag label='Level' name={editedProfile.level} /> */}
                                </div>
                            </div>
                            <div className="border-2 rounded-lg ">
                                <CardHeader
                                    label="Other Information"
                                    showEditIcon
                                    onEdit={() => setOthersModal(true)}
                                />

                                <div className="grid grid-cols-1 gap-4 md:grid-cols-2  p-4">
                                    <CardNameTag
                                        label="Instagram"
                                        name={editedProfile.instagram}
                                    />
                                    <CardNameTag
                                        label="Linked-In"
                                        name={editedProfile.linkedIn}
                                    />
                                    <CardNameTag label="Facebook" name={editedProfile.facebook} />
                                    <CardNameTag label="Youtube" name={editedProfile.youtube} />
                                    <CardNameTag
                                        label="X (Formely Twitter)"
                                        name={editedProfile.twitter}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            <Modal
                open={photoModal}
                onClose={() => setPhotoModal(false)}
                role="alertdialog"
                autoFocus
                size={"xs"}
            >
                <Modal.Header></Modal.Header>
                <Modal.Body>
                    <div className="w-56 h-56 overflow-hidden mx-auto">
                        <img
                            src={editedProfile.imageUrl || man}
                            alt=""
                            className="w-full h-full object-cover"
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
            <Modal
                open={isCameraModalOpen}
                onClose={() => setCameraModalOpen(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Take photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <WebcamCapture onCapture={handleCapture} loading={loading1} />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>
            <Modal
                open={isFileModalOpen}
                onClose={() => setFileModalOpen(false)}
                role="alertdialog"
                backdrop="static"
                autoFocus
                size={"xs"}
            >
                <Modal.Header>
                    <Modal.Title className="font-bold">Upload photo</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <CustomInput
                        label=""
                        type="file"
                        accept=".pdf,.doc,.docx,.jpg,.jpeg"
                        onChange={handleFileChange}
                    />

                    <div className="mx-auto text-center mt-3">
                        <button
                            type="submit"
                            onClick={submitData}
                            disabled={loading ? true : false}
                            className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
                        >
                            {loading1 ? <Spinner /> : "Upload"}
                        </button>
                    </div>
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
            </Modal>




            <AdminEditComp
                personalInfoModal={personalInfoModal}
                setPersonalInfoModal={setPersonalInfoModal}
                editedProfile={editedProfile}
                handleInputChange={handleInputChange}
                submitData={submitData}
                loading1={loading1}
            />
            <AdminEmergComp
                emergencyModal={emergencyModal}
                setEmergencyModal={setEmergencyModal}
                editedProfile={editedProfile}
                handleInputChange={handleInputChange}
                submitData={submitData}
                loading1={loading1}
            />
            <AdminBankComp
                bankModal={bankModal}
                setBankModal={setBankModal}
                editedProfile={editedProfile}
                handleInputChange={handleInputChange}
                submitData={submitData}
                loading1={loading1}
            />
            <AdminOtherComp
                othersModal={othersModal}
                setOthersModal={setOthersModal}
                editedProfile={editedProfile}
                handleInputChange={handleInputChange}
                submitData={submitData}
                loading1={loading1}
            />
            <AdminEmployComp
                employModal={employModal}
                setEmployModal={setEmployModal}
                editedProfile={editedProfile}
                handleInputChange={handleInputChange}
                submitData={submitData}
                loading1={loading1}
            />
        </>
    );
};

export default AdministratorProfile;
