import React, { FormEvent, useEffect, useState } from 'react'
import SEO from '../../../constants/SEO'
import Breadcrumb from '../../../Components/label/BreadCrumb'
import { useSelector } from 'react-redux';
import { AppDispatch, RootState, useAppDispatch } from '../../../store/store';
import { fetchRole, roleData, roleLoading } from '../../../store/slices/roleSlice';
import Spinner from '../../../Components/Spinner';
import { Link } from 'react-router-dom';
import axiosInstance from '../../../store/axiosInstance';
import { isAxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Button, Modal } from 'rsuite';

const ManageRoles = () => {

    // const [editModal, setEditModal] = useState<boolean>(false);
    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [loading1, setLoading1] = useState<boolean>(false);
    // const [loading2, setLoading2] = useState<boolean>(false);
    const [saveId, setSaveId] = useState<number>(0);
    const user = useSelector((state: RootState) => state?.auth?.user);
    const dispatch: AppDispatch = useAppDispatch();
    const companyId = user?.companyId;
    useEffect(() => {
        if (companyId) {
            dispatch(fetchRole(companyId));
        }
    }, [dispatch, companyId]);
    const roles = useSelector(roleData);
    const loading = useSelector(roleLoading);

    const handleDel = (id: number) => {
        setSaveId(id)
        setDeleteModal(true)
    }


    const handleDelete = async (e: FormEvent) => {
        e.preventDefault();
        setLoading1(true)
        try {
            const { data } = await axiosInstance.get(`/Account/delete_role?userId=${user?.uid}&roleId=${saveId}`)
            toast.success(data.message);
            setDeleteModal(false);
            companyId && dispatch(fetchRole(companyId));
            setLoading1(false);

        } catch (error: unknown) {
            if (isAxiosError(error)) {
                toast.error(error.response?.data?.message);
                toast.error(error.response?.data?.title);
                // throw error;
            } else {
                toast.error("An error occurred");
            }
            setLoading1(false);
            setDeleteModal(false);
        }
        finally {
            setLoading1(false);
        }
    }

    // const [details, setDetails] = useState(
    //     {
    //         uRolesId: 0,
    //         role: ""
    //     }
    // )
    // const handleInputChange = (
    //     e: React.ChangeEvent<
    //         HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    //     >
    // ) => {
    //     const { name, value } = e.target;
    //     setDetails((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    // };

    // const handleEditClick = async (id: number) => {
    //     setLoading2(true)
    //     setEditModal(true);
    //     try {
    //         const { data } = await axiosInstance.get(`/Account/role_details?roleId=${id}`);
    //         setDetails({ ...data.roles });
    //         setLoading2(false)
    //     } catch (error: unknown) {
    //         if (isAxiosError(error)) {
    //             toast.error(error.response?.data?.message);
    //             toast.error(error.response?.data?.title);
    //             // throw error;
    //         } else {
    //             toast.error("An error occurred");
    //         }
    //         setLoading2(false)
    //     }
    // }

    // const HandleEdit = async (e: FormEvent) => {
    //     e.preventDefault();
    //     setLoading1(true)
    //     const info = {
    //         uRolesId: details.uRolesId.toString(),
    //         role: details.role,
    //         companyId: user?.companyId
    //     }

    //     try {
    //         const { data } = await axiosInstance.post(`/Account/role_edit/${details.uRolesId}?userId=${user?.uid}`, info);
    //         toast.success(data.message)
    //         companyId && dispatch(fetchRole(companyId));
    //         setEditModal(false);
    //         setLoading1(false);
    //     } catch (error: unknown) {
    //         if (isAxiosError(error)) {
    //             toast.error(error.response?.data?.message);
    //             toast.error(error.response?.data?.title);
    //             // throw error;
    //             console.log(error)
    //         } else {
    //             toast.error("An error occurred");
    //         }
    //         setLoading1(false);
    //     }

    // }

    return (
        <>
            <SEO
                title='Manage Roles'
                description='Roles'
                name='Promax-Care'
            />
            <Breadcrumb />

            <div className='mt-10'>
                <div className='flex justify-between items-center flex-wrap'>
                    <h2 className='font-bold text-2xl text-black w-auto'>Manage Roles</h2>
                    <Link to={'/app/user/create-roles'}

                        className="px-4 py-2 hover:text-white hover:no-underline bg-primary text-white  disabled:bg-slate-400 transition duration-300 transform active:scale-95 ease-in-out hover:bg-primary/80 font-semibold rounded text-sm 
   ">
                        Create New Role
                    </Link>
                </div>

                <div className='mt-5'>
                    <h2 className='font-bold text-lg text-black w-auto'>All Roles</h2>
                </div>
                {roles.length <= 0 && loading && <Spinner color='#071952' />}

                <div>
                    {
                        !loading && roles.length <= 0 &&
                        <div className='w-full'>
                            <h4 className='text-center font-bold'>No Roles Found</h4>

                        </div>
                    }
                </div>

                <div className='mt-5 grid grid-cols-1 gap-4 lg:grid-cols-4 2xl:grid-cols-5'>

                    {
                        roles.length > 0 && roles.map((item, i) =>

                            <div className='border-2 rounded-lg' key={i}>
                                <div className='bg-gray-200 text-center p-2 flex justify-between items-center text-sm font-bold text-black rounded-t-lg '>
                                    <span> Role Name:</span> <span className='rounded py-1 px-2 text-xs text-black bg-secondary !capitalize'>{item.role}</span>
                                </div>
                                {/* <div className='flex gap-2 flex-col justify-start items-start p-2'>
                                    <span>Privileges:</span>
                                </div> */}
                                <div className='flex justify-center gap-4 items-center py-2'>
                                    <button className='bg-red-600 px-6 py-1 rounded-md text-white text-xs' onClick={() => handleDel(item.uRolesId)}>Delete</button>
                                    {/* <button className='bg-primary px-6 py-1 rounded-md text-white text-xs' onClick={() => handleEditClick(item.uRolesId)}>Edit</button> */}
                                </div>

                            </div>

                        )
                    }

                </div>

            </div>

            <Modal open={deleteModal} onClose={() => setDeleteModal(false)} role="alertdialog" backdrop="static" autoFocus size={"xs"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Delete Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className=''>
                        <p className="mb-4 font-bold">Are you sure you want to delete this Role?</p>
                        <div className="flex justify-center items-center space-x-4">
                            <Button
                                onClick={handleDelete}
                                className='bg-red-500 text-white hover:bg-red-700 hover:text-white'>
                                {
                                    loading1 ? <Spinner /> : "Yes, I'm sure"
                                }
                            </Button>
                            <Button onClick={() => setDeleteModal(false)} className='bg-gray-500 text-white'>
                                No, cancel
                            </Button>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            {/* <Modal open={editModal} onClose={() => setEditModal(false)} backdrop="static" autoFocus size={"sm"} >
                <Modal.Header>
                    <Modal.Title className='font-bold'>Edit Role</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading2 ? <Spinner /> : (
                        <form action="">
                            <div className=''>

                                <CustomInput
                                    label='Role'
                                    type='text'
                                    name='role'
                                    value={details.role}
                                    onChange={handleInputChange}
                                    placeholder="Role"
                                />
                            </div>
                            <div className='mx-auto text-center mt-3'>
                                <button type="submit"
                                    onClick={HandleEdit} disabled={loading1 ? true : false}
                                    className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary">{
                                        loading1 ? <Spinner /> : "Update"
                                    }</button>

                            </div>
                        </form>

                    )}

                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal> */}
        </>
    )
}

export default ManageRoles