import React from 'react'
import man from '../../../../images/user/man.png'
import ProgressBar from './ProgressBar';
import { Link } from 'react-router-dom';
interface Props {
    userName: string;
    email: string;
    image: string | null;
    clientPercentage?: number,
    link: string;
}

const ProfileDisp = ({ email, userName, image, clientPercentage, link }: Props) => {

    return (
        <figure className="relative flex flex-col-reverse bg-gray-50 rounded-lg p-2 mb-2 dark:bg-slate-800 dark:highlight-white/5">

            <figcaption className="flex items-center space-x-4">
                <img src={image || man} alt="img" className="flex-none w-14 h-14 rounded-full object-cover" loading="lazy" decoding="async" />
                <div className="flex-auto truncate ...">
                    <Link to={link} className="text-base text-slate-900 font-semibold dark:text-slate-200 hover:text-slate-700 hover:no-underline ">
                        {userName}
                    </Link>
                    <div className="mt-0.5 dark:text-slate-300">
                        {email}
                    </div>
                </div>
                {clientPercentage && <div>
                    <ProgressBar clientPercentage={clientPercentage} />
                </div>}
            </figcaption>
        </figure>
    )
}

export default ProfileDisp