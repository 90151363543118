import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";
import { isAxiosError } from "axios";

export interface StaffShift {
    shiftRosterId: number;
    activities: string;
    reportId: number;
    appointment: string;
    attendId: number;
    attendance: boolean;
    cancellationDate: string;
    clients: string;
    companyID: number;
    dateCreated: string;
    dateFrom: string;
    dateModified: string;
    dateTo: string;
    isCurrent: boolean;
    isEnded: boolean;
    isExceptionalShift: boolean;
    isShiftReportSigned: boolean;
    isNightShift: boolean;
    profileId: number;
    progressNoteId: number;
    reason: string;
    repeat: boolean;
    requestCancellation: boolean;
    staffId: number;
    status: string;
    userCreated: string;
    userModified: string;
    staff: {
        staffId: number;
        maxStaffId: string | null;
        firstName: string;
        surName: string;
        middleName: string | null;
        aboutMe: string | null;
        address: string;
        postcode: string | null;
        email: string;
        phoneNumber: string;
        profession: string | null;
        gender: string;
        imageUrl: string | null;
        imageFile: string | null;
        country: string;
        state: string | null;
        city: string | null;
        isCompleted: boolean;
        isActive: boolean;
        dateOfBirth: string;
        facebook: string | null;
        twitter: string | null;
        instagram: string | null;
        youtube: string | null;
        linkedIn: string | null;
        github: string | null;
        employmentType: string | null;
        taxFile: string | null;
        position: string | null;
        level: string | null;
        australianCitizen: boolean | null;
        dateJoined: string | null;
        salary: number;
        payDay: number;
        payRate: string | null;
        bankName: string | null;
        bsb: string | null;
        branch: string | null;
        accountName: string | null;
        accountNumber: string | null;
        nextOfKin: string | null;
        relationship: string | null;
        kinPostcode: string | null;
        kinAddress: string | null;
        kinCountry: string | null;
        kinCity: string | null;
        kinEmail: string | null;
        suburb: string | null;
        kinState: string | null;
        kinPhoneNumber: string | null;
        isDocumentUploaded: boolean;
        isAdmin: boolean;
        adm_DesignationsId: number | null;
        adm_Designations: string | null;
        offerLetter: string | null;
        handbook: string | null;
        superForm: string | null;
        supportPosition: string | null;
        companyId: number;
        company: string | null;
        fullName: string;
        dateCreated: string;
        userCreated: string | null;
        dateModified: string;
        userModified: string | null;
        signatureUrl: string | null,
        signatureFile: string | null,
    };
    profile: {
        profileId: number;
        firstName: string;
        surName: string;
        middleName: string | null;
        clientId: string | null;
        address: string;
        email: string;
        phoneNumber: string;
        gender: string;
        imageUrl: string | null;
        imageFile: string | null;
        country: string;
        state: string | null;
        city: string | null;
        isCompleted: boolean;
        isActive: boolean;
        dateOfBirth: string;
        homePhone: string | null;
        workPhone: string | null;
        profession: string | null;
        culturalBackground: string;
        preferredLanguage: string;
        requireInterpreter: string;
        indigenousSatatus: string;
        ndisPlan: string;
        ndisPlanNote: string;
        privacyPreferences: string;
        financialArrangement: string;
        ndisNo: string | null;
        agreementStartDate: string | null;
        agreementEndDate: string | null;
        nextOfKin: string;
        relationship: string | null;
        kinPostcode: string;
        kinAddress: string | null;
        kinCountry: string;
        kinCity: string | null;
        kinEmail: string;
        suburb: string | null;
        kinState: string | null;
        kinPhoneNumber: string | null;
        companyId: number;
        company: string | null;
        fullName: string;
        dateCreated: string;
        userCreated: string | null;
        dateModified: string;
        userModified: string | null;
    };
}


export interface StaffShiftState {
    loading: boolean;
    data: Array<StaffShift>;
    error: string | undefined;
}
const initialState: StaffShiftState = {
    loading: false,
    data: [],
    error: undefined,
}
export const fetchStaffShift = createAsyncThunk(
    "StaffShift/fetchStaffShift",
    async (uid: number) => {
        try {
            const response = await api.fetchStaffShift(uid);
            return response.shiftRoster;
        } catch (error: unknown) {
            if (isAxiosError(error)) {
                throw error;
            } else {
                throw error;
            }
        }
    }
)


const staffShiftSlice = createSlice({
    name: 'staffShift',
    initialState,
    extraReducers: (builder) => {
        builder.addCase(fetchStaffShift.pending, (state) => {
            state.loading = true;
        });
        builder.addCase(fetchStaffShift.fulfilled, (state, action: PayloadAction<Array<StaffShift>>) => {
            state.loading = false;
            state.data = action.payload;
        });
        builder.addCase(fetchStaffShift.rejected, (state, action) => {
            state.loading = false;
            state.data = [];
            state.error = action.error.message;
        });
    },
    reducers: {}
})
export const userSelector = (state: RootState) => state.staffShift;
export default staffShiftSlice.reducer;



// Define selectors to access the profile slice state
export const staffShiftData = (state: RootState) => state.staffShift.data;
export const staffShiftLoading = (state: RootState) => state.staffShift.loading;
export const staffShiftError = (state: RootState) => state.staffShift.error;