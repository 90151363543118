import React from 'react'
import { Tooltip, Whisper } from 'rsuite';
interface Props {
    label: string;
    name: string | number;
}

const ProfileNameTag = ({ label, name }: Props) => {
    return (
        <div className='text-sm flex gap-2'>
            <span className='text-gray-700 font-bold '>{label}:</span>
            <Whisper placement="top" controlId="control-id-hover" trigger="hover" speaker={
                <Tooltip>
                    {name || "...."}
                </Tooltip>
            }>


                <span className='truncate ...'>{name || "...."}</span>
            </Whisper>
        </div>
    )
}

export default ProfileNameTag