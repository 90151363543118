import React from 'react'
import { Navigate, Route, Routes } from 'react-router'
import ResponsePage from './ResponsePage'


const XeroReportLayout = () => {

    return (
        <div>

            <Routes>
                <Route path="/" element={<Navigate to="/app/report/auth" />} />
                <Route path='/auth' element={<ResponsePage />} />


            </Routes>

        </div>
    )
}

export default XeroReportLayout