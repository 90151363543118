import React, { useRef } from "react";
import ReferralForme from "./__component/ReferralForm";
import PrintComponent from "../PrintTab";
import Breadcrumb from "../../Components/label/BreadCrumb";

const ReferralForm = () => {
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <div>
      <Breadcrumb />
      <PrintComponent
        componentRef={componentRef}
        triggerLabel="Print this Document"
        documentTitle="Refferal Form"
      />
      <div ref={componentRef} className="mx-auto  max-w-3xl p-4 md:p-4 lg:p-8 bg-white">
        <div className="flex justify-end">
          <img
            className="w-24  mb-4 lg:mb-4"
            src="https://profitmax-001-site10.ctempurl.com/Uploads/botosoft-300x183-1-300x183235551864.png"
            alt="Company Logo"
          />
        </div>

        <ReferralForme />
      </div>
    </div>
  );
};

export default ReferralForm;
