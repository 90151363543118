// PaginationComponent.js
import React from 'react';
import ReactPaginate from 'react-paginate';

interface PaginationProps {
    onPageChange: (selectedItem: { selected: number }) => void;
    pageCount: number;
}

const PaginationComponent: React.FC<PaginationProps> = ({ onPageChange, pageCount }) => {
    return (
        <ReactPaginate
            pageCount={pageCount}
            onPageChange={onPageChange}
            activeClassName={'item active'}
            breakClassName={'item break-me'}
            breakLabel={'...'}
            containerClassName={'pagination'}
            disabledClassName={'disabled-page'}
            marginPagesDisplayed={2}
            nextClassName={"item next"}
            nextLabel={"Next +"}
            pageClassName={'item pagination-page'}
            pageRangeDisplayed={2}
            previousClassName={"item previous"}
            previousLabel={"- Previous"}
        />
    );
};

export default PaginationComponent;
