import React from "react";
import MainLink from "./MainLink";
import { RootState } from "../../store/store";
import { useSelector } from "react-redux";
import { TbDisabled } from "react-icons/tb";
import { LiaBandAidSolid } from "react-icons/lia";
import { FaDailymotion } from "react-icons/fa6";
import { MdCalendarViewWeek, MdSupport } from "react-icons/md";
import { TbRibbonHealth, TbCalendarRepeat } from "react-icons/tb";
import { AiOutlineTeam } from 'react-icons/ai';

interface Props {
  open: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ClientLink = ({ open, setSidebarOpen }: Props) => {
  const user = useSelector((state: RootState) => state?.auth?.user);
  return (
    <>

      {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<MdCalendarViewWeek className="text-gray-400 text-lg" />}
          title="My Shift Roster"
          link="/app/client/roster"
          open={open}
        />
      ) : (
        ""
      )}
      {/* {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={
            <svg
              width="18"
              height="18"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 8C11.8978 8 12.2794 8.15804 12.5607 8.43934C12.842 8.72064 13 9.10218 13 9.5V10C13 11.971 11.14 14 8 14C4.86 14 3 11.971 3 10V9.5C3 9.10218 3.15804 8.72064 3.43934 8.43934C3.72064 8.15804 4.10218 8 4.5 8H11.5ZM11.5 9H4.5C4.36739 9 4.24021 9.05268 4.14645 9.14645C4.05268 9.24021 4 9.36739 4 9.5V10C4 11.438 5.432 13 8 13C10.568 13 12 11.438 12 10V9.5C12 9.36739 11.9473 9.24021 11.8536 9.14645C11.7598 9.05268 11.6326 9 11.5 9ZM8 1.5C8.72935 1.5 9.42882 1.78973 9.94454 2.30546C10.4603 2.82118 10.75 3.52065 10.75 4.25C10.75 4.97935 10.4603 5.67882 9.94454 6.19454C9.42882 6.71027 8.72935 7 8 7C7.27065 7 6.57118 6.71027 6.05546 6.19454C5.53973 5.67882 5.25 4.97935 5.25 4.25C5.25 3.52065 5.53973 2.82118 6.05546 2.30546C6.57118 1.78973 7.27065 1.5 8 1.5ZM8 2.5C7.53587 2.5 7.09075 2.68437 6.76256 3.01256C6.43437 3.34075 6.25 3.78587 6.25 4.25C6.25 4.71413 6.43437 5.15925 6.76256 5.48744C7.09075 5.81563 7.53587 6 8 6C8.46413 6 8.90925 5.81563 9.23744 5.48744C9.56563 5.15925 9.75 4.71413 9.75 4.25C9.75 3.78587 9.56563 3.34075 9.23744 3.01256C8.90925 2.68437 8.46413 2.5 8 2.5Z"
                fill="#CCCCCC"
              />
            </svg>
          }
          title="My Profile"
          link="/app/client/profile"
          open={open}
        />
      ) : (
        ""
      )} */}
      {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={
            <TbCalendarRepeat />
          }
          title="Representative"
          link="/app/client/representative"
          open={open}
        />
      ) : (
        ""
      )}

      {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<TbDisabled />}
          title="Disability Support Needs"
          link="/app/client/disability-needs"
          open={open}
        />
      ) : (
        ""
      )}

      {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<LiaBandAidSolid />}
          title="Aids-Equipments"
          link="/app/client/aid-equip"
          open={open}
        />
      ) : (
        ""
      )}

      {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<FaDailymotion />}
          title="Daily Needs & Night Support"
          link="/app/client/daily-living"
          open={open}
        />
      ) : (
        ""
      )}

      {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<TbRibbonHealth />}
          title="Health Support Needs"
          link="/app/client/health-support"
          open={open}
        />
      ) : (
        ""
      )}

      {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<MdSupport />}
          title="Behaviour Support Needs"
          link="/app/client/behaviour-support"
          open={open}
        />
      ) : (
        ""
      )}
      {user?.role === "Client" ? (
        <MainLink
          setSidebarOpen={setSidebarOpen}
          icon={<AiOutlineTeam />}
          title="Community Support Needs"
          link="/app/client/community-support"
          open={open}
        />
      ) : (
        ""
      )}
    </>
  );
};

export default ClientLink;
