import dayjs from "dayjs";
import React from "react";
import {
  MdBook,
  MdDoneOutline,
  MdOutlineCancel,
  MdOutlineEditCalendar,
  MdOutlineRefresh,
  MdOutlineRepeat,
  MdOutlineUndo,
  MdThumbUpOffAlt,
} from "react-icons/md";
import { GoTrash } from "react-icons/go";
import { Shift } from "../../../../../store/slices/shiftRosterSlice";
import DotDropDown from "../../../../../Components/menu/DotDropDown";
import IconButton from "../../../../../Components/Buttons/IconButton";
import { FaRegCopy } from "react-icons/fa";
import { BiMove } from "react-icons/bi";
import ButtonWithDetail from "./ButtonWithDetail";
import { RootState } from "../../../../../store/store";
import { useSelector } from "react-redux";
interface CardProps {
  timeFrom: string;
  timeTo: string;
  staff: string;
  client: string;
  // task: string;
  onEdit: () => void; // Function to call when Edit is clicked
  getActivityStatus(activity: Shift): string;
  activity: Shift;
  handleMarkAttendance: (activity: Shift) => void;
  handleDeleteAttendance: (activity: Shift) => void;
  handleUnmarkAttendance: (activity: Shift) => void;
  handleConfirmAttendance: (activity: Shift) => void;
  handleRejectAttendance: (activity: Shift) => void;
  handleCancelClientAttendance: (activity: Shift) => void;
  handleReAssignAttendance: (activity: Shift) => void;
  handleDuplicateAttendance: (activity: Shift) => void;
  handleMoveAttendance: (activity: Shift) => void;
  hasRequiredClaims: (claimType: string) => boolean;
  handleRepeatAttendance: (activity: Shift) => void;
  isSelected: boolean;
  onSelectShift: (shiftId: number, isSelected: boolean) => void;
  multiple: boolean;
  handleShiftClick: (shift: Shift) => void;
}

const Card = ({
  timeFrom,
  timeTo,
  staff,
  client,
  onEdit,
  activity,
  getActivityStatus,
  handleMarkAttendance,
  handleDeleteAttendance,
  handleUnmarkAttendance,
  handleConfirmAttendance,
  handleRejectAttendance,
  handleCancelClientAttendance,
  handleReAssignAttendance,
  handleDuplicateAttendance,
  handleMoveAttendance,
  hasRequiredClaims,
  isSelected,
  onSelectShift,
  multiple,
  handleRepeatAttendance,
  handleShiftClick,
}: CardProps) => {
  const user = useSelector((state: RootState) => state?.auth?.user);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onSelectShift(activity.shiftRosterId, e.target.checked);
  };

  return (
    <div

      className={`shadow-lg ${activity.status === "Pending"
        ? "bg-secondary"
        : activity.status === "Cancelled"
          ? "bg-[#f62d51] text-white"
          : "bg-white text-black"
        } rounded-lg px-1 py-2 w-full flex flex-col relative `}
    >
      {multiple && (
        <div className="flex justify-end pb-1">
          {getActivityStatus(activity) !== "Present" && (
            <input
              type="checkbox"
              checked={isSelected}
              onChange={handleCheckboxChange}
              className="accent-brand"
            />
          )}
        </div>
      )}

      <DotDropDown>
        <IconButton
          icon={<MdBook className="text-black" />}
          title="View Details"
          onClick={() => handleShiftClick(activity)}
        />
        <IconButton
          icon={<FaRegCopy className="text-black" />}
          title="Duplicate Shift"
          onClick={() => handleDuplicateAttendance(activity)}
        />
        <IconButton
          icon={<BiMove className="text-black" />}
          title="Move Shift"
          onClick={() => handleMoveAttendance(activity)}
        />
        <IconButton
          icon={<MdOutlineRepeat className="text-black" />}
          title="Repeat Shift"
          onClick={() => handleRepeatAttendance(activity)}
        />
      </DotDropDown>
      <small
        title={
          getActivityStatus(activity) === "Present"
            ? "Present"
            : getActivityStatus(activity) === "Absent"
              ? "Absent"
              : "Upcoming"
        }
        className={`absolute bottom-2 w-2 h-2 right-2 items-center text-sm hover:cursor-pointer border  p-1  rounded-full
        ${getActivityStatus(activity) === "Present"
            ? "bg-green-500"
            : getActivityStatus(activity) === "Absent"
              ? "bg-red-500"
              : "bg-secondary"
          }`}
      ></small>
      <span className="text-xs font-bold">
        {dayjs(timeFrom).format("h:mm A")} - {dayjs(timeTo).format("h:mm A")}
      </span>
      <div className="truncate ... ">
        <small className="font-bold">Staff:</small>
        &nbsp;
        <small>{staff}</small>
      </div>
      <div className="truncate ... ">
        <small className="font-bold">Clients:</small>
        &nbsp;
        <small>{client}</small>
      </div>

      {/* <div className="truncate ... ">
        <small className="font-bold">Task:</small>
        &nbsp;
        <small>{task}</small>
      </div> */}

      {!multiple && <>

        {getActivityStatus(activity) === "Active" ? (
          <div className="flex justify-center gap-2 items-center py-1">
            {user?.role === "CompanyAdmin" ||
              user?.role === "Administrator" ||
              hasRequiredClaims("Edit Shift Roster") ? (
              <ButtonWithDetail
                title="Edit Shift Roster"
                buttonContent={
                  <button
                    onClick={onEdit}
                    className="bg-gray-200 p-1 rounded text-black text-xs"
                  >
                    <MdOutlineEditCalendar />
                  </button>
                }
              />
            ) : (
              ""
            )}
            {user?.role === "CompanyAdmin" ||
              user?.role === "Administrator" ||
              hasRequiredClaims("Mark Attendances") ? (
              <ButtonWithDetail
                title="Mark attendance for staff"
                buttonContent={
                  <button className="bg-secondary p-1 rounded text-black text-xs">
                    <MdDoneOutline
                      onClick={() => handleMarkAttendance(activity)}
                    />
                  </button>
                }
              />
            ) : (
              ""
            )}
            {/* Buttons for active activity */}
          </div>
        ) : (
          <div className="flex justify-center gap-2 items-center py-1">
            {getActivityStatus(activity) !== "Present" &&
              (user?.role === "CompanyAdmin" ||
                user?.role === "Administrator" ||
                hasRequiredClaims("Delete Shift Roster")) && (
                <ButtonWithDetail
                  title="Delete Shift"
                  buttonContent={
                    <button
                      className="bg-red-600 p-1 rounded text-white text-xs"
                      onClick={() => handleDeleteAttendance(activity)}
                    >
                      <GoTrash />
                    </button>
                  }
                />
              )}

            {getActivityStatus(activity) === "Upcoming" &&
              activity.status !== "Pending" &&
              activity.status !== "Cancelled" && (
                <div className="flex justify-center gap-2 items-center py-1">
                  {user?.role === "CompanyAdmin" ||
                    user?.role === "Administrator" ||
                    hasRequiredClaims("Edit Shift Roster") ? (
                    <ButtonWithDetail
                      title="Edit Shift Roster"
                      buttonContent={
                        <button
                          onClick={onEdit}
                          className="bg-gray-200 p-1 rounded text-black text-xs"
                        >
                          <MdOutlineEditCalendar />
                        </button>
                      }
                    />
                  ) : (
                    ""
                  )}
                </div>
              )}
            {getActivityStatus(activity) === "Absent" &&
              activity.status !== "Pending" && (
                <ButtonWithDetail
                  title="Mark attendance for staff"
                  buttonContent={
                    <button className="bg-secondary p-1 rounded text-black text-xs">
                      <MdDoneOutline
                        onClick={() => handleMarkAttendance(activity)}
                      />
                    </button>
                  }
                />
              )}
            {activity.isEnded && activity.attendance ? (
              <ButtonWithDetail
                title="Unmark attendance for staff"
                buttonContent={
                  <button className="bg-red-600 p-1 rounded text-white text-xs">
                    <MdOutlineUndo
                      onClick={() => handleUnmarkAttendance(activity)}
                    />
                  </button>
                }
              />
            ) : (
              ""
            )}
            {getActivityStatus(activity) === "Upcoming" &&
              activity.status === "Pending" && (
                <ButtonWithDetail
                  title=" Approve Cancellation of Shift"
                  buttonContent={
                    <button
                      className="bg-secondary p-1 rounded text-white text-xs"
                      onClick={() => handleConfirmAttendance(activity)}
                    >
                      <MdThumbUpOffAlt />
                    </button>
                  }
                />
              )}
            {getActivityStatus(activity) === "Upcoming" &&
              activity.status === "Pending" && (
                <ButtonWithDetail
                  title=" Reject Cancellation of Shift"
                  buttonContent={
                    <button
                      className="bg-red-600 p-1 rounded text-white text-xs"
                      onClick={() => handleRejectAttendance(activity)}
                    >
                      <MdOutlineCancel />
                    </button>
                  }
                />
              )}
            {getActivityStatus(activity) === "Absent" &&
              activity.status === "Pending" && (
                <ButtonWithDetail
                  title=" Approve Cancellation of Shift"
                  buttonContent={
                    <button
                      className="bg-secondary p-1 rounded text-white text-xs"
                      onClick={() => handleConfirmAttendance(activity)}
                    >
                      <MdThumbUpOffAlt />
                    </button>
                  }
                />
              )}

            {getActivityStatus(activity) === "Absent" &&
              activity.status === "Pending" && (
                <ButtonWithDetail
                  title=" Reject Cancellation of Shift"
                  buttonContent={
                    <button
                      className="bg-red-600 p-1 rounded text-white text-xs"
                      onClick={() => handleRejectAttendance(activity)}
                    >
                      <MdOutlineCancel />
                    </button>
                  }
                />
              )}

            {getActivityStatus(activity) === "Present" &&
              activity.status === "Pending" && (
                <ButtonWithDetail
                  title=" Approve Cancellation of Shift"
                  buttonContent={
                    <button
                      className="bg-secondary p-1 rounded text-white text-xs"
                      onClick={() => handleConfirmAttendance(activity)}
                    >
                      <MdThumbUpOffAlt />
                    </button>
                  }
                />
              )}
            {getActivityStatus(activity) === "Present" &&
              activity.status === "Pending" && (
                <ButtonWithDetail
                  title=" Reject Cancellation of Shift"
                  buttonContent={
                    <button
                      className="bg-red-600 p-1 rounded text-white text-xs"
                      onClick={() => handleRejectAttendance(activity)}
                    >
                      <MdOutlineCancel />
                    </button>
                  }
                />
              )}
            {getActivityStatus(activity) === "Absent" &&
              activity.status === "Cancelled" && (
                <ButtonWithDetail
                  title="Re-assign Shift"
                  buttonContent={
                    <button
                      className="bg-primary p-1 rounded text-white text-xs"
                      onClick={() => handleReAssignAttendance(activity)}
                    >
                      <MdOutlineRefresh />
                    </button>
                  }
                />
              )}
            {getActivityStatus(activity) === "Present" &&
              activity.status === "Cancelled" && (
                <ButtonWithDetail
                  title="Re-assign Shift"
                  buttonContent={
                    <button
                      className="bg-primary p-1 rounded text-white text-xs"
                      onClick={() => handleReAssignAttendance(activity)}
                    >
                      <MdOutlineRefresh />
                    </button>
                  }
                />
              )}
            {getActivityStatus(activity) === "Upcoming" &&
              activity.status === "Cancelled" && (
                <ButtonWithDetail
                  title="Re-assign Shift"
                  buttonContent={
                    <button
                      className="bg-primary p-1 rounded text-white text-xs"
                      onClick={() => handleReAssignAttendance(activity)}
                    >
                      <MdOutlineRefresh />
                    </button>
                  }
                />
              )}
            {getActivityStatus(activity) === "Upcoming" &&
              activity.status !== "Pending" && (
                <ButtonWithDetail
                  title="Cancel Client Shift"
                  buttonContent={
                    <button
                      className="bg-gray-300 p-1 rounded text-red-600 text-xs"
                      onClick={() => handleCancelClientAttendance(activity)}
                    >
                      <MdOutlineCancel />
                    </button>
                  }
                />
              )}
            {getActivityStatus(activity) === "Absent" &&
              activity.status !== "Pending" && (
                <ButtonWithDetail
                  title="Cancel Client Shift"
                  buttonContent={
                    <button
                      className="bg-gray-300 p-1 rounded text-red-600 text-xs"
                      onClick={() => handleCancelClientAttendance(activity)}
                    >
                      <MdOutlineCancel />
                    </button>
                  }
                />
              )}

            {getActivityStatus(activity) === "Upcoming" && (
              <div className="flex justify-end absolute bottom-0 left-0">
                <span className="text-[7px] font-normal bg-secondary rounded-es-md rounded-se-md p-0.5">
                  Upcoming
                </span>
              </div>
            )}
          </div>
        )}
      </>
      }
      {/* Modal section */}
    </div>
  );
};

export default Card;
