import { isAxiosError } from "axios";
import { toast } from "react-toastify";
import axiosInstance from "./axiosInstance";

const fetchAdminData = async (companyId: string | number) => {
    try {
        const response = await axiosInstance.get(`/Administrators?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const filterAdminData = async (companyId: number, status: string) => {
    try {
        const response = await axiosInstance.get(`/Administrators/get_active_admin?companyId=${companyId}&IsActive=${status}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchClientData = async (companyId: string | number) => {
    try {
        const response = await axiosInstance.get(`/Profiles?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const filterClientData = async (companyId: number, status: string) => {
    try {
        const response = await axiosInstance.get(`/Profiles/get_active_clients?companyId=${companyId}&IsActive=${status}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffData = async (companyId: string | number) => {
    try {
        const response = await axiosInstance.get(`/Staffs?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const filterStaffData = async (companyId: number, status: string) => {
    try {
        const response = await axiosInstance.get(`/Staffs/get_active_staffs?companyId=${companyId}&IsActive=${status}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchShiftRoaster = async (companyId: string | number) => {
    try {
        const response = await axiosInstance.get(`/ShiftRosters/get_all_shift_rosters?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

// const filterShiftRoster = async (companyId: number, dateFrom: string, dateTo: string, staff: string, client: string) => {
//     try {
//         const response = await axiosInstance.get(`/ShiftRosters/get_periodic_shift_rosters?fromDate=${dateFrom}&toDate=${dateTo}&staffId=${staff}&clientId=${client}&companyId=${companyId}`);
//         return response.data;
//     } catch (error: unknown) {
//         if (isAxiosError(error)) {
//             throw error;
//         }
//         throw error;
//     }
// };
const filterShiftRoster = (
    companyId: number,
    dateFrom: string,
    dateTo: string,
    staff: string | number, // Accepts string or number
    client: string | number // Accepts string or number
) => {
    // Construct query parameters based on conditions
    let queryParams = `fromDate=${dateFrom}&toDate=${dateTo}&companyId=${companyId}`;

    if (staff !== '' && staff !== null && staff !== undefined && staff !== '0' && staff !== 0) {
        queryParams += `&staffId=${staff}`;
    }

    if (client !== '' && client !== null && client !== undefined && client !== '0' && client !== 0) {
        queryParams += `&clientId=${client}`;
    }

    return axiosInstance.get(`/ShiftRosters/get_periodic_shift_rosters?${queryParams}`)
        .then(response => response.data)
        .catch(error => {
            throw error; // Rethrow the error if needed
        });
};

const fetchTicket = async (companyId: string | number) => {
    try {
        const response = await axiosInstance.get(`/Tickets/get_all_tickets?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchDocumentData = async (companyId: string | number) => {
    try {
        const response = await axiosInstance.get(`/Documents/get_all_documents?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const filterDocumentData = async (companyId: string | number, dateFrom: string, dateTo: string, sta: string, admin: string, status: string, role: string, archive: boolean) => {
    try {
        const response = await axiosInstance.get(`/Documents/filter_documents?companyId=${companyId}&fromDate=${dateFrom}&toDate=${dateTo}&staff=${sta}&admin=${admin}&status=${status}&role=${role}&isArchive=${archive}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchChartData = async (value: string, companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Attendances/staff_duration_chart?period=${value}&companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchInboxData = async (user: string) => {
    try {
        const response = await axiosInstance.get(`/Messages/inbox?userId=${user}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchIntegration = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Integrations/get_integrations?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchTimePeriod = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/TimePeriods/get_timeperiod?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchHoliday = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/SetUp/get_public_holidays?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchFormTemplate = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Templates/get_templates?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchSupportType = async () => {

    try {
        const response = await axiosInstance.get(`/Invoice/get_all_support_type`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchSupportSchedule = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Invoice/get_all_support_schedules?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchUser = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Account/get_all_users?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchActivity = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Account/activity_logs?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const filterActivityLogs = async (companyId: number, fromDate: string, toDate: string, user: string) => {
    try {
        const response = await axiosInstance.post(`/Account/get_periodic_activities?companyId=${companyId}&fromDate=${fromDate}&toDate=${toDate}&username=${user}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchRefferal = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/ClientReferrals/get_client_referrals?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchServiceProvider = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/ClientReferrals/get_service_providers?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchCareManager = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/ClientCareManagers/get_all_users?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchRole = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Account/get_roles?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const fetchTimeSheet = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Attendances/get_all_attendances_by_company?companyId=${companyId}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchAllTimeSheet = async (user: string, dateFrom: string, dateTo: string) => {
    try {
        const response = await axiosInstance.get(`/Attendances/generate_all_staff_timesheet?userId=${user}&fromDate=${dateFrom}&toDate=${dateTo}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffTimeSheet = async (user: string, staff: string, dateFrom: string, dateTo: string) => {
    try {
        const response = await axiosInstance.get(`/Attendances/generate_staff_timesheet?userId=${user}&staffid=${staff}&fromDate=${dateFrom}&toDate=${dateTo}`);

        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};

const filterTimeSheetData = async (company: number, fromDate: string, toDate: string, staff: string) => {
    try {
        const response = await axiosInstance.get(`/Attendances/get_periodic_attendances_by_company?fromDate=${fromDate}&toDate=${toDate}&staffId=${staff}&companyId=${company}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchsplittedAttendance = async (value: number | string) => {
    try {
        const response = await axiosInstance.get(`/Attendances/get_split_attendances?attendanceId=${value}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchShiftAttendance = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Attendances/get_shift_attendance?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchAllStaffAttendances = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/StaffAttendances/get_all_staff_attendances?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchAllAdminAttendances = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/AdminAttendances/get_admin_attendance?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchShiftReport = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/ShiftReports/get_all_shiftreports?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffShiftReport = async (staffId: number) => {
    try {
        const response = await axiosInstance.get(`/ShiftReports/get_staff_shiftreports?staffId=${staffId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};


const filterShiftAttendance = async (companyId: number, dateFrom: string, dateTo: string, staff: string, client: string, type: string) => {
    try {
        const response = await axiosInstance.get(`/Attendances/get_periodic_shift_attendnace?companyId=${companyId}&fromDate=${dateFrom}&toDate=${dateTo}&staffId=${staff}&clientId=${client}&shifttype=${type}`);
        toast.success(response.data.status)
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchProgressNote = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/ProgressNotes/get_all_progressnote_by_company?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const filterProgressNote = async (staff: string, client: string) => {
    try {
        const response = await axiosInstance.get(`/ProgressNotes/get_progressnote_by_user?staffname=${staff}&profileId=${client}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchServiceProviderClient = async (uid: string) => {
    try {
        const response = await axiosInstance.get(`/ClientReferrals/get_referral_clients?email=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchClientSchedule = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/ClientSchedules/get_client_schedule?clientId=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchClientRepresentative = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/Assistances/get_all?clientId=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchClientSupportSchedule = async (id: number) => {
    try {
        const response = await axiosInstance.get(`/Invoice/get_client_support_schedules?clientId=${id}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};


const fetchClientShift = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/ShiftRosters/get_shifts_by_user?client=${uid}&staff=`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchClientProgressNote = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/ProgressNotes/get_progressnote_by_user?staffname=&profileId=${uid}&staffId=`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchClientDailyLiving = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/DailyLivings/get_all?clientId=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchClientAidAndEquip = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/Aids/get_client_aids?clientId=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchSavedInvoice = async (companyId: number) => {
    try {
        const response = await axiosInstance.get(`/Invoice/list_dategroup_invoices?companyId=${companyId}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffShift = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/ShiftRosters/get_shifts_by_user?client=&staff=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffAttendance = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/Attendances/get_staff_attendances?staffId=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffAvailability = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/StaffAvailibilities/get_staff_availabilities?staffId=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffDailyReport = async (uid: number) => {
    try {
        const response = await axiosInstance.get(`/StaffAttendances/get_staff_attendances?staffId=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffProgressNote = async (fName: string) => {
    try {
        const response = await axiosInstance.get(`/ProgressNotes/get_progressnote_by_user?staffname=${fName}&profileId=`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchUserTicket = async (uid: string) => {
    try {
        const response = await axiosInstance.get(`/Tickets/get_user_tickets?userId=${uid}`);
        return response.data;
    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
};
const fetchStaffInfo = async (uid: string | undefined) => {
    try {

        const { data } = await axiosInstance.get(`/Staffs/${uid}`)
        return data || null


    } catch (error: unknown) {
        if (isAxiosError(error)) {
            throw error;
        }
        throw error;
    }
}



const api = {
    fetchAdminData,
    fetchClientData,
    fetchStaffData,
    fetchShiftRoaster,
    filterShiftRoster,
    fetchTicket,
    fetchDocumentData,
    filterDocumentData,
    fetchChartData,
    fetchInboxData,
    fetchIntegration,
    fetchTimePeriod,
    fetchHoliday,
    fetchFormTemplate,
    fetchSupportType,
    fetchSupportSchedule,
    fetchUser,
    fetchActivity,
    fetchRefferal,
    fetchServiceProvider,
    fetchCareManager,
    filterActivityLogs,
    filterAdminData,
    filterStaffData,
    filterClientData,
    fetchRole,
    fetchTimeSheet,
    fetchAllTimeSheet,
    fetchStaffTimeSheet,
    filterTimeSheetData,
    fetchsplittedAttendance,
    fetchShiftAttendance,
    fetchProgressNote,
    filterProgressNote,
    filterShiftAttendance,
    fetchClientSchedule,
    fetchClientShift,
    fetchClientProgressNote,
    fetchClientDailyLiving,
    fetchClientAidAndEquip,
    fetchSavedInvoice,
    fetchStaffShift,
    fetchStaffProgressNote,
    fetchStaffAvailability,
    fetchStaffAttendance,
    fetchAllStaffAttendances,
    fetchAllAdminAttendances,
    fetchUserTicket,
    fetchStaffInfo,
    fetchShiftReport,
    fetchStaffShiftReport,
    fetchClientSupportSchedule,
    fetchStaffDailyReport,
    fetchServiceProviderClient,
    fetchClientRepresentative
}

export default api
