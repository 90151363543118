import React, { FormEvent, useEffect, useState } from "react";
import SEO from "../../../constants/SEO";
import man from "../../../images/user/man.png";
import { Avatar, Modal } from "rsuite";
import axiosInstance from "../../../store/axiosInstance";
import FallBack from "../../../Pages/FallBack";
import Spinner from "../../../Components/Spinner";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "../../../store/store";
import { fetchCareManager } from "../../../store/slices/careManagerSlice";
import { fetchServiceProvider } from "../../../store/slices/serviceProviderSlice";
import Breadcrumb from "../../../Components/label/BreadCrumb";
import dayjs from "dayjs";
import EditDropDown from "../../../Components/menu/EditDropDown";
import { ClientDataRow } from "../../../types/AuthData";
import CustomInput from "../../../Components/Input/CustomInput";
import CardHeader from "../../Admin/Profiles/components/CardHeader";
import CardNameTag from "../../Admin/Profiles/components/CardNameTag";
import WebcamCapture from "../../Admin/Profiles/components/CapturePicture";
import ClientEmergComp from "../../Admin/Profiles/components/ClientEmergComp";
import ClientEditComp from "../../Admin/Profiles/components/ClientEditComp";
import { Client } from "../../../store/slices/clientSlice";
import ProfileNameTag from "../../Admin/Profiles/components/ProfileNameTag";
import ClientDocument from "../../Admin/Profiles/clientTab/Document";
import ClientSchedule from "../../Admin/Profiles/clientTab/Schedule";

const ClientProfile = () => {
  const clientProfileString = sessionStorage.getItem("clientProfile");
  const clientProfile: Client = clientProfileString
    ? JSON.parse(clientProfileString)
    : null;
  const [loading, setLoading] = useState<boolean>(false);
  useState<boolean>(false);
  const user = useSelector((state: RootState) => state?.auth?.user);

  const [editedProfile, setEditedProfile] = useState<ClientDataRow>({
    address: "",
    city: "",
    clientId: 0,
    contactId: "",
    country: "",
    email: "",
    firstName: "",
    gender: "",
    imageFile: "",
    imageUrl: "",
    middleName: "",
    phoneNumber: "",
    profileId: 0,
    state: "",
    surName: "",
    ndisNo: "",
    referral: "",
    careManager: "",
    isActive: false,
    auditApproved: false,
    xerolink: "",
    culturalBackground: "",
    dateOfBirth: "",
    financialArrangement: "",
    fullName: "",
    indigenousSatatus: "",
    kinAddress: "",
    kinCity: "",
    kinCountry: "",
    kinEmail: "",
    kinSuburb: "",
    kinPhoneNumber: "",
    kinPostcode: "",
    kinState: "",
    ndisPlan: "",
    nextOfKin: "",
    preferredLanguage: "",
    privacyPreferences: "",
    relationship: "",
    requireInterpreter: false,
    suburb: "",
    agreementEndDate: "",
    agreementStartDate: "",
  });
  const companyId = user?.companyId;
  const dispatch: AppDispatch = useAppDispatch();
  useEffect(() => {
    if (companyId) {
      dispatch(fetchCareManager(companyId));
      dispatch(fetchServiceProvider(companyId));
    }
  }, [dispatch, companyId]);

  const FetchClient = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(
        `/Profiles/${clientProfile.profileId}`
      );

      setEditedProfile({ ...data });
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    FetchClient();
  }, []);

  const handleInputChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = e.target;
    setEditedProfile((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [loading1, setLoading1] = useState<boolean>(false);
  const [isCameraModalOpen, setCameraModalOpen] = useState(false);
  const [isFileModalOpen, setFileModalOpen] = useState(false);
  const [photoModal, setPhotoModal] = useState(false);
  const [personalInfoModal, setPersonalInfoModal] = useState<boolean>(false);
  const [emergencyModal, setEmergencyModal] = useState<boolean>(false);
  const [upLoadPic, setUpLoadPic] = useState<File | null>(null);

  const handleCapture = async (imageBlob: Blob | null) => {
    if (imageBlob) {
      // Convert Blob to File
      const imageFile = new File([imageBlob], "captured-image.jpg", {
        type: imageBlob.type, // Preserving the MIME type
        lastModified: Date.now(), // Setting last modification time
      });

      if (imageFile) {
        setLoading1(true);
        const formData = new FormData();

        if (imageFile) {
          formData.append("imageFile", imageFile);
        }
        for (const key in editedProfile) {
          const value = editedProfile[key as keyof typeof editedProfile];
          if (value === null) {
            formData.append(key, ""); // Pass empty string if value is null
          } else {
            formData.append(key, value.toString());
          }
        }

        try {
          const { data } = await axiosInstance.post(
            `/Profiles/edit/${clientProfile.clientId}?userId=${user?.uid}`,
            formData
          );
          toast.success(data.message);
          setLoading1(false);
          setCameraModalOpen(false);
          FetchClient();
        } catch (error: unknown) {
          if (isAxiosError(error)) {
            toast.error(error.response?.data?.message);
            toast.error(error.response?.data?.title);
            // throw error;
          } else {
            toast.error("An error occurred");
          }
          setLoading1(false);
        } finally {
          setLoading1(false);
        }
      }

      // Close modal
    } else {
      console.log("No image captured");
      setCameraModalOpen(false);
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setUpLoadPic(file);
    }
  };
  // const handleCapture = async (imageBlob: Blob | null) => {
  //     if (imageBlob) {
  //         const reader = new FileReader();
  //         reader.readAsDataURL(imageBlob);
  //         reader.onloadend = () => {
  //             console.log("Captured image", reader.result);
  //             // setImageData(reader.result)
  //             // Do something with the base64 string (reader.result)
  //             setCameraModalOpen(false);  // Close modal after receiving the image
  //         };
  //     } else {
  //         console.log("No image captured");
  //         setCameraModalOpen(false);
  //     }
  // };

  const submitData = async (e?: FormEvent) => {
    e?.preventDefault();
    setLoading1(true);
    const formData = new FormData();

    if (upLoadPic) {
      formData.append("imageFile", upLoadPic);
    }

    for (const key in editedProfile) {
      const value = editedProfile[key as keyof typeof editedProfile];
      if (value === null) {
        formData.append(key, ""); // Pass empty string if value is null
      } else {
        formData.append(key, value.toString());
      }
    }

    try {
      const { data } = await axiosInstance.post(
        `/Profiles/edit/${clientProfile.profileId}?userId=${user?.uid}`,
        formData
      );
      toast.success(data.message);
      setLoading1(false);
      setPersonalInfoModal(false);
      setFileModalOpen(false);
      setEmergencyModal(false);
      FetchClient();
    } catch (error: unknown) {
      if (isAxiosError(error)) {
        toast.error(error.response?.data?.message);
        toast.error(error.response?.data?.title);
        // throw error;
      } else {
        toast.error("An error occurred");
      }
      setLoading1(false);
    } finally {
      setLoading1(false);
    }
  };

  const [activeTab, setActiveTab] = useState<string>("Documents");
  const handleTab = (tab: string) => {
    setActiveTab(tab);
  };

  const tabsArray: string[] = [
    "Documents",
    "Schedule",
  ];

  return (
    <>
      <SEO title="Clients" description="Client" name="Promax-Care" />
      <div className="flex justify-between items-center flex-wrap">
        <Breadcrumb />
        <h1 className="font-bold text-black text-2xl">Client</h1>
      </div>

      <div>
        {loading ? (
          <FallBack />
        ) : (
          <div className="space-y-4 py-12">
            <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:grid-cols-4">
              <div className="border-2 rounded-lg">
                <CardHeader label="Profile" />
                <div className=" space-y-2 py-4">
                  <div className="flex flex-col gap-2 justify-center items-center">
                    <Avatar
                      size="lg"
                      circle
                      src={editedProfile.imageUrl || man}
                      alt="@simonguo"
                      style={{ marginTop: 8, cursor: "pointer" }}
                      onClick={() => setPhotoModal(true)}
                    />
                    <EditDropDown>
                      <div className="z-[999] w-max rounded bg-white  p-3 text-xs shadow-xl flex flex-col gap-2 shadow-shadow-500 dark:!bg-navy-700 dark:shadow-none">
                        <button
                          onClick={() => setCameraModalOpen(true)}
                          className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                          Take Photo
                        </button>

                        <button
                          onClick={() => setFileModalOpen(true)}
                          className="flex justify-between items-center px-4 py-2 border rounded border-brand bg-gray-100 text-brand no-underline hover:no-underline"
                        >
                          Choose Photo
                        </button>
                      </div>
                    </EditDropDown>
                    <p className="font-bold text-sm text-black">
                      {editedProfile.fullName}
                    </p>
                  </div>
                  {/* <Avatar size="xxl" circle /> */}
                  <div className="px-4 space-y-2">
                    <ProfileNameTag
                      label="Client ID"
                      name={editedProfile.contactId}
                    />
                    <ProfileNameTag
                      label="NDIS No"
                      name={editedProfile.ndisNo}
                    />
                    <ProfileNameTag
                      label="Referral"
                      name={editedProfile.referral}
                    />
                    <ProfileNameTag
                      label="Care Manager"
                      name={editedProfile.careManager}
                    />
                  </div>
                </div>
              </div>
              <div className=" lg:col-span-2 space-y-2 border-2 rounded-lg">
                <CardHeader
                  label="Emergency Contact"
                  showEditIcon
                  onEdit={() => setEmergencyModal(true)}
                />

                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 p-4">
                  <CardNameTag label="Name" name={editedProfile.nextOfKin} />
                  <CardNameTag
                    label="Phone"
                    name={editedProfile.kinPhoneNumber}
                  />
                  <CardNameTag label="City" name={editedProfile.kinCity} />
                  <CardNameTag
                    label="Relationship"
                    name={editedProfile.relationship}
                  />
                  <CardNameTag
                    label="Country"
                    name={editedProfile.kinCountry}
                  />
                  <CardNameTag
                    label="Postal Code"
                    name={editedProfile.kinPostcode}
                  />
                  <CardNameTag label="Email" name={editedProfile.kinEmail} />
                  <CardNameTag label="State" name={editedProfile.kinState} />

                  <div className="grid grid-cols-1  gap-4 lg:grid-cols-3 lg:col-span-3 border-t-4 py-2">
                    <CardNameTag
                      label="Agreement Start Date"
                      name={dayjs(editedProfile.agreementStartDate).format(
                        "MMM D, YYYY"
                      )}
                    />
                    <CardNameTag
                      label="Agreement End Date"
                      name={dayjs(editedProfile.agreementEndDate).format(
                        "MMM D, YYYY"
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className="border-2 rounded-lg">
                <CardHeader
                  label="Personal Information"
                  showEditIcon
                  onEdit={() => setPersonalInfoModal(true)}
                />
                <div className="grid grid-cols-1 gap-4 md:grid-cols-2 p-2">
                  <CardNameTag label="Phone" name={editedProfile.phoneNumber} />
                  <CardNameTag label="Email" name={editedProfile.email} />
                  <CardNameTag label="Gender" name={editedProfile.gender} />
                  <CardNameTag
                    label="Date Of Birth"
                    name={dayjs(editedProfile.dateOfBirth).format("DD-MM-YYYY")}
                  />
                  <CardNameTag
                    label="Nationality"
                    name={editedProfile.country}
                  />
                  <CardNameTag label="State" name={editedProfile.state} />
                  <CardNameTag label="City" name={editedProfile.city} />
                  <CardNameTag label="Address" name={editedProfile.address} />
                  <CardNameTag label="Suburb" name={editedProfile.suburb} />
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <div className="flex gap-2 items-center horizontal-scroll scroll-smoothscrollbar-hide  border-t border-b py-4 px-4 md:px-0">
        {tabsArray.map((category, index) => (
          <button
            key={index}
            className={`${activeTab === category
              ? "bg-gray-500 text-white"
              : "border-gray-400 border"
              }  rounded text-xs p-2 whitespace-nowrap`}
            onClick={() => handleTab(category)}
          >
            {category}
          </button>
        ))}
      </div>

      {activeTab === "Documents" && clientProfile.profileId !== undefined && <ClientDocument uid={clientProfile.profileId.toString()} />}
      {activeTab === "Schedule" && clientProfile.profileId !== undefined && (
        <ClientSchedule uid={clientProfile.profileId.toString()} />
      )}

      <Modal
        open={photoModal}
        onClose={() => setPhotoModal(false)}
        role="alertdialog"
        autoFocus
        size={"xs"}
      >
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <div className="w-56 h-56 overflow-hidden mx-auto">
            <img
              src={editedProfile.imageUrl || man}
              alt=""
              className="w-full h-full object-cover"
            />
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        open={isCameraModalOpen}
        onClose={() => setCameraModalOpen(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Take photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WebcamCapture onCapture={handleCapture} loading={loading1} />
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
      <Modal
        open={isFileModalOpen}
        onClose={() => setFileModalOpen(false)}
        role="alertdialog"
        backdrop="static"
        autoFocus
        size={"xs"}
      >
        <Modal.Header>
          <Modal.Title className="font-bold">Upload photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <CustomInput
            label=""
            type="file"
            accept="image/jpeg, image/png"
            onChange={handleFileChange}
          />

          <div className="mx-auto text-center mt-3">
            <button
              type="submit"
              onClick={submitData}
              disabled={loading ? true : false}
              className="text-white bg-primary hover:bg-primary focus:ring-4 focus:ring-primary 
font-medium rounded-md text-md px-12 py-2.5 me-2 mt-2 dark:bg-primary dark:hover:bg-primary focus:outline-none
dark:focus:ring-primary"
            >
              {loading1 ? <Spinner /> : "Upload"}
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>

      <ClientEditComp
        personalInfoModal={personalInfoModal}
        setPersonalInfoModal={setPersonalInfoModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
      <ClientEmergComp
        emergencyModal={emergencyModal}
        setEmergencyModal={setEmergencyModal}
        editedProfile={editedProfile}
        handleInputChange={handleInputChange}
        submitData={submitData}
        loading1={loading1}
      />
    </>
  );
};

export default ClientProfile;
