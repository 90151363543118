import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import api from "../api";

interface Chart {
    duration: number;
    staffName: string;
}

export interface ChartState {
    loading: boolean;
    data: Chart[];
    error: string | undefined;
}

const initialState: ChartState = {
    loading: false,
    data: [],
    error: undefined,
};

export const fetchChartData = createAsyncThunk(
    "chart/fetchChartData",
    async ({ value, companyId }: { value: string; companyId: number }) => {

        const response = await api.fetchChartData(value, companyId);
        return response.chart_info;

    }
);

const chartSlice = createSlice({
    name: 'chart',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchChartData.pending, (state) => {
                state.loading = true;
                state.error = undefined; // Reset error state
            })
            .addCase(fetchChartData.fulfilled, (state, action: PayloadAction<Chart[]>) => {
                state.loading = false;
                state.data = action.payload;
            })
            .addCase(fetchChartData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message ?? "An error occurred"; // Set error message
                state.data = []; // Reset data
            });
    },
});

export const chartData = (state: RootState) => state.chart.data;
export const chartLoading = (state: RootState) => state.chart.loading;
export const chartError = (state: RootState) => state.chart.error;

export default chartSlice.reducer;
